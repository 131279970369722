export default {
  "facility-groups.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門設施與服務"])},
  "facility.24hours_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時接待入住"])},
  "facility.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可代收包裹"])},
  "facility.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可代收外送"])},
  "facility.laundrydelivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣物送洗服務"])},
  "facility.entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娛樂設備租借"])},
  "facility.3c_peripheral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3C週邊租借"])},
  "facility.non-smokinghotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸飯店"])},
  "facility-groups.counter_and_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["櫃台與服務"])},
  "facility.24hours_counter_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時櫃檯服務"])},
  "facility.24hours_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時送餐服務"])},
  "facility.luggage_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行李寄放服務"])},
  "facility.master_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["master刷卡服務"])},
  "facility.visa_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visa刷卡服務"])},
  "facility.electronic_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子支付服務"])},
  "facility.personal_checkincheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專人辦理入住/退房"])},
  "facility.ondutybutler_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值班管家服務"])},
  "facility.foreign_currency_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外幣兌換"])},
  "facility.mail_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵寄服務"])},
  "facility.security_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保險箱租借"])},
  "facility.ticket_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票券代售服務"])},
  "facility.morning_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning call服務"])},
  "facility-groups.internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網路"])},
  "facility.public_free_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共區域免費上網"])},
  "facility.free_wifi_forallrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有房型免費Ｗi-Fi"])},
  "facility.paid_internetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費上網服務"])},
  "facility.portable_wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攜帶型ＷiFi租借"])},
  "facility-groups.leisure_and_ent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休閒娛樂"])},
  "facility.gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健身房"])},
  "facility.videogame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電動遊戲室"])},
  "facility.cinema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電影院"])},
  "facility.karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡拉ＯＫ"])},
  "facility.steamroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸氣室"])},
  "facility.hotspring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溫泉"])},
  "facility.sauna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三溫暖"])},
  "facility.hairdressingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美髮服務"])},
  "facility.beautyservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美容服務"])},
  "facility.spa-massageservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPA按摩服務"])},
  "facility.massagechair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按摩椅"])},
  "facility.indoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["室內游泳池"])},
  "facility.outdoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戶外游泳池"])},
  "facility.healthpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康步道"])},
  "facility.forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["森林浴"])},
  "facility-groups.catering_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐飲服務"])},
  "facility.restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐廳"])},
  "facility.barbecue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烤肉區"])},
  "facility.roomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客房餐飲服務"])},
  "facility.bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧"])},
  "facility.cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咖啡廳"])},
  "facility.openkitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開放式廚房"])},
  "facility.oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烤箱"])},
  "facility.weddingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["婚宴服務"])},
  "facility.snack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費迎賓茶點"])},
  "facility.publicdrinkingfountain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共飲水機"])},
  "facility-groups.complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綜合設施"])},
  "facility.barrier-freefacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無障礙設施"])},
  "facility.elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電梯"])},
  "facility.atm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動提款機"])},
  "facility.conveniencestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附近有便利商店"])},
  "facility.smokingzone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吸菸區"])},
  "facility.smokingroomtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有吸菸客房"])},
  "facility.giftshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禮品店"])},
  "facility.businesscenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商務中心"])},
  "facility.meetingroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會議室"])},
  "facility.faxmachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳真機"])},
  "facility.printer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印表機"])},
  "facility.freenewspaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費報紙"])},
  "facility.terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["露台"])},
  "facility.laundryroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自助洗衣房"])},
  "facility.infirmary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["醫務室"])},
  "facility.locker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄物櫃"])},
  "facility.banquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宴會廳"])},
  "facility.saloon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交誼廳"])},
  "facility.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖書館"])},
  "facility-groups.language_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言服務"])},
  "facility.chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "facility.english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文"])},
  "facility.japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日文"])},
  "facility.korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓文"])},
  "facility-groups.health_and_safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衛生與安全"])},
  "facility.dailycleaningdisinfection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日清潔消毒"])},
  "facility.takeoutthegarbage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日房外收取垃圾"])},
  "facility.disinfectromm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客入住前客房消毒"])},
  "facility.thermometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客及員工體溫測量"])},
  "facility.wearmasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員工佩戴口罩與面罩"])},
  "facility.firstaidkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["急救箱"])},
  "facility.24hoursecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時保全"])},
  "facility.fireextinguisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滅火器"])},
  "facility.sprinklerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灑水設備"])},
  "facility.smokedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["煙霧偵測器"])},
  "facility.carbonmonoxidedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一氧化碳偵測器"])},
  "facility.cctvmonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCTV監視器"])},
  "facility.aed-automatedexternaldefibrillator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AED心臟去顫器"])},
  "facility-groups.transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通與行程"])},
  "facility.freeairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費機場接送"])},
  "facility.paidairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費機場接送"])},
  "facility.valetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代客叫車服務"])},
  "facility.reservationparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停車位事先預約"])},
  "facility.freeparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費停車位（數量有限）"])},
  "facility.paidparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費停車位"])},
  "facility.bicyclerental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自行車租借"])},
  "facility.charteredtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包車旅遊行程"])},
  "facility.traveladvisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅遊諮詢"])},
  "facility.electricvehiclechargingstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電動車充電站"])},
  "facility.playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童遊樂場"])},
  "facility.kids_meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童餐"])},
  "facility.kids_cutlery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童餐具"])},
  "facility.kids_paddling_pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童戲水池"])},
  "facility.ball_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["球池"])},
  "facility.slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溜滑梯"])},
  "facility.kids_books_or_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童書籍或影音"])},
  "facility.family_tour_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["親子旅遊行程"])},
  "facility.crib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嬰兒床"])},
  "facility.baby_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嬰兒澡盆"])},
  "facility.feeding_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供奶瓶消毒"])},
  "facility.baby_stroller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嬰兒推車"])},
  "facility.pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物"])},
  "facility.no_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許攜帶寵物"])},
  "facility.pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可帶寵物"])},
  "facility.pets_allowed_in_the_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物可入房"])},
  "facility.small_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可攜帶小型寵物"])},
  "facility.large_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可攜帶大型寵物"])},
  "facility.pets_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供寵物餐"])},
  "facility.pets_snacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供寵物零食"])},
  "facility.pets_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物旅館"])},
  "facility-groups.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型特色"])},
  "facility.roomsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大坪數"])},
  "facility.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可開窗"])},
  "facility.non-smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸房"])},
  "facility.smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吸菸房"])},
  "facility.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴缸"])},
  "facility.onlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["線上影音平台"])},
  "facility.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淋浴設備"])},
  "facility.showerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淋浴設備"])},
  "facility.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共衛浴"])},
  "facility.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床型"])},
  "facility.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大窗"])},
  "facility.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小窗"])},
  "facility.airlight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氣窗"])},
  "facility.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["落地窗"])},
  "facility.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有陽台"])},
  "facility.king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一大床"])},
  "facility.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩大床"])},
  "facility.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一小床"])},
  "facility.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩小床"])},
  "facility.bunk_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上下舖"])},
  "facility.king_and_single_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一大床一小床"])},
  "facility.king_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一大床兩小床"])},
  "facility.four_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四小床"])},
  "facility.three_king_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三大床"])},
  "facility.three_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三小床"])},
  "facility.king_bed_or_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一大床或兩小床"])},
  "facility.double_king_bed_and_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩大床一小床"])},
  "facility.double_king_bed_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩大床兩小床"])},
  "facility.king_and_mattress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一大床加床墊"])},
  "facility.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩中床"])},
  "facility.queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一中床"])},
  "facility.double_bed_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兩小床（合併）"])},
  "facility-groups.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型設施"])},
  "facility.officedeskschairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辦公桌椅"])},
  "facility.readinglight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閱讀燈"])},
  "facility.sofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙發"])},
  "facility.indoorslippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["室內用拖鞋"])},
  "facility.roommagnetickeycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客房門卡片"])},
  "facility.roomkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客房門鑰匙"])},
  "facility.airconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冷氣機"])},
  "facility.heater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖氣機"])},
  "facility.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "facility-groups.bathroom_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衛浴設備"])},
  "facility.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免治馬桶"])},
  "facility.singlebathtub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單人浴缸"])},
  "facility.jacuzzi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按摩浴缸"])},
  "facility.dryandwetseparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乾溼分離"])},
  "facility.largetowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大毛巾"])},
  "facility.smalltowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小毛巾"])},
  "facility.toothbrushtoothpaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牙刷牙膏"])},
  "facility.hairdryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吹風機"])},
  "facility.bodywash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沐浴乳"])},
  "facility.shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗髮乳"])},
  "facility.hairconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潤髮乳"])},
  "facility.facewash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗面乳"])},
  "facility.razor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刮鬍刀"])},
  "facility.soap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肥皂"])},
  "facility.showercap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴帽"])},
  "facility.comb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梳子"])},
  "facility.bathrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴袍"])},
  "facility.cottonswab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["棉花棒"])},
  "facility-groups.ent_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娛樂設備"])},
  "facility.freeonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費線上影音平台"])},
  "facility.paidonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費線上影音平台"])},
  "facility.entertainmentequipmentrental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運動娛樂設備租借"])},
  "facility.paidwi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費Ｗi-Fi"])},
  "facility.freewi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費Ｗi-Fi"])},
  "facility.paytv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費電視"])},
  "facility.freetv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費電視"])},
  "facility-groups.catering_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐飲設備"])},
  "facility.kettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱水瓶/電熱水壺"])},
  "facility.freeteabags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費茶包"])},
  "facility.freeinstantcoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費即溶咖啡"])},
  "facility.coffeemachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咖啡機"])},
  "facility.paidsnacanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付費零食飲料"])},
  "facility.freesnacksanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費零食飲料"])},
  "facility.24-hourroomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時客房餐飲服務"])},
  "facility.refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冰箱"])},
  "facility.freebottlewater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費礦泉水"])},
  "facility-groups.clothing_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣物設備"])},
  "facility.laundrybag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗衣袋"])},
  "facility.wardrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣櫃"])},
  "facility.ironingequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熨燙設備"])},
  "facility.hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣架"])},
  "facility-groups.safety_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全設備"])},
  "facility.smokedetector_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["煙霧偵測器"])},
  "facility.securitybox_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保險箱"])},
  "facility.fireextinguisher_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滅火器"])},
  "room_category.single_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單人房"])},
  "room_category.double_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雙人房"])},
  "room_category.triple_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三人房"])},
  "room_category.four_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四人房"])},
  "room_category.five_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五人房"])},
  "room_category.six_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六人房"])},
  "room_category.seven_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七人房"])},
  "room_category.eight_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八人房"])},
  "room_category.nine_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九人房"])},
  "room_category.ten_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十人房"])},
  "room_category.bag_room_mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背包客房（混房）"])},
  "room_category.bag_room_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背包客房（女性）"])},
  "room_category.bag_room_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背包客房（男性）"])},
  "room_category.all_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包棟"])},
  "room_category.all_floor_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包層"])},
  "room_category.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標準雙人床"])},
  "room_category.queen_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加大雙人床"])},
  "room_category.king_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超大雙人床"])},
  "room_category.semi_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小型雙人床"])},
  "room_category.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單人床"])},
  "room_category.super_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單人加大床"])},
  "room_category.toddler_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童床"])},
  "room_category.baby_cribs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嬰兒床"])},
  "facility.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間熱門設施"])},
  "facility.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大坪數"])},
  "facility.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費WiFi"])},
  "facility.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辦公桌椅"])},
  "facility.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸房"])},
  "facility.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吸菸房"])},
  "facility.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店熱門服務"])},
  "facility.24_hour_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時接待入住"])},
  "facility.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣服送洗服務"])},
  "facility.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娛樂設備租借"])},
  "facility.rental_3_c_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3C周邊租借"])},
  "facility.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸飯店"])},
  "filter.multi-ents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元設施"])},
  "filter.high-ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優質旅宿"])},
  "filter.parent-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["親子旅宿"])},
  "filter.pet-friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物友善"])},
  "filter.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標準雙人床"])},
  "filter.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加大雙人床"])},
  "filter.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超大雙人床"])},
  "filter.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單人床"])},
  "filter.1km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 公里內"])},
  "filter.3km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 公里內"])},
  "filter.5km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 公里內"])},
  "filter.home_quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居家檢疫"])},
  "filter.migrant_worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移工專屬"])},
  "filter.travel-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "tag.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大坪數"])},
  "tag.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有陽台"])},
  "tag.openable_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可開窗"])},
  "tag.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辦公桌椅"])},
  "tag.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸飯店"])},
  "tag.streaming_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["線上影音平台"])},
  "tag.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娛樂設備租借"])},
  "tag.rental_sport_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運動娛樂設備租借"])},
  "tag.24_hour_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時接待入住"])},
  "tag.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免治馬桶"])},
  "tag.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可代收外送"])},
  "tag.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可代收包裹"])},
  "tag.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣物送洗服務"])},
  "tag.free_wi_fi_in_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有房型免費Ｗi-Fi"])},
  "tag.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費Ｗi-Fi"])},
  "tag.24_hour_front_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時櫃檯服務"])},
  "tag.24_hour_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小時送餐服務"])},
  "homepage.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫快訊"])},
  "homepage.prevention_grants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫補助"])},
  "homepage.prevention_fa_qs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫FAQ"])},
  "homepage.check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢訂單"])},
  "homepage.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到訂單"])},
  "homepage.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣"])},
  "homepage.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "homepage.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "homepage.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "homepage.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "homepage.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "homepage.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "homepage.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "homepage.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "homepage.yilan_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭縣"])},
  "homepage.hsinchu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹縣"])},
  "homepage.miaoli_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗栗縣"])},
  "homepage.changhua_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彰化縣"])},
  "homepage.nantou_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投縣"])},
  "homepage.yunlin_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲林縣"])},
  "homepage.chiayi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嘉義市"])},
  "homepage.chiayi_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嘉義縣"])},
  "homepage.penghu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澎湖縣"])},
  "homepage.pingtung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東縣"])},
  "homepage.taitung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺東縣"])},
  "homepage.hualien_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮縣"])},
  "homepage.kinmen_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金門縣"])},
  "homepage.lienchiang_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連江縣"])},
  "homepage.south_china_sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南海諸島"])},
  "homepage.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 晚"])},
  "homepage.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 位大人"])},
  "homepage.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 兒童"])},
  "homepage.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 間客房"])},
  "homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
  "homepage.popular_quarantine_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人氣防疫旅館"])},
  "homepage.all_county_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "homepage.taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北"])},
  "homepage.new_taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北"])},
  "homepage.taoyuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園"])},
  "homepage.hsinchu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹"])},
  "homepage.taichung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中"])},
  "homepage.tainan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南"])},
  "homepage.kaohsiung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄"])},
  "homepage.from_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起 / 晚"])},
  "homepage.see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多旅館"])},
  "homepage.hot_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱搜亮點"])},
  "homepage.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大坪數"])},
  "homepage.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可開窗"])},
  "homepage.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費Wi-Fi"])},
  "homepage.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴缸"])},
  "homepage.prevention_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫資訊"])},
  "homepage.about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於我們"])},
  "homepage.cooperation_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作提案"])},
  "homepage.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯繫我們"])},
  "homepage.service_and_unsubscribe_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務與退訂條款"])},
  "homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "homepage.customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶服務"])},
  "homepage.monday_to_friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周一至周五"])},
  "homepage.official_customer_service_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["官方客服Line"])},
  "homepage.mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信箱"])},
  "homepage.customer_service_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服時間"])},
  "homepage.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "homepage.tourbobo_travel_service_co_ltd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["易福利旅行社股份有限公司"])},
  "homepage.uniform_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編號"])},
  "homepage.class_a_travel_agencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甲種旅行社"])},
  "homepage.tourism_a_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["觀甲字 ", _interpolate(_named("n")), " 號"])},
  "homepage.tqaa_memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品保協會會員"])},
  "homepage.north_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["字第北 ", _interpolate(_named("n")), " 號"])},
  "homepage.social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社群媒體"])},
  "homepage.enter_a_destination_or_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入或選擇您想入住的縣市、飯店"])},
  "homepage.select_the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇日期"])},
  "homepage.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一"])},
  "homepage.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二"])},
  "homepage.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三"])},
  "homepage.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四"])},
  "homepage.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五"])},
  "homepage.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六"])},
  "homepage.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "homepage.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
  "homepage.please_select_the_number_of_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇人數"])},
  "homepage.adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "homepage.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童"])},
  "homepage.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間"])},
  "homepage.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依防疫規定，入住防疫旅館，原則為一人一房，若有同住照顧需求，請依政府防疫規定。"])},
  "homepage.please_select_the_age_of_the_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇兒童年齡"])},
  "homepage.years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歲"])},
  "homepage.hot_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門文章"])},
  "homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的訂單"])},
  "homepage.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱"])},
  "homepage.please_enter_your_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的電子信箱"])},
  "homepage.please_enter_a_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的電子信箱"])},
  "homepage.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單編號"])},
  "homepage.please_enter_your_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的訂單編號"])},
  "homepage.please_enter_a_valid_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的訂單編號"])},
  "homepage.inquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢"])},
  "homepage.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選單"])},
  "homepage.manage_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理訂單"])},
  "homepage.destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地"])},
  "homepage.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住日期"])},
  "homepage.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房日期"])},
  "homepage.guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂人數"])},
  "homepage.please_enter_a_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入目的地"])},
  "homepage.n_people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 人"])},
  "homepage.setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
  "homepage.please_pick_your_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇語言"])},
  "homepage.the_quarantine_protocol_accepts_only_one_person_in_one_room_in_principle_n_please_contact_the_customer_service_for_the_extra_person_rules_and_the_person_added_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依防疫規定，入住防疫旅館，原則為一人一房。若有同住需求，請洽客服詢問相關規定及加人價格。"])},
  "homepage.n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 歲"])},
  "homepage.n_from_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 起/晚"])},
  "homepage.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童年齡"])},
  "homepage.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間保留倒數"])},
  "homepage.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店類型"])},
  "homepage.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國家"])},
  "homepage.common_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尋找一般旅館"])},
  "homepage.quarantine_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尋找防疫旅館"])},
  "homepage.unique_hotels_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特色旅宿"])},
  "homepage.nantou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投"])},
  "homepage.yilan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭"])},
  "homepage.hualien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮"])},
  "homepage.taitung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台東"])},
  "homepage.pingtung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東"])},
  "homepage.normal_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般旅館"])},
  "homepage.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫旅館"])},
  "homepage.self_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主健康管理"])},
  "homepage.multi_purpose_amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元設施"])},
  "homepage.quality_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優質旅宿"])},
  "homepage.family_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["親子旅宿"])},
  "homepage.pet_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物友善"])},
  "homepage.hot_city_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣熱門城市"])},
  "homepage.recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人氣推薦"])},
  "homepage.datepicker_hint_for_quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住防疫旅館，須依防疫政策住滿規定天數才可退房。系統會自動為您選滿規定的天數喔！"])},
  "homepage.tokyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東京"])},
  "homepage.osaka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大阪"])},
  "homepage.kyoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["京都"])},
  "homepage.nara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奈良"])},
  "homepage.hokkaido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北海道"])},
  "homepage.kyushu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九州"])},
  "homepage.switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換為"])},
  "homepage.hotel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅宿類型"])},
  "homepage.choose_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇幣別"])},
  "homepage.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
  "homepage.choose_type_and_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇日期"])},
  "homepage.about_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於tourbobo"])},
  "homepage.new_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新活動"])},
  "homepage.tourbobo_accompanies_you_to_enjoy_each_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 陪伴您享受每一趟旅程"])},
  "homepage.starting_from_taiwan_tourbobo_is_dedicated_to_forging_a_simple_and_useful_booking_platform_we_not_only_gather_and_collect_quarantine_hotels_in_entire_taiwan_but_provide_profuse_common_hotels_we_expect_to_enter_the_asian_market_in_the_future_to_link_up_to_the_world_and_give_our_assistance_so_that_every_guest_can_travel_happily_and_go_home_feeling_carefree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 從台灣出發，致力於打造一個簡單、好用的訂房平台。不僅網羅全台灣的防疫旅館，更有豐富的一般旅宿。未來有望跨足亞洲市場，與世界接軌，協助每一位旅人開心出遊、安心回家。"])},
  "homepage.in_tourbobo_you_will_enjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 tourbobo 您會享受到："])},
  "homepage.service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 專人客服諮詢與協助\n- 合法合規的安心旅宿\n- 訂房流程簡單、直覺\n- 價格公開透明，還有不定期優惠\n- 熱門設施篩選，找到理想住宿"])},
  "homepage.tourbobo_makes_a_journey_easy_relaxed_with_personal_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 讓旅程更簡單、輕鬆、專人服務"])},
  "homepage.if_your_case_is_of_personal_health_management_only_by_completely_staying_for_the_number_of_days_stipulated_by_the_quarantine_policy_can_you_check_out_the_system_will_select_the_stipulated_number_of_days_automatically_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若為自主健康管理，須依防疫政策住滿規定天數才可退房。系統會自動為您選滿規定的天數喔！"])},
  "homepage.if_your_case_is_of_personal_health_management_it_will_be_one_person_per_room_if_you_have_any_demands_of_living_together_to_take_care_of_the_other_person_for_the_relevant_rules_please_follow_the_policy_issued_by_the_government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若為自主健康管理，原則為一人一房，若有同住照顧需求，相關規定請依照政府發布的政策為主。"])},
  "homepage.to_fill_up_the_personal_data_completely_sooner_will_it_be_for_your_booking_next_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料填寫完整，下次訂房更快速！"])},
  "homepage.fill_up_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即填寫"])},
  "homepage.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資訊"])},
  "homepage.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
  "homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "homepage.this_is_the_email_address_of_which_you_use_to_login_we_will_also_send_a_reservation_confirmation_letter_to_this_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這是您用來登入的電子信箱，我們也會寄送預訂確認函到此信箱"])},
  "homepage.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "homepage.keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
  "homepage.booking_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單管理"])},
  "homepage.account_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶管理"])},
  "homepage.personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
  "homepage.login_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入方式"])},
  "homepage.account_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶安全"])},
  "homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
  "homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊"])},
  "homepage.please_login_immediately_to_search_good_room_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即登入搜出住房好價格"])},
  "homepage.login_failed_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入失敗，請重新嘗試"])},
  "homepage.a_password_should_contain_8_to_12_characters_including_english_letters_and_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼需為8～12個字元，包含英文與數字"])},
  "homepage.stay_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保持登入"])},
  "homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼？"])},
  "homepage.or_use_the_social_media_account_to_login_quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或使用社群帳號快速登入"])},
  "homepage.you_must_accept_google_s_requests_so_as_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必須接受 Google 的要求才能登入"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_tourbobo_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["點擊登入或註冊即代表同意tourbobo的", _interpolate(_named("n")), "及", _interpolate(_named("m"))])},
  "homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務條款"])},
  "homepage.join_tourbobo_to_find_an_exclusive_preferential_room_rate_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入Reelax為你找出專屬優惠價"])},
  "homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您輸入的兩個密碼不相符，請再試一次"])},
  "homepage.linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綁定"])},
  "homepage.cancel_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消綁定"])},
  "homepage.email_address_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綁定電子信箱"])},
  "homepage.set_the_email_address_and_password_for_you_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定登入的電子信箱與密碼"])},
  "homepage.verify_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱驗證"])},
  "homepage.to_ensure_account_security_we_have_sent_a_verification_letter_to_your_email_address_please_follow_the_instructions_within_to_verify_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為確保帳號安全，我們已傳送驗證信件至您的信箱，請依照信件指示驗證您的電子信箱。"])},
  "homepage.the_verification_letter_has_been_sent_to_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["驗證信已發送至 ", _interpolate(_named("n"))])},
  "homepage.resending_available_after_{n}_seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "秒後可重新發送"])},
  "homepage.resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新發送"])},
  "homepage.broken_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結失效"])},
  "homepage.link_has_expired_or_been_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結已失效或已被使用過"])},
  "homepage.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解"])},
  "homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認密碼"])},
  "homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
  "homepage.go_to_tourbobo_account_to_set_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至tourbobo帳戶來設置密碼"])},
  "homepage.delete_the_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除帳戶"])},
  "homepage.delete_your_tourbobo_account_and_personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除您的tourbobo帳戶與個人資料"])},
  "homepage.join_the_membership_for_free_and_receive_the_preferential_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免費加入會員，領優惠碼～"])},
  "homepage.hot_cities_in_japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本熱門城市"])},
  "homepage.selected_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優選旅宿"])},
  "homepage.language_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂語言"])},
  "homepage.select_the_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自訂幣別"])},
  "homepage.practical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["實用資訊"])},
  "homepage.my_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的訂單"])},
  "homepage.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主管理"])},
  "homepage.never_heard_of_this_place_change_a_word_and_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒聽過這個地方，換個詞試試吧！"])},
  "homepage.enter_district_and_name_of_the_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入地區、住宿名稱"])},
  "homepage.activity_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動資訊"])},
  "homepage.{price}_and_above": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " 起"])},
  "homepage.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
  "homepage.booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單"])},
  "homepage.recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精選推薦"])},
  "homepage.popular_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門人氣住宿"])},
  "homepage.travel_info_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可錯過的旅遊資訊"])},
  "homepage.spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門焦點"])},
  "homepage.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿優惠"])},
  "homepage.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["話題住宿"])},
  "homepage.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寶島最夯"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_reelax_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["點擊登入或註冊即代表同意Reelax的", _interpolate(_named("n")), "及", _interpolate(_named("m"))])},
  "search.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
  "search.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣"])},
  "search.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "search.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "search.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "search.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "search.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "search.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "search.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "search.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "search.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋結果"])},
  "search.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看地圖"])},
  "search.filter_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過以下篩選搜尋"])},
  "search.per_night_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每晚價格"])},
  "search.sorting.recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為您推薦"])},
  "search.sorting.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價格最低"])},
  "search.sorting.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分最高"])},
  "search.sorting.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價格最高"])},
  "search.n_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["共 ", _interpolate(_named("n")), " 則評論"])},
  "search.view_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看房型"])},
  "search.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
  "search.other_recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他推薦"])},
  "search.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間熱門設施"])},
  "search.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店熱門服務"])},
  "search.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加載中"])},
  "search.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選條件"])},
  "search.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選"])},
  "search.map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地圖查詢"])},
  "search.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
  "search.sorting_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
  "search.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "search.hotel_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店服務"])},
  "search.room_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間設施"])},
  "search.is_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨機排列"])},
  "search.lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度"])},
  "search.lng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經度"])},
  "search.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低房價"])},
  "search.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高房價"])},
  "search.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "search.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分頁"])},
  "search.hotel_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到指定飯店"])},
  "search.data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到指定的資料"])},
  "search.unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作未經授權"])},
  "search.hot_room_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間熱門篩選"])},
  "search.hotel_hot_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店熱門服務"])},
  "search.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主管理"])},
  "search.quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫"])},
  "search.the_hotel_has_been_removed_and_cannot_be_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のホテルを検索"])},
  "search.find_other_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尋找其他飯店"])},
  "search.personal_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主健康管理"])},
  "search.national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "search.national_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅"])},
  "search.national_travel_subsidy_type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅宿適用補助1,300"])},
  "search.national_travel_subsidy_type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打滿3劑最高補助1,300"])},
  "search.popular_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門篩選"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
  "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣"])},
  "keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "detail.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看地圖"])},
  "detail.more_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多照片"])},
  "detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店簡介"])},
  "detail.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "detail.highlights_of_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿亮點"])},
  "detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇房型"])},
  "detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基本入住人數：", _interpolate(_named("n"))])},
  "detail.extra_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["可加人數：", _interpolate(_named("n"))])},
  "detail.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依防疫規定，入住防疫旅館，原則為一人一房。\n若有同住照顧需求，須向入住地方政府衛生單位申請同住。"])},
  "detail.extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可加床"])},
  "detail.no_extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可加床"])},
  "detail.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型特色"])},
  "detail.n_m_ft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 平方公尺"])},
  "detail.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁菸房"])},
  "detail.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吸菸房"])},
  "detail.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴缸"])},
  "detail.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淋浴設備"])},
  "detail.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共衛浴"])},
  "detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床型"])},
  "detail.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大窗"])},
  "detail.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小窗"])},
  "detail.airtight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氣窗"])},
  "detail.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["落地窗"])},
  "detail.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["陽台"])},
  "detail.more_info_on_room_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多房型資訊"])},
  "detail.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居家檢疫方案"])},
  "detail.price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每晚價格"])},
  "detail.book_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即預訂"])},
  "detail.facilities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設施與服務"])},
  "detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房須知"])},
  "detail.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住時間"])},
  "detail.check_out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房時間"])},
  "detail.extra_capacity_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加人規定"])},
  "detail.the_extra_charges_on_extra_minors_and_adults_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童與加人收費標準如下："])},
  "detail.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住客年齡"])},
  "detail.minor_under_the_age_of_5_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5歲(含)以下"])},
  "detail.infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嬰幼兒"])},
  "detail.when_no_need_an_extra_be_they_can_stay_with_the_adult_visitor_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不加床時，可免費與大人同住"])},
  "detail.6_12_years_old_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6歲 - 12歲(含)"])},
  "detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童"])},
  "detail.nt_n_charged_if_no_extra_bed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不加床時，須加收額外費用，每位每晚 NT$ ", _interpolate(_named("n"))])},
  "detail.13_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13歲(含)以上"])},
  "detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "detail.room_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住須知"])},
  "detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店注意事項"])},
  "detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂取消規定"])},
  "detail.guest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客評論"])},
  "detail.n_points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 分"])},
  "detail.n_comments_in_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["總共 ", _interpolate(_named("n")), " 則評論"])},
  "detail.check_all_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看全部評論"])},
  "detail.hotel_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店服務"])},
  "detail.entertaining_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娛樂設備"])},
  "detail.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間設施"])},
  "detail.more_info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多飯店資訊"])},
  "detail.info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店資訊"])},
  "detail.info_on_the_room_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型資訊"])},
  "detail.n_after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_years_old_and_minus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "歲(含)以下"])},
  "detail.m_n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "歲 - ", _interpolate(_named("n")), "歲(含)"])},
  "detail.n_years_old_and_plus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "歲(含)以上"])},
  "detail.please_contact_the_customer_service_for_the_extra_person_rules_and_room_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加人規定與費用請洽飯店客服"])},
  "detail.the_room_price_per_night_with_no_bed_added_is_nt_count_per_head": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不加床時，須加收額外費用，每位每晚 NT$ $", _interpolate(_named("count"))])},
  "detail.show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "detail.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收起更多"])},
  "detail.latest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新評論"])},
  "detail.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高分"])},
  "detail.lowest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低分"])},
  "detail.date_review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "  評論"])},
  "detail.from_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["來自 ", _interpolate(_named("source"))])},
  "detail.hotel_s_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店回覆"])},
  "detail.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "detail.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過濾來源"])},
  "area.zhongzheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中正區"])},
  "area.datong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大同區"])},
  "area.zhongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中山區"])},
  "area.songshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["松山區"])},
  "area.da_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大安區"])},
  "area.wanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬華區"])},
  "area.xinyi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信義區"])},
  "area.shilin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士林區"])},
  "area.beitou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北投區"])},
  "area.neihu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內湖區"])},
  "area.nangang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南港區"])},
  "area.wenshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文山區"])},
  "area.ren_ai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁愛區"])},
  "area.anle_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安樂區"])},
  "area.nuannuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖暖區"])},
  "area.qidu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七堵區"])},
  "area.wanli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬里區"])},
  "area.jinshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金山區"])},
  "area.nangan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南竿鄉"])},
  "area.beigan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北竿鄉"])},
  "area.juguang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["莒光鄉"])},
  "area.dongyin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東引鄉"])},
  "area.banqiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["板橋區"])},
  "area.xizhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汐止區"])},
  "area.shenkeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深坑區"])},
  "area.shiding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石碇區"])},
  "area.ruifang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞芳區"])},
  "area.pingxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平溪區"])},
  "area.shuangxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雙溪區"])},
  "area.gongliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貢寮區"])},
  "area.xindian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新店區"])},
  "area.pinglin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["坪林區"])},
  "area.wulai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏來區"])},
  "area.yonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永和區"])},
  "area.zhonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中和區"])},
  "area.tucheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土城區"])},
  "area.sanxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三峽區"])},
  "area.shulin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["樹林區"])},
  "area.yingge_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鶯歌區"])},
  "area.sanchong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三重區"])},
  "area.xinzhuang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新莊區"])},
  "area.taishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰山區"])},
  "area.linkou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林口區"])},
  "area.luzhou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘆洲區"])},
  "area.wugu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五股區"])},
  "area.bali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八里區"])},
  "area.tamsui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淡水區"])},
  "area.sanzhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三芝區"])},
  "area.shimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石門區"])},
  "area.yilan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭市"])},
  "area.toucheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭城鎮"])},
  "area.jiaoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礁溪鄉"])},
  "area.zhuangwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["壯圍鄉"])},
  "area.yuanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員山鄉"])},
  "area.luodong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["羅東鎮"])},
  "area.sanxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三星鄉"])},
  "area.datong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大同鄉"])},
  "area.wujie_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五結鄉"])},
  "area.dongshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冬山鄉"])},
  "area.su_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘇澳鎮"])},
  "area.nan_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南澳鄉"])},
  "area.diaoyutai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釣魚台列嶼"])},
  "area.east_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東區"])},
  "area.zhubei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹北市"])},
  "area.hukou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖口鄉"])},
  "area.xinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新豐鄉"])},
  "area.xinpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新埔鎮"])},
  "area.guanxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關西鎮"])},
  "area.qionglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芎林鄉"])},
  "area.baoshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寶山鄉"])},
  "area.zhudong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹東鎮"])},
  "area.wufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五峰鄉"])},
  "area.hengshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橫山鄉"])},
  "area.jianshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尖石鄉"])},
  "area.beipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北埔鄉"])},
  "area.emei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["峨眉鄉"])},
  "area.zhongli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中壢區"])},
  "area.pingzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平鎮區"])},
  "area.longtan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍潭區"])},
  "area.yangmei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楊梅區"])},
  "area.xinwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新屋區"])},
  "area.guanyin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["觀音區"])},
  "area.taoyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃源區"])},
  "area.guishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龜山區"])},
  "area.bade_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八德區"])},
  "area.daxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大溪區"])},
  "area.fuxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["復興區"])},
  "area.dayuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大園區"])},
  "area.luzhu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路竹區"])},
  "area.zhunan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹南鎮"])},
  "area.toufen_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭份市"])},
  "area.sanwan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三灣鄉"])},
  "area.nanzhuang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南庄鄉"])},
  "area.shitan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獅潭鄉"])},
  "area.houlong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後龍鎮"])},
  "area.tongxiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通霄鎮"])},
  "area.yuanli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苑裡鎮"])},
  "area.miaoli_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗栗市"])},
  "area.zaoqiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造橋鄉"])},
  "area.touwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭屋鄉"])},
  "area.gongguan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公館鄉"])},
  "area.dahu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大湖鄉"])},
  "area.tai_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰安鄉"])},
  "area.tongluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銅鑼鄉"])},
  "area.sanyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三義鄉"])},
  "area.xihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溪湖鎮"])},
  "area.zhuolan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓蘭鎮"])},
  "area.central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中區"])},
  "area.south_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南區"])},
  "area.west_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西區"])},
  "area.north_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北區"])},
  "area.beitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北屯區"])},
  "area.xitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西屯區"])},
  "area.nantun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南屯區"])},
  "area.taiping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太平區"])},
  "area.dali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大里區"])},
  "area.wufeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霧峰區"])},
  "area.wuri_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏日區"])},
  "area.fengyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豐原區"])},
  "area.houli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后里區"])},
  "area.shigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石岡區"])},
  "area.dongshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東勢區"])},
  "area.heping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和平區"])},
  "area.xinshe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新社區"])},
  "area.tanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潭子區"])},
  "area.daya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大雅區"])},
  "area.shengang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["神岡區"])},
  "area.dadu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大肚區"])},
  "area.shalu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙鹿區"])},
  "area.longjing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍井區"])},
  "area.wuqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梧棲區"])},
  "area.qingshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清水區"])},
  "area.dajia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大甲區"])},
  "area.waipu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外埔區"])},
  "area.changhua_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彰化市"])},
  "area.fenyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芬園鄉"])},
  "area.huatan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花壇鄉"])},
  "area.xiushui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秀水鄉"])},
  "area.lukang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿港鎮"])},
  "area.fuxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["福興鄉"])},
  "area.xianxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["線西鄉"])},
  "area.hemei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和美鎮"])},
  "area.shengang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伸港鄉"])},
  "area.yuanlin_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員林市"])},
  "area.shetou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社頭鄉"])},
  "area.yongjing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永靖鄉"])},
  "area.puxin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔心鄉"])},
  "area.dacun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大村鄉"])},
  "area.puyan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔鹽鄉"])},
  "area.tianzhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田中鎮"])},
  "area.beidou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北斗鎮"])},
  "area.tianwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田尾鄉"])},
  "area.pitou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埤頭鄉"])},
  "area.xizhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溪州鄉"])},
  "area.zhutang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹塘鄉"])},
  "area.erlin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二林鎮"])},
  "area.dacheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大城鄉"])},
  "area.fangyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芳苑鄉"])},
  "area.ershui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二水鄉"])},
  "area.nantou_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投市"])},
  "area.zhongliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中寮鄉"])},
  "area.caotun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草屯鎮"])},
  "area.guoxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國姓鄉"])},
  "area.puli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔里鎮"])},
  "area.ren_ai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁愛鄉"])},
  "area.mingjian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名間鄉"])},
  "area.jiji_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集集鎮"])},
  "area.shuili_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水里鄉"])},
  "area.yuchi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魚池鄉"])},
  "area.xinyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信義鄉"])},
  "area.zhushan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹山鎮"])},
  "area.lugu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿谷鄉"])},
  "area.fanlu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["番路鄉"])},
  "area.meishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梅山鄉"])},
  "area.zhuqi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹崎鄉"])},
  "area.alishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿里山鄉"])},
  "area.zhongpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中埔鄉"])},
  "area.dapu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大埔鄉"])},
  "area.shuishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水上鄉"])},
  "area.lucao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿草鄉"])},
  "area.taibao_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太保市"])},
  "area.puzi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朴子市"])},
  "area.dongshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東勢鄉"])},
  "area.liujiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六腳鄉"])},
  "area.xingang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新港鄉"])},
  "area.minxiong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["民雄鄉"])},
  "area.dalin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大林鎮"])},
  "area.xikou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溪口鄉"])},
  "area.yizhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["義竹鄉"])},
  "area.budai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布袋鎮"])},
  "area.dounan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斗南鎮"])},
  "area.dapi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大埤鄉"])},
  "area.huwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虎尾鎮"])},
  "area.tuku_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土庫鎮"])},
  "area.baozhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["褒忠鄉"])},
  "area.taixi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺西鄉"])},
  "area.lunbei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["崙背鄉"])},
  "area.mailiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麥寮鄉"])},
  "area.douliu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斗六市"])},
  "area.linnei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林內鄉"])},
  "area.gukeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古坑鄉"])},
  "area.citong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["莿桐鄉"])},
  "area.xiluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西螺鎮"])},
  "area.erlun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二崙鄉"])},
  "area.beigang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北港鎮"])},
  "area.shuilin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水林鄉"])},
  "area.kouhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口湖鄉"])},
  "area.sihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四湖鄉"])},
  "area.yuanzhang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元長鄉"])},
  "area.west_central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中西區"])},
  "area.anping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安平區"])},
  "area.annan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安南區"])},
  "area.yongkang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永康區"])},
  "area.guiren_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歸仁區"])},
  "area.xinhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新化區"])},
  "area.zuozhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左鎮區"])},
  "area.yujing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玉井區"])},
  "area.nanxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楠西區"])},
  "area.nanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南化區"])},
  "area.rende_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁德區"])},
  "area.guanmiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關廟區"])},
  "area.longqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍崎區"])},
  "area.guantian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["官田區"])},
  "area.madou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麻豆區"])},
  "area.jiali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佳里區"])},
  "area.xigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西港區"])},
  "area.qigu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七股區"])},
  "area.jiangjun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將軍區"])},
  "area.xuejia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["學甲區"])},
  "area.beimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北門區"])},
  "area.xinying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新營區"])},
  "area.houbi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後壁區"])},
  "area.baihe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白河區"])},
  "area.dongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東山區"])},
  "area.liujia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六甲區"])},
  "area.xiaying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下營區"])},
  "area.liuying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柳營區"])},
  "area.yanshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹽水區"])},
  "area.shanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["善化區"])},
  "area.danei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大內區"])},
  "area.shanshang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山上區"])},
  "area.xinshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新市區"])},
  "area.anding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安定區"])},
  "area.xinxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新興區"])},
  "area.qianjin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前金區"])},
  "area.lingya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苓雅區"])},
  "area.yancheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹽埕區"])},
  "area.gushan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鼓山區"])},
  "area.qijin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗津區"])},
  "area.qianzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前鎮區"])},
  "area.sanmin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三民區"])},
  "area.nanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楠梓區"])},
  "area.xiaogang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小港區"])},
  "area.zuoying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左營區"])},
  "area.renwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁武區"])},
  "area.dashe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大社區"])},
  "area.dongsha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東沙群島"])},
  "area.nansha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南沙群島"])},
  "area.gangshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["岡山區"])},
  "area.alian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿蓮區"])},
  "area.tianliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田寮區"])},
  "area.yanchao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燕巢區"])},
  "area.qiaotou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橋頭區"])},
  "area.ziguan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梓官區"])},
  "area.mituo_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彌陀區"])},
  "area.yong_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永安區"])},
  "area.hunei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖內區"])},
  "area.fengshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳳山區"])},
  "area.daliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大寮區"])},
  "area.linyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林園區"])},
  "area.niaosong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳥松區"])},
  "area.dashu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大樹區"])},
  "area.qishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗山區"])},
  "area.meinong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美濃區"])},
  "area.liugui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六龜區"])},
  "area.neimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內門區"])},
  "area.shanlin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杉林區"])},
  "area.jiaxian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甲仙區"])},
  "area.namaxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["那瑪夏區"])},
  "area.maolin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茂林區"])},
  "area.qieding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茄萣區"])},
  "area.magong_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬公市"])},
  "area.xiyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西嶼鄉"])},
  "area.wang_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["望安鄉"])},
  "area.qimei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七美鄉"])},
  "area.baisha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白沙鄉"])},
  "area.huxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖西鄉"])},
  "area.jinsha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金沙鎮"])},
  "area.jinhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金湖鎮"])},
  "area.jinning_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金寧鄉"])},
  "area.jincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金城鎮"])},
  "area.lieyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈嶼鄉"])},
  "area.wuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏坵鄉"])},
  "area.pingtung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東市"])},
  "area.sandimen_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三地門鄉"])},
  "area.wutai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霧臺鄉"])},
  "area.majia_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑪家鄉"])},
  "area.jiuru_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九如鄉"])},
  "area.ligang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["里港鄉"])},
  "area.gaoshu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高樹鄉"])},
  "area.yanpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹽埔鄉"])},
  "area.changzhi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長治鄉"])},
  "area.linluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麟洛鄉"])},
  "area.zhutian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹田鄉"])},
  "area.neipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內埔鄉"])},
  "area.wandan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬丹鄉"])},
  "area.chaozhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潮州鎮"])},
  "area.taiwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰武鄉"])},
  "area.laiyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["來義鄉"])},
  "area.wanluan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬巒鄉"])},
  "area.kanding_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["崁頂鄉"])},
  "area.xinpi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新埤鄉"])},
  "area.nanzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南州鄉"])},
  "area.linbian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林邊鄉"])},
  "area.donggang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東港鄉"])},
  "area.liuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["琉球鄉"])},
  "area.jiadong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佳冬鄉"])},
  "area.xinyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新園鄉"])},
  "area.fangliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枋寮鄉"])},
  "area.fangshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枋山鄉"])},
  "area.chunri_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["春日鄉"])},
  "area.shizi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獅子鄉"])},
  "area.checheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車城鄉"])},
  "area.mudan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牡丹鄉"])},
  "area.hengchun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恆春鎮"])},
  "area.manzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滿州鄉"])},
  "area.taitung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺東市"])},
  "area.ludao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綠島鄉"])},
  "area.lanyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘭嶼鄉"])},
  "area.yanping_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延平鄉"])},
  "area.beinan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卑南鄉"])},
  "area.luye_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿野鄉"])},
  "area.guanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關山鎮"])},
  "area.haiduan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海端鄉"])},
  "area.chishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["池上鄉"])},
  "area.donghe_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東河鄉"])},
  "area.chenggong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功鎮"])},
  "area.changbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長濱鄉"])},
  "area.taimali_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太麻里鄉"])},
  "area.jinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金峰鄉"])},
  "area.dawu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大武鄉"])},
  "area.daren_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["達仁鄉"])},
  "area.hualien_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮市"])},
  "area.xincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新城鄉"])},
  "area.xiulin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秀林鄉"])},
  "area.ji_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉安鄉"])},
  "area.shoufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["壽豐鄉"])},
  "area.fenglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳳林鎮"])},
  "area.guangfu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光復鄉"])},
  "area.fengbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豐濱鄉"])},
  "area.ruisui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞穗鄉"])},
  "area.wanrong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬榮鄉"])},
  "area.yuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玉里鎮"])},
  "area.zhuoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓溪鄉"])},
  "area.fuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["富里鄉"])},
  "area.naniwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浪速區"])},
  "area.doun_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隆邊區"])},
  "homepage.phnom_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金邊"])},
  "cancellation_and_cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消規定以旅宿業者設定之各房型方案退訂政策為主。倘若旅宿業者未設定退訂政策，請參照服務與退訂條款中的退訂與取消規定。"])},
  "detail.the_cancellation_rules_shall_be_subject_to_the_cancellation_refund_policy_set_by_a_hotel_operator_for_each_room_type_and_project_if_there_is_no_such_policy_please_refer_to_n_of_our_terms_of_service_and_cancellation_refund_policy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["取消規定以旅宿業者設定之各房型方案退訂政策為主。倘若旅宿業者未設定退訂政策，請參照服務與退訂條款中的 ", _interpolate(_named("n")), "。"])},
  "detail.cancellation_refund_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂與取消規定"])},
  "detail.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫"])},
  "hotel.this_hotel_have_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此旅宿適用悠遊國旅補助，請詳閱說明！"])},
  "hotel.this_hotel_no_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週五、週六、連續假期不適用國旅補助，請詳閱說明！"])},
  "hotel.lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "hotel.national_travel_subsidy_directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國旅補助說明"])},
  "hotel.national_travel_subsidy_directions_for_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 悠遊國旅補助說明："])},
  "hotel.national_travel_subsidy_during_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動期間"])},
  "hotel.national_travel_subsidy_during_activity_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年7月15日 至 2022年12月15日，適用於平日（週日至週四），活動至經費預算用罄為止。\n不適用日期：週五、週六、中秋節連續假期（2022年9月9日至9月11日）、國慶日連續假期（2022年10月8日至10月10日）不適用。"])},
  "hotel.national_travel_subsidy_promotion_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠方式"])},
  "hotel.national_travel_subsidy_promotion_method_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本國籍自由行旅客，若入住全台灣縣市有參加本活動且領有營業執照或旅宿業登記證之旅宿業，每房可享折抵 800 元。另使用優惠旅客本人完整接種三劑疫苗，或符合入住星級旅館、好客民宿、自行車友善旅宿、取得環保標章或溫泉標章之旅宿業，再加碼折抵 500 元，最高每房每晚可折抵 1,300 元！"])},
  "hotel.national_travel_subsidy_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方法"])},
  "hotel.national_travel_subsidy_use_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 於 tourbobo 平台選擇標註「悠遊國旅」文字的旅宿，且依照補助活動期間預訂住宿。於訂房流程中，悠遊國旅補助欄位選擇「使用補助」，旅客自行確認補助資格並勾選同意免責聲明。入住當天告知旅宿業者欲申請國旅補助，查核身份符合後，於旅宿現場給予折扣。\n\n2. 請於入住前或入住當天至「悠遊國旅個別旅客住宿優惠活動」網頁專區，掃描並上傳身分證或健保卡（任一）正面圖檔，建立旅客資料檔案（健保卡無照片亦可），且入住時將您身分證或健保卡正本（任一）提供給旅宿業者核對是否本人入住。\n\n3. 若您勾選「已完整接種三劑」：請於入住前或入住當日至「悠遊國旅個別旅客住宿優惠活動」網頁專區掃描並上傳完整接種3劑疫苗證明，並於入住時出示證明供旅宿查核。接種3劑疫苗之證明以衛生福利部或中央流行疫情指揮中心認定之辨別方式為準，如紙本疫苗接種卡（俗稱小黃卡）、健保快易通/健康存摺APP、數位新冠病毒健康證明等。"])},
  "hotel.national_travel_subsidy_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意事項"])},
  "hotel.national_travel_subsidy_notice_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 每筆訂單限一位入住旅客申請補助，每一身分證字號限使用一次補助，同時使用旅遊補助與接種三劑加碼補助需為同一入住旅客。\n\n2. 入住時由旅宿做補助資格查核，若資格不符，您同意於旅宿現場支付全額，且付款完成後才能入住，否則旅宿業者得拒絕您入住，且您不得請求免費取消或延期入住。\n\n3. 若您沒有勾選「已完整接種三劑」，提交訂單後，您將無法再更改選項，也無法要求旅宿業者或 tourbobo 提供加碼補助折抵。\n\n4. 悠遊國旅補助相關活動辦法，依照政府公告為準，倘若因政府補助經費用完而不再受理申請，或因悠遊國旅補助辦法異動，您同意旅宿業者或 tourbobo 可能因此變更您的訂單，包含但不限於：由旅客支付訂單全額、更改入住日期...等。\n\n5. 因知悉政府補助經費已用完，您於入住前想要取消訂房時，您同意依著個別旅客訂房定型化契約之規定辦理。"])},
  "hotel.clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清空篩選"])},
  "order.my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本資料"])},
  "order.personal_profile_for_quarantine_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住資料"])},
  "order.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款頁面"])},
  "order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單資訊"])},
  "order.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住日期"])},
  "order.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房日期"])},
  "order.n_total_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總共入住"])},
  "order.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 晚"])},
  "order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總房間數"])},
  "order.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 間房"])},
  "order.total_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總人數"])},
  "order.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人，", _interpolate(_named("n")), " 兒童"])},
  "order.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居家檢疫方案"])},
  "order.daily_three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附早午晚三餐"])},
  "order.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總價"])},
  "order.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價格"])},
  "order.discount_by_the_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠碼折扣"])},
  "order.plan_with_three_meals_no_extra_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入含三餐方案(不加床)"])},
  "order.early_check_in_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提前入住方案"])},
  "order.hotel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店 ID"])},
  "order.room_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型 ID"])},
  "order.plan_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方案 ID"])},
  "order.extra_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人加人"])},
  "order.extra_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童加人"])},
  "order.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童年齡"])},
  "order.check_out_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結帳金額"])},
  "order.basic_information_of_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客基本資料"])},
  "order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的姓氏"])},
  "order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "order.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的名字"])},
  "order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
  "order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
  "order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
  "order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國籍"])},
  "order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您的國籍"])},
  "order.id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號/護照號碼"])},
  "order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的證件號碼"])},
  "order.enter_valid_id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的證件號碼"])},
  "order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的護照號碼"])},
  "order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的護照號碼"])},
  "order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號碼"])},
  "order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的手機號碼"])},
  "order.choose_country_code_of_cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇手機國碼"])},
  "order.enter_keyword_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入關鍵字"])},
  "order.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "order.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱"])},
  "order.please_enter_your_email_address_and_you_will_receive_the_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入電子信箱，會寄送訂單編號給您"])},
  "order.confirm_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認電子信箱"])},
  "order.please_enter_your_email_address_to_avoid_any_biased_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請再次輸入電子信箱，確保資料無誤"])},
  "order.different_from_the_email_address_column_please_confirm_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與電子信箱欄位不相同，請再次確認"])},
  "order.emergency_contact_person_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人資料"])},
  "order.please_enter_the_last_name_of_the_emergency_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人的姓氏"])},
  "order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人名字"])},
  "order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人姓氏"])},
  "order.contact_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人手機號碼"])},
  "order.please_enter_the_first_name_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人的名字"])},
  "order.please_enter_the_cell_phone_number_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入緊急聯絡人的手機號碼"])},
  "order.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此為必填欄位"])},
  "order.finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "nation.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
  "nation.cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中國"])},
  "nation.hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
  "nation.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澳門"])},
  "nation.jp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本"])},
  "nation.us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美國"])},
  "nation.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南韓"])},
  "nation.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰國"])},
  "nation.vn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["越南"])},
  "nation.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬來西亞"])},
  "nation.ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菲律賓"])},
  "nation.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加拿大"])},
  "nation.au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澳洲"])},
  "nation.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印尼"])},
  "nation.kh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柬埔寨"])},
  "nation.mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緬甸"])},
  "nation.ae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿拉伯聯合大公國"])},
  "nation.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土耳其"])},
  "nation.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法國"])},
  "nation.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["荷蘭"])},
  "nation.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["德國"])},
  "nation.gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英國"])},
  "nation.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美屬維京群島"])},
  "nation.mp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北馬利安納群島"])},
  "nation.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關島"])},
  "nation.as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美屬薩摩亞"])},
  "nation.pr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波多黎各"])},
  "nation.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴哈馬"])},
  "nation.bb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴貝多"])},
  "nation.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安圭拉"])},
  "nation.ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安地卡及巴布達"])},
  "nation.vg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英屬維京群島"])},
  "nation.ky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開曼群島"])},
  "nation.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百慕達"])},
  "nation.gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格瑞那達"])},
  "nation.tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特克斯與凱科斯群島"])},
  "nation.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒙哲臘"])},
  "nation.sx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["荷屬聖馬丁"])},
  "nation.lc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖露西亞"])},
  "nation.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多米尼克"])},
  "nation.vc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖文森及格瑞那丁"])},
  "nation.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多明尼加"])},
  "nation.tt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["千里達及托巴哥"])},
  "nation.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖克里斯多福及尼維斯"])},
  "nation.jm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牙買加"])},
  "nation.eg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埃及"])},
  "nation.ss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南蘇丹"])},
  "nation.ma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩洛哥"])},
  "nation.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿爾及利亞"])},
  "nation.tn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["突尼西亞"])},
  "nation.ly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利比亞"])},
  "nation.gm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甘比亞"])},
  "nation.sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞內加爾"])},
  "nation.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茅利塔尼亞"])},
  "nation.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬利"])},
  "nation.gn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幾內亞"])},
  "nation.ci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["象牙海岸"])},
  "nation.bf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布吉納法索"])},
  "nation.ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尼日"])},
  "nation.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多哥"])},
  "nation.bj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貝南"])},
  "nation.mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模里西斯"])},
  "nation.lr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賴比瑞亞"])},
  "nation.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獅子山"])},
  "nation.gh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迦納"])},
  "nation.ng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奈及利亞"])},
  "nation.td": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查德"])},
  "nation.cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中非"])},
  "nation.cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喀麥隆"])},
  "nation.cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["維德角"])},
  "nation.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖多美普林西比"])},
  "nation.gq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赤道幾內亞"])},
  "nation.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加彭"])},
  "nation.cg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剛果共和國"])},
  "nation.cd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剛國民主共和國"])},
  "nation.ao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安哥拉"])},
  "nation.gw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幾內亞比索"])},
  "nation.io": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英屬印度洋領地"])},
  "nation.ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞森欣島"])},
  "nation.sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞席爾"])},
  "nation.sd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘇丹"])},
  "nation.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盧安達"])},
  "nation.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣索比亞"])},
  "nation.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索馬利亞"])},
  "nation.dj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉布地"])},
  "nation.ke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肯亞"])},
  "nation.tz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桑給巴爾"])},
  "nation.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏干達"])},
  "nation.bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒲隆地"])},
  "nation.mz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["莫三比克"])},
  "nation.zm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚比亞"])},
  "nation.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬達加斯加"])},
  "nation.re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留尼旺"])},
  "nation.yt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬約特"])},
  "nation.zw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辛巴威"])},
  "nation.na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["納米比亞"])},
  "nation.mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬拉威"])},
  "nation.ls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賴索托"])},
  "nation.bw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波札那"])},
  "nation.sz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史瓦帝尼"])},
  "nation.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葛摩"])},
  "nation.za": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南非"])},
  "nation.sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["崔斯坦達庫尼亞"])},
  "nation.er": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厄利垂亞"])},
  "nation.aw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿魯巴"])},
  "nation.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法羅群島"])},
  "nation.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格陵蘭"])},
  "nation.gr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["希臘"])},
  "nation.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比利時"])},
  "nation.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西班牙"])},
  "nation.gi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直布羅陀"])},
  "nation.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葡萄牙"])},
  "nation.lu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盧森堡"])},
  "nation.ie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愛爾蘭"])},
  "nation.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冰島"])},
  "nation.al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿爾巴尼亞"])},
  "nation.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬爾他"])},
  "nation.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賽普勒斯"])},
  "nation.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芬蘭"])},
  "nation.ax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奧蘭"])},
  "nation.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保加利亞"])},
  "nation.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匈牙利"])},
  "nation.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立陶宛"])},
  "nation.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉脫維亞"])},
  "nation.ee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愛沙尼雅"])},
  "nation.md": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩爾多瓦"])},
  "nation.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞美尼亞"])},
  "nation.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白俄羅斯"])},
  "nation.ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安道爾"])},
  "nation.mc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩納哥"])},
  "nation.sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖馬利諾"])},
  "nation.va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梵蒂岡"])},
  "nation.ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏克蘭"])},
  "nation.rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞爾維亞"])},
  "nation.me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒙特內哥羅"])},
  "nation.xk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科索沃"])},
  "nation.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["克羅埃西亞"])},
  "nation.si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯洛維尼亞"])},
  "nation.ba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波士尼亞與赫塞哥維納"])},
  "nation.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北馬其頓"])},
  "nation.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["義大利"])},
  "nation.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["羅馬尼亞"])},
  "nation.ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞士"])},
  "nation.cz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捷克"])},
  "nation.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯洛伐克"])},
  "nation.li": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["列支敦斯登"])},
  "nation.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奧地利"])},
  "nation.gg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根西"])},
  "nation.je": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澤西"])},
  "nation.im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼島"])},
  "nation.dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丹麥"])},
  "nation.se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞典"])},
  "nation.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挪威"])},
  "nation.sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯瓦爾巴和揚馬延"])},
  "nation.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波蘭"])},
  "nation.fk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["福克蘭群島"])},
  "nation.bz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貝里斯"])},
  "nation.gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓜地馬拉"])},
  "nation.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薩爾瓦多"])},
  "nation.hn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宏都拉斯"])},
  "nation.ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尼加拉瓜"])},
  "nation.cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哥斯大黎加"])},
  "nation.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴拿馬"])},
  "nation.pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖皮埃與密克隆群島"])},
  "nation.ht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海地"])},
  "nation.pe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘魯"])},
  "nation.mx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["墨西哥"])},
  "nation.cu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古巴"])},
  "nation.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿根廷"])},
  "nation.br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴西"])},
  "nation.cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智利"])},
  "nation.co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哥倫比亞"])},
  "nation.ve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["委內瑞拉"])},
  "nation.gp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓜地洛普"])},
  "nation.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["坡利維亞"])},
  "nation.gy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓋亞那"])},
  "nation.ec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厄瓜多"])},
  "nation.gf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法屬圭亞那"])},
  "nation.py": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴拉圭"])},
  "nation.mq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬丁尼克"])},
  "nation.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘇利南"])},
  "nation.uy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏拉圭"])},
  "nation.cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古拉索"])},
  "nation.cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科科斯（基林）群島"])},
  "nation.cx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聖誕島"])},
  "nation.nz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紐西蘭"])},
  "nation.pn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮特肯群島"])},
  "nation.sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
  "nation.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東帝汶"])},
  "nation.nf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諾福克島"])},
  "nation.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汶萊"])},
  "nation.nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["諾魯"])},
  "nation.pg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴布亞紐幾內亞"])},
  "nation.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東加"])},
  "nation.sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索羅門群島"])},
  "nation.vu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萬那杜"])},
  "nation.fj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斐濟"])},
  "nation.pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帛琉"])},
  "nation.wf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓦利斯和富圖那"])},
  "nation.ck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庫克群島"])},
  "nation.nu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紐埃"])},
  "nation.ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薩摩亞"])},
  "nation.ki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉里巴斯"])},
  "nation.nc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新喀里多尼亞"])},
  "nation.tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吐瓦魯"])},
  "nation.pf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法屬玻里尼西亞"])},
  "nation.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托克勞"])},
  "nation.fm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密克羅尼西亞聯邦"])},
  "nation.mh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬紹爾群島"])},
  "nation.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俄羅斯"])},
  "nation.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哈薩克"])},
  "nation.kp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北韓"])},
  "nation.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寮國"])},
  "nation.bd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孟加拉"])},
  "nation.in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印度"])},
  "nation.pk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴基斯坦"])},
  "nation.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿富汗"])},
  "nation.lk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯里蘭卡"])},
  "nation.mv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬爾地夫"])},
  "nation.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黎巴嫩"])},
  "nation.jo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約旦"])},
  "nation.sy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敘利亞"])},
  "nation.iq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊拉克"])},
  "nation.kw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["科威特"])},
  "nation.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙烏地阿拉伯"])},
  "nation.ye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葉門"])},
  "nation.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿曼"])},
  "nation.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴勒斯坦"])},
  "nation.il": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以色列"])},
  "nation.bh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴林"])},
  "nation.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡達"])},
  "nation.bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不丹"])},
  "nation.mn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒙古"])},
  "nation.np": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尼泊爾"])},
  "nation.ir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊朗"])},
  "nation.tj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塔吉克"])},
  "nation.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土庫曼"])},
  "nation.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞塞拜然"])},
  "nation.ge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喬治亞"])},
  "nation.kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉爾吉斯"])},
  "nation.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏茲別克"])},
  "order.departures_arrivals_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出入境資料"])},
  "order.departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出境國家"])},
  "order.please_pick_the_departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您的出境國家"])},
  "order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抵達班機編號"])},
  "order.please_enter_your_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的班機編號"])},
  "order.arrival_date_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抵達台灣日期"])},
  "order.please_pick_your_arrival_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您抵達的日期"])},
  "order.arrival_time_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抵達台灣時間"])},
  "order.please_pick_your_arrival_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您抵達的時間"])},
  "order.scheduled_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預計入住時間"])},
  "order.please_pick_your_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您的入住時間"])},
  "order.estimated_check_in_time_shall_not_be_earlier_than_the_time_arriving_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預計入住時間不能早於抵達台灣時間"])},
  "order.any_visitor_in_need_of_early_check_in_must_pay_the_early_check_in_bill_pursuant_to_the_rules_made_by_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若提早入住，依各飯店規定，酌收早進費用"])},
  "order.health_and_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康與飲食"])},
  "order.food_habits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飲食習慣"])},
  "order.non_vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葷"])},
  "order.vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["素"])},
  "order.any_specific_illness_cardiovascular_or_chronic_disease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否有特殊疾病(包含心血管、慢性疾病)"])},
  "order.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
  "order.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "order.please_enter_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的特殊疾病"])},
  "order.any_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過敏或忌口的食物"])},
  "order.please_do_enter_the_allergic_or_banned_food_if_you_pick_the_plan_with_meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若含餐，請務必填寫您過敏或忌口的食物"])},
  "order.please_enter_the_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您會過敏或忌口的食物"])},
  "order.use_the_discount_cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用優惠碼"])},
  "order.please_enter_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入優惠代碼"])},
  "order.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "order.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註"])},
  "order.please_enter_your_specific_need_but_the_hotel_cannot_ensure_provision_of_such_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的特殊需求，飯店不保證可提供服務，且視情況現場酌收費用。"])},
  "order.extra_purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加購項目"])},
  "order.the_room_type_capacity_is_2_persons_and_the_hotel_receives_no_extra_person_unless_the_caregiver_is_a_must": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此房型最多可住2人，須符合共同照護之規定才可加人"])},
  "order.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "order.12_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12歲以上"])},
  "order.child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小孩"])},
  "order.0_12_years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0-12歲"])},
  "order.night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚"])},
  "order.the_hotel_receives_the_visitors_after_15_00_and_will_issue_the_early_check_in_bill_for_any_visitor_in_need_of_early_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此飯店入住時間為15:00後，您若提早入住，需酌收早進費用"])},
  "order.early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早進費用"])},
  "order.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回首頁"])},
  "order.please_enter_the_name_of_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫特殊疾病的名稱"])},
  "order.please_enter_the_valid_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的優惠碼"])},
  "order.10_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九折券"])},
  "order.20_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八折券"])},
  "order.30_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七折券"])},
  "order.40_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六折券"])},
  "order.50_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五折券"])},
  "order.60_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四折券"])},
  "order.70_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三折券"])},
  "order.80_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二折券"])},
  "order.90_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一折券"])},
  "order.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
  "order.booking_serial_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單編號"])},
  "order.tax_id_no_for_the_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開立統一編號"])},
  "order.tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編號"])},
  "order.please_enter_the_company_tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入公司統一編號"])},
  "order.company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司抬頭"])},
  "order.please_enter_the_company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入公司抬頭"])},
  "order.payment_manner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
  "order.pay_by_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡付款"])},
  "order.cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂政策"])},
  "order.1_any_booking_altered_cancelled_on_3_days_before_the_check_in_date_the_hotel_receives_the_booking_to_be_altered_by_the_visitor_once_only_or_refunds_the_full_deposit_to_the_visitor_if_requested_as_well_with_the_processing_fee_of_nt_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 入住日前3天，可提供乙次訂單修改，亦可申請退款(將另收取100元手續費)。"])},
  "order.2_in_the_event_of_the_booking_to_be_cancelled_on_1_2_days_before_the_check_in_date_the_hotel_will_deduct_70_and_refund_the_rest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 入住日前1-2天取消，將扣取訂金的50%後，退款剩餘費用。"])},
  "order.3_any_booking_to_be_altered_cancelled_on_the_check_in_date_the_hotel_neither_receives_the_booking_to_be_altered_nor_refunds_the_deposit_to_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 入住當日恕無法提供訂單修改，取消亦不退還訂金。"])},
  "order.4_any_contingent_cancellation_after_the_stay_the_hotel_will_deduct_the_room_price_calculated_by_the_actual_lodging_days_from_the_scheduled_lodging_price_and_charge_the_sterilization_cleaning_fee_by_nt_800_1_000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. 入住後如因突發狀況，退訂後續未入住天數，將扣除已入住費用後，另酌收清潔消毒費用。"])},
  "order.i_have_read_the_cancellation_policy_and_agree_to_the_privacy_terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已詳閱", _interpolate(_named("n")), "，並同意", _interpolate(_named("m"))])},
  "order.submit_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交訂單"])},
  "order.booking_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單逾時"])},
  "order.sorry_you_fail_to_pay_within_a_given_time_limit_and_the_system_cancels_your_booking_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops！訂單未於時間內付款，已取消。請重新下訂。"])},
  "order.failed_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交失敗"])},
  "order.sorry_the_room_type_of_the_hotel_is_fully_booked_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops！很抱歉，該飯店房型已售完。請重新下訂。"])},
  "order.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜您完成訂房！"])},
  "order.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，付款失敗！\n請查看訂單，再次確認付款"])},
  "order.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看訂單"])},
  "order.my_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的訂單"])},
  "order.booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房成功"])},
  "order.your_payment_completed_have_a_nice_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成付款，祝您旅途愉快！"])},
  "order.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週一"])},
  "order.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週二"])},
  "order.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週三"])},
  "order.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週四"])},
  "order.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週五"])},
  "order.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週六"])},
  "order.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週日"])},
  "order.booking_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單狀態"])},
  "order.booking_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成立"])},
  "order.booking_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
  "order.booking_received_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立時間"])},
  "order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款狀態"])},
  "order.not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未付款"])},
  "order.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付款"])},
  "order.payment_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款時間"])},
  "order.booking_total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單總價"])},
  "order.invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開立收據"])},
  "order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收據查詢"])},
  "order.the_hotel_will_issue_the_collection_and_remittance_receipt_after_7_days_from_the_check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房後七天會開立代收轉付收據"])},
  "order.room_type_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房型資訊"])},
  "order.total_room_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總房間數"])},
  "order.total_person_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總人數"])},
  "order.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人"])},
  "order.three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附早午晚三餐"])},
  "order.n_person_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 人份"])},
  "order.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳情"])},
  "order.hotel_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店地址"])},
  "order.hotel_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店電話"])},
  "order.hotel_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店LINE"])},
  "order.tourbobo_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourbobo客服"])},
  "order.customer_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服LINE"])},
  "order.payment_failed_your_booking_will_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未付款，訂單即將取消"])},
  "order.you_are_so_close_to_get_your_booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離訂單完成，只差一點點了！"])},
  "order.pay_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即付款"])},
  "order.the_booking_has_been_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單已取消"])},
  "order.what_a_pity_hope_to_see_you_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好可惜～期待您下次再預訂！"])},
  "order.booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房成功"])},
  "order.sorry_no_booking_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，查無訂單。"])},
  "order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照號碼"])},
  "order.please_enter_your_passport_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的護照號碼"])},
  "order.please_enter_your_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的手機號碼"])},
  "order.check_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看明細"])},
  "order.booking_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房明細"])},
  "order.last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一步"])},
  "order.terms_of_privacy_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權與使用條款"])},
  "order.please_enter_valid_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的資料"])},
  "order.the_room_rice_depends_on_weekdays_or_holidays_check_the_price_per_night_by_pressing_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房價因平假日而有所不同，點擊總價可查看每晚價格明細"])},
  "order.within_7_days_after_cancellation_a_receipt_of_collection_and_transfer_will_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房後七天內會開立代收轉付收據"])},
  "order.to_be_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將入住"])},
  "order.checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已入住"])},
  "order.checked_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退房"])},
  "order.not_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未入住"])},
  "order.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房間保留倒數"])},
  "order.non_editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非即將入住，無法編輯訂單"])},
  "order.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到訂單"])},
  "order.card_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到有效的信用卡付款方式"])},
  "order.transaction_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到該筆交易"])},
  "order.already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成付款，請勿重複付款"])},
  "order.accommodation_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿費"])},
  "order.room_price_add_ons_and_tax_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含房價、加購費用及稅金"])},
  "order.detailed_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單明細"])},
  "order.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項目"])},
  "order.additional_purchase_item_early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加購項目-早進費用"])},
  "order.additional_purchase_item_extra_guest_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加購項目-加人費用"])},
  "order.additional_purchase_item_extra_bed_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加購項目-加床費用"])},
  "order.booking_to_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消預訂"])},
  "order.hotel_operator_to_refund": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("d")), " 日(含)取消訂房者，得請求旅宿業者退還 ", _interpolate(_named("n")), " 已付金額。"])},
  "order.before_{n}_day_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["前 ", _interpolate(_named("n")), " 日(含)"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_50_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("d")), " 日(含)取消訂房者，應負擔 ", _interpolate(_named("n")), " 已付金額作為取消費用。"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_100_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住當日取消訂房或未入住者，應負擔 ", _interpolate(_named("n")), " 已付金額作為取消費用。"])},
  "order.the_guest_who_cancels_the_reversation_{n}_days_prior_to_the_desired_check_in_date_including_the_check_in_date_or_no_show_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("n")), " 日(含)取消訂房或入住日未入住者，應負擔 ", _interpolate(_named("n")), " 已付金額作為取消費用。"])},
  "order.the_guest_who_cancels_the_reversation_at_any_time_or_no_show_on_the_check_in_date_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於任何時間取消訂房或入住日未入住，應負擔 ", _interpolate(_named("n")), " 已付金額作為取消費用。"])},
  "order.on_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["當天"])},
  "order.cancellation_on_n_day_s_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["入住前 ", _interpolate(_named("n")), " 日取消"])},
  "order.no_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不收取任何費用"])},
  "order.the_50_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收取50%已付金額"])},
  "order.check_in_date_booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住日"])},
  "order.the_100_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收取100%已付金額"])},
  "order.the_cancellation_bill_calculated_by_50_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("n")), " 日(含)取消訂房者，應負擔 50% 已付金額作為取消費用。"])},
  "order.1_2_days_before_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前 1 - 2 日(含)"])},
  "order.real_payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結帳金額"])},
  "order.cancellation_bill_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消退訂費用"])},
  "order.refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款金額"])},
  "order.cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消原因"])},
  "order.the_prepaid_deposit_will_be_deducted_from_the_credit_card_account_within_21_working_days_for_any_refund_meeting_the_terms_and_conditions_of_refunds_due_to_cancelled_altered_bookings_or_early_check_outs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有因取消預訂、修改預訂或提前退房所產生的退費，只要符合退款規定，就會在21個工作天內退還至您的信用卡帳戶，如有疑慮請洽客服。若因特殊原因改用不同退款方式衍生手續費，將自退款金額中扣除。"])},
  "order.the_room_prices_on_weekdays_and_holidays_differ_please_click_total_price_and_check_the_detailed_room_price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房價因平假日而有所不同，點擊結帳金額可查看每晚價格明細"])},
  "order.date_room_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 房價"])},
  "order.please_enter_a_cause_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入原因"])},
  "order.upon_submission_of_the_booking_with_or_without_completion_of_the_prepaid_deposit_this_bargain_code_cannot_be_used_again_and_no_request_of_bargains_in_any_way_will_be_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交訂單後，無論是否完成結帳，將無法再使用此優惠碼，也不能要求以其他方式進行折扣"])},
  "order.my_schedule_is_delayed_or_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的行程已延期或取消"])},
  "order.cheaper_prices_are_found_on_the_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網路上找到有更便宜的價格"])},
  "order.the_inn_is_not_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這間住宿的位置不好到達"])},
  "order.force_majeure_e_g_natural_disasters_and_pandemics_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可抗的天災、疾病因素"])},
  "order.i_d_like_to_book_the_room_from_this_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我要直接向這間住宿預訂"])},
  "order.i_d_like_to_book_a_new_inn_on_this_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我想換訂網站上其他一間住宿"])},
  "order.the_offers_from_other_tour_agencies_are_cheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他旅行社報價更便宜"])},
  "order.i_am_unhappy_with_the_payment_rules_for_the_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我不滿意這筆預訂的付款規定"])},
  "order.due_to_personal_health_factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因個人健康因素"])},
  "order.changes_in_the_guest_number_or_needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住人數或需求改變"])},
  "order.others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "order.please_enter_the_cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇取消的原因"])},
  "order.keep_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留預訂"])},
  "order.please_enter_or_pick_a_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入或選擇國家"])},
  "order.a_visitor_having_cancelled_the_booking_not_within_3_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客於入住日前 3 日取消訂房者，得請求旅宿業者退還 100% 已付金額。"])},
  "order.a_visitor_having_cancelled_the_booking_within_1_2_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_half_of_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客於入住日前 1-2 日取消訂房者，得請求旅宿業者退還 50% 已付金額。"])},
  "order.a_visitor_having_cancelled_the_booking_on_the_booked_check_in_date_or_failing_to_stay_in_the_booked_room_shall_pay_the_whole_paid_deposit_to_the_business_owner_as_the_cancellation_bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客於入住當日取消訂房或未入住者，應負擔 100% 已付金額作為取消費用。"])},
  "cancelled_policy.loose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寬鬆"])},
  "cancelled_policy.normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
  "cancelled_policy.strict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嚴格"])},
  "cancelled_policy.non": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可退費"])},
  "order.the_percent_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["收取", _interpolate(_named("n")), "%已付金額"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_percent_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("date")), " 日取消訂房者，得請求旅宿業者退還 ", _interpolate(_named("n")), "% 已付金額。"])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日前 ", _interpolate(_named("date")), " 日取消訂房者，將會收取折扣前的", _interpolate(_named("n")), "%訂單原價，作為取消退訂房費。"])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_on_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["旅客於入住日當日取消訂房者，將會收取折扣前的", _interpolate(_named("n")), "%訂單原價，作為取消退訂房費。"])},
  "order.contact_customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡客服"])},
  "order.alert_not_upcoming_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非即將入住，無法取消訂單"])},
  "order.alert_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單已取消，無法重複取消"])},
  "order.alert_is_epidemic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫訂單無法取消"])},
  "order.alert_refunded_type_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款金流不存在"])},
  "order.alert_expired_refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單已超過退款時間"])},
  "order.refund_has_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已申請退款"])},
  "order.check_the_accommodation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看住宿規定"])},
  "order.send_an_email_to_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄電子郵件給旅宿"])},
  "order.book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次預定"])},
  "order.sorry_no_booking_data_have_been_found_please_confirm_the_email_address_and_booking_id_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，查無此訂單，請再次確認電子信箱與訂單編號。"])},
  "order.after_submitting_the_booking_the_preferential_code_is_not_available_whether_completing_check_out_settling_the_bill_or_not_and_no_discount_can_be_requested_in_any_other_ways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交訂單後，無論是否完成結帳，將無法再使用此優惠碼，也不能要求以其他方式進行折扣。"])},
  "order.cancellation_policy_for_this_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本訂單的取消政策"])},
  "order.special_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特殊狀況"])},
  "order.after_check_in_if_a_guest_cancels_the_reservation_for_subsequent_days_not_staying_in_owing_to_an_emergency_we_will_deduct_the_amount_of_money_already_staying_in_and_charge_the_cleaning_fee_and_cost_of_disinfection_additionally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客入住後因突發狀況，退訂後續未入住天數，將扣除已入住費用後，另酌收清潔消毒費用。"])},
  "order.credit_card_refund_chargeback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡退款"])},
  "order.invoice_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票/收據"])},
  "order.a_hotel_operator_can_issue_an_invoice_or_reciept_for_the_booking_that_is_paid_at_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅宿業者可為到店付款的訂單開立發票或收據。"])},
  "order.if_your_booking_belongs_to_the_pre_paid_booking_fully_paid_to_tourbobo_we_will_issue_a_reciept_of_travel_agency_and_you_can_go_to_the_booking_content_to_check_in_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您是全額付款給tourbo的預付型訂單，tourbobo將開立代收轉付收據，可於退房後7天內至訂單內容查詢。"])},
  "order.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解"])},
  "order.please_read_policies_and_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請詳閱政策與條款"])},
  "order.tick_the_box_of_i_have_carefully_read_the_cancellation_refund_policy_and_agreed_the_terms_of_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請勾選「已詳閱退訂政策，並同意隱私權使用條款」"])},
  "order.leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離開"])},
  "order.stay_at_the_current_sreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留在此頁"])},
  "order.are_you_sure_you_want_to_leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定要離開嗎?"])},
  "order.the_booking_will_be_cancelled_automatically_after_leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["離開後訂單將自動取消"])},
  "order.the_booking_has_been_established_please_remember_the_booking_number_so_as_to_check_the_booking_content_the_booking_will_be_cancelled_automatically_if_not_paid_within_the_time_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單已成立，請記住訂單編號，以便查詢訂單內容。若您未於限內付款，此訂單將自動取消。"])},
  "order.failed_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消失敗"])},
  "order.whoops_a_technical_problem_and_cannot_cancel_the_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歪腰，技術上出了一點問題，無法取消預訂。"])},
  "order.open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Google 地圖"])},
  "order.failed_to_open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Google 地圖失敗"])},
  "order.open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Apple 地圖"])},
  "order.failed_to_open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Apple 地圖失敗"])},
  "order.succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
  "order.cancellation_refund_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂政策"])},
  "order.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "order.the_address_has_been_copied_to_the_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址已經複製到剪貼簿"])},
  "order.rules_of_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿規定"])},
  "order.please_choose_the_reason_for_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇取消原因"])},
  "order.please_enter_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的電子信箱"])},
  "order.please_enter_your_booking_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的訂單編號"])},
  "order.query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢"])},
  "order.please_choose_the_departure_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇出境國家"])},
  "order.failed_to_dial_the_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撥打電話失敗"])},
  "order.failed_to_open_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Facebook 失敗"])},
  "order.failed_to_open_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟 Line 失敗"])},
  "order.failed_to_send_an_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄送電子郵件失敗"])},
  "order.national_travel.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住時間"])},
  "order.national_travel.allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "order.national_travel.once_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每筆訂單限使用一筆補助，不得與其他優惠合併使用"])},
  "order.national_travel.no_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不使用"])},
  "order.national_travel.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用補助 (現場折抵)"])},
  "order.national_travel.three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否完整接種三劑疫苗？"])},
  "order.national_travel.no_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未接種三劑"])},
  "order.national_travel.had_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完整接種三劑"])},
  "order.national_travel.agree_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客需同意「 免責聲明 」，才可使用悠遊國旅補助。入住當天，現場查核資格符合，旅宿將於現場折抵補助金額。如資格不符或政府預算已用完，旅客須支付全額費用。"])},
  "order.national_travel.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免責聲明"])},
  "order.national_travel.agree_for_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已確認身份資格，可使用悠遊國旅補助，且同意免責聲明。"])},
  "order.national_travel.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解"])},
  "order.credit_card.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人姓名"])},
  "order.credit_card.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡號"])},
  "order.credit_card.validity_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期限"])},
  "order.credit_card.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入持卡人姓名"])},
  "order.credit_card.validity_period.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ＭＭ/YY"])},
  "order.pay_by_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到店付款"])},
  "order.pay_by_arrival_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本平台不會從您的卡片扣款，卡片僅為預先授權使用。若您取消訂單或未如期入住，旅宿將依退訂政策收取費用。"])},
  "order.pay_can_use_each_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎使用任一卡片："])},
  "order.pay_by_arrival_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在免付任何費用－您將於入住時付款。"])},
  "order.pay_use_national_coupon_tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此為補助前之價格，悠遊國旅補助將於旅宿現場折抵。"])},
  "order.type_of_charge_and_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收付類型"])},
  "order.platform_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台付款"])},
  "order.excuse_me_your_credit_card_can_not_be_verified_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，您的信用卡無法通過驗證，請重新嘗試。"])},
  "order.please_tick_and_agree_the_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請勾選，同意免責聲明"])},
  "order.please_enter_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的卡號"])},
  "order.please_enter_valid_expiry_date_of_your_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的卡片期限"])},
  "order.partial_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分付款"])},
  "validation.cnn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡號不正確"])},
  "order.enter_your_id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的證件號碼"])},
  "order.invoice_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票開立"])},
  "order.if_full_payment_is_made_for_a_prepaid_stay_through_tourbobo_a_receipt_for_payment_confirmation_issued_by_tourbobo_will_be_available_in_your_booking_details_within_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您是全額付款給tourbobo的預付型訂單，tourbobo將開立代收轉付收據，可於退房後7天內至訂單內容查詢。"])},
  "order.in_case_of_any_unexpected_situation_occurs_and_result_in_early_checkout_the_unused_reserved_nights_will_be_refunded_an_additional_cleaning_fee_will_be_charged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客入住後如因突發狀況，退訂後續未入住天數，將扣除已入住費用後，另酌收清潔消毒費用。"])},
  "order.send_a_mail_to_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄信給旅宿"])},
  "order.looking_forward_to_your_next_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期待您下次再預訂！"])},
  "order.refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款申請中"])},
  "order.id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["證件號碼"])},
  "order.designated_credit_card_number_first_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠指定信用卡卡號（前 8 碼）"])},
  "order.this_is_to_verify_the_cardholder_s_identity_and_no_credit_card_payment_will_be_processed_now_you_may_use_the_discount_code_upon_successful_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此步驟不會從您的卡片扣款，僅為驗證卡友身份，驗證成功即可使用此優惠碼。"])},
  "order.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證"])},
  "order.cardholder_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡友身份驗證"])},
  "order.please_enter_a_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的信用卡號"])},
  "order.unlock_exclusive_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解鎖專屬優惠"])},
  "order.view_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看優惠"])},
  "order.view_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即查看"])},
  "order.exclusive_discount_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專屬優惠"])},
  "order.offer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠詳情"])},
  "order.get_your_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領取"])},
  "order.offer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠內容"])},
  "order.validity_span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期間"])},
  "order.discount_eligibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資格限制"])},
  "order.how_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方式"])},
  "order.other_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他使用條件"])},
  "news.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫快訊"])},
  "news.hot_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["熱門文章"])},
  "loading.loading_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍等一下，努力加載中..."])},
  "loading.reloading_to_show_the_latest_discount_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在重新整理，呈現最新優惠價格..."])},
  "loading.error_non_displayable_try_reloading_the_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出錯了，無法顯示，重新整理頁面試試"])},
  "loading.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回首頁"])},
  "loading.reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新整理"])},
  "loading.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜您完成訂房！"])},
  "loading.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看訂單"])},
  "loading.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，付款失敗！\n請查看訂單，再次確認付款"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_search_criteria_please_reset_the_search_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，您搜尋的條件無空房，請更改條件後，重新搜尋。"])},
  "loading.search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新搜尋"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，篩選無結果，請放寬篩選條件，再重新搜尋一次。"])},
  "loading.clear_filter_search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清空篩選並重新搜尋"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria_to_show_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，篩選無結果，請放寬篩選條件，顯示更多房型。"])},
  "loading.clear_filter_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清空篩選，顯示所有房型"])},
  "user-homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的訂單"])},
  "user-homepage.please_enter_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入訂單編號"])},
  "user-homepage.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
  "user-homepage.please_select_a_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇語言"])},
  "user-homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
  "user-homepage.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "user-homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼？"])},
  "user-homepage.my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我有帳號密碼"])},
  "user-homepage.login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook 登入"])},
  "user-homepage.login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 登入"])},
  "user-homepage.member_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員登入"])},
  "user-homepage.account_not_found_please_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有會員帳號，請前往註冊"])},
  "user-homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註冊"])},
  "user-homepage.sign_in_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook 登入"])},
  "user-homepage.sign_in_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 登入"])},
  "user-homepage.account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我有帳號密碼"])},
  "user-homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "user-homepage.please_enter_your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入姓名"])},
  "user-order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子信箱"])},
  "user-order.please_enter_your_e-mail_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入電子信箱"])},
  "user-homepage.enter_your_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的電子信箱"])},
  "user-homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
  "user-homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認密碼"])},
  "user-homepage.create_password_6_to_30_characters_long_including_letters_numbers_and_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼建立規則：任意英文字母、數字、符號，長度6~30個字。"])},
  "user-homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您輸入的兩個密碼不相符，請再試一次"])},
  "user-homepage.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此為必填欄位"])},
  "user-homepage.i_understand_and_agree_to_the_terms_of_service_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我瞭解並同意本網站服務條款及隱私權政策"])},
  "user-homepage.login_to_review_more_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入查詢更多訂單"])},
  "user-homepage.go_to_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往訂房"])},
  "user-homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
  "user-detail.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住日期"])},
  "user-detail.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房日期"])},
  "user-detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇房型"])},
  "user-detail.guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客"])},
  "user-detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "user-detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孩童"])},
  "user-detail.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 位大人"])},
  "user-detail.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 位孩童"])},
  "user-detail.n_rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 間客房"])},
  "user-detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店簡介"])},
  "user-detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基本入住人數：", _interpolate(_named("n"))])},
  "user-detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床型"])},
  "user-detail.select_the_age_of_the_child_no_additional_charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇兒童歲數，不額外加收費用"])},
  "user-detail.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住時間"])},
  "user-detail.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房時間"])},
  "user-detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房須知"])},
  "user-detail.amenities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設施與服務"])},
  "user-detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飯店注意事項"])},
  "user-detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂取消規定"])},
  "user-detail.booking_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂清單"])},
  "user-detail.number_of_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客數量"])},
  "user-detail.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人，", _interpolate(_named("n")), " 兒童"])},
  "user-detail.no_current_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前尚未預訂房型"])},
  "user-detail.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總金額"])},
  "user-detail.add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入清單"])},
  "user-detail.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
  "user-detail.no_room_is_available_for_dates_requested_please_change_the_dates_to_continue_booking_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前日期沒有空房，請查詢其他日期，謝謝"])},
  "user-detail.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢"])},
  "user-order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單資訊"])},
  "user-order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總房間數"])},
  "user-order.total_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總人數"])},
  "user-order.bed_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床型"])},
  "user-order.add-on_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加購項目"])},
  "user-order.subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小計"])},
  "user-order.add-on_parking_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停車位加購"])},
  "user-order.please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇"])},
  "user-order.domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "user-order.no_use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不使用"])},
  "user-order.use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用國旅補助"])},
  "user-order.use_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用優惠碼"])},
  "user-order.please_enter_your_promo_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入優惠碼"])},
  "user-order.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠"])},
  "user-order.please_fill_in_ID_numbers_for_domestic_travellers_and_passport_numbers_for_foreign_travellers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國內旅客請填身分證字號，國外旅客請填護照號碼。"])},
  "user-order.the_system_will_send_the_booking_confirmation_and_contact_you_via_the_email_address_and_mobile_number_provided_please_ensure_the_information_is_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系統將發送訂房確認單給您及後續聯繫，請務必正確核對電子信箱、手機號碼。"])},
  "user-order.check_the_spam_mailbox_if_you_do_not_receive_the_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信箱如沒收到請查看垃圾信箱。"])},
  "user-order.guest_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房客基本資料"])},
  "user-order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "user-order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的姓氏"])},
  "user-order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "user-eorder.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的名字"])},
  "user-order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "user-order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
  "user-order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
  "user-order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
  "user-order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國籍"])},
  "user-order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您的國籍"])},
  "user-order.id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證字號"])},
  "user-order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照號碼"])},
  "user-order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的身份證字號"])},
  "user-order.enter_valid_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的身分證字號"])},
  "user-order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的護照號碼"])},
  "user-order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的護照號碼"])},
  "user-order.id_card_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分證格式(A012345678)"])},
  "user-order.passport_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照格式( 9 位字元)"])},
  "user-order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號碼"])},
  "user-order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效的手機號碼"])},
  "user-order.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成！"])},
  "user-order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此欄位必填"])},
  "user-order.date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生年月日"])},
  "user-order.gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編號"])},
  "user-order.please_enter_gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入統一編號"])},
  "user-order.gui_composed_of_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編號為 8 碼"])},
  "user-order.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司抬頭"])},
  "user-order.please_enter_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入公司抬頭"])},
  "user-order.limited_255_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字數限制為 255 字"])},
  "user-order.estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預計到達時間"])},
  "user-order.please_enter_estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入預計到達時間"])},
  "user-order.country_of_departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出境國家"])},
  "user-order.elligibility_for_entry_to_taiwain_native_citizens_foreign_nationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入境資格(本國籍/外國籍(國籍別))"])},
  "user-order.special_medical_conditions_such_as_cardiovascular_or_chronic_diseases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否有特殊疾病，如心血管或慢性病"])},
  "user-order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抵達班機號碼"])},
  "user-order.special_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特殊需求"])},
  "user-order.please_note_that_the_hotel_does_not_guarantee_to_meet_your_needs_and_will_be_subject_to_on-site_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請注意，旅宿並不保證滿足您所提出的需求，將視現場情況而定。"])},
  "user-order.emergency_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急聯絡人資料"])},
  "user-order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡人姓氏"])},
  "user-order.please_enter_the_last_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入聯絡人姓氏"])},
  "user-order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡人名字"])},
  "user-order.please_enter_the_first_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入聯絡人名字"])},
  "user-order.contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡人電話"])},
  "user-order.please_enter_the_phone_number_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入聯絡人電話"])},
  "user-order.return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "user-order.confirm_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認訂單"])},
  "user-order.order_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的訂單已成立！"])},
  "user-order.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單編號"])},
  "user-order.payment_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款資訊"])},
  "user-order.payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
  "user-order.select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇付款方式"])},
  "user-order.please_provide_a_valid_unified_business_number_if_an_invoice_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票如需開立統編，請正確填寫統一編號。"])},
  "user-order.if_paid_by_the_unionpay_card_the_above_payment_success_page_is_only_the_reply_message_from_the_card_issuing_bank_please_refer_to_the_notification_from_the_property_you_booked_to_ensure_the_completion_of_your_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若支付方式為銀聯卡。上述支付成功頁面僅係您的銀聯卡發卡行回覆訊息，您的交易是否完成仍請以商店通知為準。"])},
  "user-order.cancellation_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂說明"])},
  "user-order.i_have_read_and_agree_to_the _terms_and_conditions_of_the_cacellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已閱讀與同意網站的退訂說明。"])},
  "user-order.proceed_to_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結帳去"])},
  "user-order.payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款金額"])},
  "user-order.note_full_payment_is_due_on_the_check-in_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註： 請入住當天付清款項"])},
  "user-order.thank_you_for_your_reservation_the_system_will_send_you_a_confirmation_for_the_reservation_to_your_email_address_please_should_there_be_any_questions_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝您的預訂，系統會寄訂單確認信件至您的信箱，若有疑問請聯繫我們。謝謝。"])},
  "user-homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務條款"])},
  "user-homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "user-unit.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間"])},
  "user-unit.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歲"])},
  "user-unit.NTD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元"])},
  "user-unit.stick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隻"])},
  "user-unit.piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個"])},
  "user-unit.portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份"])},
  "user-unit.tube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條"])},
  "user-unit.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "user-unit.bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床"])},
  "user-unit.pillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枕頭"])},
  "user-unit.blanket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被子"])},
  "user-unit.toiletries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盥洗用品"])},
  "user-unit.towel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毛巾"])},
  "user-unit.pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寵物"])},
  "user-date.january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一月"])},
  "user-date.fabruary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二月"])},
  "user-date.march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三月"])},
  "user-date.april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四月"])},
  "user-date.may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五月"])},
  "user-date.june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六月"])},
  "user-date.july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七月"])},
  "user-date.august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八月"])},
  "user-date.september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九月"])},
  "user-date.october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十月"])},
  "user-date.november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十一月"])},
  "user-date.december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十二月"])},
  "user-date.sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六"])},
  "user-date.sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "user-date.mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一"])},
  "user-date.tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二"])},
  "user-date.wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三"])},
  "user-date.thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四"])},
  "user-date.fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五"])},
  "user-nav.make_a_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房去"])},
  "user-nav.reservation_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂房中心"])},
  "user-nav.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
  "user-nav.chang_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更密碼"])},
  "user-nav.my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的帳戶"])},
  "user-nav.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
  "user-nav.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "user-nav.arriving_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將入住"])},
  "user-order.check_into_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住飯店"])},
  "user-order.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住時間"])},
  "user-order.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房時間"])},
  "user-order.order_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單價格"])},
  "user-order.order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單狀態"])},
  "user-order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款狀況"])},
  "user-order.pay_in_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付全額"])},
  "user-order.pay_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付訂金"])},
  "user-order.invoice_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票號碼"])},
  "user-order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票查詢"])},
  "user-order.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "user-dialog.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
  "user-dialog.please_contact_us_should_there_be_any_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若有任合疑問，請聯繫我們。"])},
  "user-dialog.consumer_sevice_hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服電話"])},
  "user-dialog.customer_service_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服 email"])},
  "user-dialog.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
  "user-homepage.invitation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦碼"])},
  "user-homepage.invitation_code_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正確的推薦碼"])},
  "hotel-group.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "hotel-group.north": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北區"])},
  "hotel-group.ila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭"])},
  "hotel-group.tyn_hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃竹"])},
  "hotel-group.center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部"])},
  "hotel-group.center1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部 1 區"])},
  "hotel-group.center2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部 2 區"])},
  "hotel-group.south": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南區"])},
  "hotel-group.foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國外"])},
  "userInfo.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已驗證"])}
}