export const validCreditCard = (value: string | undefined) => {
  value = value?.replace(/ /g, '') || ''
  if (/[^0-9-\s]+/.test(value)) return false
  let nCheck = 0
  let bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n)
    let nDigit = parseInt(cDigit, 10)
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9
    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

export const validCreditCardPeriod = (value: string | undefined) => {
  value = value || ''
  const today = new Date()
  const someday = new Date()
  const [exMonth, exYear] = value?.split('/') || [1, 1]
  if (/[^0-9-\s]+/.test(exMonth) || /[^0-9-\s]+/.test(exYear)) return false
  someday.setFullYear(Number('20' + exYear), Number(exMonth), 1)
  if (someday < today) {
    return false
  }
  return true
}

export const checkCreditCardNumberPrefix = (value: string | undefined) => {
  value = value?.replace(/ /g, '') || ''
  return /^\d{8}$/.test(value)
}
