/* eslint-disable camelcase */
import { Commit } from 'vuex'
import { myApi } from '@/api'
import { HotelDetail } from '@/store/types/hotel'
import store from '@/store'
import { Review } from '@/api/api'

interface popupType {
  isShow: boolean
  showOn: number
}

interface hotelState {
  popupShowRoom: popupType
  popupShowHotel: popupType
  hotelDetail: HotelDetail
  hotelDetailLoading: boolean
  reviewList: Review[]
  reviewPagination: {
    total?: number
    count?: number
    per_page?: number
    current_page?: number
    total_pages?: number
    links?: { previous?: string; next?: string }
  }
  reviewLoading: boolean
  hotelRanking: {
    one_rate: number
    two_rate: number
    three_rate: number
    four_rate: number
    five_rate: number
    review_amount: number
    score: number | null
  }
}

// initial state
const state: hotelState = {
  popupShowRoom: {
    isShow: false,
    showOn: 0
  },
  popupShowHotel: {
    isShow: false,
    showOn: 0
  },
  hotelDetail: {},
  hotelDetailLoading: false,
  reviewList: [],
  reviewPagination: {},
  reviewLoading: false,
  hotelRanking: {
    one_rate: 0,
    two_rate: 0,
    three_rate: 0,
    four_rate: 0,
    five_rate: 0,
    review_amount: 0,
    score: null
  }
}

// getters
const getters = {}

// actions
export interface HotelsDetailQueries {
  id: number
  query: {
    check_in: string
    check_out: string
    adults: number
    children: number
    'child_age[]'?: number
  }
}

export interface ReviewsQueries {
  hotel_id: number
  page?: number
  source?: 'google' | 'tourbobo' | 'tripadvisor'
  sorting: 'newest' | 'highest_ranking' | 'lowest_ranking'
}
const actions = {
  toggleRoomPopup({ commit }: { commit: Commit }, showOn: number): void {
    commit('setPopupShowRoom', showOn)
  },
  toggleHotelPopup({ commit }: { commit: Commit }, showOn: number): void {
    commit('setPopupShowHotel', showOn)
  },
  async fetchHotel({ commit }: { commit: Commit }, queries: HotelsDetailQueries): Promise<void> {
    commit('setDetailLoading', true)
    commit('setHotelDetail', {})
    const { data } = await myApi.mem.hotelsDetail(queries.id, queries.query)
    commit('setHotelDetail', data)
    commit('setDetailLoading', false)
    // when load hotel from URL, reset form business_type as hotel's business_type
    store.commit('form/setSearchCondition', { field: 'business_type', value: data.business_type })
    store.dispatch('options/setGuestNumber', data.business_type)
  },
  async fetchReviews({ commit }: { commit: Commit }, queries: ReviewsQueries): Promise<void> {
    commit('setReviewLoading')
    const { data } = await myApi.mem.reviewsList(queries)
    commit('setReviewList', data)
  }
}

// mutations
const mutations = {
  setPopupShowRoom(state: hotelState, showOn: number): void {
    state.popupShowRoom = {
      isShow: !state.popupShowRoom.isShow,
      showOn
    }
  },
  setPopupShowHotel(state: hotelState, showOn: number): void {
    state.popupShowHotel = {
      isShow: !state.popupShowHotel.isShow,
      showOn
    }
  },
  setHotelDetail(state: hotelState, hotel: hotelState['hotelDetail']): void {
    state.hotelDetail = hotel
  },
  setReviewList(state: hotelState, data: any): void {
    if (data.meta?.pagination?.current_page === 1) {
      state.reviewList = data.reviews || []
    } else {
      state.reviewList = [...state.reviewList].concat(data.reviews)
    }
    state.reviewPagination = data.meta.pagination
    state.reviewLoading = false
    state.hotelRanking = data.hotel_ranking
  },
  setReviewLoading(state: hotelState): void {
    state.reviewLoading = true
  },
  setDetailLoading(state: hotelState, loading: boolean): void {
    state.hotelDetailLoading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
