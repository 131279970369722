import { Commit } from 'vuex'
import { accountApi } from '@/api'
import { AccountState } from '../types/account'

const initMe = {
  id: '',
  name: '',
  email: '',
  remember_token: '',
  birthday: '',
  gender: '',
  mailing_email: '',
  phone: '',
  tel: '',
  nationality: '',
  id_number: '',
  county: '',
  area: '',
  address: '',
  created_date: '',
  tax_id: '',
  received_by: '',
  avatar: '',
  created_at: '',
  updated_at: '',
  from_hotel_id: null,
  verified: true
}

const state: AccountState = {
  me: initMe
}

// getters
const getters = {}

const actions = {
  resetAccount({ commit }: { commit: Commit }): void {
    commit('resetAccount', initMe)
  },
  async apiUser({ commit }: { commit: Commit }, token?: string): Promise<any> {
    const res = await accountApi.api.v1UserList(
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        : undefined
    )

    commit('setApiUser', res.data)
    return res
  },
  async updateUser({ commit }: { commit: Commit }, data: any): Promise<any> {
    const res = await accountApi.api.v1UserUpdate(data)
    commit('updateApiUser', res.data)
    return res
  },
  async googleOneTap({ commit }: { commit: Commit }, token: string): Promise<any> {
    const res = await accountApi.api.v1OnetapCreate(
      {
        token,
        client: 'mem'
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return res
  },
  async appleOneTap({ commit }: { commit: Commit }, code: string): Promise<any> {
    const res = await accountApi.api.v1OauthAppleCallbackCreate(
      {
        code,
        client: 'mem'
      },
      {
        headers: {
          Authorization: `Bearer ${code}`
        }
      }
    )
    return res
  },
  async updatePassword({ commit }: { commit: Commit }, data: any): Promise<any> {
    try {
      const res = await accountApi.api.v1UserResetPasswordCreate({
        user_id: state.me.id,
        ...data
      })
      return res
    } catch (error: any) {
      if (error.response) {
        return error.response
      }
    }
  }
}

// mutations
const mutations = {
  setApiUser(state: AccountState, data: AccountState['me']): void {
    state.me = data
  },
  updateApiUser(state: AccountState, data: AccountState['me']): void {
    state.me = {
      ...state.me,
      ...data
    }
  },
  resetAccount(state: AccountState): void {
    state.me = initMe
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
