import { Commit } from 'vuex'
import { myApi } from '@/api'
import {
  BookingState,
  BookingInfoProps,
  BookingInfoIdProps,
  BookingNumberProps,
  FillBookingQueries,
  GetBookingParam
} from '../types/bookings'
import { Statement } from '@/api/api'

// initial state
const state: BookingState = {
  bookingLoading: false,
  booking_number: null,
  hotel_id: null,
  plan_id: null,
  room_type_id: null,
  booking_info: {
    plan: {},
    room_type: {},
    hotel: {},
    cancellation_policy: null,
    travel_promotions: []
  },
  bookings: {}
}

// getters
const getters = {}

const actions = {
  async getFillBooking({ commit }: { commit: Commit }, query: FillBookingQueries): Promise<void> {
    commit('setBookLoading', true)
    const { data } = await myApi.mem.fillBookingList(query)
    commit('setBookingInfoIds', { key: 'hotel_id', id: query.hotel_id })
    commit('setBookingInfoIds', { key: 'plan_id', id: query.plan_id })
    commit('setBookingInfoIds', { key: 'room_type_id', id: query.room_type_id })
    commit('setBookLoading', false)

    if (data?.plan) commit('setBookingInfo', { key: 'plan', res: data.plan })
    if (data?.room_type) commit('setBookingInfo', { key: 'room_type', res: data.room_type })
    if (data?.hotel) commit('setBookingInfo', { key: 'hotel', res: data.hotel })
    if (data?.travel_promotions) commit('setBookingInfo', { key: 'travel_promotions', res: data.travel_promotions })
    if (data?.cancellation_policy) commit('setBookingInfo', { key: 'cancellation_policy', res: data.cancellation_policy })
  },
  async createBooking({ commit }: { commit: Commit }, data: any): Promise<any> {
    commit('setBookLoading', true)
    const res = await myApi.mem.bookingsCreate(data)
    if (res.status === 200) {
      commit('setBookingNumber', { value: res.data.booking?.booking_number })
    }

    commit('setBookLoading', false)
    return res
  },
  async getBooking({ commit }: { commit: Commit }, params: GetBookingParam): Promise<void> {
    const res = await myApi.mem.bookingsList(params)
    if (res.status === 200) {
      commit('setBooking', { success: true, ...res.data })
    } else {
      commit('setBooking', { success: false })
    }
  },
  setBookingInfoIds({ commit }: { commit: Commit }, { key, id }: BookingInfoIdProps): void {
    commit('setBookingInfoIds', { key, id })
  },
  setBookingNumber({ commit }: { commit: Commit }, { value }: BookingNumberProps): void {
    commit('setBookingNumber', { value })
  },
  setStatements({ commit }: { commit: Commit }, statements: Statement[]): void {
    commit('setBookingStatements', statements)
  }
}

// mutations
const mutations = {
  setBookingInfo(state: BookingState, { key, res }: BookingInfoProps): void {
    state.booking_info[key] = res
  },
  setBookingInfoIds(state: BookingState, { key, id }: BookingInfoIdProps): void {
    state[key] = id
  },
  setBookingNumber(state: BookingState, { value }: BookingNumberProps): void {
    state.booking_number = value
  },
  setBooking(state: BookingState, value: BookingState['bookings']): void {
    state.bookings = value
  },
  setBookLoading(state: BookingState, loading: boolean): void {
    state.bookingLoading = loading
  },
  setBookingStatements(state: BookingState, statements: Statement[]): void {
    state.bookings.statements = statements
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
