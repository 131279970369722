import { defineComponent, ref, onMounted, onUnmounted, provide } from 'vue';
export default defineComponent({
  setup: function setup() {
    var checkIsMobile = function checkIsMobile(width) {
      return width <= 812;
    };

    var checkIsHighDensity = function checkIsHighDensity() {
      return window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches) || window.devicePixelRatio && window.devicePixelRatio > 1.3;
    };

    var windowWidth = ref(window.innerWidth);
    var isMobile = ref(checkIsMobile(window.innerWidth));
    var isHighDensity = ref(checkIsHighDensity());
    var countryCode = ref('zh-TW');

    var onResize = function onResize() {
      windowWidth.value = window.innerWidth;
      isMobile.value = checkIsMobile(window.innerWidth);
    };

    onMounted(function () {
      window.addEventListener('resize', onResize);
      isHighDensity.value = checkIsHighDensity();
    });
    onUnmounted(function () {
      window.removeEventListener('resize', onResize);
    });
    provide('windowWidth', windowWidth);
    provide('isMobile', isMobile);
    provide('isHighDensity', isHighDensity);
    provide('countryCode', countryCode);
  }
});