import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true
    },
    svgClass: {
      type: String,
      required: false
    }
  }
});