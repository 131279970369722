import { useAuthStore } from '@/hook/useAuthStore'
import { defaultLang } from '@/i18n'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale(tw|jp|en)',
    component: () => import('../layout/Route.vue'),
    children: [
      { path: '', name: 'Home', component: () => import('../views/TravelHome.vue'), sensitive: true },
      {
        path: 'hotels',
        name: 'Hotels',
        component: () => import('../views/Hotels.vue')
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('../views/News.vue')
      },
      {
        path: 'hotel/:id',
        name: 'Hotel',
        component: () => import('../views/Hotel.vue')
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import('../views/Service.vue')
      },
      {
        path: 'service-mobile',
        name: 'ServiceMobile',
        component: () => import('../views/ServiceMobile.vue')
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy.vue')
      },
      {
        path: 'privacy-mobile',
        name: 'PrivacyMobile',
        component: () => import('../views/PrivacyMobile.vue')
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../views/Order.vue')
      },
      {
        path: 'order-form',
        name: 'OrderForm',
        component: () => import('../views/OrderForm.vue')
      },
      {
        path: 'paid',
        name: 'Payment',
        component: () => import('../views/Payment.vue')
      },
      {
        path: 'event/TSB',
        name: 'TSB',
        component: () => import('../views/Event.vue')
      },
      {
        path: 'event/TSBTRAVEL',
        name: 'TSBTRAVEL',
        component: () => import('../views/Event.vue')
      },
      {
        path: 'event/vipcar',
        name: 'Vipcar',
        component: () => import('../views/Event.vue')
      },
      {
        path: 'event/fubon',
        name: 'Fubon',
        component: () => import('../views/Event.vue')
      },
      {
        path: 'travel',
        name: 'TravelHome',
        component: () => import('../views/TravelHome.vue')
      },
      {
        path: 'travel/quarantine',
        name: 'Quarantine',
        component: () => import('../views/Home.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../views/Register.vue')
      },
      {
        path: 'personal/info',
        name: 'Information',
        component: () => import('../views/Information.vue')
      },
      {
        path: 'personal/wallet',
        name: 'Wallet',
        component: () => import('../views/Wallet.vue')
      },
      {
        path: 'personal/level',
        name: 'Level',
        component: () => import('../views/MemberLevel.vue')
      },
      {
        path: 'personal/login_method',
        name: 'LoginMethod',
        component: () => import('../views/LoginMethod.vue')
      },
      {
        path: 'personal/security',
        name: 'Security',
        component: () => import('../views/Security.vue')
      },
      {
        path: 'personal/coupon',
        name: 'Coupon',
        component: () => import('../views/Coupon.vue')
      },
      {
        path: 'oauth/callback',
        name: 'OauthCallback',
        component: () => import('../views/Callback.vue')
      },
      {
        path: '/callback',
        name: 'Callback',
        component: () => import('../views/Callback.vue')
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('../views/Forgot.vue')
      },
      {
        path: 'invitation',
        name: 'Invitation',
        component: () => import('../views/Invitation.vue')
      },
      {
        path: 'membership',
        name: 'Membership',
        component: () => import('../views/Membership.vue')
      },
      {
        path: 'personal/order',
        name: 'OrderList',
        component: () => import('../views/OrderList.vue')
      },
      {
        path: ':pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.locale) {
    next()
  } else {
    next(`/${defaultLang}${to.fullPath}`)
  }
})
export default router
