import { Commit } from 'vuex'
import { myApi } from '@/api'
import { Keyword } from '@/api/api'
import { RootState } from '@/store/types/root'

import { City, CountryCode, Nationality, SortingEnum, FeatureToggles, OptionsState } from '../types/options'

interface OptionsGetters {
  getCityLocation: (state: OptionsState) => {
    lat: number
    lng: number
  }
}

type BusinessType = 1 | 2

// initial state
const state: OptionsState = {
  countryCode: localStorage.getItem('locale') || 'tw',
  cities: [],
  twPopularCities: [],
  jpPopularCities: [],
  countryCodes: [],
  nationalities: [],
  keywords: [],
  people: [
    {
      lang_key: 'adult',
      n_lang_key: 'n_adults',
      value: 'adults',
      max: 1,
      min: 1,
      defaultValue: 1
    },
    {
      lang_key: 'children',
      n_lang_key: 'n_children',
      value: 'children',
      max: 0,
      min: 0,
      defaultValue: 0
    },
    {
      lang_key: 'room',
      n_lang_key: 'n_room',
      value: 'room',
      max: 1,
      min: 1,
      defaultValue: 1
    }
  ],
  childAges: Array.from(Array(18).keys()),
  genderOptions: [
    {
      label: '男',
      lang_key: 'order.male',
      value: 'MALE'
    },
    {
      label: '女',
      lang_key: 'order.female',
      value: 'FEMALE'
    }
  ],
  meatDietOptions: [
    {
      label: '葷',
      lang_key: 'order.non_vegan',
      value: true
    },
    {
      label: '素',
      lang_key: 'order.vegan',
      value: false
    }
  ],
  hasDiseaseOptions: [
    {
      label: '否',
      lang_key: 'order.no',
      value: false
    },
    {
      label: '是',
      lang_key: 'order.yes',
      value: true
    }
  ],
  nationalTravelOptions: [
    {
      label: '不使用',
      lang_key: 'order.national_travel.no_use',
      value: false
    },
    {
      label: '使用補助（現場折抵）',
      lang_key: 'order.national_travel.use',
      value: true
    }
  ],
  vaccineOptions: [
    {
      label: '未接種三劑',
      lang_key: 'order.national_travel.no_three_shots_of_vaccine',
      value: false
    },
    {
      label: '已完整接種三劑',
      lang_key: 'order.national_travel.had_three_shots_of_vaccine',
      value: true
    }
  ],
  sortingItems: [
    {
      name: SortingEnum.Recommend,
      label: '為您推薦'
    },
    {
      name: SortingEnum.LowestPrice,
      label: '價格最低'
    },
    {
      name: SortingEnum.HightestScore,
      label: '評分最高'
    },
    {
      name: SortingEnum.HightestPrice,
      label: '價格最高'
    }
  ],
  featureToggles: {
    arrival_time: true,
    meat: true,
    extra: true
  }
}

// getters
const getters = {
  getCityLocation: (state: OptionsState, getters: OptionsGetters, rootState: RootState, rootGetters: any) => {
    const currentCity = state.cities.find((city) => city.city_id === rootState.form.search.city)
    const cityLocation = currentCity
      ? {
          lat: currentCity.lat,
          lng: currentCity.lng
        }
      : rootGetters['hotels/getFirstHotelLocation']
    return cityLocation
  }
}

// actions
const actions = {
  async getAllCities({ commit }: { commit: Commit }): Promise<void> {
    const { data } = await myApi.mem.newCitiesList()
    commit('setCities', [
      ...(data.cities || []),
      {
        city_en_name: 'osaka',
        city_id: 25,
        city_name: '大阪市',
        image: 'https://ota-image.tourbobo.com/image/hotel/1039n8MQKWcyycZGtIBN76Si.jpg?p=ota_hotel_large',
        lat: '34.69374123359254',
        lng: '135.51813219960886'
      }
    ])
  },
  async getPopularTWCities({ commit }: { commit: Commit }, businessType: BusinessType): Promise<void> {
    const { data } = await myApi.mem.newCitiesList({ business_type: businessType, country: 'tw' })

    commit('setTWPopularCities', data.popular)
  },
  async getPopularJPCities({ commit }: { commit: Commit }, businessType: BusinessType): Promise<void> {
    const { data } = await myApi.mem.newCitiesList({ business_type: businessType, country: 'jp' })

    commit(
      'setJPPopularCities',
      (data.popular || []).filter((city) => (city.city_en_name || '')?.toLowerCase() === 'osaka')
    )
  },
  async getAllCountryCodes({ commit }: { commit: Commit }): Promise<void> {
    const { data } = await myApi.mem.countryCodesList()
    commit('setCountryCodes', data)
  },
  async getAllNationalities({ commit }: { commit: Commit }): Promise<void> {
    const { data } = await myApi.mem.nationalitiesList()
    commit('setNationalities', data)
  },
  async getFeatureToggles({ commit }: { commit: Commit }): Promise<void> {
    const { data } = await myApi.mem.featureTogglesList()
    commit('setfeatureTogglesList', data)
  },
  async getKeywords({ commit }: { commit: Commit }): Promise<void> {
    const { data } = await myApi.mem.keywordsList()
    commit('setKeywordList', data)
  },
  setCountryCode({ commit }: { commit: Commit }, countryCode: string): void {
    commit('setCountryCode', countryCode)
  },
  setGuestNumber({ commit }: { commit: Commit }, businessType: BusinessType): void {
    if (businessType === 1) {
      commit('setNormalHotelGuest')
    } else {
      commit('setQuarantineHotelGuest')
    }
  }
}

// mutations
const mutations = {
  setCities(state: OptionsState, cities: City[]): void {
    state.cities = cities
  },
  setTWPopularCities(state: OptionsState, cities: City[]): void {
    state.twPopularCities = cities
  },
  setJPPopularCities(state: OptionsState, cities: City[]): void {
    state.jpPopularCities = cities
  },
  setCountryCodes(state: OptionsState, countryCodes: CountryCode[]): void {
    state.countryCodes = countryCodes
  },
  setNationalities(state: OptionsState, nationalities: Nationality[]): void {
    state.nationalities = nationalities
  },
  setfeatureTogglesList(state: OptionsState, featureToggles: FeatureToggles): void {
    state.featureToggles = featureToggles
  },
  setKeywordList(state: OptionsState, keywordList: Keyword[]): void {
    state.keywords = keywordList.sort((a: Keyword, b: Keyword) => {
      if (a.type && b.type) {
        return a.type > b.type ? 1 : -1
      } else {
        return 0
      }
    })
  },
  setCountryCode(state: OptionsState, countryCode: string): void {
    state.countryCode = countryCode
  },
  setNormalHotelGuest(state: OptionsState): void {
    state.people[0].max = 30
    state.people[0].min = 1
    state.people[1].max = 30
    state.people[1].min = 0
  },
  setQuarantineHotelGuest(state: OptionsState): void {
    state.people[0].max = 1
    state.people[0].min = 1
    state.people[1].max = 0
    state.people[1].min = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
