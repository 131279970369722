import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Resizer from '@/components/share/Resizer.vue';
import Global from '@/components/share/Global.vue';
import { parse, startOfToday, isPast, addDays } from 'date-fns';
import { setKeywordMeta } from '@/utils/setKeywordMeta';
export default defineComponent({
  components: {
    Resizer: Resizer,
    Global: Global
  },
  setup: function setup() {
    var store = useStore();
    var router = useRouter();

    if (store.state.options.cities.length === 0) {
      store.dispatch('options/getAllCities');
    }

    if (store.state.options.keywords.length === 0) {
      store.dispatch('options/getKeywords');
    }

    onMounted(function () {
      store.dispatch('options/getFeatureToggles');
    }); // update store when router changed

    watch(function () {
      return router.currentRoute.value.query;
    }, function (newValue) {
      var checkIn = new Date();

      if (newValue.check_in) {
        var val = parse(newValue.check_in, 'yyyy-MM-dd', new Date());
        checkIn = isPast(val) ? startOfToday() : val;
        store.dispatch('form/setSearchCondition', {
          field: 'startDate',
          value: checkIn
        });
      }

      if (newValue.check_out) {
        var _val = parse(newValue.check_out, 'yyyy-MM-dd', new Date());

        var checkOut = isPast(_val) ? addDays(checkIn, 5) : _val;
        store.dispatch('form/setSearchCondition', {
          field: 'endDate',
          value: checkOut
        });
      }

      if (newValue.business_type) {
        store.dispatch('form/setSearchCondition', {
          field: 'business_type',
          value: Number(newValue.business_type)
        });
        store.dispatch('options/setGuestNumber', Number(newValue.business_type));
        setKeywordMeta(newValue.business_type);
      }

      if (newValue.city) store.dispatch('form/setSearchCondition', {
        field: 'city',
        value: Number(newValue.city)
      });
      if (newValue.adults) store.dispatch('form/setSearchCondition', {
        field: 'adults',
        value: Number(newValue.adults)
      });
      if (newValue.children) store.dispatch('form/setSearchCondition', {
        field: 'children',
        value: Number(newValue.children)
      });
      if (newValue.lowest_price) store.dispatch('form/setSearchCondition', {
        field: 'lowest_price',
        value: newValue.lowest_price
      });
      if (newValue.highest_price) store.dispatch('form/setSearchCondition', {
        field: 'highest_price',
        value: newValue.highest_price
      });
      if (newValue.filter) store.dispatch('form/setSearchCondition', {
        field: 'filter',
        value: newValue.filter
      });

      if (newValue.hotel_facility) {
        var isString = typeof newValue.hotel_facility === 'string';
        store.dispatch('form/setSearchCondition', {
          field: 'hotel_facility',
          value: isString ? [newValue.hotel_facility] : newValue.hotel_facility
        });
      }

      if (newValue.room_facility) {
        var _isString = typeof newValue.room_facility === 'string';

        store.dispatch('form/setSearchCondition', {
          field: 'room_facility',
          value: _isString ? [newValue.room_facility] : newValue.room_facility
        });
      }

      if (newValue.hotel_id) store.dispatch('booking/setBookingInfoIds', {
        key: 'hotel_id',
        id: newValue.hotel_id
      });
      if (newValue.plan_id) store.dispatch('booking/setBookingInfoIds', {
        key: 'plan_id',
        id: newValue.plan_id
      });
      if (newValue.room_type_id) store.dispatch('booking/setBookingInfoIds', {
        key: 'room_type_id',
        id: newValue.room_type_id
      });
    });
  }
});