export default {
  "facility-groups.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気の施設とサービス"])},
  "facility.24hours_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン（24時間対応）"])},
  "facility.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ預かりサービス"])},
  "facility.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達預かりサービス"])},
  "facility.laundrydelivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランドリーサービス"])},
  "facility.entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽機器レンタル"])},
  "facility.3c_peripheral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電気・周辺機器レンタル"])},
  "facility.non-smokinghotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全館禁煙"])},
  "facility-groups.counter_and_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付とサービス"])},
  "facility.24hours_counter_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カウンターサービス（24時間対応）"])},
  "facility.24hours_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルームサービス（24時間対応）"])},
  "facility.luggage_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["荷物預かりサービス"])},
  "facility.master_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masterカード"])},
  "facility.visa_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visaカード"])},
  "facility.electronic_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子決済サービス"])},
  "facility.personal_checkincheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン/チェックアウトの専門アシスタント"])},
  "facility.ondutybutler_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バトラーサービス"])},
  "facility.foreign_currency_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["両替"])},
  "facility.mail_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便サービス"])},
  "facility.security_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セーフティボックスレンタル"])},
  "facility.ticket_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット代行販売"])},
  "facility.morning_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モーニングコール"])},
  "facility-groups.internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インターネット"])},
  "facility.public_free_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有エリア内（Wi-Fi無料）"])},
  "facility.free_wifi_forallrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全室（Wi-Fi無料）"])},
  "facility.paid_internetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インターネット（有料）"])},
  "facility.portable_wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポケット型Wi-Fiレンタル"])},
  "facility-groups.leisure_and_ent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽設備"])},
  "facility.gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィットネスセンター"])},
  "facility.videogame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームルーム"])},
  "facility.cinema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シネマ"])},
  "facility.karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カラオケ"])},
  "facility.steamroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スチームルーム"])},
  "facility.hotspring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温泉"])},
  "facility.sauna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウナ"])},
  "facility.hairdressingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘアサロン"])},
  "facility.beautyservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美容サービス"])},
  "facility.spa-massageservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPA マッサージサービス"])},
  "facility.massagechair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッサージチェア"])},
  "facility.indoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屋内プール"])},
  "facility.outdoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屋外プール"])},
  "facility.healthpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康遊歩道"])},
  "facility.forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["森風呂"])},
  "facility-groups.catering_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケータリングサービス"])},
  "facility.restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レストラン"])},
  "facility.barbecue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーベキューエリア"])},
  "facility.roomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルームサービス"])},
  "facility.bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バー"])},
  "facility.cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーヒーショップ"])},
  "facility.openkitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープンキッチン"])},
  "facility.oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーブン"])},
  "facility.weddingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウェディングサービス"])},
  "facility.snack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料ウェルカムドリンク"])},
  "facility.publicdrinkingfountain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共のウォーターサーバー"])},
  "facility-groups.complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総合設備"])},
  "facility.barrier-freefacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バリアフリー設備"])},
  "facility.elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エレベーター"])},
  "facility.atm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金引き出し"])},
  "facility.conveniencestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近くのコンビニ"])},
  "facility.smokingzone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙所"])},
  "facility.smokingroomtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙室があります"])},
  "facility.giftshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["館内ショップ"])},
  "facility.businesscenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスセンター"])},
  "facility.meetingroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会議室"])},
  "facility.faxmachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファックス"])},
  "facility.printer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プリンター"])},
  "facility.freenewspaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新聞（無料）"])},
  "facility.terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バルコニー"])},
  "facility.laundryroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン式洗濯機"])},
  "facility.infirmary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["診療所"])},
  "facility.locker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロッカー"])},
  "facility.banquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宴会場"])},
  "facility.saloon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラウンジ"])},
  "facility.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["図書館"])},
  "facility-groups.language_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語サービス"])},
  "facility.chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国語"])},
  "facility.english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "facility.japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "facility.korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語"])},
  "facility-groups.health_and_safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康と安全"])},
  "facility.dailycleaningdisinfection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日共用エリアの消毒を実施"])},
  "facility.takeoutthegarbage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の外での毎日のごみ収集"])},
  "facility.disinfectromm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日客室の消毒を実施"])},
  "facility.thermometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲストおよび従業員の検温実施"])},
  "facility.wearmasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従業員による顔面保護具の着用"])},
  "facility.firstaidkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["救急箱"])},
  "facility.24hoursecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間セキュリティ"])},
  "facility.fireextinguisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消火器"])},
  "facility.sprinklerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スプリンクラー設備"])},
  "facility.smokedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙検知器"])},
  "facility.carbonmonoxidedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一酸化炭素検出器"])},
  "facility.cctvmonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCTVモニター"])},
  "facility.aed-automatedexternaldefibrillator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AED"])},
  "facility-groups.transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通アクセス"])},
  "facility.freeairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空港送迎（無料）"])},
  "facility.paidairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空港送迎（有料）"])},
  "facility.valetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タクシーサービス"])},
  "facility.reservationparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事前予約駐車場"])},
  "facility.freeparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料駐車場（数量限定）"])},
  "facility.paidparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["駐車場（有料）"])},
  "facility.bicyclerental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自転車レンタル"])},
  "facility.charteredtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャーターツアー"])},
  "facility.traveladvisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行のアドバイス"])},
  "facility.electricvehiclechargingstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電気自動車充電スタンド"])},
  "facility.playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供遊び場"])},
  "facility.kids_meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キッズミール"])},
  "facility.kids_cutlery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供用食器"])},
  "facility.kids_paddling_pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供用プール"])},
  "facility.ball_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボールプール"])},
  "facility.slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滑り台"])},
  "facility.kids_books_or_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供向けの本、ビデオ、音楽"])},
  "facility.family_tour_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファミリーツアーパッケージ"])},
  "facility.crib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["べビーベッド"])},
  "facility.baby_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベビーバス"])},
  "facility.feeding_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哺乳瓶の消毒サービスを提供"])},
  "facility.baby_stroller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベビーカー"])},
  "facility.pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット"])},
  "facility.no_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット禁止"])},
  "facility.pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット同伴可"])},
  "facility.pets_allowed_in_the_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット入室可"])},
  "facility.small_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小型ペット同伴可"])},
  "facility.large_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大型ペット同伴可"])},
  "facility.pets_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペットミール提供"])},
  "facility.pets_snacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペットおやつ提供"])},
  "facility.pets_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペットホテル"])},
  "facility-groups.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の特徴"])},
  "facility.roomsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空間が広い"])},
  "facility.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["窓開け可"])},
  "facility.non-smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁煙ルーム"])},
  "facility.smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙ルーム"])},
  "facility.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴槽"])},
  "facility.onlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインビデオ"])},
  "facility.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートバスルーム"])},
  "facility.showerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートバスルーム"])},
  "facility.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共用シャワー"])},
  "facility.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドタイプ"])},
  "facility.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大きな窓"])},
  "facility.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小さな窓"])},
  "facility.airlight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エアタイト窓"])},
  "facility.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピクチャーウインドー"])},
  "facility.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バルコニー"])},
  "facility.king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド"])},
  "facility.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド　2台"])},
  "facility.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シングルベッド 1台"])},
  "facility.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ツイン"])},
  "facility.bunk_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二段ベッド"])},
  "facility.king_and_single_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド1台とシングベッドル1台"])},
  "facility.king_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド1台とツインベッド"])},
  "facility.four_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シングルベッド 4台"])},
  "facility.three_king_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド3台"])},
  "facility.three_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シングルベッド　3台"])},
  "facility.king_bed_or_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッドまたはツイン"])},
  "facility.double_king_bed_and_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド2台シングルベッド1台"])},
  "facility.double_king_bed_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッド2台シングルベッド2台"])},
  "facility.king_and_mattress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダブルベッドとベッドマット追加"])},
  "facility.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミダブルベッド2台"])},
  "facility.queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミダブルベッド1台"])},
  "facility.double_bed_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ツイン（組み合わせ）"])},
  "facility-groups.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の設備"])},
  "facility.officedeskschairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフィスデスクチェア"])},
  "facility.readinglight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読書灯"])},
  "facility.sofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソファ"])},
  "facility.indoorslippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スリッパ"])},
  "facility.roommagnetickeycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドアカード"])},
  "facility.roomkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の鍵"])},
  "facility.airconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エアコン"])},
  "facility.heater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖房"])},
  "facility.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "facility-groups.bathroom_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴室設備"])},
  "facility.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖房便座"])},
  "facility.singlebathtub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シングルバスタブ"])},
  "facility.jacuzzi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジャグジー"])},
  "facility.dryandwetseparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乾湿分離"])},
  "facility.largetowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大きなタオル"])},
  "facility.smalltowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小さなタオル"])},
  "facility.toothbrushtoothpaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歯ブラシ歯磨き粉"])},
  "facility.hairdryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘアドライヤー"])},
  "facility.bodywash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボディウォッシュ"])},
  "facility.shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シャンプー"])},
  "facility.hairconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンディショナー"])},
  "facility.facewash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗顔料"])},
  "facility.razor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["かみそり"])},
  "facility.soap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石鹸"])},
  "facility.showercap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シャワーキャップ"])},
  "facility.comb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["櫛"])},
  "facility.bathrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスローブ"])},
  "facility.cottonswab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綿棒"])},
  "facility-groups.ent_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽設備"])},
  "facility.freeonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインビデオ（無料）"])},
  "facility.paidonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインビデオ（有料）"])},
  "facility.entertainmentequipmentrental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポーツ用具レンタル"])},
  "facility.paidwi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi（有料）"])},
  "facility.freewi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi（無料）"])},
  "facility.paytv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テレビ（有料）"])},
  "facility.freetv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テレビ（無料）"])},
  "facility-groups.catering_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケータリング機器"])},
  "facility.kettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法瓶/電気ケトル"])},
  "facility.freeteabags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティーバッグ（無料）"])},
  "facility.freeinstantcoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インスタントコーヒー（無料）"])},
  "facility.coffeemachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーヒーメーカー"])},
  "facility.paidsnacanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["軽食と飲み物（有料）"])},
  "facility.freesnacksanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["軽食と飲み物（無料）"])},
  "facility.24-hourroomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルームサービス（24時間対応）"])},
  "facility.refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冷蔵庫"])},
  "facility.freebottlewater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミネラルウォーター（無料）"])},
  "facility-groups.clothing_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衣装に関する設備"])},
  "facility.laundrybag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗濯物袋"])},
  "facility.wardrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワードローブ"])},
  "facility.ironingequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイロン設備"])},
  "facility.hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハンガー"])},
  "facility-groups.safety_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全装置"])},
  "facility.smokedetector_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙検知器"])},
  "facility.securitybox_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セーフティボックス（フロント）"])},
  "facility.fireextinguisher_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消火器"])},
  "room_category.single_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1人部屋"])},
  "room_category.double_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2人部屋"])},
  "room_category.triple_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3人部屋"])},
  "room_category.four_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4人部屋"])},
  "room_category.five_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5人部屋"])},
  "room_category.six_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6人部屋"])},
  "room_category.seven_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7人部屋"])},
  "room_category.eight_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8人部屋"])},
  "room_category.nine_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9人部屋"])},
  "room_category.ten_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10人部屋"])},
  "room_category.bag_room_mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミトリー（男女共用）"])},
  "room_category.bag_room_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミトリー（女性専用）"])},
  "room_category.bag_room_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミトリー（男性専用）"])},
  "room_category.all_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一棟まるごと貸切"])},
  "room_category.all_floor_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フロアー貸切"])},
  "room_category.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンダードダブルベッド"])},
  "room_category.queen_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイーンサイズダブルベッド"])},
  "room_category.king_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キングサイズダブルベッド"])},
  "room_category.semi_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミダブルベッド"])},
  "room_category.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミシングルベッド"])},
  "room_category.super_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シングルベッド"])},
  "room_category.toddler_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供ベッド"])},
  "room_category.baby_cribs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベビーベッド"])},
  "facility.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室の人気施設"])},
  "facility.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空間が広い"])},
  "facility.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi（無料）"])},
  "facility.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフィスデスクチェア"])},
  "facility.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁煙ルーム"])},
  "facility.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙ルーム"])},
  "facility.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル人気サービス"])},
  "facility.24_hour_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン（24時間対応）"])},
  "facility.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランドリーサポート"])},
  "facility.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽設備のご案内"])},
  "facility.rental_3_c_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電気・周辺機器レンタル"])},
  "facility.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全館禁煙"])},
  "filter.multi-ents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元化施設"])},
  "filter.high-ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優秀宿泊施設"])},
  "filter.parent-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子連れ向け宿泊施設"])},
  "filter.pet-friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペットフレンドリー"])},
  "filter.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンダードダブルベッド"])},
  "filter.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クイーンサイズダブルベッド"])},
  "filter.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キングサイズダブルベッド"])},
  "filter.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セミシングルベッド"])},
  "filter.1km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1キロ以内"])},
  "filter.3km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3キロ以内"])},
  "filter.5km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5キロ以内"])},
  "filter.home_quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル検疫"])},
  "filter.migrant_worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外国人労働者向け"])},
  "filter.travel-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "tag.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空間が広い"])},
  "tag.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バルコニー"])},
  "tag.openable_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["窓開け可"])},
  "tag.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフィスデスクチェア"])},
  "tag.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全館禁煙"])},
  "tag.streaming_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインビデオ"])},
  "tag.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽設備のご案内"])},
  "tag.rental_sport_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポーツ用具レンタル"])},
  "tag.24_hour_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン（24時間対応）"])},
  "tag.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖房便座"])},
  "tag.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達預かりサービス"])},
  "tag.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ預かりサービス"])},
  "tag.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランドリーサポート"])},
  "tag.free_wi_fi_in_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全室（Wi-Fi無料）"])},
  "tag.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi（無料）"])},
  "tag.24_hour_front_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カウンターサービス（24時間対応）"])},
  "tag.24_hour_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルームサービス（24時間対応）"])},
  "homepage.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫関連速報"])},
  "homepage.prevention_grants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫サポート"])},
  "homepage.prevention_fa_qs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫Q&A"])},
  "homepage.check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約を検索"])},
  "homepage.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約記録が見つからない"])},
  "homepage.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾全国"])},
  "homepage.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "homepage.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "homepage.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "homepage.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "homepage.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "homepage.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "homepage.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "homepage.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "homepage.yilan_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭県"])},
  "homepage.hsinchu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹県"])},
  "homepage.miaoli_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗栗県"])},
  "homepage.changhua_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彰化県"])},
  "homepage.nantou_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投県"])},
  "homepage.yunlin_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲林県"])},
  "homepage.chiayi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嘉義市"])},
  "homepage.chiayi_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嘉義県"])},
  "homepage.penghu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澎湖県"])},
  "homepage.pingtung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東県"])},
  "homepage.taitung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台東県"])},
  "homepage.hualien_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮県"])},
  "homepage.kinmen_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金門県"])},
  "homepage.lienchiang_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連江県"])},
  "homepage.south_china_sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南海諸島"])},
  "homepage.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 泊"])},
  "homepage.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人"])},
  "homepage.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 子供"])},
  "homepage.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 部屋"])},
  "homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "homepage.popular_quarantine_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気防疫ホテル"])},
  "homepage.all_county_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "homepage.taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北"])},
  "homepage.new_taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北"])},
  "homepage.taoyuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園"])},
  "homepage.hsinchu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹"])},
  "homepage.taichung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中"])},
  "homepage.tainan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南"])},
  "homepage.kaohsiung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄"])},
  "homepage.from_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から/ 泊"])},
  "homepage.see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の宿泊施設を探す"])},
  "homepage.hot_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おすすめスポット"])},
  "homepage.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大部屋"])},
  "homepage.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["窓開け可"])},
  "homepage.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料Wi-Fi"])},
  "homepage.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴槽"])},
  "homepage.prevention_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫情報"])},
  "homepage.about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["弊社について"])},
  "homepage.cooperation_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["協力提案"])},
  "homepage.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
  "homepage.service_and_unsubscribe_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用規約およびキャンセル規定"])},
  "homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
  "homepage.customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客サービス"])},
  "homepage.monday_to_friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月曜日〜金曜日"])},
  "homepage.official_customer_service_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公式LINEアカウント"])},
  "homepage.mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
  "homepage.customer_service_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービス時間"])},
  "homepage.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "homepage.tourbobo_travel_service_co_ltd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["易福利旅行社股份有限公司"])},
  "homepage.uniform_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一番号"])},
  "homepage.class_a_travel_agencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甲種旅行社"])},
  "homepage.tourism_a_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["観甲字 ", _interpolate(_named("n")), " 号"])},
  "homepage.tqaa_memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品保協会会員"])},
  "homepage.north_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["字第北 ", _interpolate(_named("n")), " 号"])},
  "homepage.social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティメディア"])},
  "homepage.enter_a_destination_or_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご宿泊の県市、ホテルをお選びください"])},
  "homepage.select_the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊日をお選びください"])},
  "homepage.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "homepage.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火"])},
  "homepage.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "homepage.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
  "homepage.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金"])},
  "homepage.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土"])},
  "homepage.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "homepage.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定"])},
  "homepage.please_select_the_number_of_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人数をお選びください"])},
  "homepage.adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "homepage.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
  "homepage.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋"])},
  "homepage.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫規定により、防疫ホテルは原則お一人様一室となります。同居が必要の場合は、政府防疫規定にお従いください。"])},
  "homepage.please_select_the_age_of_the_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お子様の年齢をお選びください"])},
  "homepage.years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歲"])},
  "homepage.hot_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気記事"])},
  "homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容"])},
  "homepage.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
  "homepage.please_enter_your_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
  "homepage.please_enter_a_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスを入力してください"])},
  "homepage.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号"])},
  "homepage.please_enter_your_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号を入力してください"])},
  "homepage.please_enter_a_valid_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な予約番号を入力してください"])},
  "homepage.inquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "homepage.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニュー"])},
  "homepage.manage_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約管理"])},
  "homepage.destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地"])},
  "homepage.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン日"])},
  "homepage.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト日"])},
  "homepage.guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約人数"])},
  "homepage.please_enter_a_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地をご入力ください"])},
  "homepage.n_people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 人"])},
  "homepage.setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
  "homepage.please_pick_your_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語をご選択ください"])},
  "homepage.the_quarantine_protocol_accepts_only_one_person_in_one_room_in_principle_n_please_contact_the_customer_service_for_the_extra_person_rules_and_the_person_added_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コロナ対策により、検疫ホテルを宿泊する時は原則として一人が一部屋に泊まる。同じ部屋に宿泊する要求がある場合、カスタマーサービスに相関規則及び価格をお問い合わせください。"])},
  "homepage.n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 歲"])},
  "homepage.n_from_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から/ 泊"])},
  "homepage.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供の年齢"])},
  "homepage.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋キープカウントダウン"])},
  "homepage.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル・旅館分類"])},
  "homepage.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
  "homepage.common_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般のホテル・旅館を探す"])},
  "homepage.quarantine_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫ホテル・旅館を探す"])},
  "homepage.unique_hotels_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なホテル・旅館"])},
  "homepage.nantou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投"])},
  "homepage.yilan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭"])},
  "homepage.hualien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮"])},
  "homepage.taitung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台東"])},
  "homepage.pingtung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東"])},
  "homepage.normal_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般ホテル"])},
  "homepage.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫ホテル"])},
  "homepage.self_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主的健康管理"])},
  "homepage.multi_purpose_amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元化施設"])},
  "homepage.quality_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優秀宿泊施設"])},
  "homepage.family_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子連れ向け宿泊施設"])},
  "homepage.pet_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペットフレンドリー"])},
  "homepage.hot_city_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾人気都市"])},
  "homepage.recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気のオススメ"])},
  "homepage.datepicker_hint_for_quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫ホテルを宿泊する際に、検疫政策により規定する宿泊数が満たさなければチェックアウトできません。システムから自動的に規定する宿泊数を選択されます。"])},
  "homepage.tokyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東京"])},
  "homepage.osaka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大阪"])},
  "homepage.kyoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["京都"])},
  "homepage.nara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奈良"])},
  "homepage.hokkaido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北海道"])},
  "homepage.kyushu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九州"])},
  "homepage.switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換"])},
  "homepage.hotel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設タイプ"])},
  "homepage.choose_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨を選択してください"])},
  "homepage.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["さらに多く"])},
  "homepage.choose_type_and_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日程を選択してください"])},
  "homepage.about_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourboboについて"])},
  "homepage.new_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新情報"])},
  "homepage.tourbobo_accompanies_you_to_enjoy_each_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboはすべてのご旅程をサポートいたします"])},
  "homepage.starting_from_taiwan_tourbobo_is_dedicated_to_forging_a_simple_and_useful_booking_platform_we_not_only_gather_and_collect_quarantine_hotels_in_entire_taiwan_but_provide_profuse_common_hotels_we_expect_to_enter_the_asian_market_in_the_future_to_link_up_to_the_world_and_give_our_assistance_so_that_every_guest_can_travel_happily_and_go_home_feeling_carefree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboは、台湾発のシンプルで簡単な予約プラットフォームです。全台湾の防疫ホテルだけでなく、一般の宿泊施設も幅広くご紹介します。今後、アジアを始め、世界中を皆さまが楽しく安心におでかけできるようサポートさせていただきたいと考えております。"])},
  "homepage.in_tourbobo_you_will_enjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboでお楽しみいただけます："])},
  "homepage.service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["・お客様に寄り添ったアドバイスとサポート\n・安心の宿泊施設\n・シンプルで直感的な予約プロセス\n・わかりやすい価格、キャンペーンも実施\n・人気設備から理想の宿泊施設をセレクト"])},
  "homepage.tourbobo_makes_a_journey_easy_relaxed_with_personal_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboは旅行をより簡単、気軽にお客様をサポート"])},
  "homepage.if_your_case_is_of_personal_health_management_only_by_completely_staying_for_the_number_of_days_stipulated_by_the_quarantine_policy_can_you_check_out_the_system_will_select_the_stipulated_number_of_days_automatically_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主隔離の場合、防疫規則で定められた日数の滞在後にチェックアウトが可能となります。自動システムにより規定の日数が選択されます！"])},
  "homepage.if_your_case_is_of_personal_health_management_it_will_be_one_person_per_room_if_you_have_any_demands_of_living_together_to_take_care_of_the_other_person_for_the_relevant_rules_please_follow_the_policy_issued_by_the_government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主隔離の場合、原則お一人様一室となります。同居が必要の場合、台湾政府より発布された防疫規則にお従いください。"])},
  "homepage.to_fill_up_the_personal_data_completely_sooner_will_it_be_for_your_booking_next_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料がすべて記入されました。次回よりご予約がより簡単になります！"])},
  "homepage.fill_up_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直ちに記入"])},
  "homepage.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
  "homepage.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
  "homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名"])},
  "homepage.this_is_the_email_address_of_which_you_use_to_login_we_will_also_send_a_reservation_confirmation_letter_to_this_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こちらはログインに使用するメールアドレスで、ご予約確認メールもこちらへ送信されます"])},
  "homepage.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "homepage.keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "homepage.booking_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約管理"])},
  "homepage.account_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント管理"])},
  "homepage.personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料"])},
  "homepage.login_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン方法"])},
  "homepage.account_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントセキュリティ"])},
  "homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
  "homepage.please_login_immediately_to_search_good_room_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直ちにログインして、最適の宿泊プランを探します"])},
  "homepage.login_failed_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインに失敗しました、再度お試しください"])},
  "homepage.a_password_should_contain_8_to_12_characters_including_english_letters_and_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは8～12ケタの英数字です"])},
  "homepage.stay_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインを継続"])},
  "homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れましたか？"])},
  "homepage.or_use_the_social_media_account_to_login_quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはコミュニティIDを使用してクイックログイン"])},
  "homepage.you_must_accept_google_s_requests_so_as_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインにはGoogleの要求を受諾しなければなりません"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_tourbobo_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ログインまたは登録をクリックすると、tourboboの", _interpolate(_named("n")), "と", _interpolate(_named("m")), "に同意したものと見なされます"])},
  "homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
  "homepage.join_tourbobo_to_find_an_exclusive_preferential_room_rate_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboに加入して、あなただけの特別価格を見つけましょう。"])},
  "homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した二つのパスワードが異なります、再度お試しください。"])},
  "homepage.linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連付け"])},
  "homepage.cancel_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連付けをキャンセル"])},
  "homepage.email_address_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを関連付ける"])},
  "homepage.set_the_email_address_and_password_for_you_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインのメールとパスワード設定"])},
  "homepage.verify_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール確認コード"])},
  "homepage.to_ensure_account_security_we_have_sent_a_verification_letter_to_your_email_address_please_follow_the_instructions_within_to_verify_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントセキュリティの確保のため、確認メールを送信しました。メールの指示に従って確認作業を行ってください。"])},
  "homepage.the_verification_letter_has_been_sent_to_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["確認メールを送信しました ", _interpolate(_named("n"))])},
  "homepage.resending_available_after_{n}_seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "秒後に再送信が可能となります"])},
  "homepage.resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再送信"])},
  "homepage.broken_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクが失効"])},
  "homepage.link_has_expired_or_been_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクはすでに失効及びすでに使用されています"])},
  "homepage.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解しました"])},
  "homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを確認"])},
  "homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "homepage.go_to_tourbobo_account_to_set_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboアカウントに移動してパスワードを設定"])},
  "homepage.delete_the_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを削除"])},
  "homepage.delete_your_tourbobo_account_and_personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboアカウントと個人資料を削除"])},
  "homepage.join_the_membership_for_free_and_receive_the_preferential_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録無料、割引コードをゲット！"])},
  "homepage.hot_cities_in_japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本の人気都市"])},
  "homepage.selected_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高の宿泊施設"])},
  "homepage.language_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語をカスタマイズ"])},
  "homepage.select_the_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨をカスタマイズ"])},
  "homepage.practical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実用的な情報"])},
  "homepage.my_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の予約"])},
  "homepage.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主隔離"])},
  "homepage.never_heard_of_this_place_change_a_word_and_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この場所は聞いたことがありません、他のワードでお試しください！"])},
  "homepage.enter_district_and_name_of_the_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域、宿泊施設の名称を入力"])},
  "homepage.activity_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント情報"])},
  "homepage.{price}_and_above": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), "より"])},
  "homepage.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップページ"])},
  "homepage.booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
  "homepage.recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特選おすすめ"])},
  "homepage.popular_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気宿泊プラン"])},
  "homepage.travel_info_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見逃せない旅行情報"])},
  "homepage.spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホットスポット"])},
  "homepage.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊特典"])},
  "homepage.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今話題の宿泊プラン"])},
  "homepage.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾で一番人気"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_reelax_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "search.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
  "search.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
  "search.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "search.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "search.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "search.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "search.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "search.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "search.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "search.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "search.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果"])},
  "search.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マップで調べる"])},
  "search.filter_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の条件で絞り込み"])},
  "search.per_night_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1泊料金"])},
  "search.sorting.recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おすすめ"])},
  "search.sorting.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金が低い順"])},
  "search.sorting.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価が高い順"])},
  "search.sorting.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金が高い順"])},
  "search.n_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["共 ", _interpolate(_named("n")), " 件のレビュー"])},
  "search.view_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプを調べる"])},
  "search.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリア"])},
  "search.other_recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のおすすめ"])},
  "search.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室の人気施設"])},
  "search.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル人気サービス"])},
  "search.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローディング中"])},
  "search.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絞り込み条件"])},
  "search.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["絞り込み"])},
  "search.map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マップ検索"])},
  "search.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並び替え"])},
  "search.sorting_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並び替え"])},
  "search.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並び順"])},
  "search.hotel_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサービス"])},
  "search.room_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室施設"])},
  "search.is_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランダム並び替え"])},
  "search.lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度"])},
  "search.lng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度"])},
  "search.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金が一番低い部屋"])},
  "search.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金が一番高い部屋"])},
  "search.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都市"])},
  "search.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タブ"])},
  "search.hotel_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご指定のホテルが見つかりません"])},
  "search.data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご指定の資料が見つかりません"])},
  "search.unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作は授権されていません"])},
  "search.hot_room_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気部屋検索"])},
  "search.hotel_hot_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気ホテル検索"])},
  "search.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主管理"])},
  "search.quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫"])},
  "search.the_hotel_has_been_removed_and_cannot_be_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このホテルは取り下げられました、見つかりません！"])},
  "search.find_other_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のホテルを検索"])},
  "search.personal_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自主健康管理"])},
  "search.national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "search.national_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅"])},
  "search.national_travel_subsidy_type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅宿適用補助1,300"])},
  "search.national_travel_subsidy_type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打滿3劑最高補助1,300"])},
  "search.popular_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気検索"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
  "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
  "keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基隆市"])},
  "taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北市"])},
  "new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新北市"])},
  "taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市"])},
  "hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新竹市"])},
  "taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台中市"])},
  "tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台南市"])},
  "kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高雄市"])},
  "detail.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マップを見る"])},
  "detail.more_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと写真を見る"])},
  "detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設の紹介"])},
  "detail.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見る"])},
  "detail.highlights_of_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おすすめポイント"])},
  "detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプを選択"])},
  "detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基本定員：", _interpolate(_named("n")), "人"])},
  "detail.extra_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["追加可能人数：", _interpolate(_named("n")), "人"])},
  "detail.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫規定に基づき、防疫ホテルにご宿泊の方は、原則としてお一人様一室でご宿泊いただきます。同室をご希望の方は宿泊先を管轄する政府の衛生管理関連機関に申請が必要です。"])},
  "detail.extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキストラベッド可"])},
  "detail.no_extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキストラベッド不可"])},
  "detail.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の特徴"])},
  "detail.n_m_ft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " m²"])},
  "detail.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁煙ルーム"])},
  "detail.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喫煙ルーム"])},
  "detail.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浴槽"])},
  "detail.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートバスルーム"])},
  "detail.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共用シャワー"])},
  "detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドタイプ"])},
  "detail.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大きな窓"])},
  "detail.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小さな窓"])},
  "detail.airtight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気密窓"])},
  "detail.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピクチャーウインドー"])},
  "detail.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バルコニー"])},
  "detail.more_info_on_room_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の部屋情報"])},
  "detail.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル検疫プラン"])},
  "detail.price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1泊料金"])},
  "detail.book_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ予約"])},
  "detail.facilities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設備・サービス"])},
  "detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約時の注意事項"])},
  "detail.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間"])},
  "detail.check_out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト時間"])},
  "detail.extra_capacity_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポリシーを加える"])},
  "detail.the_extra_charges_on_extra_minors_and_adults_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お子様の追加料金は以下のとおりです"])},
  "detail.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊者年齢"])},
  "detail.minor_under_the_age_of_5_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5歳以下"])},
  "detail.infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乳幼児"])},
  "detail.when_no_need_an_extra_be_they_can_stay_with_the_adult_visitor_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エキストラベッド未使用の場合、無料で大人のお客様と同室できます。"])},
  "detail.6_12_years_old_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 6〜12歳"])},
  "detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
  "detail.nt_n_charged_if_no_extra_bed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["エキストラベッド未使用の場合、1泊NT$ ", _interpolate(_named("n")), "の追加料金がかかります。"])},
  "detail.13_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13歳以上"])},
  "detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "detail.room_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフォメーション"])},
  "detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご宿泊時の注意事項"])},
  "detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル規定"])},
  "detail.guest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様レビュー"])},
  "detail.n_points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 点"])},
  "detail.n_comments_in_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["コメント ", _interpolate(_named("n")), " 件"])},
  "detail.check_all_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを全部見る"])},
  "detail.hotel_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサービス"])},
  "detail.entertaining_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娯楽施設"])},
  "detail.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室施設"])},
  "detail.more_info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと多くのホテル情報"])},
  "detail.info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル情報"])},
  "detail.info_on_the_room_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室情報"])},
  "detail.n_after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_years_old_and_minus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 才(含む)以下"])},
  "detail.m_n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "才-", _interpolate(_named("n")), "才(含む)"])},
  "detail.n_years_old_and_plus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 才(含む)以上"])},
  "detail.please_contact_the_customer_service_for_the_extra_person_rules_and_room_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊者追加及び費用についてはホテルのカスタマーサービスにお問い合わせください"])},
  "detail.the_room_price_per_night_with_no_bed_added_is_nt_count_per_head": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ベッドマットを追加されない場合、使用料金をご請求させていただきます。お一人当たりお一晩はNT$", _interpolate(_named("count"))])},
  "detail.show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見る　　"])},
  "detail.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折りたたむ"])},
  "detail.latest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新コメント"])},
  "detail.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高点"])},
  "detail.lowest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低点"])},
  "detail.date_review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " レビュー"])},
  "detail.from_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["から ", _interpolate(_named("source"))])},
  "detail.hotel_s_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルからのお返事"])},
  "detail.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並び順"])},
  "detail.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースを洗い出す"])},
  "area.zhongzheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中正区"])},
  "area.datong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大同区"])},
  "area.zhongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中山区"])},
  "area.songshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["松山区"])},
  "area.da_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大安区"])},
  "area.wanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万華区"])},
  "area.xinyi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信義区"])},
  "area.shilin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["士林区"])},
  "area.beitou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北投区"])},
  "area.neihu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内湖区"])},
  "area.nangang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南港区"])},
  "area.wenshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文山区"])},
  "area.ren_ai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁愛区"])},
  "area.anle_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安楽区"])},
  "area.nuannuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暖暖区"])},
  "area.qidu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七堵区"])},
  "area.wanli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万里区"])},
  "area.jinshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金山区"])},
  "area.nangan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南竿郷"])},
  "area.beigan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北竿郷"])},
  "area.juguang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["莒（キョ）光郷"])},
  "area.dongyin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東引郷"])},
  "area.banqiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["板橋郷"])},
  "area.xizhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汐止区"])},
  "area.shenkeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深坑区"])},
  "area.shiding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石碇区"])},
  "area.ruifang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞芳区"])},
  "area.pingxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平渓区"])},
  "area.shuangxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双渓区"])},
  "area.gongliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貢寮区"])},
  "area.xindian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新店区"])},
  "area.pinglin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["坪林区"])},
  "area.wulai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏来区"])},
  "area.yonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永和区"])},
  "area.zhonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中和区"])},
  "area.tucheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土城区"])},
  "area.sanxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山峡区"])},
  "area.shulin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["樹林区"])},
  "area.yingge_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鶯歌区"])},
  "area.sanchong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三重区"])},
  "area.xinzhuang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新荘区"])},
  "area.taishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰山区"])},
  "area.linkou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林口区"])},
  "area.luzhou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘆洲区"])},
  "area.wugu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五股区"])},
  "area.bali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八里区"])},
  "area.tamsui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淡水区"])},
  "area.sanzhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三芝区"])},
  "area.shimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石門区"])},
  "area.yilan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭市"])},
  "area.toucheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭城鎮"])},
  "area.jiaoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礁渓郷"])},
  "area.zhuangwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["壮囲郷"])},
  "area.yuanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員山郷"])},
  "area.luodong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["羅東鎮"])},
  "area.sanxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三星郷"])},
  "area.datong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大同郷"])},
  "area.wujie_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五結郷"])},
  "area.dongshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冬山郷"])},
  "area.su_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘇澳鎮"])},
  "area.nan_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南澳郷"])},
  "area.diaoyutai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魚釣島諸島"])},
  "area.east_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東区"])},
  "area.zhubei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹北市"])},
  "area.hukou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖口郷"])},
  "area.xinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新豊郷"])},
  "area.xinpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新埔鎮"])},
  "area.guanxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関西鎮"])},
  "area.qionglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芎（キュウ）林郷"])},
  "area.baoshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝山郷"])},
  "area.zhudong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹東鎮"])},
  "area.wufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五峰郷"])},
  "area.hengshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["横山郷"])},
  "area.jianshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尖石郷"])},
  "area.beipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北埔郷"])},
  "area.emei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["峨眉郷"])},
  "area.zhongli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中壢区"])},
  "area.pingzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平鎮区"])},
  "area.longtan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍潭区"])},
  "area.yangmei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楊梅区"])},
  "area.xinwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新屋区"])},
  "area.guanyin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["観音区"])},
  "area.taoyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃源区"])},
  "area.guishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亀山区"])},
  "area.bade_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八徳区"])},
  "area.daxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大渓区"])},
  "area.fuxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["復興区"])},
  "area.dayuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大園区"])},
  "area.luzhu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路竹区"])},
  "area.zhunan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹南鎮"])},
  "area.toufen_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭份市"])},
  "area.sanwan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三湾郷"])},
  "area.nanzhuang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南庄郷"])},
  "area.shitan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獅潭郷"])},
  "area.houlong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後龍鎮"])},
  "area.tongxiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通霄鎮"])},
  "area.yuanli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苑裡鎮"])},
  "area.miaoli_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗栗市"])},
  "area.zaoqiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造橋郷"])},
  "area.touwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭屋郷"])},
  "area.gongguan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公館郷"])},
  "area.dahu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大湖郷"])},
  "area.tai_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰安郷"])},
  "area.tongluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銅鑼郷"])},
  "area.sanyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三義郷"])},
  "area.xihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渓湖鎮"])},
  "area.zhuolan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓蘭鎮"])},
  "area.central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中区"])},
  "area.south_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南区"])},
  "area.west_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西区"])},
  "area.north_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北区"])},
  "area.beitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北屯区"])},
  "area.xitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西屯区"])},
  "area.nantun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南屯区"])},
  "area.taiping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太平区"])},
  "area.dali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大里区"])},
  "area.wufeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霧峰区"])},
  "area.wuri_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏日区"])},
  "area.fengyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豊原区"])},
  "area.houli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后里区"])},
  "area.shigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石岡区"])},
  "area.dongshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東勢区"])},
  "area.heping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和平区"])},
  "area.xinshe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新社区"])},
  "area.tanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潭子区"])},
  "area.daya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大雅区"])},
  "area.shengang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["神岡区"])},
  "area.dadu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大肚区"])},
  "area.shalu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙鹿区"])},
  "area.longjing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍井区"])},
  "area.wuqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梧棲区"])},
  "area.qingshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清水区"])},
  "area.dajia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大甲区"])},
  "area.waipu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外埔区"])},
  "area.changhua_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彰化市"])},
  "area.fenyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芬園郷"])},
  "area.huatan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花壇郷"])},
  "area.xiushui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秀水郷"])},
  "area.lukang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿港鎮"])},
  "area.fuxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["福興郷"])},
  "area.xianxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["線西郷"])},
  "area.hemei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和美鎮"])},
  "area.shengang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伸港郷"])},
  "area.yuanlin_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["員林市"])},
  "area.shetou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社頭郷"])},
  "area.yongjing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永靖郷"])},
  "area.puxin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔心郷"])},
  "area.dacun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大村郷"])},
  "area.puyan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔塩郷"])},
  "area.tianzhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田中鎮"])},
  "area.beidou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北斗鎮"])},
  "area.tianwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田尾郷"])},
  "area.pitou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埤(ヒ)頭郷"])},
  "area.xizhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渓州郷"])},
  "area.zhutang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹塘郷"])},
  "area.erlin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二林鎮"])},
  "area.dacheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大城郷"])},
  "area.fangyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芳苑郷"])},
  "area.ershui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二水郷"])},
  "area.nantou_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南投市"])},
  "area.zhongliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中寮郷"])},
  "area.caotun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草屯鎮"])},
  "area.guoxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国姓郷"])},
  "area.puli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埔里鎮"])},
  "area.ren_ai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁愛郷"])},
  "area.mingjian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名間郷"])},
  "area.jiji_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集集鎮"])},
  "area.shuili_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水里郷"])},
  "area.yuchi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魚池郷"])},
  "area.xinyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信義郷"])},
  "area.zhushan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹山鎮"])},
  "area.lugu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿谷郷"])},
  "area.fanlu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["番路郷"])},
  "area.meishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梅山郷"])},
  "area.zhuqi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹崎郷"])},
  "area.alishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿里山郷"])},
  "area.zhongpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中埔郷"])},
  "area.dapu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大埔郷"])},
  "area.shuishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水上郷"])},
  "area.lucao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿草郷"])},
  "area.taibao_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太保市"])},
  "area.puzi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朴子市"])},
  "area.dongshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東勢郷"])},
  "area.liujiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六脚郷"])},
  "area.xingang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新港郷"])},
  "area.minxiong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["民雄郷"])},
  "area.dalin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大林鎮"])},
  "area.xikou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渓口郷"])},
  "area.yizhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["義竹郷"])},
  "area.budai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布袋鎮"])},
  "area.dounan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斗南鎮"])},
  "area.dapi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大ヒ郷"])},
  "area.huwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虎尾鎮"])},
  "area.tuku_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土庫鎮"])},
  "area.baozhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["褒忠郷"])},
  "area.taixi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台西郷"])},
  "area.lunbei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["崙背郷"])},
  "area.mailiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麦寮郷"])},
  "area.douliu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斗六市"])},
  "area.linnei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林内郷"])},
  "area.gukeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古坑郷"])},
  "area.citong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["莿(シ)桐郷"])},
  "area.xiluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西螺鎮"])},
  "area.erlun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二崙郷"])},
  "area.beigang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北港鎮"])},
  "area.shuilin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水林郷"])},
  "area.kouhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口湖郷"])},
  "area.sihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四湖郷"])},
  "area.yuanzhang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元長郷"])},
  "area.west_central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中西区"])},
  "area.anping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安平区"])},
  "area.annan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安南区"])},
  "area.yongkang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永康区"])},
  "area.guiren_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帰仁区"])},
  "area.xinhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新化区"])},
  "area.zuozhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左鎮区"])},
  "area.yujing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玉井区"])},
  "area.nanxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楠西区"])},
  "area.nanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南化区"])},
  "area.rende_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁徳区"])},
  "area.guanmiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関廟区"])},
  "area.longqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龍崎区"])},
  "area.guantian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["官田区"])},
  "area.madou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麻豆区"])},
  "area.jiali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佳里区"])},
  "area.xigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西港区"])},
  "area.qigu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七股区"])},
  "area.jiangjun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将軍区"])},
  "area.xuejia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["学甲区"])},
  "area.beimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北門区"])},
  "area.xinying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新営区"])},
  "area.houbi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後壁区"])},
  "area.baihe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白河区"])},
  "area.dongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東山区"])},
  "area.liujia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六甲区"])},
  "area.xiaying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下営区"])},
  "area.liuying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柳営区"])},
  "area.yanshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塩水区"])},
  "area.shanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["善化区"])},
  "area.danei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大内区"])},
  "area.shanshang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山上区"])},
  "area.xinshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新市区"])},
  "area.anding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安定区"])},
  "area.xinxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新興区"])},
  "area.qianjin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前金区"])},
  "area.lingya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苓雅区"])},
  "area.yancheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塩埕区"])},
  "area.gushan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鼓山区"])},
  "area.qijin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗津区"])},
  "area.qianzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前鎮区"])},
  "area.sanmin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三民区"])},
  "area.nanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楠梓区"])},
  "area.xiaogang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小港区"])},
  "area.zuoying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左営区"])},
  "area.renwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仁武区"])},
  "area.dashe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大社区"])},
  "area.dongsha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東沙島"])},
  "area.nansha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南沙島"])},
  "area.gangshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["岡山区"])},
  "area.alian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿蓮区"])},
  "area.tianliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["田寮区"])},
  "area.yanchao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燕巣区"])},
  "area.qiaotou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橋頭区"])},
  "area.ziguan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梓官区"])},
  "area.mituo_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["弥陀区"])},
  "area.yong_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永安区"])},
  "area.hunei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖内区"])},
  "area.fengshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳳山区"])},
  "area.daliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大寮区"])},
  "area.linyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林園区"])},
  "area.niaosong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳥松区"])},
  "area.dashu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大樹区"])},
  "area.qishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗山区"])},
  "area.meinong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美濃区"])},
  "area.liugui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六亀区"])},
  "area.neimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内門区"])},
  "area.shanlin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杉林区"])},
  "area.jiaxian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甲仙区"])},
  "area.namaxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナマシャ区"])},
  "area.maolin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茂林区"])},
  "area.qieding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["茄テイ区"])},
  "area.magong_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["馬公市"])},
  "area.xiyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西嶼郷"])},
  "area.wang_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["望安郷"])},
  "area.qimei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七美郷"])},
  "area.baisha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白沙郷"])},
  "area.huxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湖西郷"])},
  "area.jinsha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金沙鎮"])},
  "area.jinhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金湖鎮"])},
  "area.jinning_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金寧郷"])},
  "area.jincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金城鎮"])},
  "area.lieyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈嶼郷"])},
  "area.wuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烏坵(キュウ)郷"])},
  "area.pingtung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏東市"])},
  "area.sandimen_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三地門郷"])},
  "area.wutai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霧台郷"])},
  "area.majia_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑪家郷"])},
  "area.jiuru_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九如郷"])},
  "area.ligang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["里港郷"])},
  "area.gaoshu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高樹郷"])},
  "area.yanpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塩埔郷"])},
  "area.changzhi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長治郷"])},
  "area.linluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麟洛郷"])},
  "area.zhutian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹田郷"])},
  "area.neipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内埔郷"])},
  "area.wandan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万丹郷"])},
  "area.chaozhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潮州鎮"])},
  "area.taiwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰武郷"])},
  "area.laiyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来義郷"])},
  "area.wanluan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万巒郷"])},
  "area.kanding_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["崁(カン)頂郷"])},
  "area.xinpi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新埤(ピ)郷"])},
  "area.nanzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南州郷"])},
  "area.linbian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["林辺郷"])},
  "area.donggang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東港郷"])},
  "area.liuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["琉球郷"])},
  "area.jiadong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佳冬郷"])},
  "area.xinyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新園郷"])},
  "area.fangliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枋寮郷"])},
  "area.fangshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枋山郷"])},
  "area.chunri_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["春日郷"])},
  "area.shizi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獅子郷"])},
  "area.checheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車城郷"])},
  "area.mudan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牡丹郷"])},
  "area.hengchun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恒春鎮"])},
  "area.manzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満州郷"])},
  "area.taitung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台東市"])},
  "area.ludao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緑島郷"])},
  "area.lanyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蘭嶼郷"])},
  "area.yanping_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延平郷"])},
  "area.beinan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卑南郷"])},
  "area.luye_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鹿野郷"])},
  "area.guanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関山鎮"])},
  "area.haiduan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海端郷"])},
  "area.chishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["池上郷"])},
  "area.donghe_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東河郷"])},
  "area.chenggong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功鎮"])},
  "area.changbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長浜郷"])},
  "area.taimali_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["太麻里郷"])},
  "area.jinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金峰郷"])},
  "area.dawu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大武郷"])},
  "area.daren_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["達仁郷"])},
  "area.hualien_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["花蓮市"])},
  "area.xincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新城郷"])},
  "area.xiulin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秀林郷"])},
  "area.ji_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉安郷"])},
  "area.shoufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寿豊郷"])},
  "area.fenglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳳林鎮"])},
  "area.guangfu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["光復郷"])},
  "area.fengbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豊浜郷"])},
  "area.ruisui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞穂郷"])},
  "area.wanrong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万栄郷"])},
  "area.yuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玉里鎮"])},
  "area.zhuoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓渓郷"])},
  "area.fuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["富里郷"])},
  "area.naniwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浪速区"])},
  "area.doun_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンペンセクション"])},
  "homepage.phnom_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cancellation_and_cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルポリシーは、宿泊業者が設定する各客室タイプに基づくものです。業者側がキャンセルポリシーを定めていない場合は、サービスとキャンセル条項のキャンセル規定をご参照ください。"])},
  "detail.the_cancellation_rules_shall_be_subject_to_the_cancellation_refund_policy_set_by_a_hotel_operator_for_each_room_type_and_project_if_there_is_no_such_policy_please_refer_to_n_of_our_terms_of_service_and_cancellation_refund_policy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["キャンセルポリシーは、宿泊業者が設定する各客室タイプに基づくものです。業者側がキャンセルポリシーを定めていない場合は、サービスとキャンセル条項の ", _interpolate(_named("n")), "をご参照ください。"])},
  "detail.cancellation_refund_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約取り消しとキャンセル"])},
  "detail.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫"])},
  "hotel.this_hotel_have_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此旅宿適用悠遊國旅補助，請詳閱說明！"])},
  "hotel.this_hotel_no_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週五、週六、連續假期不適用國旅補助，請詳閱說明！"])},
  "hotel.lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "hotel.national_travel_subsidy_directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國旅補助說明"])},
  "hotel.national_travel_subsidy_directions_for_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 悠遊國旅補助說明："])},
  "hotel.national_travel_subsidy_during_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動期間"])},
  "hotel.national_travel_subsidy_during_activity_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年7月15日 至 2022年12月15日，適用於平日（週日至週四），活動至經費預算用罄為止。\n不適用日期：週五、週六、中秋節連續假期（2022年9月9日至9月11日）、國慶日連續假期（2022年10月8日至10月10日）不適用。"])},
  "hotel.national_travel_subsidy_promotion_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠方式"])},
  "hotel.national_travel_subsidy_promotion_method_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本國籍自由行旅客，若入住全台灣縣市有參加本活動且領有營業執照或旅宿業登記證之旅宿業，每房可享折抵 800 元。另使用優惠旅客本人完整接種三劑疫苗，或符合入住星級旅館、好客民宿、自行車友善旅宿、取得環保標章或溫泉標章之旅宿業，再加碼折抵 500 元，最高每房每晚可折抵 1,300 元！"])},
  "hotel.national_travel_subsidy_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方法"])},
  "hotel.national_travel_subsidy_use_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 於 tourbobo 平台選擇標註「悠遊國旅」文字的旅宿，且依照補助活動期間預訂住宿。於訂房流程中，悠遊國旅補助欄位選擇「使用補助」，旅客自行確認補助資格並勾選同意免責聲明。入住當天告知旅宿業者欲申請國旅補助，查核身份符合後，於旅宿現場給予折扣。\n\n2. 請於入住前或入住當天至「悠遊國旅個別旅客住宿優惠活動」網頁專區，掃描並上傳身分證或健保卡（任一）正面圖檔，建立旅客資料檔案（健保卡無照片亦可），且入住時將您身分證或健保卡正本（任一）提供給旅宿業者核對是否本人入住。\n\n3. 若您勾選「已完整接種三劑」：請於入住前或入住當日至「悠遊國旅個別旅客住宿優惠活動」網頁專區掃描並上傳完整接種3劑疫苗證明，並於入住時出示證明供旅宿查核。接種3劑疫苗之證明以衛生福利部或中央流行疫情指揮中心認定之辨別方式為準，如紙本疫苗接種卡（俗稱小黃卡）、健保快易通/健康存摺APP、數位新冠病毒健康證明等。"])},
  "hotel.national_travel_subsidy_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意事項"])},
  "hotel.national_travel_subsidy_notice_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 每筆訂單限一位入住旅客申請補助，每一身分證字號限使用一次補助，同時使用旅遊補助與接種三劑加碼補助需為同一入住旅客。\n\n2. 入住時由旅宿做補助資格查核，若資格不符，您同意於旅宿現場支付全額，且付款完成後才能入住，否則旅宿業者得拒絕您入住，且您不得請求免費取消或延期入住。\n\n3. 若您沒有勾選「已完整接種三劑」，提交訂單後，您將無法再更改選項，也無法要求旅宿業者或 tourbobo 提供加碼補助折抵。\n\n4. 悠遊國旅補助相關活動辦法，依照政府公告為準，倘若因政府補助經費用完而不再受理申請，或因悠遊國旅補助辦法異動，您同意旅宿業者或 tourbobo 可能因此變更您的訂單，包含但不限於：由旅客支付訂單全額、更改入住日期...等。\n\n5. 因知悉政府補助經費已用完，您於入住前想要取消訂房時，您同意依著個別旅客訂房定型化契約之規定辦理。"])},
  "hotel.clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリア"])},
  "order.my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])},
  "order.personal_profile_for_quarantine_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入国情報"])},
  "order.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払ページ"])},
  "order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約情報"])},
  "order.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン日"])},
  "order.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト日"])},
  "order.n_total_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計泊数"])},
  "order.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 泊"])},
  "order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計部屋数"])},
  "order.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 部屋"])},
  "order.total_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計宿泊人数"])},
  "order.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人，", _interpolate(_named("n")), " 子供"])},
  "order.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル検疫プラン"])},
  "order.daily_three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3食付き"])},
  "order.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
  "order.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
  "order.discount_by_the_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンコード"])},
  "order.plan_with_three_meals_no_extra_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3食プラン（エキストラベッドなし）を追加"])},
  "order.early_check_in_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーリーチェックイン"])},
  "order.hotel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルID"])},
  "order.room_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプID"])},
  "order.plan_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プランID"])},
  "order.extra_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人人数追加"])},
  "order.extra_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供人数追加"])},
  "order.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供年齢"])},
  "order.check_out_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い金額"])},
  "order.basic_information_of_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊者基本情報"])},
  "order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓（ローマ字）"])},
  "order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓を入力してください"])},
  "order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名（ローマ字）"])},
  "order.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名を入力してください"])},
  "order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
  "order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
  "order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの国籍を選択してください"])},
  "order.id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証番号/パスポート番号"])},
  "order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポート番号を入力してください"])},
  "order.enter_valid_id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なパスポート番号を入力してください"])},
  "order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのパスポート番号を入力してください"])},
  "order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なパスポート番号を入力してください"])},
  "order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
  "order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話の番号を入力してください"])},
  "order.choose_country_code_of_cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話の国番号を選択してください"])},
  "order.enter_keyword_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワードを入力してください"])},
  "order.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "order.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
  "order.please_enter_your_email_address_and_you_will_receive_the_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力、予約番号を送信します"])},
  "order.confirm_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス確認用"])},
  "order.please_enter_your_email_address_to_avoid_any_biased_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再度メールアドレスを入力し、入力情報に誤りがないことを確保します"])},
  "order.different_from_the_email_address_column_please_confirm_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス欄と異なります、再度ご確認ください"])},
  "order.emergency_contact_person_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先情報"])},
  "order.please_enter_the_last_name_of_the_emergency_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先の方の姓を入力してください"])},
  "order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先人名前"])},
  "order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先人名字"])},
  "order.contact_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先人携帯番号"])},
  "order.please_enter_the_first_name_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先の方の名を入力してください"])},
  "order.please_enter_the_cell_phone_number_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先の方の携帯電話番号を入力してください"])},
  "order.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
  "order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この項目は入力が必須です"])},
  "order.finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
  "nation.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
  "nation.cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国"])},
  "nation.hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
  "nation.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マカオ"])},
  "nation.jp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本"])},
  "nation.us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメリカ"])},
  "nation.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国"])},
  "nation.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイ"])},
  "nation.vn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベトナム"])},
  "nation.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マレーシア"])},
  "nation.ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィリピン"])},
  "nation.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カナダ"])},
  "nation.au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーストラリア"])},
  "nation.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インドネシア"])},
  "nation.kh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンボジア"])},
  "nation.mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミャンマー"])},
  "nation.ae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラブ首長国連邦"])},
  "nation.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トルコ"])},
  "nation.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス"])},
  "nation.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オランダ"])},
  "nation.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドイツ"])},
  "nation.gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イギリス"])},
  "nation.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメリカ領ヴァージン諸島"])},
  "nation.mp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北マリアナ諸島"])},
  "nation.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グアム"])},
  "nation.as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメリカ領サモア"])},
  "nation.pr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プエルトリコ"])},
  "nation.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バハマ"])},
  "nation.bb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バルバドス"])},
  "nation.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アングィラ"])},
  "nation.ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンティグア・バーブーダ"])},
  "nation.vg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イギリス領ヴァージン諸島"])},
  "nation.ky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケイマン諸島"])},
  "nation.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バミューダ諸島"])},
  "nation.gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グレナダ"])},
  "nation.tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タークス・カイコス諸島"])},
  "nation.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モントセラト"])},
  "nation.sx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シント・マールテン"])},
  "nation.lc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セントルシア"])},
  "nation.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミニカ国"])},
  "nation.vc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セントビンセントおよびグレナディーン諸島"])},
  "nation.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミニカ共和国"])},
  "nation.tt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリニダード・トバゴ"])},
  "nation.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セントクリストファーネイビス"])},
  "nation.jm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジャマイカ"])},
  "nation.eg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エジプト"])},
  "nation.ss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南スーダン"])},
  "nation.ma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モロッコ"])},
  "nation.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルジェリア"])},
  "nation.tn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チュニジア"])},
  "nation.ly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リビア"])},
  "nation.gm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガンビア"])},
  "nation.sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セネガル"])},
  "nation.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モーリタニア"])},
  "nation.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マリ"])},
  "nation.gn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギニア"])},
  "nation.ci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コートジボワール"])},
  "nation.bf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブルキナファソ"])},
  "nation.ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニジェール"])},
  "nation.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーゴ"])},
  "nation.bj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベナン"])},
  "nation.mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モーリシャス"])},
  "nation.lr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リベリア"])},
  "nation.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シエラレオネ"])},
  "nation.gh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガーナ"])},
  "nation.ng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナイジェリア"])},
  "nation.td": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャド"])},
  "nation.cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中央アフリカ共和国"])},
  "nation.cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメルーン"])},
  "nation.cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カーボベルデ"])},
  "nation.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サントメ・プリンシペ"])},
  "nation.gq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赤道ギニア"])},
  "nation.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガボン"])},
  "nation.cg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンゴ共和国"])},
  "nation.cd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンゴ民主共和国"])},
  "nation.ao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンゴラ"])},
  "nation.gw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギニアビサウ"])},
  "nation.io": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イギリス領インド洋地域"])},
  "nation.ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アセンション島"])},
  "nation.sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セーシェル"])},
  "nation.sd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スーダン"])},
  "nation.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルワンダ"])},
  "nation.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エチオピア"])},
  "nation.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソマリア"])},
  "nation.dj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジブチ"])},
  "nation.ke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケニア"])},
  "nation.tz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ザンジバル島"])},
  "nation.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウガンダ"])},
  "nation.bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブルンジ"])},
  "nation.mz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モザンビーク"])},
  "nation.zm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ザンビア"])},
  "nation.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マダガスカル"])},
  "nation.re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レユニオン"])},
  "nation.yt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マヨット"])},
  "nation.zw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジンバブエ"])},
  "nation.na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナミビア"])},
  "nation.mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マラウイ"])},
  "nation.ls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レソト"])},
  "nation.bw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボツワナ"])},
  "nation.sz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エスワティニ"])},
  "nation.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コモロ"])},
  "nation.za": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南アフリカ共和国"])},
  "nation.sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリスタンダクーニャ"])},
  "nation.er": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エリトリア"])},
  "nation.aw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルバ"])},
  "nation.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フェロー諸島"])},
  "nation.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グリーンランド"])},
  "nation.gr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギリシャ"])},
  "nation.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベルギー"])},
  "nation.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペイン"])},
  "nation.gi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジブラルタル"])},
  "nation.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポルトガル"])},
  "nation.lu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルクセンブルク"])},
  "nation.ie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイルランド"])},
  "nation.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイスランド"])},
  "nation.al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルバニア"])},
  "nation.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マルタ"])},
  "nation.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キプロス"])},
  "nation.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィンランド"])},
  "nation.ax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーランド諸島"])},
  "nation.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブルガリア"])},
  "nation.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハンガリー"])},
  "nation.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リトアニア"])},
  "nation.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラトビア"])},
  "nation.ee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニア"])},
  "nation.md": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モルドバ"])},
  "nation.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルメニア"])},
  "nation.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベラルーシ"])},
  "nation.ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンドラ"])},
  "nation.mc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モナコ"])},
  "nation.sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サンマリノ"])},
  "nation.va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バチカン"])},
  "nation.ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウクライナ"])},
  "nation.rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セルビア"])},
  "nation.me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モンテネグロ"])},
  "nation.xk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コソボ"])},
  "nation.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロアチア"])},
  "nation.si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スロベニア"])},
  "nation.ba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボスニア・ヘルツェゴビナ"])},
  "nation.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北マケドニア"])},
  "nation.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イタリア"])},
  "nation.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルーマニア"])},
  "nation.ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スイス"])},
  "nation.cz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェコ"])},
  "nation.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スロバキア"])},
  "nation.li": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リヒテンシュタイン"])},
  "nation.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーストリア"])},
  "nation.gg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガーンジー島"])},
  "nation.je": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジャージー島"])},
  "nation.im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マン島"])},
  "nation.dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デンマーク"])},
  "nation.se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スウェーデン"])},
  "nation.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ノルウェー"])},
  "nation.sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スヴァールバル諸島およびヤンマイエン島"])},
  "nation.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポーランド"])},
  "nation.fk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォークランド諸島"])},
  "nation.bz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベリーズ"])},
  "nation.gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グアテマラ"])},
  "nation.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エルサルバドル"])},
  "nation.hn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホンジュラス"])},
  "nation.ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニカラグア"])},
  "nation.cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コスタリカ"])},
  "nation.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パナマ"])},
  "nation.pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サンピエール島・ミクロン島"])},
  "nation.ht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハイチ"])},
  "nation.pe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペルー"])},
  "nation.mx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メキシコ"])},
  "nation.cu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キューバ"])},
  "nation.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゼンチン"])},
  "nation.br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラジル"])},
  "nation.cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チリ"])},
  "nation.co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コロンビア"])},
  "nation.ve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベネズエラ"])},
  "nation.gp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グアドループ"])},
  "nation.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボリビア"])},
  "nation.gy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイアナ"])},
  "nation.ec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクアドル"])},
  "nation.gf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス領ギアナ"])},
  "nation.py": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パラグアイ"])},
  "nation.mq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マルティニーク"])},
  "nation.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スリナム"])},
  "nation.uy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウルグアイ"])},
  "nation.cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キュラソー島"])},
  "nation.cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ココス群島"])},
  "nation.cx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリスマス島"])},
  "nation.nz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュージーランド"])},
  "nation.pn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピトケアン島"])},
  "nation.sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シンガポール"])},
  "nation.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東ティモール"])},
  "nation.nf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ノーフォーク島"])},
  "nation.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブルネイ"])},
  "nation.nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナウル"])},
  "nation.pg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パプアニューギニア"])},
  "nation.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トンガ"])},
  "nation.sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソロモン諸島"])},
  "nation.vu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バヌアツ"])},
  "nation.fj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィジー"])},
  "nation.pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パラオ"])},
  "nation.wf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォリス・フツナ"])},
  "nation.ck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クック諸島"])},
  "nation.nu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニウエ"])},
  "nation.ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サモア"])},
  "nation.ki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キリバス"])},
  "nation.nc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニューカレドニア"])},
  "nation.tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ツバル"])},
  "nation.pf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス領ポリネシア"])},
  "nation.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トケラウ"])},
  "nation.fm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミクロネシア連邦"])},
  "nation.mh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マーシャル諸島"])},
  "nation.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロシア"])},
  "nation.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カザフスタン"])},
  "nation.kp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北朝鮮"])},
  "nation.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラオス"])},
  "nation.bd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バングラデシュ"])},
  "nation.in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インド"])},
  "nation.pk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パキスタン"])},
  "nation.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アフガニスタン"])},
  "nation.lk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スリランカ"])},
  "nation.mv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モルディブ"])},
  "nation.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レバノン"])},
  "nation.jo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヨルダン"])},
  "nation.sy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シリア"])},
  "nation.iq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イラク"])},
  "nation.kw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クウェート"])},
  "nation.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウジアラビア"])},
  "nation.ye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イエメン"])},
  "nation.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オマーン"])},
  "nation.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パレスチナ"])},
  "nation.il": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イスラエル"])},
  "nation.bh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーレーン"])},
  "nation.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カタール"])},
  "nation.bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブータン"])},
  "nation.mn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モンゴル"])},
  "nation.np": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネパール"])},
  "nation.ir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イラン"])},
  "nation.tj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タジキスタン"])},
  "nation.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トルクメニスタン"])},
  "nation.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アゼルバイジャン"])},
  "nation.ge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジョージア"])},
  "nation.kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キルギスタン"])},
  "nation.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウズベキスタン"])},
  "order.departures_arrivals_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出入国・到着"])},
  "order.departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出発国"])},
  "order.please_pick_the_departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出発国をお選びください"])},
  "order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着便名"])},
  "order.please_enter_your_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着便名を入力してください"])},
  "order.arrival_date_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾到着日"])},
  "order.please_pick_your_arrival_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾到着日をお選びください"])},
  "order.arrival_time_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾到着時間"])},
  "order.please_pick_your_arrival_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾到着時間をお選びください"])},
  "order.scheduled_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予定チェックイン時間"])},
  "order.please_pick_your_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間をお選びください"])},
  "order.estimated_check_in_time_shall_not_be_earlier_than_the_time_arriving_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間は、台湾到着時間より早くならないようにしてください"])},
  "order.any_visitor_in_need_of_early_check_in_must_pay_the_early_check_in_bill_pursuant_to_the_rules_made_by_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーリーチェックインをご希望される方は、ホテルの規定によりアーリーチェックインの料金をご請求させていただきます。"])},
  "order.health_and_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康・飲食情報"])},
  "order.food_habits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飲食習慣"])},
  "order.non_vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肉食可"])},
  "order.vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベジタリアン"])},
  "order.any_specific_illness_cardiovascular_or_chronic_disease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持病の有無（心血管疾患、慢性疾患など)"])},
  "order.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
  "order.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
  "order.please_enter_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持病の内容を記入してください"])},
  "order.any_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アレルギーまたは食べられない食べ物"])},
  "order.please_do_enter_the_allergic_or_banned_food_if_you_pick_the_plan_with_meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食事付きプランの場合、必ずアレルギーまたは食べられない食べ物を記入してください"])},
  "order.please_enter_the_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アレルギーまたは食べられない食べ物を記入してください"])},
  "order.use_the_discount_cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンコードを使用"])},
  "order.please_enter_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンコードを入力してください"])},
  "order.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "order.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考"])},
  "order.please_enter_your_specific_need_but_the_hotel_cannot_ensure_provision_of_such_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なニーズを入力してください。ホテルはサービスを保証するものではなく、状況に応じてオンサイト料金が請求されます。"])},
  "order.extra_purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加メニュー"])},
  "order.the_room_type_capacity_is_2_persons_and_the_hotel_receives_no_extra_person_unless_the_caregiver_is_a_must": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この部屋タイプは定員2人です、共同介護の規定に符合する方のみ追加可能。"])},
  "order.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "order.12_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12歳以上"])},
  "order.child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
  "order.0_12_years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0～12歳未満"])},
  "order.night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泊"])},
  "order.the_hotel_receives_the_visitors_after_15_00_and_will_issue_the_early_check_in_bill_for_any_visitor_in_need_of_early_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この宿泊施設は15:00チェックインです。アーリーチェックインは別途料金がかかります"])},
  "order.early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーリーチェックイン料金"])},
  "order.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム画面へ戻る"])},
  "order.please_enter_the_name_of_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持病の名前を記入してください"])},
  "order.please_enter_the_valid_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なクーポンコードを入力してください"])},
  "order.10_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10％割引"])},
  "order.20_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20％割引"])},
  "order.30_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30％割引"])},
  "order.40_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40％割引"])},
  "order.50_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50％割引"])},
  "order.60_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60％割引"])},
  "order.70_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70％割引"])},
  "order.80_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80％割引"])},
  "order.90_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90％割引"])},
  "order.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
  "order.booking_serial_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号"])},
  "order.tax_id_no_for_the_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一発票を発行"])},
  "order.tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一番号"])},
  "order.please_enter_the_company_tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の統一番号を入力してください"])},
  "order.company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書の会社名"])},
  "order.please_enter_the_company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書の会社名を入力してください"])},
  "order.payment_manner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い方法"])},
  "order.pay_by_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードで支払い"])},
  "order.cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル規定"])},
  "order.1_any_booking_altered_cancelled_on_3_days_before_the_check_in_date_the_hotel_receives_the_booking_to_be_altered_by_the_visitor_once_only_or_refunds_the_full_deposit_to_the_visitor_if_requested_as_well_with_the_processing_fee_of_nt_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.ご宿泊日から3日前までは無料で予約変更や、返金を1回のみ申請ができます(NT$100の手数料がかかります)。"])},
  "order.2_in_the_event_of_the_booking_to_be_cancelled_on_1_2_days_before_the_check_in_date_the_hotel_will_deduct_70_and_refund_the_rest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.宿泊日の1日～2日前にキャンセルされる場合、宿泊の手付金を50％をお差し引きしてから残額をご返金させていただきます。"])},
  "order.3_any_booking_to_be_altered_cancelled_on_the_check_in_date_the_hotel_neither_receives_the_booking_to_be_altered_nor_refunds_the_deposit_to_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.宿泊当日にご予約の変更ができません。キャンセルされる場合のご返金は致しかねます。"])},
  "order.4_any_contingent_cancellation_after_the_stay_the_hotel_will_deduct_the_room_price_calculated_by_the_actual_lodging_days_from_the_scheduled_lodging_price_and_charge_the_sterilization_cleaning_fee_by_nt_800_1_000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.チェックイン後に緊急事態が発生したことで、残りの宿泊日数をキャンセルせざるを得ない場合、宿泊済料金をお差し引きするほか、別途で消毒び料金をご請求させていただきます。"])},
  "order.i_have_read_the_cancellation_policy_and_agree_to_the_privacy_terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "を読み、", _interpolate(_named("n")), "に同意します"])},
  "order.submit_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
  "order.booking_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セッションタイムアウト"])},
  "order.sorry_you_fail_to_pay_within_a_given_time_limit_and_the_system_cancels_your_booking_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません。時間内にお支払いされていませんのでキャンセルされました。もう一度ご予約ください。"])},
  "order.failed_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約できませんでした"])},
  "order.sorry_the_room_type_of_the_hotel_is_fully_booked_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません。このお部屋タイプは売り切れです。もう一度ご予約ください。"])},
  "order.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約を完了しました"])},
  "order.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません。お支払いを完了できませんでした。ご予約内容の支払い状況を再確認してください"])},
  "order.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容を見る"])},
  "order.my_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容"])},
  "order.booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約完了"])},
  "order.your_payment_completed_have_a_nice_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いが完了しました。当日気を付けてお越しください"])},
  "order.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "order.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火"])},
  "order.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "order.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
  "order.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金"])},
  "order.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土"])},
  "order.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "order.booking_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状態"])},
  "order.booking_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約済み"])},
  "order.booking_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約取り消し済み"])},
  "order.booking_received_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約成立日時"])},
  "order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い状態"])},
  "order.not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未払い"])},
  "order.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い済み"])},
  "order.payment_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い日時"])},
  "order.booking_total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約合計金額"])},
  "order.invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書を発行"])},
  "order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書検索"])},
  "order.the_hotel_will_issue_the_collection_and_remittance_receipt_after_7_days_from_the_check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立て替えでお支払いの場合、チェックアウトから7日後に領収書を発行します"])},
  "order.room_type_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプ情報"])},
  "order.total_room_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計部屋数"])},
  "order.total_person_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計宿泊人数"])},
  "order.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), "大人"])},
  "order.three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3食付き"])},
  "order.n_person_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 人分"])},
  "order.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細情報"])},
  "order.hotel_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設住所"])},
  "order.hotel_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設電話番号"])},
  "order.hotel_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設公式LINEアカウント"])},
  "order.tourbobo_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourbobo公式LINEアカウント"])},
  "order.customer_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービスLINE"])},
  "order.payment_failed_your_booking_will_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いにならない場合、ご予約はキャンセルされます。"])},
  "order.you_are_so_close_to_get_your_booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あと少しでご予約完了です。"])},
  "order.pay_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐお支払い"])},
  "order.the_booking_has_been_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約をキャンセルしました。"])},
  "order.what_a_pity_hope_to_see_you_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またのご予約をお待ちしております。"])},
  "order.booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約完了"])},
  "order.sorry_no_booking_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約がみつかりません"])},
  "order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポートナンバー"])},
  "order.please_enter_your_passport_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポートナンバーをご記入ください"])},
  "order.please_enter_your_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号をご記入ください"])},
  "order.check_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内訳を確認する"])},
  "order.booking_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊予約明細"])},
  "order.last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前へ戻る"])},
  "order.terms_of_privacy_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシー及び利用規約"])},
  "order.please_enter_valid_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効の資料をご記入ください"])},
  "order.the_room_rice_depends_on_weekdays_or_holidays_check_the_price_per_night_by_pressing_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋料金は平日と休日によって異なります。合計をクリックすると毎晩の料金明細を確認できます"])},
  "order.within_7_days_after_cancellation_a_receipt_of_collection_and_transfer_will_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" チェックアウト後の7日以内に領収書を発行されます。"])},
  "order.to_be_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなくチェックインする"])},
  "order.checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン済み"])},
  "order.checked_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト済み"])},
  "order.not_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不泊"])},
  "order.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋キープカウントダウン"])},
  "order.non_editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなくチェックインではない、予約を編集できない"])},
  "order.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約が見つからない"])},
  "order.card_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効のクレジットカードの支払い方法が見つからない"])},
  "order.transaction_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当する取引が見つからない"])},
  "order.already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お払済み。二重のお払がないようにご注意ください。"])},
  "order.accommodation_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊料金"])},
  "order.room_price_add_ons_and_tax_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋料金、追加料金及び税金"])},
  "order.detailed_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約明細"])},
  "order.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["項目"])},
  "order.additional_purchase_item_early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加購入項目-アーリーチェックイン代"])},
  "order.additional_purchase_item_extra_guest_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加購入項目-追加人数代"])},
  "order.additional_purchase_item_extra_bed_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加購入項目-追加ベッド代"])},
  "order.booking_to_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約をキャンセルする"])},
  "order.hotel_operator_to_refund": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チェックイン日の", _interpolate(_named("d")), "日前に予約をキャンセルした場合、宿泊業者に", _interpolate(_named("n")), "支払金額の返金を請求できます。"])},
  "order.before_{n}_day_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["前から ", _interpolate(_named("n")), " 日(含み)"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_50_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チェックイン日の", _interpolate(_named("d")), "日前に予約をキャンセルした場合、", _interpolate(_named("n")), "支払金額よりキャンセル料が発生します。"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_100_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チェックイン当日にキャンセル及びチェックインされなかった場合、", _interpolate(_named("n")), "支払金額よりキャンセル料が発生します。"])},
  "order.the_guest_who_cancels_the_reversation_{n}_days_prior_to_the_desired_check_in_date_including_the_check_in_date_or_no_show_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チェックイン日の", _interpolate(_named("n")), "日前に予約をキャンセル及びチェックイン日にチェックインされなかった場合、", _interpolate(_named("n")), "支払金額よりキャンセル料が発生します。"])},
  "order.the_guest_who_cancels_the_reversation_at_any_time_or_no_show_on_the_check_in_date_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["いかなる予約のキャンセル及びチェックインをされなかった場合、", _interpolate(_named("n")), "支払金額よりキャンセル料が発生します。"])},
  "order.on_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当日"])},
  "order.cancellation_on_n_day_s_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["宿泊する前の", _interpolate(_named("n")), "日にキャンセル"])},
  "order.no_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金を請求しません"])},
  "order.the_50_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払金額の50％を徴収"])},
  "order.check_in_date_booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊日"])},
  "order.the_100_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払金額の全額を徴収"])},
  "order.the_cancellation_bill_calculated_by_50_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チェックイン日の", _interpolate(_named("n")), "日前までのキャンセルは、支払金額の50%のキャンセル料が発生します。"])},
  "order.1_2_days_before_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の1-2日（含み）"])},
  "order.real_payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済金額"])},
  "order.cancellation_bill_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル料を取り消し"])},
  "order.refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金金額"])},
  "order.cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルの理由"])},
  "order.the_prepaid_deposit_will_be_deducted_from_the_credit_card_account_within_21_working_days_for_any_refund_meeting_the_terms_and_conditions_of_refunds_due_to_cancelled_altered_bookings_or_early_check_outs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約のキャンセル、変更、早期チェックアウトで発生する返金は、返金規定に準じる場合のみ、21営業日以内にクレジットカードに返金されます。ご不明な点は、カスタマーサービスまでお問い合わせください。特別な理由で別の返金方法で行われた場合、その際に生じる手数料は返金額から差し引かれます。"])},
  "order.the_room_prices_on_weekdays_and_holidays_differ_please_click_total_price_and_check_the_detailed_room_price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金は平日や休日により異なります。決済金額をタップすると、1泊あたりの料金の詳細が表示されます。"])},
  "order.date_room_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), "部屋料金"])},
  "order.please_enter_a_cause_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因を入力してください"])},
  "order.upon_submission_of_the_booking_with_or_without_completion_of_the_prepaid_deposit_this_bargain_code_cannot_be_used_again_and_no_request_of_bargains_in_any_way_will_be_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約シートを提出すると決済はともかく同じクーポンをご使用できません。別方法による割引の要請も引き受けかねます。"])},
  "order.my_schedule_is_delayed_or_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のスケジュールは延期またはキャンセルしました。"])},
  "order.cheaper_prices_are_found_on_the_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットでもっと安価の部屋を見つけました"])},
  "order.the_inn_is_not_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この宿泊施設に行くには難しいです"])},
  "order.force_majeure_e_g_natural_disasters_and_pandemics_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可抗力の天災や病気です"])},
  "order.i_d_like_to_book_the_room_from_this_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この宿泊施設へ直接に予約したいです"])},
  "order.i_d_like_to_book_a_new_inn_on_this_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の宿泊施設に予約したいです"])},
  "order.the_offers_from_other_tour_agencies_are_cheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の旅行会社の料金が安いからです"])},
  "order.i_am_unhappy_with_the_payment_rules_for_the_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約の支払規定に納得できません"])},
  "order.due_to_personal_health_factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人的健康の原因です"])},
  "order.changes_in_the_guest_number_or_needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊する人数やニーズは変わりました"])},
  "order.others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
  "order.please_enter_the_cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルの原因をご選択ください"])},
  "order.keep_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約を保留する"])},
  "order.please_enter_or_pick_a_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国をご入力やご選択ください"])},
  "order.a_visitor_having_cancelled_the_booking_not_within_3_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊の3日前にキャンセルされる場合、支払済の料金を100％返金してもらうことができます。"])},
  "order.a_visitor_having_cancelled_the_booking_within_1_2_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_half_of_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊日の1日～2日前にキャンセルされる場合支払済の料金を50％返金してもらうことができます。"])},
  "order.a_visitor_having_cancelled_the_booking_on_the_booked_check_in_date_or_failing_to_stay_in_the_booked_room_shall_pay_the_whole_paid_deposit_to_the_business_owner_as_the_cancellation_bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊当日にキャンセルまたは不泊される場合、支払済料金の100％をキャンセル料金として請求されます。"])},
  "cancelled_policy.loose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゆるい"])},
  "cancelled_policy.normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
  "cancelled_policy.strict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厳しい"])},
  "cancelled_policy.non": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金しない"])},
  "order.the_percent_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["部屋料金の", _interpolate(_named("n")), "％を請求します"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_percent_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["宿泊日の前に", _interpolate(_named("date")), "にキャンセルする方はホテル業者に支払い済みの部屋料金の", _interpolate(_named("n")), "％を返金することを要請できます。"])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_on_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order.contact_customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービスに連絡する"])},
  "order.alert_not_upcoming_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインされない場合は、予約のキャンセルができません"])},
  "order.alert_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約はキャンセルされましたので、再度キャンセルはできません"])},
  "order.alert_is_epidemic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫予約はキャンセルできません"])},
  "order.alert_refunded_type_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金システムが存在しません"])},
  "order.alert_expired_refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約はすでキャンセル料の時間を超過しました"])},
  "order.refund_has_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル料を申請しました"])},
  "order.check_the_accommodation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊規定をチェック"])},
  "order.send_an_email_to_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設にメールを送る"])},
  "order.book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再度予約する"])},
  "order.sorry_no_booking_data_have_been_found_please_confirm_the_email_address_and_booking_id_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありませんが、こちらの予約はございません。メールアドレスと予約番号をご確認ください。"])},
  "order.after_submitting_the_booking_the_preferential_code_is_not_available_whether_completing_check_out_settling_the_bill_or_not_and_no_discount_can_be_requested_in_any_other_ways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が送信されると、決済完了の有無に関係なく、クーポンコードの再使用や他の方法による割引の要求ができません。"])},
  "order.cancellation_policy_for_this_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本予約のキャンセルポリシー"])},
  "order.special_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別な状況"])},
  "order.after_check_in_if_a_guest_cancels_the_reservation_for_subsequent_days_not_staying_in_owing_to_an_emergency_we_will_deduct_the_amount_of_money_already_staying_in_and_charge_the_cleaning_fee_and_cost_of_disinfection_additionally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン後に予期せぬ事情により予約をキャンセルする場合、未滞在日数の料金は差し引かれ、清掃・消毒の追加料金が請求されます。"])},
  "order.credit_card_refund_chargeback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード返金"])},
  "order.invoice_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一発票/領収書"])},
  "order.a_hotel_operator_can_issue_an_invoice_or_reciept_for_the_booking_that_is_paid_at_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊業者によってチェックイン時に統一発票​​または領収書を発行する場合がございます。"])},
  "order.if_your_booking_belongs_to_the_pre_paid_booking_fully_paid_to_tourbobo_we_will_issue_a_reciept_of_travel_agency_and_you_can_go_to_the_booking_content_to_check_in_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboに全額前払いをされた場合、tourboboは「代收轉付收據」を発行します。内容は、チェックアウト後7日以内まで確認できます。"])},
  "order.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解"])},
  "order.please_read_policies_and_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポリシーと条項をご覧ください"])},
  "order.tick_the_box_of_i_have_carefully_read_the_cancellation_refund_policy_and_agreed_the_terms_of_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「キャンセルポリシーを読み、プライバシー利用条項に同意します」にチェックを入れてください"])},
  "order.leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出する"])},
  "order.stay_at_the_current_sreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページに留まる"])},
  "order.are_you_sure_you_want_to_leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出を確定しますか？"])},
  "order.the_booking_will_be_cancelled_automatically_after_leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出後は予約が自動的に取り消されます"])},
  "order.the_booking_has_been_established_please_remember_the_booking_number_so_as_to_check_the_booking_content_the_booking_will_be_cancelled_automatically_if_not_paid_within_the_time_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約が成立しました。予約内容ご確認のため、予約番号を控えてください。期限内に支払いのない場合、ご予約は自動的にキャンセルされます。"])},
  "order.failed_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル失敗"])},
  "order.whoops_a_technical_problem_and_cannot_cancel_the_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技術的な問題により、予約をキャンセルできません。"])},
  "order.open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleマップを開く"])},
  "order.failed_to_open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleマップが開けません"])},
  "order.open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appleマップを開く"])},
  "order.failed_to_open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appleマップが開けません"])},
  "order.succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功です"])},
  "order.cancellation_refund_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセルポリシー"])},
  "order.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
  "order.the_address_has_been_copied_to_the_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレスはクリップボードにコピーされました"])},
  "order.rules_of_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊規定"])},
  "order.please_choose_the_reason_for_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル理由をお選びください"])},
  "order.please_enter_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
  "order.please_enter_your_booking_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約番号を入力してください"])},
  "order.query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "order.please_choose_the_departure_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出発国を選択してください"])},
  "order.failed_to_dial_the_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話をかけることができません"])},
  "order.failed_to_open_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebookが開けません"])},
  "order.failed_to_open_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINEが開けません"])},
  "order.failed_to_send_an_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールの送信ができません"])},
  "order.national_travel.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間"])},
  "order.national_travel.allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order.national_travel.once_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各予約に1つのサポートとなりますので、その他割引と合わせてのご使用はできません"])},
  "order.national_travel.no_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用しない"])},
  "order.national_travel.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートを使用する(館内割引)"])},
  "order.national_travel.three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワクチンを三回接種されましたか？"])},
  "order.national_travel.no_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三回ワクチンを接種していません"])},
  "order.national_travel.had_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに三回ワクチンを接種しました"])},
  "order.national_travel.agree_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客需同意「 免責聲明 」，才可使用悠遊國旅補助。入住當天，現場查核資格符合，旅宿將於現場折抵補助金額。如資格不符或政府預算已用完，旅客須支付全額費用。"])},
  "order.national_travel.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免責聲明"])},
  "order.national_travel.agree_for_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已確認身份資格，可使用悠遊國旅補助，且同意免責聲明。"])},
  "order.national_travel.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解しました"])},
  "order.credit_card.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード名義人の氏名"])},
  "order.credit_card.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号"])},
  "order.credit_card.validity_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "order.credit_card.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード名義人の氏名を入力してください"])},
  "order.credit_card.validity_period.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ＭＭ/YY"])},
  "order.pay_by_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着後にお支払い"])},
  "order.pay_by_arrival_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプラットフォームでは、事前承認の目的でのみカードが使用されますが、カードでの引き落としは行えません。ご予約のキャンセル、またはご予定時間にチェックインが行われなかった場合、宿泊施設よりキャンセルポリシーに準じた料金が請求されます。"])},
  "order.pay_can_use_each_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのカードがご使用いただけます："])},
  "order.pay_by_arrival_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在いかなる費用もかかりません ‐ チェックイン時にお支払いください。"])},
  "order.type_of_charge_and_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い方法"])},
  "order.platform_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プラットフォームでお支払い"])},
  "order.excuse_me_your_credit_card_can_not_be_verified_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございませんが、クレジットカードが確認できませんので、もう一度お試しください。"])},
  "order.please_tick_and_agree_the_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免責声明にチェックを入れてください"])},
  "order.please_enter_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なカード番号を入力してください"])},
  "order.please_enter_valid_expiry_date_of_your_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なカード期限を入力してください"])},
  "order.partial_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部支払い"])},
  "validation.cnn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード番号が正確ではありません"])},
  "order.enter_your_id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証番号を入力してください"])},
  "order.invoice_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領收書"])},
  "order.if_full_payment_is_made_for_a_prepaid_stay_through_tourbobo_a_receipt_for_payment_confirmation_issued_by_tourbobo_will_be_available_in_your_booking_details_within_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourboboに全額前払いをされた場合、tourboboは「代收轉付收據」を発行します。内容は、チェックアウト後7日以内まで確認ができます。"])},
  "order.in_case_of_any_unexpected_situation_occurs_and_result_in_early_checkout_the_unused_reserved_nights_will_be_refunded_an_additional_cleaning_fee_will_be_charged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン後に予期せぬ事情により予約をキャンセルする場合、未滞在日数の料金は差し引かれた後、清掃・消毒の追加料金が請求されます。"])},
  "order.send_a_mail_to_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設にメールを送る"])},
  "order.looking_forward_to_your_next_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回のご予約お待ちしております！"])},
  "order.refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払い戻し申請中"])},
  "order.id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証番号"])},
  "order.designated_credit_card_number_first_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典指定のクレジットカード番号(上8ケタ)"])},
  "order.this_is_to_verify_the_cardholder_s_identity_and_no_credit_card_payment_will_be_processed_now_you_may_use_the_discount_code_upon_successful_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このステップではカード会員様の認証のみ行われ、引き落としはございません。認証に成功しますと、こちらの特典コードのご使用が可能になります。"])},
  "order.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])},
  "order.cardholder_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード会員様の本人認証"])},
  "order.please_enter_a_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なクレジットカード番号を入力してください"])},
  "order.unlock_exclusive_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別特典のロックを解除"])},
  "order.view_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典をチェック"])},
  "order.view_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐにチェック"])},
  "order.exclusive_discount_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別特典"])},
  "order.offer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典詳細"])},
  "order.get_your_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取る"])},
  "order.offer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典内容"])},
  "order.validity_span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
  "order.discount_eligibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資格制限"])},
  "order.how_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご使用方法"])},
  "order.other_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他の使用条件"])},
  "news.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防疫速報"])},
  "news.hot_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気記事"])},
  "loading.loading_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローディング中です。お待ちください…"])},
  "loading.reloading_to_show_the_latest_discount_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在更新中です。お得な料金をご案内します…"])},
  "loading.error_non_displayable_try_reloading_the_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー（表示できません）。更新後、もう一度お試しください。"])},
  "loading.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム画面へ戻る"])},
  "loading.reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "loading.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約が完了しました。"])},
  "loading.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容を見る"])},
  "loading.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません。お支払いを完了できませんでした。ご予約内容の支払い状況を再確認してください。"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_search_criteria_please_reset_the_search_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありません。お探しの条件では空室がありません。検索条件を変更してもう一度お探しください。"])},
  "loading.search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう一度探す"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありません。検索結果はありませんでした。絞り込み条件を広げてもう一度お探しください。"])},
  "loading.clear_filter_search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件をクリアしてもう一度探す"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria_to_show_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ありません。検索結果はありませんでした。絞り込み条件を広げて他の部屋タイプもご検討ください。"])},
  "loading.clear_filter_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件をクリアして全ての部屋タイプを表示"])},
  "user-homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容"])},
  "user-homepage.please_enter_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号を入力してください"])},
  "user-homepage.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
  "user-homepage.please_select_a_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を選択してください"])},
  "user-homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "user-homepage.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "user-homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れましたか？"])},
  "user-homepage.my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントとパスワードがあります"])},
  "user-homepage.login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebookにログイン"])},
  "user-homepage.login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleにログイン"])},
  "user-homepage.member_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員にログイン"])},
  "user-homepage.account_not_found_please_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員アカウントがございませんので、登録をしてください"])},
  "user-homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
  "user-homepage.sign_in_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebookにログイン"])},
  "user-homepage.sign_in_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleにログイン"])},
  "user-homepage.account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントとパスワードがあります"])},
  "user-homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名"])},
  "user-homepage.please_enter_your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名を入力してください"])},
  "user-order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
  "user-order.please_enter_your_e-mail_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
  "user-homepage.enter_your_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスを入力してください"])},
  "user-homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "user-homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを確認"])},
  "user-homepage.create_password_6_to_30_characters_long_including_letters_numbers_and_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード設定：任意の英数字、記号を6文字以上30文字以下まで使用できます。"])},
  "user-homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した二つのパスワードが異なります、再度お試しください。"])},
  "user-homepage.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この項目は入力が必須です"])},
  "user-homepage.i_understand_and_agree_to_the_terms_of_service_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当サイトの利用規約とプライバシーポリシーを了承し同意します"])},
  "user-homepage.login_to_review_more_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインしてさらにリストを検索"])},
  "user-homepage.go_to_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約へ戻る"])},
  "user-homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "user-detail.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン日"])},
  "user-detail.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト日"])},
  "user-detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプを選択"])},
  "user-detail.guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊客"])},
  "user-detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "user-detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
  "user-detail.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人"])},
  "user-detail.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 子供"])},
  "user-detail.n_rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 部屋"])},
  "user-detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設の紹介"])},
  "user-detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基本定員：", _interpolate(_named("n")), "人"])},
  "user-detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドタイプ"])},
  "user-detail.select_the_age_of_the_child_no_additional_charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加料金のないお子様のご年齢を選択"])},
  "user-detail.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間"])},
  "user-detail.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト時間"])},
  "user-detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約時の注意事項"])},
  "user-detail.amenities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["施設及びサービス"])},
  "user-detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご宿泊時の注意事項"])},
  "user-detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル規定"])},
  "user-detail.booking_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約リスト"])},
  "user-detail.number_of_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊人数"])},
  "user-detail.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 大人，", _interpolate(_named("n")), " 子供"])},
  "user-detail.no_current_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在予約されている客室タイプはございません"])},
  "user-detail.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総額"])},
  "user-detail.add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストに追加"])},
  "user-detail.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確定する"])},
  "user-detail.no_room_is_available_for_dates_requested_please_change_the_dates_to_continue_booking_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございませんが現在空室がございません、その他の日程を検索してください"])},
  "user-detail.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索する"])},
  "user-order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約情報"])},
  "user-order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計部屋数"])},
  "user-order.total_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計宿泊人数"])},
  "user-order.bed_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドタイプ"])},
  "user-order.add-on_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加購入項目"])},
  "user-order.subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
  "user-order.add-on_parking_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["駐車場を追加購入"])},
  "user-order.please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お選びください"])},
  "user-order.domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊国内旅行サポート"])},
  "user-order.no_use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用しません"])},
  "user-order.use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内旅行サポートを使用する"])},
  "user-order.use_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンコードを使用"])},
  "user-order.please_enter_your_promo_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典コードを入力してください"])},
  "user-order.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典"])},
  "user-order.please_fill_in_ID_numbers_for_domestic_travellers_and_passport_numbers_for_foreign_travellers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内のお客様は身分証番号、海外のお客様はパスポート番号をご記入ください。"])},
  "user-order.the_system_will_send_the_booking_confirmation_and_contact_you_via_the_email_address_and_mobile_number_provided_please_ensure_the_information_is_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["システムより予約確認及び連絡事項が送信されますので、メールアドレスと携帯電話番号はお間違えのないようご確認ください。"])},
  "user-order.check_the_spam_mailbox_if_you_do_not_receive_the_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールボックスに受信されていない場合は、ゴミ箱をご確認ください。"])},
  "user-order.guest_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊客の基本資料"])},
  "user-order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓（ローマ字）"])},
  "user-order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓を入力してください"])},
  "user-order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名（ローマ字）"])},
  "user-eorder.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名を入力してください"])},
  "user-order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "user-order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "user-order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "user-order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
  "user-order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
  "user-order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの国籍を選択してください"])},
  "user-order.id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証番号"])},
  "user-order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポート番号"])},
  "user-order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書番号を入力してください"])},
  "user-order.enter_valid_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な身分証番号を入力してください"])},
  "user-order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのパスポート番号を入力してください"])},
  "user-order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なパスポート番号を入力してください"])},
  "user-order.id_card_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証番号の形式(A012345678)"])},
  "user-order.passport_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポート番号の形式(9文字)"])},
  "user-order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
  "user-order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話の番号を入力してください"])},
  "user-order.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了です！"])},
  "user-order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この項目は必須です"])},
  "user-order.date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
  "user-order.gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編号"])},
  "user-order.please_enter_gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編号を入力してください"])},
  "user-order.gui_composed_of_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一編号は8ケタです"])},
  "user-order.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名"])},
  "user-order.please_enter_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名を入力してください"])},
  "user-order.limited_255_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["255字以内"])},
  "user-order.estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着予定時間"])},
  "user-order.please_enter_estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着予定時間を入力してください"])},
  "user-order.country_of_departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出発国"])},
  "user-order.elligibility_for_entry_to_taiwain_native_citizens_foreign_nationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入国資格(本国籍/外国籍(国籍別))"])},
  "user-order.special_medical_conditions_such_as_cardiovascular_or_chronic_diseases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心血管疾患、慢性疾患などの特別な持病の有無"])},
  "user-order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着便名"])},
  "user-order.special_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なご要望"])},
  "user-order.please_note_that_the_hotel_does_not_guarantee_to_meet_your_needs_and_will_be_subject_to_on-site_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設がお客様のニーズをすべて満たすことを保証するものではございません、現場状況に従われることをご注意ください。"])},
  "user-order.emergency_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先情報"])},
  "user-order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先の姓"])},
  "user-order.please_enter_the_last_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先氏名を入力してください"])},
  "user-order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先の名"])},
  "user-order.please_enter_the_first_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先名を入力してください"])},
  "user-order.contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先の電話番号"])},
  "user-order.please_enter_the_phone_number_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先電話番号を入力してください"])},
  "user-order.return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "user-order.confirm_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約リストの確認"])},
  "user-order.order_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が成立しました！"])},
  "user-order.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号"])},
  "user-order.payment_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い情報"])},
  "user-order.payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い方法"])},
  "user-order.select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い方法を選択"])},
  "user-order.please_provide_a_valid_unified_business_number_if_an_invoice_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一番号の発行が必要な場合は、統一番号を正確にご記入ください。"])},
  "user-order.if_paid_by_the_unionpay_card_the_above_payment_success_page_is_only_the_reply_message_from_the_card_issuing_bank_please_refer_to_the_notification_from_the_property_you_booked_to_ensure_the_completion_of_your_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀聯カードでお支払いされる場合、決済成功ページは銀聯カード発行会社からの返信のみとなりますので、お取引完了の有無は宿泊施設からのお知らせをご確認ください。"])},
  "user-order.cancellation_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約取り消しの説明"])},
  "user-order.i_have_read_and_agree_to_the _terms_and_conditions_of_the_cacellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイトの予約取り消し説明を読み、同意します。"])},
  "user-order.proceed_to_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済する"])},
  "user-order.payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い金額"])},
  "user-order.note_full_payment_is_due_on_the_check-in_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考：チェックイン当日にお支払いください"])},
  "user-order.thank_you_for_your_reservation_the_system_will_send_you_a_confirmation_for_the_reservation_to_your_email_address_please_should_there_be_any_questions_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約いただきありがとうございます、システムより予約確認メールが送信されます。もし何かご不明な点がございましたら、お問い合わせください。"])},
  "user-homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
  "user-homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
  "user-unit.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋"])},
  "user-unit.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歳"])},
  "user-unit.NTD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾元"])},
  "user-unit.stick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本"])},
  "user-unit.piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個"])},
  "user-unit.portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人分"])},
  "user-unit.tube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枚"])},
  "user-unit.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "user-unit.bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッド"])},
  "user-unit.pillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まくら"])},
  "user-unit.blanket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ふとん"])},
  "user-unit.toiletries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメニティ"])},
  "user-unit.towel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タオル"])},
  "user-unit.pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット"])},
  "user-date.january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1月"])},
  "user-date.fabruary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2月"])},
  "user-date.march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3月"])},
  "user-date.april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4月"])},
  "user-date.may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5月"])},
  "user-date.june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6月"])},
  "user-date.july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7月"])},
  "user-date.august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8月"])},
  "user-date.september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9月"])},
  "user-date.october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10月"])},
  "user-date.november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11月"])},
  "user-date.december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12月"])},
  "user-date.sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土"])},
  "user-date.sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "user-date.mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "user-date.tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火"])},
  "user-date.wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "user-date.thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
  "user-date.fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金"])},
  "user-nav.make_a_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約へ"])},
  "user-nav.reservation_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約センター"])},
  "user-nav.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
  "user-nav.chang_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
  "user-nav.my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のアカウント"])},
  "user-nav.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしました"])},
  "user-nav.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました"])},
  "user-nav.arriving_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まもなくチェックイン"])},
  "user-order.check_into_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊ホテル"])},
  "user-order.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間"])},
  "user-order.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト時間"])},
  "user-order.order_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約価格"])},
  "user-order.order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状態"])},
  "user-order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払状況"])},
  "user-order.pay_in_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全額を支払う"])},
  "user-order.pay_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デポジットを支払う"])},
  "user-order.invoice_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一発票番号"])},
  "user-order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統一発票を検索"])},
  "user-order.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "user-dialog.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
  "user-dialog.please_contact_us_should_there_be_any_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お不明な点がございましたら、どうぞお問い合わせください。"])},
  "user-dialog.consumer_sevice_hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービス電話番号"])},
  "user-dialog.customer_service_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービスメールアドレス"])},
  "user-dialog.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
  "user-homepage.invitation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード"])},
  "user-homepage.invitation_code_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正しい紹介コードを入力してください"])},
  "hotel-group.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "hotel-group.north": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北地区"])},
  "hotel-group.ila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宜蘭"])},
  "hotel-group.tyn_hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園 新竹"])},
  "hotel-group.center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中心"])},
  "hotel-group.center1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中心 1 区"])},
  "hotel-group.center2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中心 2 区"])},
  "hotel-group.south": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南地区"])},
  "hotel-group.foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外国"])},
  "userInfo.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}