import format from 'date-fns/format'
import parse from 'date-fns/parse'
import differenceInDays from 'date-fns/differenceInDays'
import { zhTW, enUS, ja } from 'date-fns/locale'

export const formatLocaleWeek = (date?: Date, selectedLocale?: string): string | undefined => {
  const locale = selectedLocale === 'en' ? enUS : selectedLocale === 'jp' ? ja : zhTW
  return date ? format(date, 'yyyy/MM/dd', { locale }) + '\n' + format(date, 'eee', { locale }) : undefined
}

export const parseDateString = (dateString?: string): Date | undefined => {
  return dateString ? parse(dateString, 'yyyy-MM-dd', new Date()) : undefined
}

export const getDuration = (startDate?: Date, endDate?: Date): number => {
  return !startDate || !endDate ? 0 : differenceInDays(startDate, endDate)
}
