import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_dialog = _resolveComponent("van-dialog");

  return _openBlock(), _createBlock(_component_van_dialog, {
    show: _ctx.show,
    "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.show = $event;
    }),
    className: "dialog-custom ".concat(_ctx.customClass),
    width: _ctx.width,
    title: _ctx.title,
    showConfirmButton: false
  }, {
    default: _withCtx(function () {
      return [!_ctx.customClass ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        class: "close-icon",
        iconName: "icon_48_close_big",
        onClick: _ctx.onClose
      }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.customClass ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        class: "close-icon",
        iconName: "icon-24-contact",
        onClick: _ctx.onClose
      }, null, 8, ["onClick"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  }, 8, ["show", "className", "width", "title"]);
}