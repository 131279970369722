import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import { defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
import Dialog from '@/components/share/Dialog.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'LoginPopup',
  components: {
    DialogComponent: Dialog
  },
  setup: function setup() {
    var t = inject('t');
    var router = useRouter();
    var isMobile = inject('isMobile');
    var isLoginModelOpen = inject('isLoginModelOpen');
    var onLoginModelToggle = inject('onLoginModelToggle');
    var store = useStore();
    var vueAppUrl = process.env.VUE_APP_URL;

    var getResToken = function getResToken(token) {
      store.dispatch('user/oauthTokenExchange', token).then(function (memRes) {
        setTimeout(function () {
          store.dispatch('user/userLevel', memRes.data.access_token);
          store.dispatch('user/getRewardCount', token);
          store.dispatch('account/apiUser', token);
          if (onLoginModelToggle) onLoginModelToggle();
        }, 1000);
      }).catch(function (e) {
        store.dispatch('user/resetUserToken');
        store.dispatch('account/resetAccount');
      });
    };

    var callback = function callback(response) {
      setTimeout(function () {
        store.dispatch('account/googleOneTap', response.credential).then(function (res) {
          localStorage.setItem('pms_token', res.data.access_token);
          getResToken(res.data.access_token);
        });
      }, 600);
    };

    var saveRedirectUrl = function saveRedirectUrl() {
      localStorage.setItem('redirect_url', router.currentRoute.value.fullPath);
    };

    var onSuccess = function onSuccess(res) {
      var code = res.authorization.code;
      fetch('https://accounts.mastripms.com/api/v1/oauth/apple_callback', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: "Bearer ".concat(code)
        },
        body: JSON.stringify({
          code: code,
          client: 'mem'
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res) {
        localStorage.setItem('pms_token', res.access_token);
        getResToken(res.access_token);
      });
    };

    var onFailure = function onFailure(e) {
      console.error('onFailure', e);
    };

    return {
      t: t,
      vueAppUrl: vueAppUrl,
      isMobile: isMobile,
      isLoginModelOpen: isLoginModelOpen,
      onLoginModelToggle: onLoginModelToggle,
      callback: callback,
      saveRedirectUrl: saveRedirectUrl,
      onSuccess: onSuccess,
      onFailure: onFailure
    };
  }
});