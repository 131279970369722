import { Commit } from 'vuex'
import { accountApi, memApi, myApi, pmsApi } from '@/api'
import { UserState } from '@/store/types/user'

// initial state
const initToken = {
  access_token: '',
  expired_in: 0,
  user: {
    name: '',
    image: ''
  }
}
const state: UserState = {
  registering: false,
  levelInfo: {
    level_id: 0,
    level_name: '',
    discount_percent: 0,
    amount_to_upgrade: 0,
    reward_point_percent: 0,
    level_cumulative_amount: 0,
    cumulative_amount: 0,
    downgrade_alert: false,
    discount_extras: [''],
    invitation_code: '',
    invited_count: 0,
    has_inviter: false,
    first_purchase_reward_received: false
  },
  token: initToken,
  bookings: [],
  rewardCount: {
    count: 0,
    point: 0,
    store: 0
  }
}

// getters
const getters = {}

const actions = {
  async register({ commit }: { commit: Commit }, data: any): Promise<any> {
    commit('setRegistering', true)
    const res = await pmsApi.personal.pmsPostUserRegister(data, {
      hotel_id: '1031'
    })
    commit('setRegistering', false)
    return res
  },
  async invitationCode({ commit }: { commit: Commit }, invitationCode: any): Promise<any> {
    const res = await memApi.personal.pmsGetPersonalUserInvitationCode(invitationCode, {
      hotel_id: '1031'
    })
    return res
  },
  async eventCode({ commit }: { commit: Commit }, eventCode: any): Promise<any> {
    const res = await myApi.mem.pmsGetPersonalUserEventCode(eventCode)
    return res
  },
  async userLevel({ commit }: { commit: Commit }, token?: string): Promise<any> {
    const { data } = await memApi.personal.pmsGetPersonalUser(
      {
        hotel_id: '1031'
      },
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        : undefined
    )
    commit('setUserLevel', data)
  },
  async oauthTokenExchange({ commit }: { commit: Commit }, token: string): Promise<any> {
    const res = await pmsApi.oauth.pmsPostOauthTokenExchange(
      {
        hotel_id: 1031
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    commit('setToken', res.data)
    return res
  },
  resetUserToken({ commit }: { commit: Commit }): void {
    commit('resetToken', initToken)
  },
  async getPersonalBookings({ commit }: { commit: Commit }, status: 'UPCOMING' | 'CHECKED_IN' | 'CANCELED'): Promise<any> {
    const memToken = localStorage.getItem('mem_token')
    const res = await memApi.personal.pmsGetPersonalBookings(
      {
        hotel_id: '1031',
        status
      },
      {
        headers: {
          Authorization: `Bearer ${memToken}`
        }
      }
    )
    commit('setBookings', (res.data as any)?.bookings || [])
    return res
  },
  async getRewardCount({ commit }: { commit: Commit }, token?: string): Promise<any> {
    const res = await accountApi.api.v1UserRewardCountList(
      {
        kind: '1'
      },
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        : undefined
    )
    commit('setRewardCount', res.data)
    return res
  }
}

// mutations
const mutations = {
  setRegistering(state: UserState, loading: boolean): void {
    state.registering = loading
  },
  setUserLevel(state: UserState, data: UserState['levelInfo']): void {
    state.levelInfo = data
  },
  setToken(state: UserState, data: UserState['token']): void {
    localStorage.setItem('mem_token', data.access_token)
    state.token = data
  },
  setBookings(state: UserState, datas: UserState['bookings']): void {
    state.bookings = datas
  },
  setRewardCount(state: UserState, data: UserState['rewardCount']): void {
    state.rewardCount = data
  },
  resetToken(state: UserState, data: UserState['token']): void {
    localStorage.removeItem('mem_token')
    localStorage.removeItem('pms_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('redirect_url')
    state.token = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
