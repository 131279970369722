export const DATE_FORMAT = 'yyyy/MM/dd'
export const DATE_TIME_FORMAT = 'yyyy/MM/dd HH:mm'
export const DATA_DATE_FORMAT = 'yyyy-MM-dd'
export const DATEWEEK_FORMAT = 'yyyy/MM/dd (ccccc)'
export const MOBILE_DATEWEEK_FORMAT = 'MM/dd (ccccc)'
export const MOBILE_DATEWEEK_FORMAT_SHORT = 'MM/dd'

export const ERROR_MESSAGE = {
  REQUIRED: 'order.required_field',
  DISEASE_REQUIRED: 'order.please_enter_the_name_of_your_specific_illness',
  EMAIL: 'homepage.please_enter_a_valid_email_address',
  EMAIL_CONFIRMATION: 'order.please_enter_your_email_address_to_avoid_any_biased_data',
  PROMOTION_CODE: 'order.please_enter_the_valid_discount_code',
  CREDIT_CARD_NUMBER: 'order.please_enter_valid_credit_card_number',
  CREDIT_CARD_PERIOD: 'order.please_enter_valid_expiry_date_of_your_credit_card'
}

export const BOOKING_STATUS = {
  UPCOMING: 'UPCOMING',
  CANCELED: 'CANCELED',
  CHECKED_IN: 'CHECKED_IN',
  CHECKED_OUT: 'CHECKED_OUT',
  NO_SHOW: 'NO_SHOW'
}

export const BOOKING_STATUS_MAPPING = {
  [BOOKING_STATUS.UPCOMING]: 'order.to_be_checked_in',
  [BOOKING_STATUS.CANCELED]: 'order.booking_cancelled',
  [BOOKING_STATUS.CHECKED_IN]: 'order.checked_in',
  [BOOKING_STATUS.CHECKED_OUT]: 'order.checked_out',
  [BOOKING_STATUS.NO_SHOW]: 'order.not_checked_in'
}

export const PAYMENT_STATUS = {
  UNPAID: 'UNPAID',
  PAID_IN_FULL: 'PAID_IN_FULL',
  PAID_IN_PART: 'PAID_IN_PART',
  REFUNDING: 'REFUNDING'
}

export const PAYMENT_STATUS_MAPPING = {
  [PAYMENT_STATUS.UNPAID]: 'order.not_paid',
  [PAYMENT_STATUS.PAID_IN_FULL]: 'order.paid',
  [PAYMENT_STATUS.PAID_IN_PART]: 'order.partial_payment',
  [PAYMENT_STATUS.REFUNDING]: 'order.refunding'
}

export const ORDER_STATUS = {
  SUCCESS: 'success',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
  CHECKED_OUT: 'checkedOut',
  REFUNDING: 'refunding'
}

export const ALL_LANGUAGES = [
  {
    countryCode: 'tw',
    icon: 'flag-for-taiwan',
    name: '繁體中文'
  },
  // {
  //   countryCode: 'zh-CN',
  //   icon: 'flag-for-china',
  //   name: '简体中文'
  // },
  {
    countryCode: 'jp',
    icon: 'flag-for-japan',
    name: '日本語'
  },
  {
    countryCode: 'en',
    icon: 'flag-for-us-outlying-islands',
    name: 'English'
  }
]

export const GENDER: { [key: string]: string } = {
  FEMALE: 'order.female',
  MALE: 'order.male'
}
