import { Keyword } from '@/api/api'
export interface City {
  ['city_id']: number
  ['city_name']: string
  ['city_en_name']: string
  lat: number
  lng: number
}

export interface CountryCode {
  label: string
  value: string
}

export interface Nationality {
  label: string
  value: string
}

export enum SortingEnum {
  Recommend = 'recommend',
  LowestPrice = 'lowest_price',
  HightestScore = 'highest_score',
  HightestPrice = 'highest_price'
}

export interface People {
  ['lang_key']: string
  ['n_lang_key']: string
  value: string
  max: number
  min: number
  defaultValue: number
}

export interface MeatDietOptions {
  label: string
  ['lang_key']?: string
  value: boolean
}

export interface DiseaseOptions {
  label: string
  ['lang_key']?: string
  value: boolean
}

export interface GenderOptions {
  label: string
  ['lang_key']?: string
  value: string
}
export interface NationalTravelOption {
  label: string
  ['lang_key']?: string
  value: boolean
}
export interface VaccineOptions {
  label: string
  ['lang_key']?: string
  value: boolean
}

export interface SortingOptions {
  name: string
  label: string
}

export interface FeatureToggles {
  [key: string]: boolean
}

export interface OptionsState {
  countryCode: string
  cities: City[]
  twPopularCities: City[]
  jpPopularCities: City[]
  countryCodes: CountryCode[]
  nationalities: Nationality[]
  keywords: Keyword[]
  people: People[]
  childAges: number[]
  genderOptions: GenderOptions[]
  nationalTravelOptions: NationalTravelOption[]
  vaccineOptions: VaccineOptions[]
  meatDietOptions: MeatDietOptions[]
  hasDiseaseOptions: DiseaseOptions[]
  sortingItems: SortingOptions[]
  featureToggles: FeatureToggles
}
