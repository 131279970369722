import startOfDay from 'date-fns/startOfDay'
import intervalToDuration from 'date-fns/intervalToDuration'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import differenceInDays from 'date-fns/differenceInDays'

const FIRST_DATE = new Date('10/13/22')
const LAST_DATE = new Date(9999, 12, 31)

export const isDuringSpringFestival = (date: Date): boolean => {
  return isAfter(date, FIRST_DATE) && isBefore(date, LAST_DATE)
}

export const computeCheckOutDate = (startDate: Date, endDate: Date): Date => {
  if (isAfter(startDate, FIRST_DATE)) {
    /**
     * “入住日期” 為10/14(包含)後的訂房，日期預設為1天。
     */
    return notMoreThan90Days(startDate, endDate)
  } else {
    /**
     * “入住日期” 為10/14前的訂房，日期預設為4天。
     */
    const durationDays =
      intervalToDuration({
        start: startDate,
        end: endDate
      }).days || 0
    if (durationDays < 4) {
      return startOfDay(addDays(startDate, 4))
    } else {
      return notMoreThan90Days(startDate, endDate)
    }
    /**
     * “入住日期” 為3/6(包含)前的訂房
     * i.  結束日期與入住日期差1~8天，預設為+8天。
     * ii. 結束日期與入住日期差9~11天，預設為+11天。
     * iii. 結束日期與入住日期差12~15天，預設為+15天。
     * iv. 結束日期與入住日期差16天以上，依使用者所選結束日期。
     * if (durationDays <= 8) {
        return startOfDay(addDays(startDate, 8))
      } else if (durationDays >= 9 && durationDays <= 11) {
        return startOfDay(addDays(startDate, 11))
      } else if (durationDays >= 12 && durationDays <= 15) {
        return startOfDay(addDays(startDate, 15))
      } else {
        return startOfDay(endDate)
      }
     */
  }

  // if (isDuringSpringFestival(startDate)) {
  //   /**
  //    * “入住日期” 介於 2021/12/14 ~ 2022/2/28
  //    * i.  結束日期與入住日期差1~8天，預設為+8天。
  //    * ii. 結束日期與入住日期差9~11天，預設為+11天。
  //    * iii. 結束日期與入住日期差12~15天，預設為+15天。
  //    * iv. 結束日期與入住日期差16天以上，依使用者所選結束日期。
  //    */
  //   if (durationDays <= 8) {
  //     return startOfDay(addDays(startDate, 8))
  //   } else if (durationDays >= 9 && durationDays <= 11) {
  //     return startOfDay(addDays(startDate, 11))
  //   } else if (durationDays >= 12 && durationDays <= 15) {
  //     return startOfDay(addDays(startDate, 15))
  //   } else {
  //     return startOfDay(endDate)
  //   }
  // } else {
  //   /**
  //    * 一般日期選擇，退房日期預設為入住日期+15天。
  //    */
  //   if (durationDays < 15) {
  //     return startOfDay(addDays(startDate, 15))
  //   } else {
  //     return startOfDay(endDate)
  //   }
  // }
}

export const computeDateForSelfHealth = (startDate: Date, endDate: Date): Date => {
  const durationDays =
    intervalToDuration({
      start: startDate,
      end: endDate
    }).days || 0
  if (durationDays < 7) {
    return startOfDay(addDays(startDate, 7))
  } else {
    return startOfDay(endDate)
  }
}

export const notMoreThan90Days = (startDate: Date, endDate: Date): Date => {
  const durationDays = differenceInDays(endDate, startDate)
  if (durationDays <= 1) {
    return startOfDay(addDays(startDate, 1))
  } else if (durationDays <= 90) {
    return startOfDay(endDate)
  } else {
    return startOfDay(addDays(startDate, 90))
  }
}
