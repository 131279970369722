import { Api } from '@/api/api'
import { Api as ApiAccount } from '@/api/account-api'
import i18n from '../i18n'
export const BASE_URL = process.env.VUE_APP_MEM_API || '/mockAPI'
export const BASE_ACCOUNT_URL = process.env.VUE_APP_ACCOUNT_API || '/mockAPI'
export const BASE_PMS_URL = process.env.VUE_APP_PMS_API || '/mockAPI'
export const pmsToken = localStorage.getItem('pms_token') || ''
export const memToken = localStorage.getItem('mem_token') || ''
export const myApi = new Api({
  baseURL: BASE_URL,
  headers: {
    'Accept-Language': i18n.global.locale.value
  }
})
myApi.instance.interceptors.request.use(
  (config: any) => {
    config.headers['Accept-Language'] = i18n.global.locale.value
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

myApi.instance.interceptors.response.use(
  (response) => {
    if (response.status === 301) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', 301)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-header"]').setAttribute('content', 'Location: /')
    }
    if (response.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', response.status)
      return response
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const accountApi = pmsToken
  ? new ApiAccount({
      baseURL: BASE_ACCOUNT_URL,
      headers: {
        Authorization: `Bearer ${pmsToken}`
      }
    })
  : new ApiAccount({
      baseURL: BASE_ACCOUNT_URL
    })

accountApi.instance.interceptors.response.use(
  (response) => {
    response.headers['Accept-Language'] = i18n.global.locale.value
    if (response.status === 301) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', 301)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-header"]').setAttribute('content', 'Location: /')
    }
    if (response.status === 401) {
      window.location.href = `https://accounts.mastripms.com/oauth/authorize?client_id=1048&response_type=code&redirect_uri=${process.env.VUE_APP_URL}/oauth/callback&scope=&email=&role=mem`
    }
    if (response.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', response.status)
      return response
    }

    return response
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = `https://accounts.mastripms.com/oauth/authorize?client_id=1048&response_type=code&redirect_uri=${process.env.VUE_APP_URL}/oauth/callback&scope=&email=&role=mem`
    }
    return Promise.reject(error)
  }
)

export const pmsApi = pmsToken
  ? new Api({
      baseURL: BASE_PMS_URL,
      headers: {
        Authorization: `Bearer ${pmsToken}`
      }
    })
  : new Api({
      baseURL: BASE_PMS_URL
    })

pmsApi.instance.interceptors.response.use(
  (response) => {
    response.headers['Accept-Language'] = i18n.global.locale.value
    if (response.status === 301) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', 301)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-header"]').setAttribute('content', 'Location: /')
    }
    if (response.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', response.status)
      return response
    }

    return response
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.href =
      //   `https://accounts.mastripms.com/oauth/authorize?client_id=1048&response_type=code&redirect_uri=${process.env.VUE_APP_URL}/oauth/callback&scope=&email=&role=mem`
    }
    return Promise.reject(error)
  }
)

export const memApi = memToken
  ? new Api({
      baseURL: BASE_PMS_URL,
      headers: {
        Authorization: `Bearer ${memToken}`
      }
    })
  : new Api({
      baseURL: BASE_PMS_URL
    })

memApi.instance.interceptors.response.use(
  (response) => {
    response.headers['Accept-Language'] = i18n.global.locale.value
    if (response.status === 301) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', 301)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-header"]').setAttribute('content', 'Location: /')
    }
    if (response.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.querySelector('meta[name="prerender-status-code"]').setAttribute('content', response.status)
      return response
    }

    return response
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.href =
      //   `https://accounts.mastripms.com/oauth/authorize?client_id=1048&response_type=code&redirect_uri=${process.env.VUE_APP_URL}/oauth/callback&scope=&email=&role=mem`
    }
    return Promise.reject(error)
  }
)
