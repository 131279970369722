import { Commit } from 'vuex'
import { accountApi } from '@/api'
import { PointState, PointHistoryItem, CalculatePoint } from '../types/point'
import { addYears, format, isPast } from 'date-fns'
import { DATE_TIME_FORMAT, DATA_DATE_FORMAT, DATE_FORMAT } from '@/constants'
import { Pagination, RewardPace } from '@/api/account-api'

const state: PointState = {
  pointList: [],
  pointHistory: [],
  pointHistoryPagination: {},
  pointListLoading: false,
  point: 0
}

// getters
const getters = {
  getPointHistory: (state: PointState): PointHistoryItem[] => {
    return state.pointHistory
      .map((item) => {
        return {
          point: item.value || 0,
          desc: item.remark || '',
          created_at: format(new Date(item.created_at || ''), DATE_TIME_FORMAT),
          expired_date: format(new Date(item.expired_date || ''), DATE_TIME_FORMAT)
        } as PointHistoryItem
      })
      .filter((item) => !isPast(new Date(item.expired_date || '')))
  },
  calculateExpiredPoint: (state: PointState): CalculatePoint => {
    const points: { [key: string]: number } = {}
    for (const item of state.pointHistory) {
      const expiredDate = format(new Date(item.expired_date || ''), DATE_FORMAT) as string
      if (!isPast(new Date(item.expired_date || ''))) {
        if (expiredDate && Object.prototype.hasOwnProperty.call(points, expiredDate)) {
          points[expiredDate] += item.value || 0
        } else {
          points[expiredDate] = item.value || 0
        }
      }
    }
    return points
  }
}

const actions = {
  async pointList({ commit }: { commit: Commit }, queryPage: number): Promise<any> {
    const pmsToken = localStorage.getItem('pms_token')
    commit('setLoading', true)
    const { data } = await accountApi.api.v1UserRewardPacesList(
      {
        from: format(addYears(new Date(), -1), DATA_DATE_FORMAT),
        to: format(new Date(), DATA_DATE_FORMAT),
        kind: '1',
        _page: queryPage,
        _take: 50
      },
      {
        headers: {
          Authorization: `Bearer ${pmsToken}`
        }
      }
    )
    commit('setPointList', data)
    commit('setLoading', false)
  },
  async userPoint({ commit }: { commit: Commit }): Promise<any> {
    const pmsToken = localStorage.getItem('pms_token')
    const { data } = await accountApi.api.v1UserRewardCountList(
      {
        kind: '1'
      },
      {
        headers: {
          Authorization: `Bearer ${pmsToken}`
        }
      }
    )
    commit('setUserPoint', data.point || 0)
  },
  async pointHistory({ commit }: { commit: Commit }): Promise<any> {
    const pmsToken = localStorage.getItem('pms_token')
    const { data } = await accountApi.api.v1UserRewardsList(
      {
        kind: '1'
      },
      {
        headers: {
          Authorization: `Bearer ${pmsToken}`
        }
      }
    )
    commit('setPointHistory', data.data)
  }
}

// mutations
const mutations = {
  setPointList(
    state: PointState,
    data: {
      data: RewardPace[]
      meta: Pagination
    }
  ): void {
    if (data.meta?.pagination?.current_page === 1) {
      state.pointList = data.data || []
    } else {
      state.pointList = [...state.pointList].concat(data.data)
    }
    state.pointHistoryPagination = data.meta && data.meta.pagination
  },
  setUserPoint(state: PointState, data: PointState['point']): void {
    state.point = data
  },
  setPointHistory(state: PointState, data: PointState['pointHistory']): void {
    state.pointHistory = data
  },
  setLoading(state: PointState, loading: boolean): void {
    state.pointListLoading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
