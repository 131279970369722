import { createStore, createLogger } from 'vuex'
import options from './modules/options'
import form from './modules/form'
import booking from './modules/booking'
import hotels from '@/store/modules/hotels'
import hotel from '@/store/modules/hotel'
import user from '@/store/modules/user'
import account from '@/store/modules/account'
import point from '@/store/modules/point'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    version: {
      namespaced: true,
      state: {
        packageVersion: process.env.PACKAGE_VERSION || '0'
      },
      getters: {
        appVersion: (state) => {
          return state.packageVersion
        }
      }
    },
    form,
    options,
    booking,
    hotels,
    hotel,
    user,
    account,
    point
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
