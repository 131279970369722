import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import 'element-plus/dist/index.css'
import 'vant/lib/index.css'
import '@/assets/styles/main.scss'
import '@/assets/styles/field.scss'
import '@/assets/styles/icons.scss'
import i18n from './i18n'
import '@vant/touch-emulator'
import Vue3Lottie from 'vue3-lottie'

import { Dialog, Swipe, SwipeItem, Lazyload } from 'vant'
import { ElDatePicker } from 'element-plus'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import vue3GoogleLogin from 'vue3-google-login'
import VueAppleLogin from 'vue-apple-login'

window.dataLayer = window.dataLayer || []
declare global {
  interface Window {
    dataLayer: any[]
  }
}
const app = createApp(App)
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    LottieAnimation: typeof import('vue3-lottie')['Vue3Lottie']
  }
}

app.use(Vue3Lottie)
app.use(i18n)
app.use(Dialog)
app.use(Swipe)
app.use(SwipeItem)
app.use(Lazyload)
app.use(ElDatePicker)

app.use(store)
app.use(router)
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
  }
})
app.use(VueAppleLogin, {
  clientId: 'tw.dunqian',
  scope: 'email name',
  redirectURI: `${process.env.VUE_APP_URL}/callback`,
  state: Date.now().toString(),
  usePopup: true
})

app.use(vue3GoogleLogin, {
  clientId: '717453150552-76cn013uma3dl1f1o0028loojb7gndqc.apps.googleusercontent.com'
})

app.mount('#app')
app.config.unwrapInjectedRef = true

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
const req = require.context('@/assets/icons', true, /\.svg$/)
requireAll(req)
