/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
 *   * `1` - 一般飯店
 *   * `2` - 防疫飯店
 *   * `3` - 自主管理
 */
export enum BusinessType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3
}

/** 關鍵字 */
export interface Keyword {
  /** 關鍵字類型 */
  type?: 'city' | 'hotel_id'
  /**
   * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
   *   * `1` - 一般飯店
   *   * `2` - 防疫飯店
   *   * `3` - 自主管理
   */
  business_type?: BusinessType
  /** @example 2 */
  key?: number
  /**
   * 緯度
   * @example 23.5
   */
  lat?: number
  /**
   * 經度
   * @example 23.5
   */
  lng?: number
  /** @example "新北市" */
  name?: string
}

/** 城市 */
export interface City {
  /** @example 1 */
  city_id?: number
  /** @example "台北市" */
  city_name?: string
  /** @example "taipei" */
  city_en_name?: string
  /**
   * 緯度
   * @example 23.5
   */
  lat?: number
  /**
   * 經度
   * @example 23.5
   */
  lng?: number
  /**
   * 圖片網址
   * @example "https://picsum.photos/220/154"
   */
  image?: string | null
}

/** 設備群組 */
export interface FacilityGroup {
  /**
   * 名稱
   * @example "熱門設施與服務"
   */
  name?: string
  facilities?: Facility[]
}

/** 設備 */
export interface Facility {
  /**
   * 名稱
   * @example "電梯"
   */
  name?: string
  /**
   * 多語系欄位/設施 key 值
   * @example "elevator"
   */
  lang_key?: string
  /**
   * 圖示 CSS class
   * @example "icon-elevator myicon"
   */
  class?: string
}

/** 飯店篩選 */
export interface HotelFilter {
  /**
   * 名稱
   * @example "含早餐"
   */
  name?: string
  /**
   * 多語系欄位/設施 key 值
   * @example "filter.breakfast"
   */
  lang_key?: string
  /**
   * 圖片
   * @example "https://picsum.photos/300/230"
   */
  image?: string | null
}

/** 訂房 */
export interface BookingRoom {
  /** 房型 */
  room_type?: RoomType
  /** 方案 */
  plan?: Plan
  extras?: ExtraItem[]
}

/** 訂單 */
export interface Booking {
  /**
   * 訂單 id
   * @example 3
   */
  booking_id?: number
  /**
   * 訂單編號
   * @example "6P96QP"
   */
  booking_number?: string
  /**
   * Email
   * @example "example@example.com"
   */
  email?: string
  /**
   * 房間總數
   * @example 2
   */
  room_count?: number
  /**
   * 入住日期
   * @example "2021-12-12"
   */
  check_in?: string
  /**
   * 退房日期
   * @example "2021-12-27"
   */
  check_out?: string
  /**
   * 入住天數
   * @example 15
   */
  days?: number
  /**
   * 成人人數
   * @example 2
   */
  adults?: number
  /**
   * 兒童人數
   * @example 3
   */
  children?: number
  /**
   * 房型原價
   * @example 2500
   */
  raw_price?: number
  /**
   * 折扣
   * @example 500
   */
  discount?: number
  /**
   * 結帳金額
   * @example 2000
   */
  price?: number
  /**
   * 取消退訂房費 (超過可退款時間時，該欄位為 null)
   * @example -1000
   */
  cancellation_fee?: number | null
  /**
   * 取消退訂退款金額 (超過可退款時間時，該欄位為 null)
   * @example 1000
   */
  cancellation_refund?: number | null
  /**
   * 適用退款規則 (超過可退款時間時，該欄位為 null)
   * @example "旅客於入住日前 1-2 日取消訂房者，將會收取 50% 已付金額，作為取消退訂房費。"
   */
  cancel_rule?: string | null
  /**
   * 訂單成立時間
   * @example "2012-12-12 12:12:12"
   */
  created_at?: string
  /** 訂單為防疫訂單 */
  is_epidemic?: boolean
  /** 訂單是否取消 */
  is_canceled?: boolean
  /**
   * 訂單狀態:
   *  * `UPCOMING` - 即將入住
   *  * `CANCELED` - 已取消
   *  * `CHECKED_IN` - 已入住
   *  * `CHECKED_OUT` - 已退房
   *  * `NO_SHOW` - 未入住
   */
  status?: 'UPCOMING' | 'CANCELED' | 'CHECKED_IN' | 'CHECKED_OUT' | 'NO_SHOW'
  /**
   * 付款狀態:
   *  * `UNPAID` - 未付款
   *  * `PAID_IN_PART` - 部分付款
   *  * `PAID_IN_FULL` - 已付款
   *  * `REFUNDING` - 退款申請中
   */
  payment_status?: 'UNPAID' | 'PAID_IN_PART' | 'PAID_IN_FULL' | 'REFUNDING'
  /**
   * 收付類型:
   *  * 0 - 平台付款
   *  * 1 - 到店付款
   * @example 1
   */
  payment_type?: any
  /** 發票號碼 */
  invoice_numbers?: string[]
  /**
   * 發票抬頭
   * @example "王大明股份有限公司"
   */
  received_by?: string
  /**
   * 統一編號
   * @example "12345678"
   */
  tax_id?: string
  /**
   * 備註
   * @example "不要吸菸房"
   */
  comment?: string
}

/** 房型 */
export interface RoomType {
  /**
   * 房型 id
   * @example 3
   */
  room_type_id?: number
  /**
   * 房型名稱
   * @example "標準雙人房"
   */
  name?: string
  /**
   * 房型介紹
   * @example "◆床型：一大雙人床
   * ◆坪數：5坪
   * ◆窗戶：有
   * ◆其他：不可加人"
   */
  intro?: string
  /**
   * 價格
   * @example 1500
   */
  price?: number
  /**
   * 官網價格
   * @example 1500
   */
  pms_price?: number
  /**
   * 會員九折官網價
   * @example 1500
   */
  mem_pms_price?: number
  /**
   * 最大入住成人數量
   * @example 2
   */
  adults?: number
  /**
   * 最大入住兒童數量
   * @example 2
   */
  children?: number
  /**
   * 房間大小
   * @example 20
   */
  room_size?: number
  /**
   * 床型
   * @example "一大床兩小床"
   */
  bed_type?: string
  /** 是否吸菸房 */
  smoking?: boolean
  /** 浴室設施 */
  bathroom?: '浴缸' | '淋浴設備' | '公共衛浴' | null
  /** 窗型 */
  window?: '大窗' | '小窗' | '氣窗' | '落地窗' | null
  /** 是否有陽台 */
  balcony?: boolean
  /**
   * 房型圖片 (220x154)
   * @example "https://picsum.photos/220/154"
   */
  image?: string
  /**
   * 房型全部圖片 (960x760)
   * @example ["https://picsum.photos/960/760","https://picsum.photos/960/760"]
   */
  images?: string[]
  /** 房間基礎設施 */
  infrastructures?: Facility[]
  /** 房型特色 */
  features?: Facility[]
  /** 設備 */
  facilities?: FacilityGroup[]
}

/** 加購項目 */
export interface ExtraItem {
  /**
   * 名稱
   * @example "成人"
   */
  name?: string
  /**
   * 項目鍵值
   * @example "adults"
   */
  key?: string
  /**
   * 簡介
   * @example "12歲以上"
   */
  intro?: string
  /**
   * 數量 (為 0 表示不可加)
   * @example 1
   */
  qty?: number
  /**
   * 費用
   * @example 800
   */
  charge?: number
}

/** 方案 */
export interface Plan {
  /**
   * 方案 id
   * @example 12
   */
  plan_id?: number
  /**
   * 方案名稱
   * @example "居家檢疫方案"
   */
  name?: string
  /**
   * 方案描述
   * @example "每日附早午晚三餐"
   */
  description?: string
  /**
   * 房型 id
   * @example 23
   */
  room_type_id?: number
  /**
   * 原價
   * @example 2000
   */
  original_price?: number
  /**
   * 官網原價
   * @example 2000
   */
  pms_original_price?: number
  /**
   * 會員九折官網原價
   * @example 2000
   */
  mem_pms_original_price?: number
  /**
   * 房價
   * @example 1500
   */
  price?: number
  /**
   * 官網房價
   * @example 1500
   */
  pms_price?: number
  /**
   * 會員九折官網房價
   * @example 1500
   */
  mem_pms_price?: number
}

/**
 * 退訂政策
 * @example [{"days":"入住前 3 日取消","description":"不收取任何費用","long_description":"旅客於入住日前 3 日(含)取消訂房者，得請求旅宿業者退還 100% 已付金額。","date":"4/17 23:59","expired_at":"2023-04-17T15:59:59.000000Z","free":true},{"days":"入住前 1-2 日取消","description":"收取50%房費","long_description":"旅客於入住日前 1-2 日(含)取消訂房者，得請求旅宿業者退還 50% 已付金額。","date":"4/19 23:59","expired_at":"2023-04-19T15:59:59.000000Z","free":true},{"days":"入住日","description":"收取100%房費","long_description":"旅客於入住當日取消訂房或未入住者，應負擔 100% 已付金額作為取消費用。","date":"4/20","expired_at":"2023-04-20T15:59:59.000000Z","free":true}]
 */
export interface CancellationPolicy {
  /** 退款規則 */
  rules?: Rule[]
}

/** 退訂原因 */
export interface CancelledReason {
  /**
   * 原因
   * @example "我的行程已延期或取消"
   */
  label?: string
  /**
   * 鍵值
   * @example 1
   */
  value?: number | null
}

/**
 * 退訂原因範例
 * @example [{"label":"我的行程已延期或取消","value":1},{"label":"網路上找到更便宜的價格","value":2},{"label":"這間旅宿的位置不好到達","value":3},{"label":"不可抗的天災、疾病等原因","value":4},{"label":"我要直接向這間住宿預訂","value":5},{"label":"我想換訂網站上其他一間住宿","value":6},{"label":"其他旅行社報價更便宜","value":7},{"label":"我不滿意這筆預訂的付款規定","value":8},{"label":"因個人健康因素","value":9},{"label":"入住人數或需求變更","value":10},{"label":"其他","value":null}]
 */
export type CancelledReasons = CancelledReason[]

/** 退訂規則 */
export interface Rule {
  /**
   * 取消天數
   * @example "入住前 2-5 日取消"
   */
  days?: string
  /**
   * 收款比例
   * @example "收取50%房費"
   */
  description?: string
  /**
   * 長描述
   * @example "旅客於入住日前 2-5 日(含)取消訂房者，得請求旅宿業者退還 50% 已付金額。"
   */
  long_description?: string
  /**
   * 取消時間
   * @example "4/20 23:59"
   */
  date?: string
  /**
   * 取消時刻(ISO 8601)
   * @example "2023-04-20T15:59:59.000000Z"
   */
  expired_at?: string
  /** 該規則是否支持免費取消 */
  free?: boolean
}

/** 飯店 */
export interface Hotel {
  /**
   * 飯店 id
   * @example 2
   */
  hotel_id?: number
  /**
   * 網域名稱
   * @example "partner"
   */
  domain?: string
  /**
   * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
   *   * `1` - 一般飯店
   *   * `2` - 防疫飯店
   *   * `3` - 自主管理
   */
  business_type?: BusinessType
  /**
   * 旅宿補助資格
   *   * 0 - 沒有參加
   *   * 1 - 有參加，無加碼標章
   *   * 2 - 有加碼，有加碼標章
   * @example 1
   */
  subsidy_type?: number
  /**
   * 收付類型:
   *  * 0 - 平台付款
   *  * 1 - 到店付款
   * @example 1
   */
  payment_type?: any
  /** 是否有可用的國旅優惠代碼 */
  travel_promotion?: boolean
  /** 是否接受自主健康管理 */
  accept_self_health?: boolean
  /**
   * Google Place ID
   * @example "ChIJgUbEo8cfqokR5lP9_Wh_DaM"
   */
  place_id?: string
  /**
   * 緯度
   * @example 23.75
   */
  lat?: number
  /**
   * 經度
   * @example 23.75
   */
  lng?: number
  /**
   * 飯店名稱
   * @example "大倉久和大飯店"
   */
  name?: string
  /**
   * 城市
   * @example "台北縣"
   */
  county?: string
  /**
   * 區域
   * @example "中山區"
   */
  area?: string
  /**
   * 評分
   * @example "4.0"
   */
  ranking?: string
  /**
   * 評論數
   * @example 122
   */
  reviews?: number
  /**
   * 飯店簡介
   * @example "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
   */
  intro?: string
  /**
   * 訂房須知
   * @example "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
   */
  booking_notice?: string
  /**
   * 飯店注意事項
   * @example "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
   */
  notice?: string
  /**
   * 飯店退訂取消規定
   * @example "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
   */
  cancel_notice?: string
  /**
   * 原價
   * @example 2500
   */
  original_price?: number | null
  /**
   * 官網原價
   * @example 2500
   */
  pms_original_price?: number | null
  /**
   * 會員九折官網原價
   * @example 2500
   */
  mem_pms_original_price?: number | null
  /**
   * 最低房價
   * @example 1500
   */
  lowest_price?: number | null
  /**
   * 官網最低房價
   * @example 1500
   */
  pms_lowest_price?: number | null
  /**
   * 會員九折官網最低房價
   * @example 1500
   */
  msm_pms_lowest_price?: number | null
  /**
   * 電話
   * @example "886+2123456789"
   */
  tel?: string
  /**
   * 地址
   * @example "台北市中山區南京東路一段9號"
   */
  address?: string
  /**
   * Line 帳號
   * @example "https://liff.line.me/1645278921-kWRPP32q/?accountId=tourbobo"
   */
  line?: string | null
  /**
   * LINE 名稱
   * @example "@Ha"
   */
  line_name?: string | null
  /**
   * Facebook 帳號
   * @example "https://examele.com"
   */
  facebook?: string | null
  /**
   * Facebook 名稱
   * @example "Haha"
   */
  facebook_name?: string | null
  /**
   * 服務信箱
   * @example "example@example.com"
   */
  service_mail?: string | null
  /**
   * 飯店圖片 (300x230)
   * @example "https://picsum.photos/300/230"
   */
  image?: string
  /**
   * 最早入住時間
   * @example "15:00"
   */
  check_in?: string
  /**
   * 最晚入住時間
   * @example "21:00"
   */
  last_check_in?: string
  /**
   * 退房時間
   * @example "11:00"
   */
  check_out?: string
  /**
   * 是否開放提前入住，不開放為 null，開放則為早進費用
   * @example 1500
   */
  early_check_in_fee?: number | null
  /** 年齡政策 */
  ages?: {
    /** 不接受幾歲以下入住 */
    not_accept_under?: number | null
    /** 允許嬰幼兒入住 */
    accept_baby?: boolean
    /** 嬰幼兒最大年齡 */
    accept_baby_under?: number
    /** 嬰幼兒加收費用 */
    baby_extra_charge?: number | null
    /** 允許兒童入住 */
    accept_child?: boolean
    /** 兒童最大年齡 */
    accept_child_under?: number
    /** 兒童加收費用 */
    child_extra_charge?: number | null
    /** 允許成人入住 */
    accept_adult?: boolean
    /** 成人最大年齡 */
    accept_adult_under?: number
    /** 成人加收費用 */
    adult_extra_charge?: number | null
  }
  /**
   * 飯店全部圖片 (300x230)
   * @example ["https://picsum.photos/300/230","https://picsum.photos/300/230"]
   */
  small_images?: string[]
  /**
   * 飯店全部圖片 (960x490)
   * @example ["https://picsum.photos/960/490","https://picsum.photos/960/490"]
   */
  images?: string[]
  /** 設備標籤 */
  facility_tags?: Facility[]
  /** 設備 */
  facilities?: FacilityGroup[]
  /** 住宿亮點 */
  features?: {
    /** 設備 */
    entertainment?: Facility
    /** 設備 */
    room?: Facility
    /** 設備 */
    hotel?: Facility
  }
}

/** 旅客評論 */
export interface Review {
  /**
   * 用戶
   * @example "王小明"
   */
  author_name?: string
  /**
   * 來源
   * @example "google"
   */
  source?: string
  /**
   * 評論內容
   * @example "很棒!!!"
   */
  text?: string
  /**
   * 評論分數
   * @example 4
   */
  rating?: number
  /**
   * 用戶圖片連結
   * @example "http://picsum.photos/72/72"
   */
  profile_photo_url?: string
  /**
   * 提交時間
   * @example "2020-12-12 12:12:12"
   */
  submitted_at?: string
}

/** 旅店評論 */
export interface HotelReviews {
  /** @example 70 */
  five_rate?: number
  /** @example 10 */
  four_rate?: number
  /** @example 5 */
  three_rate?: number
  /** @example 5 */
  two_rate?: number
  /** @example 10 */
  one_rate?: number
  /**
   * 評論數
   * @example 5566
   */
  review_amount?: number
  /**
   * 飯店分數
   * @example 4.5
   */
  score?: number
}

/** 費用明細 */
export interface Statement {
  /**
   * 項目
   * @example "2022/09/01 房價"
   */
  title?: string
  /**
   * 價格
   * @example 3000
   */
  price?: number
  /**
   * 明細品項
   *   * `room` - 房價
   *   * `extra` - 加購項目
   *   * `discount` - 折扣
   */
  item?: 'room' | 'extra' | 'discount'
}

/** WP官網飯店 */
export interface OfficialHotel {
  /**
   * 飯店 id
   * @example 2
   */
  hotel_id?: number
  /**
   * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
   *   * `1` - 一般飯店
   *   * `2` - 防疫飯店
   *   * `3` - 自主管理
   */
  business_type?: BusinessType
  /**
   * 飯店名稱
   * @example "大倉久和大飯店"
   */
  name?: string
  /**
   * 城市
   * @example "台北市"
   */
  county?: string
  /**
   * 區域
   * @example "中山區"
   */
  area?: string
  /**
   * 最高房價
   * @example 2500
   */
  original_price?: number | null
  /**
   * 最低房價
   * @example 1500
   */
  lowest_price?: number | null
  /**
   * 平均房價
   * @example 1500
   */
  avg_price?: number | null
  /**
   * PMS最低價
   * @example 1500
   */
  pms_lowest_price?: number | null
  /**
   * 飯店圖片
   * @example "https://picsum.photos/300/230"
   */
  image?: string
  /**
   * 飯店全部圖片 (960x490)
   * @example ["https://picsum.photos/960/490","https://picsum.photos/960/490"]
   */
  images?: string[]
  /** 設備標籤 */
  facility_tags?: Facility[]
  /**
   * 地址
   * @example "台北市中山區南京東路一段9號"
   */
  address?: string
  /**
   * 緯度
   * @example 23.5
   */
  lat?: number
  /**
   * 經度
   * @example 23.5
   */
  lng?: number
}

/** WP官網房型(承攜官網) */
export interface OfficialHotelRooms {
  /**
   * 房型id
   * @example 1
   */
  room_type_id?: number
  /**
   * 旅館加房型名稱
   * @example "新竹中央館 – 承光標準雙人客房"
   */
  name?: string
  /**
   * 房型介紹
   * @example "◆床型：一大雙人床<br/>◆坪數：5坪<br/>◆窗戶：有<br/>◆其他：不可加人"
   */
  intro?: string
  /**
   * 入住日售價(Check_in當日)
   * @example 2000
   */
  price?: number
  /**
   * 平均一晚價格(抓Check_in到Check_out平均)
   * @example 1500
   */
  avg_price?: number
  /**
   * 官網均價
   * @example 100
   */
  pms_avg_price?: number
  /**
   * 定價
   * @example 150
   */
  list_price?: number
  /**
   * 床型
   * @example "兩大床"
   */
  bed_type?: string
  /**
   * 坪數
   * @example 5
   */
  avg_square_feet?: number
  /**
   * 衛浴型態 0：專屬衛浴 1：公共衛浴 2：浴缸 3：淋浴設備
   * @example 0
   */
  public_shower?: number
  /**
   * 有無窗戶 0：無窗戶 1：有窗戶 2：部分房間有窗戶
   * @example 1
   */
  window?: number
  /**
   * 房型小圖片(308x420)
   * @example "https://picsum.photos/308/420"
   */
  image?: string
  /**
   * 房型大圖片 (1100x656)
   * @example ["https://picsum.photos/1100/656","https://picsum.photos/1100/656"]
   */
  images?: string[]
  /** 設備 */
  facilities?: Facility[]
  /** 房間所屬旅館資訊 */
  hotel?: {
    /**
     * 旅館名稱
     * @example "新竹中央館"
     */
    name?: string
    /**
     * 電話
     * @example "03-521-2323"
     */
    tel?: string
    /**
     * 地址
     * @example "30041新竹市東區中央路106號1號樓及6-15樓"
     */
    address?: string
    /**
     * 緯度
     * @example 23.5
     */
    lat?: number
    /**
     * 經度
     * @example 23.5
     */
    lng?: number
  }
}

/** WP官網房型(14天) */
export interface OfficialHotelRooms14Day {
  /**
   * 房型id
   * @example 1
   */
  room_type_id?: number
  /**
   * 房型名稱
   * @example "標準雙人房"
   */
  name?: string
  /**
   * 房型介紹
   * @example "◆床型：一大雙人床
   * ◆坪數：5坪
   * ◆窗戶：有
   * ◆其他：不可加人"
   */
  intro?: string
  /**
   * 房間定價
   * @example 3000
   */
  list_price?: number
  /**
   * 最低價格
   * @example 1500
   */
  lowest_price?: number
  /**
   * 平均價格
   * @example 2350
   */
  avg_price?: number
  /**
   * 官網平均價格
   * @example 2350
   */
  pms_avg_price?: number
  /**
   * PMS最低價
   * @example 1500
   */
  pms_lowest_price?: number | null
  /**
   * 床型
   * @example "兩大床"
   */
  bed_type?: string
  /**
   * 坪數
   * @example 5
   */
  avg_square_feet?: number
  /** 浴室設施 */
  public_shower?: '浴缸' | '淋浴設備' | '公共衛浴' | null
  /** 窗型 */
  window?: '大窗' | '小窗' | '氣窗' | '落地窗' | null
  /**
   * 是否可售
   * @example true
   */
  sellable?: boolean
  /**
   * 房型小圖片(308x420)
   * @example "https://picsum.photos/308/420"
   */
  image?: string
  /**
   * 房型大圖片 (1100x656)
   * @example ["https://picsum.photos/1100/656","https://picsum.photos/1100/656"]
   */
  images?: string[]
  /** 房型特色 */
  features?: Facility[]
  /** 設備 */
  facilities?: FacilityGroup[]
  /** 長住方案 */
  plans?: {
    /**
     * 方案id
     * @example 1
     */
    plan_id?: number
    /**
     * 方案名稱
     * @example "長住 2 晚方案"
     */
    name?: string
    /**
     * 每晚平均價格
     * @example 2350
     */
    avg_price?: number
    /**
     * 總價
     * @example 4700
     */
    total_price?: number
  }[]
}

export interface Header {
  /**
   * 通訊協定標準
   * @example "http://www.sitemaps.org/schemas/sitemap/0.9"
   */
  xmlns?: string
}

/** 分頁描述 */
export interface Pagination {
  pagination?: {
    /**
     * 總筆數
     * @example 9
     */
    total?: number
    /**
     * 當前分頁筆數
     * @example 4
     */
    count?: number
    /**
     * 每頁數量
     * @example 5
     */
    per_page?: number
    /**
     * 當前分頁
     * @example 2
     */
    current_page?: number
    /**
     * 所有分頁數量
     * @example 2
     */
    total_pages?: number
    links?: {
      /**
       * 上一個分頁 API 連結
       * @example "http://api.pms.tw/mem/hotels?page=1"
       */
      previous?: string
      /**
       * 下一個分頁 API 連結
       * @example "http://api.pms.tw/mem/hotels?page=3"
       */
      next?: string
    }
  }
}

/** 找不到資源 */
export interface Response404 {
  /** 找不到指定的資料 / No Information / 訂單已過期 */
  message?: string
  /** 找不到指定的資料 / No Information / 訂單已過期 */
  error?: string
  /** 說明錯誤類型 是訂單不存在(booking) 或是 優惠碼尚未建立(coupon)  或是 訂單已過期(expired) */
  type?: 'booking' | 'coupon' | 'expired'
}

/** 驗證失敗 */
export interface Response422 {
  /**
   * 錯誤訊息描述
   * @example "The given data was invalid."
   */
  message?: string
  /** 驗證失敗欄位說明 */
  error?: {
    /** xxxxx 為欄位名稱 */
    xxxxx?: string[]
  }
}

/** 傳遞參數 */
export interface CouponViptwcar {
  /**
   * 訂單編號
   * @example "KR45YP"
   */
  booking_number?: number
  /**
   * 訂購人信箱
   * @example "empty@empty.com"
   */
  email?: string
}

/** 成功回應 */
export interface CouponResponse200 {
  /**
   * Coupoon序號
   * @example 123456789
   */
  code?: string
  /**
   * 有效期限 YYYY-MM-DD h:i
   * @example "2022/12/14 23:59"
   */
  expire?: string
}

/** 成功回應 */
export interface CouponResponseCancel200 {
  /**
   * Coupoon序號
   * @example 123456789
   */
  code?: string
  /**
   * 0(未使用)，1(已使用)，-1(已作廢或已過期或失效)
   * @example -1
   */
  status?: string
}

/** 成功回應 */
export interface CouponResponseCheck200 {
  /**
   * Coupoon序號
   * @example 123456789
   */
  code?: string
  /**
   * 有效期限 YYYY-MM-DD h:i
   * @example "2022/12/14 23:59"
   */
  expire?: string
  /**
   * 0(未使用)，1(已使用)，-1((已作廢或已過期或失效))
   * @example 0
   */
  status?: string
}

/** 伺服器發生錯誤 */
export interface CouponResponse500 {
  /**
   * 錯誤訊息描述
   * @example "訂單狀態非即將入住"
   */
  error?: string
  /**
   * 錯誤訊息描述
   * @example "訂單狀態非即將入住"
   */
  message?: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://ota-api.tourbobo.com' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title 會員平台
 * @version 1.0.0
 * @baseUrl https://ota-api.tourbobo.com
 *
 * 非MEM開頭的路由host皆為 https://api.mastripms.com
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  mem = {
    /**
     * No description
     *
     * @tags option
     * @name BannersList
     * @summary 首頁 Banner
     * @request GET:/mem/banners
     */
    bannersList: (params: RequestParams = {}) =>
      this.request<
        {
          /** @example "#" */
          link?: string
          /**
           * 圖片
           * @example "https://picsum.photos/1420/280"
           */
          image?: string
        }[],
        any
      >({
        path: `/mem/banners`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 參數抓取一次後儲存為 json，直接編譯到前端畫面。不需要每次抓取。
     *
     * @tags option
     * @name FeatureTogglesList
     * @summary 功能開關，決定哪些功能已經開啟顯示，哪些應該暫時關閉。
     * @request GET:/mem/feature_toggles
     */
    featureTogglesList: (params: RequestParams = {}) =>
      this.request<
        {
          /** 出入境與入住時間，該區塊除了`入住時間`一律顯示， 其他根據功能開關關閉或顯示。 */
          arrival_time?: boolean
          /** 健康與飲食區塊 */
          meat?: boolean
          /** 加購項目 */
          extra?: boolean
        },
        any
      >({
        path: `/mem/feature_toggles`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 參數抓取一次後儲存為 json，直接編譯到前端畫面。不需要每次抓取。
     *
     * @tags option
     * @name NationalitiesList
     * @summary 國籍，房客基本資料使用
     * @request GET:/mem/nationalities
     */
    nationalitiesList: (params: RequestParams = {}) =>
      this.request<
        {
          /**
           * 國家名稱
           * @example "台灣"
           */
          label?: string
          /**
           * 國家代碼
           * @example "TW"
           */
          value?: string
        }[],
        any
      >({
        path: `/mem/nationalities`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 參數抓取一次後儲存為 json，直接編譯到前端畫面。不需要每次抓取。
     *
     * @tags option
     * @name CountryCodesList
     * @summary 國碼
     * @request GET:/mem/country_codes
     */
    countryCodesList: (params: RequestParams = {}) =>
      this.request<
        {
          /**
           * 國家名稱
           * @example "台灣"
           */
          label?: string
          /**
           * 國家代碼
           * @example "+886"
           */
          value?: string
        }[],
        any
      >({
        path: `/mem/country_codes`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 根據關鍵字的 type 欄位決定飯店查詢使用哪個欄位，並搭配 key 欄位使用。 <br> Ex: `"type":"city", "key":2`  =>  `?city=2`
     *
     * @tags option
     * @name KeywordsList
     * @summary 關鍵字查詢
     * @request GET:/mem/keywords
     */
    keywordsList: (params: RequestParams = {}) =>
      this.request<Keyword[], any>({
        path: `/mem/keywords`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 新版城市列表，包含熱門縣市
     *
     * @tags option
     * @name NewCitiesList
     * @summary 城市列表
     * @request GET:/mem/new_cities
     */
    newCitiesList: (
      query?: {
        /**
         * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
         *   * `1` - 一般飯店
         *   * `2` - 防疫飯店
         *   * `3` - 自主管理
         */
        business_type?: BusinessType
        /**
         * 國家代碼，，預設帶入 `TW`
         * @default "TW"
         */
        country?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          cities?: City[]
          popular?: City[]
        },
        any
      >({
        path: `/mem/new_cities`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 參數抓取一次後儲存為 json，直接編譯到前端畫面。不需要每次抓取。
     *
     * @tags option
     * @name CitiesList
     * @summary 城市列表
     * @request GET:/mem/cities
     * @deprecated
     */
    citiesList: (params: RequestParams = {}) =>
      this.request<City[], any>({
        path: `/mem/cities`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description 參數抓取一次後儲存為 json，直接編譯到前端畫面。不需要每次抓取。
     *
     * @tags option
     * @name HotelOptionsList
     * @summary 搜尋參數
     * @request GET:/mem/hotel_options
     */
    hotelOptionsList: (
      query?: {
        /**
         * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
         *   * `1` - 一般飯店
         *   * `2` - 防疫飯店
         *   * `3` - 自主管理
         */
        business_type?: BusinessType
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 熱門篩選，同時做為首頁的(熱搜防疫亮點)
           * @example [{"name":"大坪數","lang_key":"roomsize","class":"icon-roomsize","image":"https://ota-api.tourbobo.com/images/features/roomsize.jpeg"},{"name":"可開窗","lang_key":"window","class":"icon-window","image":"https://ota-api.tourbobo.com/images/features/window.jpeg"},{"name":"免費Wi-Fi","lang_key":"freewi-fi","class":"icon-freewi-fi","image":"https://ota-api.tourbobo.com/images/features/freewi-fi.jpeg"},{"name":"浴缸","lang_key":"tub","class":"icon-tub","image":"https://ota-api.tourbobo.com/images/features/tub.jpeg"},{"name":"禁菸房","lang_key":"non-smoking_room","class":"icon-non-smoking_room","image":"https://ota-api.tourbobo.com/images/features/non-smoking_room.jpeg"},{"name":"有陽台","lang_key":"balcony","class":"icon-balcony","image":"https://ota-api.tourbobo.com/images/features/balcony.jpeg"}]
           */
          popular_facilities?: (Facility & {
            /**
             * 圖片網址
             * @example "https://picsum.photos/340/340"
             */
            image?: string
          })[]
          /** 房間設施 */
          room_facilities?: Facility[]
          /** 飯店服務 */
          hotel_facilities?: Facility[]
          /**
           * 熱門篩選
           * @example [{"name":"含早餐","lang_key":"filter.breakfast","image":null},{"name":"免費取消","lang_key":"filter.free-cancellation","image":null},{"name":"停車位","lang_key":"filter.parking-lot","image":null}]
           */
          popular_filter?: HotelFilter[]
          /**
           * 特色旅宿
           * @example [{"name":"多元設施","lang_key":"filter.multi-ents","image":"https://ota-image.tourbobo.com/image/ota.exclusive_hotel/多元設施.jpg"},{"name":"優質旅宿","lang_key":"filter.high-ranking","image":"https://ota-image.tourbobo.com/image/ota.exclusive_hotel/優質旅宿.jpg"},{"name":"親子旅宿","lang_key":"filter.parent-child","image":"https://ota-image.tourbobo.com/image/ota.exclusive_hotel/親子旅宿.jpg"},{"name":"寵物友善","lang_key":"filter.pet-friendly","image":"https://ota-image.tourbobo.com/image/ota.exclusive_hotel/寵物友善.jpg"}]
           */
          exclusive_hotel?: HotelFilter[]
          /**
           * 旅宿類型
           * @example [{"name":"旅館","lang_key":"business_category.hotel","image":null},{"name":"青年旅宿","lang_key":"business_category.hostel","image":null},{"name":"汽車旅館","lang_key":"business_category.motel","image":null},{"name":"民宿","lang_key":"business_category.b&b","image":null}]
           */
          business_category?: HotelFilter[]
          /**
           * 床型
           * @example [{"name":"標準雙人房","lang_key":"filter.double_bed","image":null},{"name":"標準加大雙人床","lang_key":"filter.double_queen_bed","image":null},{"name":"超大雙人床","lang_key":"filter.double_king_bed","image":null}]
           */
          bed_types?: HotelFilter[]
          /**
           * 距離市中心
           * @example [{"name":"1 公里內","lang_key":"filter.1km","image":null},{"name":"3 公里內","lang_key":"filter.3km","image":null},{"name":"5 公里內","lang_key":"filter.5km","image":null}]
           */
          distance?: HotelFilter[]
          /**
           * 防疫旅館類型
           * @example [{"name":"居家檢疫","lang_key":"filter.home_quarantine"},{"name":"移工專屬","lang_key":"filter.migrant_worker"}]
           */
          prevention_types?: HotelFilter[]
          /**
           * 排序
           * @example [{"name":"為你推薦","sort":"recommend"},{"name":"價格最低","sort":"lowest_price"},{"name":"評價最高","sort":"highest_ranking"},{"name":"價格最高","sort":"highest_price"}]
           */
          sorting?: {
            /** @example "為你推薦" */
            name?: string
            /** @example "recommend" */
            sort?: string
          }[]
          /** @example [{"start_date":1661970175,"end_date":1664608522,"value":4},{"start_date":1661970175,"end_date":1664608522,"value":1}] */
          epidemic_min_days?: {
            /**
             * 開始日期
             * @example 1661970175
             */
            start_date?: number
            /**
             * 結束日期
             * @example 1664608522
             */
            end_date?: number
            /**
             * 入住天數
             * @example 4
             */
            value?: number
          }[]
        },
        any
      >({
        path: `/mem/hotel_options`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 目前正在進行中的活動
     *
     * @tags option
     * @name EventsList
     * @summary 活動列表
     * @request GET:/mem/events
     */
    eventsList: (params: RequestParams = {}) =>
      this.request<
        {
          /**
           * 活動名稱
           * @example "台灣快綫專屬優惠"
           */
          title?: string
          /**
           * 活動圖片
           * @example "https://picsum.photos/400/300"
           */
          image_url?: string
          /** 活動代碼 */
          code?: 'vipcar'
        }[],
        any
      >({
        path: `/mem/events`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags option
     * @name PmsGetPersonalUserEventCode
     * @summary 確認活動碼是否存在
     * @request GET:/mem/event_code/{event_code}
     */
    pmsGetPersonalUserEventCode: (eventCode: string, params: RequestParams = {}) =>
      this.request<
        {
          /**
           * 訊息
           * @default "success"
           */
          message?: string
        },
        void
      >({
        path: `/mem/event_code/${eventCode}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags hotel
     * @name HotelsList
     * @summary 搜尋飯店
     * @request GET:/mem/hotels
     */
    hotelsList: (
      query: {
        /**
         * 入住城市 (預設顯示所有地區飯店)
         * @example 2
         */
        city?: number
        /**
         * 飯店分類 (未提供時，預設帶入`2` - 防疫飯店)
         *   * `1` - 一般飯店
         *   * `2` - 防疫飯店
         *   * `3` - 自主管理
         */
        business_type?: BusinessType
        /**
         * 入住日期
         * @example "2021-12-12"
         */
        check_in?: string
        /**
         * 退房日期
         * @example "2021-12-31"
         */
        check_out?: string
        /**
         * 成人人數
         * @example 2
         */
        adults: number
        /**
         * 兒童人數
         * @example 3
         */
        children: number
        /**
         * 兒童年齡 (兒童人數有幾位，相對應兒童年齡就要有幾個)
         * @example 8
         */
        child_age?: number
        /**
         * 最低房價
         * @example 1500
         */
        lowest_price?: number
        /**
         * 最高房價
         * @example 2500
         */
        highest_price?: number
        /**
         * 飯店服務
         * @example "elevator"
         */
        hotel_facility?: string
        /**
         * 房間設施
         * @example "big_tub"
         */
        room_facility?: string
        /**
         * 分頁
         * @example 1
         */
        page?: number
        /**
         * 飯店 id
         * @example 1
         */
        hotel_id?: any[]
        /**
         * 飯店過濾，以 /mem/hotel_options api 提供的底下內容帶入
         *  * popular_filter
         *  * exclusive_hotel
         *  * bed_types
         *  * distance
         * @example "filter.breakfast"
         */
        filter?: string
        /**
         * 旅宿類型，以 /mem/hotel_options api 的 `business_category`` 帶入
         * @example "business_category.hotel"
         */
        business_category?: string
        /**
         * 是否隨機排列回傳結果，首頁調用時需要
         *  * `1` - 隨機排列
         *  * `0` - 不隨機排列
         * @example 1
         */
        is_random?: number
        /** 排序方式 */
        sorting?: 'recommend' | 'lowest_price' | 'highest_ranking' | 'highest_price'
        /**
         * 緯度(使用時 page 參數會自動忽略)
         * @example -34.397
         */
        lat?: number
        /**
         * 經度(使用時 page 參數會自動忽略)
         * @example 150.644
         */
        lng?: number
        /**
         * 每頁筆數
         * @example 5
         */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: Hotel[]
          /** 其他推薦飯店(當查詢結果無飯店時，才有資料) */
          recommend?: Hotel[]
          /** 分頁描述 */
          meta?: Pagination
        },
        Response422
      >({
        path: `/mem/hotels`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags hotel
     * @name HotelsDetail
     * @summary 飯店資訊
     * @request GET:/mem/hotels/{id}
     */
    hotelsDetail: (
      id: number,
      query: {
        /**
         * 入住日期
         * @example "2021-12-12"
         */
        check_in: string
        /**
         * 退房日期
         * @example "2021-12-31"
         */
        check_out: string
        /**
         * 成人人數
         * @example 2
         */
        adults: number
        /**
         * 兒童人數
         * @example 3
         */
        children: number
        /**
         * 兒童年齡 (兒童人數有幾位，相對應兒童年齡就要有幾個)
         * @example 8
         */
        child_age?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 退訂政策 */
          cancellation_policy?: CancellationPolicy
        } & Hotel & {
            /** 房型 */
            room_types?: (RoomType & {
              /** 加購項目 */
              extras?: ExtraItem[]
              /** 方案 */
              plans?: Plan[]
            })[]
            /** 評價評分 */
            review_rankings?: {
              /** @example 0 */
              '1'?: number
              /** @example 0 */
              '2'?: number
              /** @example 2 */
              '3'?: number
              /** @example 15 */
              '4'?: number
              /** @example 82 */
              '5'?: number
            }
          },
        any
      >({
        path: `/mem/hotels/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags hotel
     * @name AllHotelsList
     * @summary 取得所有飯店 (首頁人氣防疫旅館, 搜尋頁未帶搜尋日期查詢)
     * @request GET:/mem/all/hotels
     * @deprecated
     */
    allHotelsList: (
      query?: {
        /**
         * 入住城市 (預設顯示所有地區飯店)
         * @example 2
         */
        city?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: Hotel[]
          /** 分頁描述 */
          meta?: Pagination
        },
        any
      >({
        path: `/mem/all/hotels`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags hotel
     * @name ReviewsList
     * @summary 飯店評價
     * @request GET:/mem/reviews
     */
    reviewsList: (
      query: {
        /**
         * 飯店 id
         * @example 2
         */
        hotel_id: number
        /**
         * 分頁
         * @example 1
         */
        page?: number
        /** 過濾來源，null或未提供則顯示所有來源 */
        source?: 'google' | 'tourbobo' | 'tripadvisor' | null
        /**
         * 每頁筆數
         * @min 1
         * @default 5
         */
        per_page?: number
        /**
         * 排序:
         *  * `newest` - 最新
         *  * `highest_ranking` - 最高評價
         *  * `lowest_ranking` - 最低評價
         * @example "newest"
         */
        sorting: 'newest' | 'highest_ranking' | 'lowest_ranking'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          reviews?: Review[]
          hotel_reviews?: HotelReviews[]
          /** 分頁描述 */
          meta?: Pagination
        },
        any
      >({
        path: `/mem/reviews`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags booking
     * @name FillBookingList
     * @summary 填寫訂單
     * @request GET:/mem/fill_booking
     */
    fillBookingList: (
      query: {
        /**
         * 飯店 id
         * @example 2
         */
        hotel_id: number
        /**
         * 房型方案
         * @example 2
         */
        plan_id: number
        /**
         * 房型 id
         * @example 2
         */
        room_type_id: number
        /**
         * 入住日期
         * @example "2021-12-12"
         */
        check_in: string
        /**
         * 退房日期
         * @example "2021-12-31"
         */
        check_out: string
        /**
         * 成人人數
         * @example 2
         */
        adults: number
        /**
         * 兒童人數
         * @example 3
         */
        children: number
        /**
         * 兒童年齡 (兒童人數有幾位，相對應兒童年齡就要有幾個)
         * @example 8
         */
        child_age?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 方案 */
          plan?: Plan
          /** 退訂政策 */
          cancellation_policy?: CancellationPolicy
          room_type?: RoomType & {
            /** 加購項目 */
            extras?: ExtraItem[]
          }
          /** 飯店 */
          hotel?: Hotel
          /** 可用國旅代碼 */
          travel_promotions?: string[]
        },
        any
      >({
        path: `/mem/fill_booking`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags booking
     * @name BookingPriceCreate
     * @summary 計算訂單價格
     * @request POST:/mem/booking_price
     */
    bookingPriceCreate: (
      data: {
        /**
         * 飯店 id
         * @example 10
         */
        hotel_id: number
        booking: {
          /**
           * 入住日期
           * @example "2012-12-12"
           */
          check_in?: string
          /**
           * 退房日期
           * @example "2012-12-25"
           */
          check_out?: string
          /**
           * 折扣代碼
           * @example "HAPPY_123"
           */
          promotion_code?: string
          /** 提前入住方案，有值代表有加購 */
          early_check_in?: boolean
        }
        rooms: {
          /**
           * 下訂房型
           * @example 2
           */
          room_type_id?: number
          /**
           * 下訂方案
           * @example 5
           */
          plan_id?: number
          /**
           * 加購項目， key 為 項目的 key 值，後面接加購數量
           * @example {"adults":1,"children":2}
           */
          extra?: Record<string, number>
        }[]
        /**
         * 是否使用官網價（請傳true）
         * @default false
         */
        use_pms?: boolean
        /** 使用點數折抵 */
        reward_points?: {
          /**
           * 使用點數
           * @example 1000
           */
          points?: number
          /**
           * 使用儲值金
           * @example 1000
           */
          stored_values?: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          statements?: Statement[]
          /**
           * 房價
           * @example 27000
           */
          room_price?: number
          /**
           * 優惠碼折扣
           * @example -1200
           */
          promotion_discount?: number
          /**
           * 點數折扣
           * @example -1500
           */
          reward_points_discount?: number
          /**
           * 加人費用
           * @example 22500
           */
          extra_people?: number
          /**
           * 提前入住方案
           * @example 1000
           */
          early_check_in?: number
          /**
           * 結帳金額
           * @example 47800
           */
          total_price?: number
        },
        any
      >({
        path: `/mem/booking_price`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags booking
     * @name BookingsCreate
     * @summary 成立訂單
     * @request POST:/mem/bookings
     */
    bookingsCreate: (
      data: {
        /**
         * 飯店 id
         * @example 10
         */
        hotel_id: number
        /**
         * 訂單編號(更新訂單時須提供)
         * @example "N189EI"
         */
        booking_number?: string
        /** 訂單 */
        booking: {
          /**
           * 名字
           * @example "大明"
           */
          first_name?: string
          /**
           * 姓氏
           * @example "王"
           */
          last_name?: string
          /** 性別 */
          gender?: 'MALE' | 'FEMALE'
          /**
           * 國籍, 兩碼
           * @example "TW"
           */
          nationality?: string
          /**
           * 身分證字號
           * @example "A123456789"
           */
          id_number?: string
          /**
           * 生日
           * @example "2012-12-12"
           */
          birthday?: string
          /**
           * Email
           * @example "wang@example.com"
           */
          email?: string
          /**
           * 確認 Email
           * @example "wang@example.com"
           */
          email_confirmation?: string
          /**
           * 入住日期
           * @example "2012-12-12"
           */
          check_in?: string
          /**
           * 退房日期
           * @example "2012-12-25"
           */
          check_out?: string
          /**
           * 電話
           * @example "+886900000000"
           */
          phone?: string
          /**
           * 成人數量
           * @example 2
           */
          adults?: number
          /**
           * 兒童數量
           * @example 2
           */
          children?: number
          /** 兒童年齡 */
          child_age?: number[]
          /**
           * 緊急聯絡人姓名
           * @example "小明"
           */
          contact_first_name?: string
          /**
           * 緊急聯絡人姓氏
           * @example "王"
           */
          contact_last_name?: string
          /**
           * 緊急聯絡人電話
           * @example "+88690000000"
           */
          contact_phone?: string
          /**
           * 出境國家, 兩碼
           * @example "TW"
           */
          departure?: string
          /**
           * 航班編號
           * @example "CX430"
           */
          flight_number?: string
          /**
           * 抵達台灣日期
           * @example "2012-12-12"
           */
          flight_arrival_date?: string
          /**
           * 抵達台灣時間
           * @example "12:30"
           */
          flight_arrival_time?: string
          /**
           * 預計入住時間
           * @example "18:30"
           */
          arrival?: string
          /** 飲食習慣，是否葷食 */
          meat_diet?: boolean
          /**
           * 特殊飲食習慣
           * @example "不吃豬"
           */
          special_diet?: string
          /** 是否有特殊疾病 */
          has_disease?: boolean
          /**
           * 特殊疾病
           * @example "高血壓"
           */
          disease?: string
          /**
           * 優惠代碼（會員折扣碼： Memberpromotion）
           * @example "HAPPY_123"
           */
          promotion_code?: string
          /**
           * 備註
           * @example "不要吸菸房"
           */
          comment?: string
          /**
           * 提前入住時間，有值表示有選購。（由於目前前台沒相關欄位，請填入字串 1 或者 0）
           * @example 1
           */
          early_check_in?: string
          /**
           * 發票抬頭
           * @example "王大明股份有限公司"
           */
          received_by?: string
          /**
           * 統一編號
           * @example "12345678"
           */
          tax_id?: string
        }
        rooms: {
          /**
           * 下訂房型
           * @example 2
           */
          room_type_id?: number
          /**
           * 下訂方案
           * @example 5
           */
          plan_id?: number
          /**
           * 加購項目， key 為 項目的 key 值，後面接加購數量
           * @example {"adults":1,"children":2}
           */
          extras?: Record<string, number>
        }[]
        /** 信用卡資訊 (一般飯店訂單新增時為必填) */
        credit_card?: {
          /**
           * 持卡人姓名
           * @example "王大明"
           */
          name?: string
          /**
           * 卡號
           * @example "1234567812345678"
           */
          card_number?: string
          /**
           * 有效期限(MM/YY)
           * @example "05/26"
           */
          validity_period?: string
        }
        /** 訂單點數折扣（尚未實做） */
        reward_points?: {
          /**
           * 使用點數
           * @min 0
           */
          points?: number
          /**
           * 使用儲值金
           * @min 0
           */
          stored_values?: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 過期時間
           * @example "2012-12-12 12:12:12"
           */
          expired_at?: string
          /**
           * 過期 Timestamp
           * @example 1647942015
           */
          expired_ts?: number
          /** 訂單 */
          booking?: Booking
          booking_rooms?: BookingRoom[]
          /** 飯店 */
          hotel?: Hotel
        },
        any
      >({
        path: `/mem/bookings`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 取得訂單（未登入時適用）
     *
     * @tags booking
     * @name BookingsList
     * @summary 查詢訂單
     * @request GET:/mem/bookings
     */
    bookingsList: (
      query: {
        /**
         * email
         * @example "example@example.com"
         */
        email: string
        /**
         * 訂單編號
         * @example "N189EI"
         */
        booking_number: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 過期時間
           * @example "2012-12-12 12:12:12"
           */
          expired_at?: string
          /**
           * 過期 Timestamp
           * @example 1647942015
           */
          expired_ts?: number
          /** 訂單 */
          booking?: Booking
          booking_rooms?: BookingRoom[]
          statements?: Statement[]
          /** 飯店 */
          hotel?: Hotel
          /** 退訂政策 */
          cancellation_policy?: CancellationPolicy
          /** 退訂原因範例 */
          cancelled_reasons?: CancelledReasons
          /**
           * 取消原因列表(請改用cancelled_reasons)
           * @deprecated
           * @example ["我的行程已延期或取消","網路上找到有更便宜的價格","這間住宿的位置不好到達","不可抗的天災、疾病因素","我要直接向這間住宿預訂","我想換訂網站上其他一間住宿","其他旅行社報價更便宜","我不滿意這筆預定的付款規定","因個人健康因素","入住人數或需求改變"]
           */
          reasons?: string[]
        },
        any
      >({
        path: `/mem/bookings`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags booking
     * @name PromotionCreate
     * @summary 驗證優惠碼是否有效
     * @request POST:/mem/promotion
     */
    promotionCreate: (
      data: {
        /**
         * 飯店 id
         * @example 12
         */
        hotel_id: number
        /**
         * 優惠碼
         * @example "HAPPY_2021"
         */
        code: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 優惠名稱
           * @example "快樂 2021"
           */
          name?: string
          /**
           * 優惠碼
           * @example "HAPPY_2021"
           */
          code?: string
          /** 優惠類型 * $ 折扣金額，參考 discunt * % 折扣比率，參考 percent */
          discount_type?: '$' | '%'
          /**
           * 折扣金額
           * @example 100
           */
          discount?: number
          /**
           * 折扣比率
           * @example 2
           */
          percent?: number
          /**
           * 是否通過驗證 * true，優惠代碼可使用 * false，優惠代碼需驗證
           * @example true
           */
          verify?: boolean
        },
        Response404
      >({
        path: `/mem/promotion`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags booking
     * @name CancelCreate
     * @summary 取消訂單
     * @request POST:/mem/cancel
     */
    cancelCreate: (
      data: {
        /**
         * 訂單編號
         * @example "N189EI"
         */
        booking_number: string
        /**
         * 訂單 email （驗證用，確保訂單編號與 email 匹配）
         * @example "example@example.com"
         */
        email: string
        /**
         * 取消原因(請用鍵值帶入，如選的其他，則提供理由字串)
         * @example "我的行程已延期或取消"
         */
        reason?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/mem/cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 請用表單送出請求，並轉跳到金流商頁面
     *
     * @tags payment
     * @name PaymentCreate
     * @summary 訂單付款
     * @request POST:/mem/payment
     */
    paymentCreate: (
      data: {
        /**
         * 訂單編號
         * @example "N189EI"
         */
        booking_number: string
        /**
         * 訂單 email （驗證用，確保訂單編號與 email 匹配）
         * @example "example@example.com"
         */
        email: string
        /**
         * 付款結果轉跳頁
         * @example "http://localhost/N189EI"
         */
        redirect: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/mem/payment`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        ...params
      }),

    /**
     * @description 請用表單送出請求，並轉跳到金流商頁面
     *
     * @tags payment
     * @name AppPaymentCreate
     * @summary APP 訂單付款
     * @request POST:/mem/app_payment
     */
    appPaymentCreate: (
      data: {
        /**
         * 訂單編號
         * @example "N189EI"
         */
        booking_number: string
        /**
         * 訂單 email （驗證用，確保訂單編號與 email 匹配）
         * @example "example@example.com"
         */
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/mem/app_payment`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        ...params
      }),

    /**
     * No description
     *
     * @tags payment
     * @name InvoiceCreate
     * @summary 發票買受人更新
     * @request POST:/mem/invoice
     */
    invoiceCreate: (
      data: {
        /**
         * 訂單編號
         * @example "N189EI"
         */
        booking_number: string
        /**
         * 訂單 email （驗證用，確保訂單編號與 email 匹配）
         * @example "example@example.com"
         */
        email: string
        /**
         * 發票抬頭
         * @example "王大明股份有限公司"
         */
        received_by?: string
        /**
         * 統一編號
         * @example "12345678"
         */
        tax_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        void,
        {
          /**
           * 錯誤訊息
           * @example "找不到訂單"
           */
          error?: string
        }
      >({
        path: `/mem/invoice`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 使用者訂單付款後，可以請求機場快線優惠券(Coupon)
     *
     * @tags vipcartw
     * @name VipcartwCreate
     * @summary 建立Coupon
     * @request POST:/mem/vipcartw
     */
    vipcartwCreate: (data: CouponViptwcar, params: RequestParams = {}) =>
      this.request<CouponResponse200, Response404 | Response422 | CouponResponse500>({
        path: `/mem/vipcartw`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 退房日期變更時，調整有效期限
     *
     * @tags vipcartw
     * @name VipcartwUpdate
     * @summary 更新Coupon
     * @request PUT:/mem/vipcartw
     */
    vipcartwUpdate: (data: CouponViptwcar, params: RequestParams = {}) =>
      this.request<CouponResponse200, Response404 | Response422 | CouponResponse500>({
        path: `/mem/vipcartw`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 取消訂房時，conpou要失效不提供。
     *
     * @tags vipcartw
     * @name VipcartwDelete
     * @summary 取消訂房，conpou失效
     * @request DELETE:/mem/vipcartw
     */
    vipcartwDelete: (data: CouponViptwcar, params: RequestParams = {}) =>
      this.request<CouponResponseCancel200, Response404 | Response422 | CouponResponse500>({
        path: `/mem/vipcartw`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 查詢coupon狀態。
     *
     * @tags vipcartw
     * @name VipcartwCheckCreate
     * @summary 查詢coupon
     * @request POST:/mem/vipcartw/check
     */
    vipcartwCheckCreate: (data: CouponViptwcar, params: RequestParams = {}) =>
      this.request<CouponResponseCheck200, Response404 | Response422 | CouponResponse500>({
        path: `/mem/vipcartw/check`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags sitemap
     * @name SitemapList
     * @summary 取得網站sitemap
     * @request GET:/mem/sitemap
     */
    sitemapList: (params: RequestParams = {}) =>
      this.request<
        Header & {
          url?: {
            /**
             * 網頁的 URL
             * @example "https://www.reelax.com.tw/tw/hotels?city=1"
             */
            loc?: string
            /**
             * 檔案的最後修改日期
             * @example "2022-12-09T00:10:08+08:00"
             */
            lastmod?: string
            /**
             * 網頁可能變更的頻率
             * @example "daily"
             */
            changefreq?: string
          }
        },
        any
      >({
        path: `/mem/sitemap`,
        method: 'GET',
        ...params
      }),

    /**
     * @description 取得會員等級資料（bearer token為該會員在Mem的access token） 包含邀請碼等資訊
     *
     * @tags user
     * @name MemGetPersonalUser
     * @summary 取得會員等級
     * @request GET:/mem/user/level
     * @deprecated
     * @secure
     */
    memGetPersonalUser: (
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 等級ID
           * @example 0
           */
          level_id?: number
          /**
           * 等級名稱
           * @example "尊爵不凡"
           */
          level_name?: string
          /**
           * 訂單折扣比例
           * @min 0
           * @max 100
           * @example 10
           */
          discount_percent?: number
          /**
           * 距離升級差額（若有降級提醒則為還需要多少才能維持當前等級）
           * @min 0
           * @example 5000
           */
          amount_to_upgrade?: number
          /**
           * 訂單回饋點數比例
           * @min 0
           * @max 100
           * @example 15
           */
          reward_point_percent?: number
          /**
           * 當前等級累積消費金額門檻
           * @min 0
           * @example 50000
           */
          level_cumulative_amount?: number
          /**
           * 會員近一年累積消費金額
           * @min 0
           * @example 50000
           */
          cumulative_amount?: number
          /** 降級提醒 */
          downgrade_alert?: boolean
          /** 其餘會員優惠 */
          discount_extras?: string[]
          /**
           * 邀請碼
           * @example "abcdefgh"
           */
          invitation_code?: string
          /**
           * 已邀請人數
           * @min 0
           * @example 1
           */
          invited_count?: number
          /** 是否有邀請人（填寫邀請碼） */
          has_inviter?: boolean
          /** 是否有收到首次消費贈點 */
          first_purchase_reward_received?: boolean
        },
        {
          /** @example "該場域沒有使用會員等級" */
          message?: string
          /** @example "該場域沒有使用會員等級" */
          error?: string
        }
      >({
        path: `/mem/user/level`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name ServersMem
     * @request SERVERS:/mem/user/level
     */
    serversMem: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/mem/user/level`,
        method: 'SERVERS',
        ...params
      }),

    /**
     * @description 取得會員訂單資料
     *
     * @tags user, booking
     * @name PmsPmsPersonalBookings
     * @summary 取得會員訂單
     * @request GET:/mem/user/bookings
     * @deprecated
     * @secure
     */
    pmsPmsPersonalBookings: (
      query: {
        /** 狀態 */
        status: 'UPCOMING' | 'CHECKED_IN' | 'CANCELED'
        /** 訂單編號 */
        booking_number?: string
        /** 場域ID */
        hotel_id: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 訂單編號
           * @example 484
           */
          id?: string
          /**
           * 飯店編號
           * @example 1
           */
          hotel_id?: number
          /**
           * 旅客編號
           * @example 1
           */
          guest_id?: number
          /**
           * 訂單編號
           * @example "6P96QP"
           */
          number?: string
          /** 平台 */
          platform_id?: string
          /** 平台單號 */
          platform_number?: string
          /**
           * 平台佣金
           * @example 0
           */
          platform_commission?: number
          /**
           * 訂單來源
           * @example "線上"
           */
          source?: '線上' | '電話' | '平台'
          /**
           * 旅客名稱
           * @example "王大明"
           */
          name?: string
          /**
           * 性別
           * @example "MALE"
           */
          gender?: 'MALE' | 'FEMALE'
          /**
           * 電話
           * @example 989323511
           */
          phone?: string
          /**
           * 生日
           * @example "1999-10-10"
           */
          birthday?: string
          /**
           * 身分證字號
           * @example "A123456789"
           */
          id_number?: string
          /**
           * 電子信箱
           * @example "test@gmail.com"
           */
          email?: string
          /**
           * 入住時間
           * @example "2018-09-11"
           */
          check_in?: string
          /**
           * 退房時間
           * @example "2018-09-12"
           */
          check_out?: string
          /**
           * 房間狀態
           * @example "UPCOMING"
           */
          status?: 'UPCOMING' | 'CHECKED_IN' | 'CHECKED_OUT' | 'CANCELED'
          /**
           * 房間狀態
           * @example "即將入住"
           */
          status_text?: '即將入住' | '已入住' | '已退房'
          /**
           * 稅額
           * @example "0.00"
           */
          tax_rate?: string
          /**
           * 服務費
           * @example "0.05"
           */
          service_rate?: string
          /**
           * 訂單金額
           * @example "NT$1,260.00"
           */
          price?: string
          /**
           * 自訂價格
           * @example "0.00"
           */
          custom_price?: string
          /** 付款方式 */
          payment_method?: string
          /**
           * 付款狀態
           * @example "PAID_IN_FULL"
           */
          payment_status?: 'UNPAID' | 'PAID_IN_PART' | 'PAID_IN_FULL'
          /**
           * 國籍別, 兩碼
           * @example "TW"
           */
          nationality?: string
          /**
           * 出境國家, 兩碼
           * @example "JP"
           */
          departure?: string
          /**
           * 發票抬頭
           * @example "王大明股份有限公司"
           */
          received_by?: string
          /**
           * 統一編號
           * @example "12345678"
           */
          tax_id?: string
          /**
           * 是否有特殊疾病 false:否 true:是
           * @example true
           */
          has_disease?: boolean
          /**
           * 特殊疾病說明，如心血管或慢性病
           * @example "高血壓"
           */
          disease?: string
          /**
           * 飲食需求 0:純素 1:葷 2:蛋奶素 3:不含餐
           * @example 1
           */
          meat_diet?: number
          /**
           * 忌口過敏食物
           * @example "海鮮"
           */
          special_diet?: string
          /** 備註 */
          comment?: string
          /** 入住核對收款人員 */
          receptionist?: string
          /** 入住核對收款時間 */
          receptionist_time?: string
          /**
           * 預計抵達時間
           * @example "16:30"
           */
          arrival?: string
          /**
           * 抵達班機編號
           * @example "F302"
           */
          flight_number?: string
          /**
           * 入住人數（大人）
           * @example 2
           */
          adults?: number
          /**
           * 入住人數（小孩）
           * @example 0
           */
          children?: number
          /**
           * 建立時間
           * @example "2018-09-12 14:06:42"
           */
          created_at?: string
          /**
           * 最後更新時間
           * @example "2018-09-12 14:09:18"
           */
          updated_at?: string
          /**
           * 是否已付款
           * @example true
           */
          is_paid?: boolean
          /**
           * 付款狀態文字
           * @example "已付全額"
           */
          payment_status_text?: string
          /**
           * 飯店名稱
           * @example "Test Hotel 測試飯店"
           */
          hotel_name?: string
          /** @example "http://api.pms.tw/booking/edit/484" */
          edit_url?: string
          /**
           * 服務費
           * @example 60
           */
          service_fee?: number
          /** 訂房資訊 */
          room_info?: {
            /**
             * 房型名稱
             * @example "多種房型方案"
             */
            name?: string
            /**
             * 訂購數量
             * @example "2 間"
             */
            quantity?: string
          }[]
          /**
           * 剩餘應付款金額
           * @example "200"
           */
          remain_amount?: string
        }[],
        any
      >({
        path: `/mem/user/bookings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 取得該場域的所有會員等級
     *
     * @tags hotel
     * @name MemGetHotelLevels
     * @summary 取得該場域所有會員等級
     * @request GET:/mem/hotels/levels
     * @deprecated
     */
    memGetHotelLevels: (
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 等級ID
           * @example 0
           */
          id?: number
          /**
           * 等級名稱
           * @example "尊爵不凡"
           */
          name?: string
          /**
           * 訂單折扣比例
           * @min 0
           * @max 100
           * @example 10
           */
          discount_percent?: number
          /**
           * 訂單回饋點數比例
           * @min 0
           * @max 100
           * @example 15
           */
          reward_point_percent?: number
          /**
           * 累積消費金額門檻
           * @min 0
           * @example 50000
           */
          cumulative_amount?: number
          /** 其餘會員優惠 */
          discount_extras?: string[]
        }[],
        {
          /** @example "該場域沒有使用會員等級" */
          message?: string
          /** @example "該場域沒有使用會員等級" */
          error?: string
        }
      >({
        path: `/mem/hotels/levels`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 確認邀請碼是否存在<br> 如果會員於註冊前就有填寫邀請碼的話，必須先呼叫這個API<br> 因為對PMS請求註冊的時候因為會員中心已經建立會員了<br> 所以就算邀請碼不存在也不會影響該會員在會員中心已註冊成功<br> 基於以上因素，若邀請碼填寫錯誤的話在此步驟邀請人的欄位會直接設定為空值
     *
     * @tags user
     * @name MemGetPersonalUserInvitationCode
     * @summary 確認邀請碼是否存在
     * @request GET:/mem/user/invitation_code/{invitation_code}
     * @deprecated
     */
    memGetPersonalUserInvitationCode: (
      invitationCode: string,
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 訊息
           * @default "success"
           */
          message?: string
        },
        void
      >({
        path: `/mem/user/invitation_code/${invitationCode}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 填寫邀請人的邀請碼 JWT token請使用Mem個人token
     *
     * @tags user
     * @name MemPostPersonalUserInvitationCode
     * @summary 填寫邀請碼
     * @request POST:/mem/user/invitation_code
     * @deprecated
     * @secure
     */
    memPostPersonalUserInvitationCode: (
      data: {
        /**
         * 邀請碼
         * @example "asdfghjk"
         */
        invitation_code: string
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/mem/user/invitation_code`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 更新自己的邀請碼 JWT token請使用Mem個人token
     *
     * @tags user
     * @name MemPutPersonalUserInvitationCodeInvitationCode
     * @summary 更新邀請碼
     * @request PUT:/mem/user/invitation_code
     * @deprecated
     * @secure
     */
    memPutPersonalUserInvitationCodeInvitationCode: (
      data: {
        /** 邀請碼 */
        invitation_code?: string
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/mem/user/invitation_code`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 會員註冊填寫邀請碼及產生會員資料的API<br> 由於呼叫此API時，會員中心已經成功註冊<br> 所以如果邀請碼錯誤，則只會將邀請人的欄位設定為null<br>
     *
     * @tags user
     * @name MemPostUserRegister
     * @summary 註冊
     * @request POST:/mem/user/register
     * @deprecated
     */
    memPostUserRegister: (
      data: {
        /**
         * 邀請碼（可為空）
         * @pattern 1234qwer
         */
        invitation_code?: string
        /**
         * 會員名稱
         * @example "王小明"
         */
        name: string
        /**
         * EMAIL
         * @example "test@test.tw"
         */
        email: string
        /**
         * 密碼
         * @example "cd23rsdf"
         */
        password: string
        /**
         * 密碼確認
         * @example "cd23rsdf"
         */
        password_confirmation: string
        /** 性別 */
        gender: 'FEMALE' | 'MALE'
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 返回訊息
           * @example "success"
           */
          message: string
          /**
           * 返回HTTP代碼
           * @example "201"
           */
          code: number
          /**
           * 資源
           * @example "AC.User"
           */
          resource: string
        }[],
        {
          error?: string
          message?: string
        }
      >({
        path: `/mem/user/register`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description 將會員中心的token交換成pms的token
     *
     * @tags oauth
     * @name MemPostOauthTokenExchange
     * @summary Token交換
     * @request POST:/mem/user/token/exchange
     * @deprecated
     * @secure
     */
    memPostOauthTokenExchange: (
      data: {
        /**
         * 飯店ID
         * @example "partner"
         */
        hotel_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * pms token
           * @example "qdsadwadw"
           */
          access_token?: string
          /**
           * 逾期
           * @example 123456
           */
          expired_in?: number
          /** 會員 */
          user?: {
            /**
             * 名稱
             * @example "小貓"
             */
            name?: string
            /**
             * 圖片網址
             * @example "http://image.url"
             */
            image?: string
          }
        },
        any
      >({
        path: `/mem/user/token/exchange`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  personal = {
    /**
     * @description 取得會員等級資料（bearer token為該會員在Mem的access token） 包含邀請碼等資訊
     *
     * @tags user
     * @name PmsGetPersonalUser
     * @summary 取得會員等級
     * @request GET:/personal/user/level
     * @secure
     */
    pmsGetPersonalUser: (
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 等級ID
           * @example 0
           */
          level_id?: number
          /**
           * 等級名稱
           * @example "尊爵不凡"
           */
          level_name?: string
          /**
           * 訂單折扣比例
           * @min 0
           * @max 100
           * @example 10
           */
          discount_percent?: number
          /**
           * 距離升級差額（若有降級提醒則為還需要多少才能維持當前等級）
           * @min 0
           * @example 5000
           */
          amount_to_upgrade?: number
          /**
           * 訂單回饋點數比例
           * @min 0
           * @max 100
           * @example 15
           */
          reward_point_percent?: number
          /**
           * 當前等級累積消費金額門檻
           * @min 0
           * @example 50000
           */
          level_cumulative_amount?: number
          /**
           * 會員近一年累積消費金額
           * @min 0
           * @example 50000
           */
          cumulative_amount?: number
          /** 降級提醒 */
          downgrade_alert?: boolean
          /** 其餘會員優惠 */
          discount_extras?: string[]
          /**
           * 邀請碼
           * @example "abcdefgh"
           */
          invitation_code?: string
          /**
           * 已邀請人數
           * @min 0
           * @example 1
           */
          invited_count?: number
          /** 是否有邀請人（填寫邀請碼） */
          has_inviter?: boolean
          /** 是否有收到首次消費贈點 */
          first_purchase_reward_received?: boolean
        },
        {
          /** @example "該場域沒有使用會員等級" */
          message?: string
          /** @example "該場域沒有使用會員等級" */
          error?: string
        }
      >({
        path: `/personal/user/level`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name ServersPersonal
     * @request SERVERS:/personal/user/level
     */
    serversPersonal: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/personal/user/level`,
        method: 'SERVERS',
        ...params
      }),

    /**
     * @description 取得會員訂單資料
     *
     * @tags user, booking
     * @name PmsGetPersonalBookings
     * @summary 取得會員訂單
     * @request GET:/personal/bookings
     * @secure
     */
    pmsGetPersonalBookings: (
      query: {
        /** 狀態 */
        status: 'UPCOMING' | 'CHECKED_IN' | 'CANCELED'
        /** 訂單編號 */
        booking_number?: string
        /** 場域ID */
        hotel_id: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 訂單編號
           * @example 484
           */
          id?: string
          /**
           * 飯店編號
           * @example 1
           */
          hotel_id?: number
          /**
           * 旅客編號
           * @example 1
           */
          guest_id?: number
          /**
           * 訂單編號
           * @example "6P96QP"
           */
          number?: string
          /** 平台 */
          platform_id?: string
          /** 平台單號 */
          platform_number?: string
          /**
           * 平台佣金
           * @example 0
           */
          platform_commission?: number
          /**
           * 訂單來源
           * @example "線上"
           */
          source?: '線上' | '電話' | '平台'
          /**
           * 旅客名稱
           * @example "王大明"
           */
          name?: string
          /**
           * 性別
           * @example "MALE"
           */
          gender?: 'MALE' | 'FEMALE'
          /**
           * 電話
           * @example 989323511
           */
          phone?: string
          /**
           * 生日
           * @example "1999-10-10"
           */
          birthday?: string
          /**
           * 身分證字號
           * @example "A123456789"
           */
          id_number?: string
          /**
           * 電子信箱
           * @example "test@gmail.com"
           */
          email?: string
          /**
           * 入住時間
           * @example "2018-09-11"
           */
          check_in?: string
          /**
           * 退房時間
           * @example "2018-09-12"
           */
          check_out?: string
          /**
           * 房間狀態
           * @example "UPCOMING"
           */
          status?: 'UPCOMING' | 'CHECKED_IN' | 'CHECKED_OUT' | 'CANCELED'
          /**
           * 房間狀態
           * @example "即將入住"
           */
          status_text?: '即將入住' | '已入住' | '已退房'
          /**
           * 稅額
           * @example "0.00"
           */
          tax_rate?: string
          /**
           * 服務費
           * @example "0.05"
           */
          service_rate?: string
          /**
           * 訂單金額
           * @example "NT$1,260.00"
           */
          price?: string
          /**
           * 自訂價格
           * @example "0.00"
           */
          custom_price?: string
          /** 付款方式 */
          payment_method?: string
          /**
           * 付款狀態
           * @example "PAID_IN_FULL"
           */
          payment_status?: 'UNPAID' | 'PAID_IN_PART' | 'PAID_IN_FULL'
          /**
           * 國籍別, 兩碼
           * @example "TW"
           */
          nationality?: string
          /**
           * 出境國家, 兩碼
           * @example "JP"
           */
          departure?: string
          /**
           * 發票抬頭
           * @example "王大明股份有限公司"
           */
          received_by?: string
          /**
           * 統一編號
           * @example "12345678"
           */
          tax_id?: string
          /**
           * 是否有特殊疾病 false:否 true:是
           * @example true
           */
          has_disease?: boolean
          /**
           * 特殊疾病說明，如心血管或慢性病
           * @example "高血壓"
           */
          disease?: string
          /**
           * 飲食需求 0:純素 1:葷 2:蛋奶素 3:不含餐
           * @example 1
           */
          meat_diet?: number
          /**
           * 忌口過敏食物
           * @example "海鮮"
           */
          special_diet?: string
          /** 備註 */
          comment?: string
          /** 入住核對收款人員 */
          receptionist?: string
          /** 入住核對收款時間 */
          receptionist_time?: string
          /**
           * 預計抵達時間
           * @example "16:30"
           */
          arrival?: string
          /**
           * 抵達班機編號
           * @example "F302"
           */
          flight_number?: string
          /**
           * 入住人數（大人）
           * @example 2
           */
          adults?: number
          /**
           * 入住人數（小孩）
           * @example 0
           */
          children?: number
          /**
           * 建立時間
           * @example "2018-09-12 14:06:42"
           */
          created_at?: string
          /**
           * 最後更新時間
           * @example "2018-09-12 14:09:18"
           */
          updated_at?: string
          /**
           * 是否已付款
           * @example true
           */
          is_paid?: boolean
          /**
           * 付款狀態文字
           * @example "已付全額"
           */
          payment_status_text?: string
          /**
           * 飯店名稱
           * @example "Test Hotel 測試飯店"
           */
          hotel_name?: string
          /** @example "http://api.pms.tw/booking/edit/484" */
          edit_url?: string
          /**
           * 服務費
           * @example 60
           */
          service_fee?: number
          /** 訂房資訊 */
          room_info?: {
            /**
             * 房型名稱
             * @example "多種房型方案"
             */
            name?: string
            /**
             * 訂購數量
             * @example "2 間"
             */
            quantity?: string
          }[]
          /**
           * 剩餘應付款金額
           * @example "200"
           */
          remain_amount?: string
          /**
           * 旅店照片
           * @format uri
           * @example "https://img.google.com/test.png"
           */
          hotel_image?: string
        }[],
        any
      >({
        path: `/personal/bookings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 確認邀請碼是否存在<br> 如果會員於註冊前就有填寫邀請碼的話，必須先呼叫這個API<br> 因為對PMS請求註冊的時候因為會員中心已經建立會員了<br> 所以就算邀請碼不存在也不會影響該會員在會員中心已註冊成功<br> 基於以上因素，若邀請碼填寫錯誤的話在此步驟邀請人的欄位會直接設定為空值
     *
     * @tags user
     * @name PmsGetPersonalUserInvitationCode
     * @summary 確認邀請碼是否存在
     * @request GET:/personal/user/invitation_code/{invitation_code}
     */
    pmsGetPersonalUserInvitationCode: (
      invitationCode: string,
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 訊息
           * @default "success"
           */
          message?: string
        },
        void
      >({
        path: `/personal/user/invitation_code/${invitationCode}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 填寫邀請人的邀請碼 JWT token請使用Mem個人token
     *
     * @tags user
     * @name PmsPostPersonalUserInvitationCode
     * @summary 填寫邀請碼
     * @request POST:/personal/user/invitation_code
     * @secure
     */
    pmsPostPersonalUserInvitationCode: (
      data: {
        /**
         * 邀請碼
         * @example "asdfghjk"
         */
        invitation_code: string
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/personal/user/invitation_code`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 更新自己的邀請碼 JWT token請使用Mem個人token
     *
     * @tags user
     * @name PmsPutPersonalUserInvitationCodeInvitationCode
     * @summary 更新邀請碼
     * @request PUT:/personal/user/invitation_code
     * @secure
     */
    pmsPutPersonalUserInvitationCodeInvitationCode: (
      data: {
        /** 邀請碼 */
        invitation_code?: string
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/personal/user/invitation_code`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 會員註冊填寫邀請碼及產生會員資料的API<br> 由於呼叫此API時，會員中心已經成功註冊<br> 所以如果邀請碼錯誤，則只會將邀請人的欄位設定為null<br>
     *
     * @tags user
     * @name PmsPostUserRegister
     * @summary 註冊
     * @request POST:/personal/user/register
     */
    pmsPostUserRegister: (
      data: {
        /**
         * 邀請碼（可為空）
         * @pattern 1234qwer
         */
        invitation_code?: string
        /**
         * 活動代碼（可為空）
         * @pattern sam123
         */
        event_code?: string
        /**
         * 會員名稱
         * @example "王小明"
         */
        name: string
        /**
         * EMAIL
         * @example "test@test.tw"
         */
        email: string
        /**
         * 密碼
         * @example "cd23rsdf"
         */
        password: string
        /**
         * 密碼確認
         * @example "cd23rsdf"
         */
        password_confirmation: string
        /** 性別 */
        gender: 'FEMALE' | 'MALE'
      },
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 返回訊息
           * @example "success"
           */
          message: string
          /**
           * 返回HTTP代碼
           * @example "201"
           */
          code: number
          /**
           * 資源
           * @example "AC.User"
           */
          resource: string
        }[],
        | (
            | {
                /** 場域資訊錯誤 */
                message?: string
              }
            | {
                /** 活動碼不存在或已過期 */
                message?: string
              }
          )
        | {
            error?: string
            message?: string
          }
      >({
        path: `/personal/user/register`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  hotels = {
    /**
     * @description 取得該場域的所有會員等級
     *
     * @tags hotel
     * @name PmsGetHoteLevels
     * @summary 取得該場域所有會員等級
     * @request GET:/hotels/levels
     */
    pmsGetHoteLevels: (
      query?: {
        /**
         * 飯店 id
         * @example 14
         */
        hotel_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 等級ID
           * @example 0
           */
          id?: number
          /**
           * 等級名稱
           * @example "尊爵不凡"
           */
          name?: string
          /**
           * 訂單折扣比例
           * @min 0
           * @max 100
           * @example 10
           */
          discount_percent?: number
          /**
           * 訂單回饋點數比例
           * @min 0
           * @max 100
           * @example 15
           */
          reward_point_percent?: number
          /**
           * 累積消費金額門檻
           * @min 0
           * @example 50000
           */
          cumulative_amount?: number
          /** 其餘會員優惠 */
          discount_extras?: string[]
        }[],
        {
          /** @example "該場域沒有使用會員等級" */
          message?: string
          /** @example "該場域沒有使用會員等級" */
          error?: string
        }
      >({
        path: `/hotels/levels`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  }
  oauth = {
    /**
     * @description 將會員中心的token交換成pms的token
     *
     * @tags oauth
     * @name PmsPostOauthTokenExchange
     * @summary Token交換
     * @request POST:/oauth/token/exchange
     * @secure
     */
    pmsPostOauthTokenExchange: (
      data: {
        /**
         * 飯店ID
         * @example "partner"
         */
        hotel_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * pms token
           * @example "qdsadwadw"
           */
          access_token?: string
          /**
           * 逾期
           * @example 123456
           */
          expired_in?: number
          /** 會員 */
          user?: {
            /**
             * 名稱
             * @example "小貓"
             */
            name?: string
            /**
             * 圖片網址
             * @example "http://image.url"
             */
            image?: string
          }
        },
        any
      >({
        path: `/oauth/token/exchange`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags oauth
     * @name PostOauthRevoke
     * @summary 登出（撤銷token）
     * @request POST:/oauth/revoke
     * @secure
     */
    postOauthRevoke: (params: RequestParams = {}) =>
      this.request<
        {
          /**
           * 訊息
           * @example "success"
           */
          message?: string
        },
        any
      >({
        path: `/oauth/revoke`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      })
  }
  booking = {
    /**
     * No description
     *
     * @tags booking
     * @name BookingCreate
     * @summary 成立訂單
     * @request POST:/booking
     */
    bookingCreate: (
      data: {
        /**
         * 網域
         * @example "partner"
         */
        domain: string
        /**
         * 開始日期
         * @example "2012-12-12"
         */
        start_date: string
        /**
         * 結束日期
         * @example "2012-12-12"
         */
        end_date: string
        /**
         * 促銷編號
         * @example 1
         */
        promotion_id?: number
        /** 訂單描述 */
        booking: {
          /**
           * 姓名
           * @example "大明"
           */
          first_name: string
          /**
           * 姓氏
           * @example "王"
           */
          last_name: string
          /** 性別 */
          gender: 'MALE' | 'FEMALE'
          /**
           * 電話
           * @example 911123456
           */
          phone: string
          /**
           * 電子信箱
           * @example "adrian.chen@dunqian.tw"
           */
          email: string
          /**
           * 抵達時間，格式 H:i
           * @example "16:30"
           */
          arrival?: string
          /**
           * 成人數量
           * @example 2
           */
          adults?: number
          /**
           * 兒童數量
           * @example 0
           */
          children?: number
          /**
           * 國別，兩碼
           * @example "TW"
           */
          nationality?: string
          /**
           * 離境國家，兩碼
           * @example "JP"
           */
          departure?: string
          /**
           * 身分證字號/護照編號
           * @example "A123456789"
           */
          id_number?: string
          /**
           * 生日
           * @example "1912-12-12"
           */
          birthday?: string
          /**
           * 聯絡人姓名
           * @example "小明"
           */
          contact_first_name?: string
          /**
           * 聯絡人姓氏
           * @example "王"
           */
          contact_last_name?: string
          /**
           * 聯絡人電話
           * @example 911111111
           */
          contact_phone?: string
          /**
           * 班機編號
           * @example "BA-123"
           */
          flight_number?: string
          /**
           * 班機抵達日期
           * @example "2022-12-12"
           */
          flight_arrival_date?: string
          /**
           * 班機抵達時間
           * @example "12:30"
           */
          flight_arrival_time?: string
          /**
           * 飲食習慣
           * * `1` 純素
           * * `2` 葷
           * * `3` 蛋奶素
           * * `4` 不含餐
           */
          meat_diet?: '0' | '1' | '2' | '3' | null
          /**
           * 忌口
           * @example "不吃豬"
           */
          special_diet?: string
          /** 是否有特殊疾病 */
          has_disease?: boolean
          /** 特殊疾病說明 */
          disease?: string
          /**
           * 訂單來源
           * * `MEM` 會員平台
           * * `ONLINE` 線上
           * * `SELF_OPERATION` 自營平台
           * * `PLATFORM` 平台
           * * `TELEPHONE` 電話
           * * `KIOSK` 機台
           */
          source?: 'MEM' | 'ONLINE' | 'SELF_OPERATION' | 'PLATFORM' | 'TELEPHONE' | 'KIOSK'
        }
        /** 訂房描述 */
        rooms: {
          /**
           * 房型
           * @example 5
           */
          room_type_id: number
          /**
           * 開始日期
           * @example "2012-12-12"
           */
          start_date: string
          /**
           * 結束日期
           * @example "2012-12-12"
           */
          end_date: string
          /** 加購項目 (幾乎無使用，建議不要加) */
          extras?: Record<string, number>[]
          /** 方案ID（基本方案請直接傳null） */
          plan_id?: number
        }[]
        /** 停車位 */
        parking_space?: {
          /**
           * 開始日期
           * @example "2012-12-12"
           */
          start_date?: string
          /**
           * 結束日期
           * @example "2012-12-12"
           */
          end_date?: string
          /**
           * 數量
           * @example 1
           */
          qty?: number
        }[]
        /** 使用的會員點數 */
        reward_points?: {
          /**
           * 使用的點數
           * @example 50
           */
          points?: number
          /**
           * 使用的儲值金
           * @example 60
           */
          stored_values?: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 訂單號碼 */
          booking_number?: string
          /**
           * email
           * @format email
           */
          email?: string
          /**
           * 訂單剩餘應付款金額
           * @min 0
           */
          remain_amount?: number
        },
        any
      >({
        path: `/booking`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
}
