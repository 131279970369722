import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.function.name.js";
import { defineComponent, computed } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'ComDialog',
  components: _defineProperty({
    SvgIcon: SvgIcon
  }, _Dialog.name, _Dialog),
  props: ['slot', 'title', 'open', 'width', 'onClose', 'customClass'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var show = computed({
      get: function get() {
        return props.open;
      },
      set: function set(val) {
        return emit('update:open', val);
      }
    });
    return {
      show: show
    };
  }
});