/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** 會員 */
export interface User {
  /**
   * 會員id
   * @example 23
   */
  id?: number
  /**
   * 姓名
   * @example "會員姓名"
   */
  name: string
  /**
   * 姓氏
   * @example "會員姓氏"
   */
  last_name?: string
  /**
   * 名字
   * @example "會員名字"
   */
  first_name?: string
  /**
   * 電子信箱
   * @example "123@example.com"
   */
  email?: string
  /** @example "J45mN31snZEl2Q78Zaf1F5Po3RgfPaI2AKBsGyv4xM1lpI82EjF0XCUEG000" */
  remember_token?: string
  /**
   * 生日
   * @example "2016-08-03"
   */
  birthday?: string
  /** 性別 */
  gender?: 'MALE' | 'FEMALE'
  /**
   * 通知用電子信箱
   * @example "123@example.com"
   */
  mailing_email?: string
  /**
   * 手機號碼
   * @example "+886911222333"
   */
  phone?: string
  /**
   * 電話
   * @example "0423 663 333"
   */
  tel?: string
  /**
   * 國籍
   * @example "TW"
   */
  nationality?: string
  /**
   * 身分證字號
   * @example "L111222333"
   */
  id_number?: string
  /**
   * 縣市
   * @example "臺中市"
   */
  county?: string
  /**
   * 區
   * @example "西屯區"
   */
  area?: string
  /**
   * 地址
   * @example "中山路55"
   */
  address?: string
  /** @example "2016-08-18" */
  created_date?: string
  /**
   * 統一編號
   * @example "54890959"
   */
  tax_id?: string
  /**
   * 公司抬頭
   * @example "敦謙國際智能科技股份有限公司"
   */
  received_by?: string
  /**
   * 推播 Token
   * @example "1234567890"
   */
  notification_token?: string
  /**
   * 頭像
   * @example "https://example.com/123.jpg"
   */
  avatar?: string
  /** @example "2016-08-17 22:24:15" */
  created_at?: string
  /** @example "2020-11-20 16:46:44" */
  updated_at?: string
  /** @example null */
  from_hotel_id?: number
  /** @example true */
  verified?: boolean
}

/** OAuth client 資訊 */
export interface OauthClient {
  oauth_client?: {
    /** @example 1 */
    id?: number
    /** @example 23 */
    user_id?: number
    /**
     * 名稱
     * @example "合作夥伴"
     */
    name?: string
    /** @example "xj0l!lZ&ll6lE&c*9DjG32S7$v59Tm8*9cZ!0000" */
    secret?: string
    /**
     * 網域名稱
     * @example "partner"
     */
    domain?: string
  }
}

/** OAuth Personal Access 權仗 */
export interface OauthPersonalAccess {
  /** @example "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImZiOTVkZDFlMzRiZDJjZjc3ZDc1MjkxNTNmNGI0ZDUwOGY3YWZmMDIzZDkwMzdhZmI1Mzc1NDI3MmIzN2U5N2Q2NWIwMzJkNmU0ZDc4Y2ExIn0.eyJhdWQiOiIzNyIsImp0aSI6ImZiOTVkZDFlMzRiZDJjZjc3ZDc1MjkxNTNmNGI0ZDUwOGY3YWZmMDIzZDkwMzdhZmI1Mzc1NDI3MmIzN2U5N2Q2NWIwMzJkNmU0ZDc4Y2ExIiwiaWF0IjoxNjY3NDQ4MjQwLCJuYmYiOjE2Njc0NDgyNDAsImV4cCI6MTY5ODk4NDI0MCwic3ViIjoiNTY5Iiwic2NvcGVzIjpbXX0.ZksPDgCWrqaF3kX2gTEgVJPWcy3XFDOws38HCw88SF38lIEFy3SxlKSDndiw5Q6NO3X5F019y5kRZyifeUvxbmsy6WQsY_HRsv6pWgp2cFh3aED3YWaU4nfNJ47ePWLJHO1U9sHjOH7eoCs4eVPCPoP-9g6ANOqBizZv_zlqTlcMg6tcTjWp7wlfNB_q-oHIR_CDXWH8LoM1eBDq_rao_RY4UjKpeY31yTVh2OsUGwEx_dA9Ki18eT57emhCI_oGXqiSWgCiSk2h1-VxwJRyj3OO9LGJ_iBNIe72HCSBxXUPn7Zhpe7J7sfhHSKV7O1244rk_HmAOVNvUdURw7cEcYWUQZqk-mu7csDQH8JM8w_eh9eAVcv4TnOaqX1hHAUyHeP367iapjG0AOlPsQbb1TC5CUmHxsOHnJYIhj-hVPfq8_9_3Arb0ya32AODHFMShtWDls5emlR97Hi4yUb80DnjIFfi7XisPmEUIkoTfUxZmsP7qzNz4HWdigv4MLLyGfTxDWHrcsj0RgLRhtpXfz2Jau-vYwSu-rghET1ttnbaGosLakPDj8bON3coHvL_mm3hEpAlOJaOa5xZ2Y1MlJeRneVvuxrsCyE-3Dk-578AShjf6bs3vEjTiSZ6ORn8VZ3KiJAuxEeFCAbS93xsVkaTqj-KPpgozufYrS_Lf6Y" */
  access_token?: string
  /** @example 31536000 */
  expires_in?: number
}

/** 獎勵 */
export interface Reward {
  /**
   * ID
   * @example 23
   */
  reward_id?: number
  /**
   * 會員編號
   * @example 5489
   */
  user_id?: number
  /**
   * 會員姓名
   * @example "王大明"
   */
  user_name?: string
  /** 類型 */
  kind?: '點數' | '儲值金' | '票券'
  /**
   * 類型
   * * `1` 員工贈點
   * * `2` 推薦贈點
   * * `3` 生日點數
   * * `4` 消費贈點
   * * `5` 儲值贈點
   * * `6` 員工出差
   * * `7` 貴賓招待
   * * `8` 品牌行銷
   * @example 1
   */
  bonus_sort?: number | null
  /**
   * 數值，儲值金額/點數
   * @example 2000
   */
  value?: number
  /**
   * 到期日期
   * @example "9999-12-31 23:59:59"
   */
  expired_date?: string
  /**
   * 描述
   * @example "2000 元商品卡"
   */
  remark?: string
  /**
   * 建立時間
   * @example "2012-12-12 12:12:12"
   */
  created_at?: string
  /**
   * 更新時間
   * @example "2012-12-12 12:12:12"
   */
  updated_at?: string
}

/** 獎勵歷程 */
export interface RewardPace {
  /**
   * ID
   * @example 23
   */
  reward_pace_id?: number
  /**
   * 會員編號
   * @example 5489
   */
  user_id?: number
  /**
   * 獎勵 ID
   * @example 12
   */
  reward_id?: number
  /**
   * 類型
   * * `1`  點數
   * * `2`  儲值金
   * * `3`  票券
   */
  kind?: 1 | 2 | 3
  /**
   * 操作類型
   * * `1`  儲值/獲得點數
   * * `2`  消費/使用點數
   * * `3`  退款/退回點數
   */
  action?: 1 | 2 | 3 | null
  /**
   * 數值，儲值金額/點數
   * @example -1500
   */
  value?: number
  /**
   * 描述
   * @example "雀客藏居南港住房消費"
   */
  remark?: string
  /**
   * 元資料，任何資料額外資料
   * @example {"booking_number":"UEA3W1","hotel":"雀客藏居南港"}
   */
  meta?: Record<string, any>
  /**
   * 建立時間
   * @example "2012-12-12 12:12:12"
   */
  created_at?: string
}

/** 商品卡資訊 */
export interface Product {
  /**
   * 商品卡ID
   * @example 1
   */
  id?: number
  /**
   * 商品卡名稱
   * @example "儲值金商品卡 1000點"
   */
  name?: string
  /**
   * 價格
   * @example 1000
   */
  price?: number
  /**
   * 圖片
   * @example "https://picsum.photos/300/230"
   */
  image?: string
  /**
   * 大圖片
   * @example "https://picsum.photos/624/416"
   */
  big_image?: string | null
  /**
   * 商品簡略說明
   * @example "商品簡略說明"
   */
  brief_description?: string
  /**
   * 商品說明
   * @example "商品完整說明"
   */
  description?: string
}

/** 訂單 */
export interface Order {
  /**
   * 訂單ID
   * @example 1
   */
  id?: number
  /**
   * 訂單類型
   * * `1` 商品卡
   * * `2` 票券
   */
  type?: 1 | 2
  /**
   * 訂單編號
   * @example "121434534"
   */
  number?: string
  /**
   * 價格
   * @example 2000
   */
  price?: number
  /**
   * 金流狀況
   * @example "未付款"
   */
  payment_status?: string
  /**
   * 訂單新增日期
   * @example "2022-11-01"
   */
  created_date?: string
  /**
   * 訂單新增時間
   * @example "2022-11-01 10:02:34"
   */
  created_at?: string
  /**
   * 訂單預計取消時間
   * @example "2022-11-01 10:02:34"
   */
  expired_at?: string
  /**
   * 訂單取消時間
   * @example "2022-11-01 10:02:34"
   */
  canceled_at?: string
  /** 下訂商品 */
  products?: {
    /**
     * 商品id
     * @example 1
     */
    product_id?: number
    /**
     * 商品名稱
     * @example "儲值金商品卡 1000點"
     */
    product_name?: string
    /**
     * 圖片
     * @example "https://picsum.photos/300/230"
     */
    image?: string
    /**
     * 單價
     * @example 1000
     */
    price?: number
    /**
     * 數量
     * @example 2
     */
    qty?: number
  }[]
}

export type OrderDetail = Order & {
  /**
   * 訂購人姓名
   * @example "王小花"
   */
  user_name?: string
  /** 下訂商品 */
  products?: {
    /** 適用館別 */
    hotels?: string[] | null
    /**
     * 票券類型
     * @example "住宿"
     */
    ticket_type?: string | null
    /** 票券序號 */
    ticket_numbers?: string[] | null
  }[]
  invoice_numebrs?: string[]
  random_numbers?: string[]
}

/** 分頁資訊 */
export interface Pagination {
  pagination?: {
    /**
     * 總筆數
     * @example 35
     */
    total?: number
    /**
     * 取得筆數
     * @example 15
     */
    count?: number
    /**
     * 每頁筆數
     * @example 20
     */
    per_page?: number
    /**
     * 目前分頁
     * @example 2
     */
    current_page?: number
    /**
     * 總頁數
     * @example 2
     */
    total_pages?: number
  }
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://accounts.itrd.tw' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title 會員中心
 * @version 0.01
 * @baseUrl https://accounts.itrd.tw
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersList
     * @summary 搜尋多使用者含分頁
     * @request GET:/api/v1/users
     * @secure
     */
    v1UsersList: (
      query?: {
        /**
         * 會員 id
         * @example 5489
         */
        id?: string
        /** 姓名或電子信箱 */
        name_or_email?: string
        /** 性別 */
        gender?: 'MALE' | 'FEMALE'
        /** 電子信箱 */
        email?: string
        /** 電子信箱(完全吻合) */
        'email~'?: string
        /** 縣市 */
        county?:
          | '基隆市'
          | '臺北市'
          | '新北市'
          | '桃園市'
          | '新竹市'
          | '新竹縣'
          | '苗栗縣'
          | '臺中市'
          | '彰化縣'
          | '南投縣'
          | '雲林縣'
          | '嘉義市'
          | '嘉義縣'
          | '臺南市'
          | '高雄市'
          | '屏東縣'
          | '臺東縣'
          | '花蓮縣'
          | '宜蘭縣'
          | '澎湖縣'
          | '金門縣'
          | '連江縣'
        /**
         * 行政區
         * @example "西屯區"
         */
        area?: string
        /**
         * 電話
         * @example "0911123456"
         */
        phone?: string
        /**
         * 建立日期（開始日期）
         * @example "2012-12-12"
         */
        created_from?: string
        /**
         * 建立日期（結束日期）
         * @example "2012-12-12"
         */
        created_to?: string
        /**
         * 欄位。預設回傳所有欄位，可指定所需欄位
         * @example "id"
         */
        '_columns[]'?: string
        /**
         * 頁數
         * @default 1
         * @example 1
         */
        _page?: number
        /**
         * 每頁筆數
         * @default 25
         * @example 25
         */
        _take?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: User[]
          /** 分頁資訊 */
          meta?: Pagination
        },
        any
      >({
        path: `/api/v1/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersCreate
     * @summary 新增使用者
     * @request POST:/api/v1/users
     * @secure
     */
    v1UsersCreate: (
      data: {
        /**
         * 姓名
         * @example "會員姓名"
         */
        name?: string
        /**
         * 姓氏
         * @example "會員姓氏"
         */
        last_name?: string
        /**
         * 名稱
         * @example "會員名稱"
         */
        first_name?: string
        /**
         * 電子信箱
         * @example "123@example.com"
         */
        email?: string
        /** @example "J45mN31snZEl2Q78Zaf1F5Po3RgfPaI2AKBsGyv4xM1lpI82EjF0XCUEG000" */
        remember_token?: string
        /**
         * 生日
         * @example "2016-08-03"
         */
        birthday?: string
        /** 性別 */
        gender?: 'MALE' | 'FEMALE'
        /**
         * 通知用電子信箱
         * @example "123@example.com"
         */
        mailing_email?: string
        /**
         * 手機號碼
         * @example "+886911222333"
         */
        phone?: string
        /**
         * 電話
         * @example "0423 663 333"
         */
        tel?: string
        /**
         * 國籍
         * @example "TW"
         */
        nationality?: string
        /**
         * 身分證字號
         * @example "L111222333"
         */
        id_number?: string
        /**
         * 縣市
         * @example "臺中市"
         */
        county?: string
        /**
         * 區
         * @example "西屯區"
         */
        area?: string
        /**
         * 地址
         * @example "中山路55"
         */
        address?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersDetail
     * @summary 搜尋使用者
     * @request GET:/api/v1/users/{id}
     * @secure
     */
    v1UsersDetail: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** 會員 */
          data?: User
        },
        any
      >({
        path: `/api/v1/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersUpdate
     * @summary 更新使用者
     * @request PUT:/api/v1/users/{id}
     * @secure
     */
    v1UsersUpdate: (
      id: number,
      data: {
        /**
         * 姓名
         * @example "會員姓名"
         */
        name: string
        /**
         * 姓氏
         * @example "會員姓氏"
         */
        last_name?: string
        /**
         * 名字
         * @example "會員名字"
         */
        first_name?: string
        /**
         * 電子信箱
         * @example "123@example.com"
         */
        email?: string
        /**
         * 生日
         * @example "2016-08-03"
         */
        birthday?: string
        /** 性別 */
        gender?: 'MALE' | 'FEMALE'
        /**
         * 通知用電子信箱
         * @example "123@example.com"
         */
        mailing_email?: string
        /**
         * 手機號碼
         * @example "+886911222333"
         */
        phone?: string
        /**
         * 電話
         * @example "0423 663 333"
         */
        tel?: string
        /**
         * 國籍
         * @example "TW"
         */
        nationality?: string
        /**
         * 身分證字號
         * @example "L111222333"
         */
        id_number?: string
        /**
         * 縣市
         * @example "臺中市"
         */
        county?: string
        /**
         * 區
         * @example "西屯區"
         */
        area?: string
        /**
         * 地址
         * @example "中山路55"
         */
        address?: string
        /** @example true */
        verified?: boolean
        /**
         * 統一編號
         * @example "54890959"
         */
        tax_id?: string
        /**
         * 公司抬頭
         * @example "敦謙國際智能科技股份有限公司"
         */
        received_by?: string
        /**
         * 推播 Token
         * @example "1234567890"
         */
        notification_token?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/api/v1/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersAvatarCreate
     * @summary 上傳使用者頭像
     * @request POST:/api/v1/users/{id}/avatar
     * @secure
     */
    v1UsersAvatarCreate: (
      id: number,
      data: {
        /**
         * 頭像
         * @format binary
         */
        avatar?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}/avatar`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserList
     * @summary 取得使用者
     * @request GET:/api/v1/user
     * @secure
     */
    v1UserList: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/v1/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserUpdate
     * @summary 更新使用者
     * @request PUT:/api/v1/user
     * @secure
     */
    v1UserUpdate: (
      data: {
        /**
         * 姓名
         * @example "會員姓名"
         */
        name: string
        /**
         * 姓氏
         * @example "會員姓氏"
         */
        last_name?: string
        /**
         * 名字
         * @example "會員名字"
         */
        first_name?: string
        /**
         * 電子信箱
         * @example "123@example.com"
         */
        email?: string
        /**
         * 生日
         * @example "2016-08-03"
         */
        birthday?: string
        /** 性別 */
        gender?: 'MALE' | 'FEMALE'
        /**
         * 通知用電子信箱
         * @example "123@example.com"
         */
        mailing_email?: string
        /**
         * 手機號碼
         * @example "+886911222333"
         */
        phone?: string
        /**
         * 電話
         * @example "0423 663 333"
         */
        tel?: string
        /**
         * 國籍
         * @example "TW"
         */
        nationality?: string
        /**
         * 身分證字號
         * @example "L111222333"
         */
        id_number?: string
        /**
         * 縣市
         * @example "臺中市"
         */
        county?: string
        /**
         * 區
         * @example "西屯區"
         */
        area?: string
        /**
         * 地址
         * @example "中山路55"
         */
        address?: string
        /** @example true */
        verified?: boolean
        /**
         * 統一編號
         * @example "54890959"
         */
        tax_id?: string
        /**
         * 公司抬頭
         * @example "敦謙國際智能科技股份有限公司"
         */
        received_by?: string
        /**
         * 推播 Token
         * @example "1234567890"
         */
        notification_token?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/api/v1/user`,
        method: 'PUT',
        body: data,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserResetPasswordCreate
     * @summary 密碼變更
     * @request POST:/api/v1/user/reset_password
     * @secure
     */
    v1UserResetPasswordCreate: (
      data: {
        /**
         * 密碼
         * @example "123456"
         */
        password?: string
        /**
         * 密碼確認
         * @example "123456"
         */
        password_confirmation?: string
        /**
         * 舊密碼
         * @example "123456"
         */
        old_password?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/user/reset_password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserAvatarCreate
     * @summary 上傳使用者頭像
     * @request POST:/api/v1/user/avatar
     * @secure
     */
    v1UserAvatarCreate: (
      data: {
        /**
         * 頭像
         * @format binary
         */
        avatar?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/user/avatar`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params
      }),

    /**
     * No description
     *
     * @tags OAuth
     * @name V1ClientsList
     * @summary 查詢 OAuth client 資訊
     * @request GET:/api/v1/clients
     * @secure
     */
    v1ClientsList: (
      query?: {
        /**
         * 網域名稱
         * @example "partner"
         */
        domain?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OauthClient, any>({
        path: `/api/v1/clients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OAuth
     * @name V1ClientCreateCreate
     * @summary 新增 OAuth client 資訊
     * @request POST:/api/v1/client-create
     * @secure
     */
    v1ClientCreateCreate: (
      data: {
        /**
         * 網域名稱
         * @example "partner"
         */
        domain?: string
        /**
         * 重新導向路徑
         * @example "http://localhost"
         */
        redirect?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/client-create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1SocialsCreate
     * @summary 新增或取得社交網站會員
     * @request POST:/api/v1/socials
     * @secure
     */
    v1SocialsCreate: (
      data: {
        /** @example "2092066550806768" */
        provider_id?: string
        /**
         * 服務提供者
         * @example "facebook"
         */
        provider?: string
        /**
         * 姓名
         * @example "會員姓名"
         */
        name?: string
        /**
         * 電子信箱
         * @example "123@example.com"
         */
        email?: string
        /**
         * 頭像圖片
         * @example "me.jpg"
         */
        avatar?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<User[], any>({
        path: `/api/v1/socials`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1PasswordEmailCreate
     * @summary 重設密碼信
     * @request POST:/api/v1/password-email
     * @secure
     */
    v1PasswordEmailCreate: (
      data: {
        /**
         * 電子信箱
         * @example "123@example.com"
         */
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/password-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1UsersResetPasswordCreate
     * @summary 密碼變更
     * @request POST:/api/v1/users/reset_password
     * @secure
     */
    v1UsersResetPasswordCreate: (
      data: {
        /**
         * 使用者 ID
         * @example 5489
         */
        user_id?: number
        /**
         * 密碼
         * @example "123456"
         */
        password?: string
        /**
         * 密碼確認
         * @example "123456"
         */
        password_confirmation?: string
        /**
         * 舊密碼
         * @example "123456"
         */
        old_password?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/users/reset_password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1OnetapCreate
     * @summary 新增或取得google one tap會員，成功回傳存取權仗
     * @request POST:/api/v1/onetap
     * @secure
     */
    v1OnetapCreate: (
      data: {
        /** @example "mem" */
        client?: string
        /** @example "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImZiOTVkZDFlMzRiZDJjZjc3ZDc1MjkxNTNmNGI0ZDUwOGY3YWZmMDIzZDkwMzdhZmI1Mzc1NDI3MmIzN2U5N2Q2NWIwMzJkNmU0ZDc4Y2ExIn0.eyJhdWQiOiIzNyIsImp0aSI6ImZiOTVkZDFlMzRiZDJjZjc3ZDc1MjkxNTNmNGI0ZDUwOGY3YWZmMDIzZDkwMzdhZmI1Mzc1NDI3MmIzN2U5N2Q2NWIwMzJkNmU0ZDc4Y2ExIiwiaWF0IjoxNjY3NDQ4MjQwLCJuYmYiOjE2Njc0NDgyNDAsImV4cCI6MTY5ODk4NDI0MCwic3ViIjoiNTY5Iiwic2NvcGVzIjpbXX0.ZksPDgCWrqaF3kX2gTEgVJPWcy3XFDOws38HCw88SF38lIEFy3SxlKSDndiw5Q6NO3X5F019y5kRZyifeUvxbmsy6WQsY_HRsv6pWgp2cFh3aED3YWaU4nfNJ47ePWLJHO1U9sHjOH7eoCs4eVPCPoP-9g6ANOqBizZv_zlqTlcMg6tcTjWp7wlfNB_q-oHIR_CDXWH8LoM1eBDq_rao_RY4UjKpeY31yTVh2OsUGwEx_dA9Ki18eT57emhCI_oGXqiSWgCiSk2h1-VxwJRyj3OO9LGJ_iBNIe72HCSBxXUPn7Zhpe7J7sfhHSKV7O1244rk_HmAOVNvUdURw7cEcYWUQZqk-mu7csDQH8JM8w_eh9eAVcv4TnOaqX1hHAUyHeP367iapjG0AOlPsQbb1TC5CUmHxsOHnJYIhj-hVPfq8_9_3Arb0ya32AODHFMShtWDls5emlR97Hi4yUb80DnjIFfi7XisPmEUIkoTfUxZmsP7qzNz4HWdigv4MLLyGfTxDWHrcsj0RgLRhtpXfz2Jau-vYwSu-rghET1ttnbaGosLakPDj8bON3coHvL_mm3hEpAlOJaOa5xZ2Y1MlJeRneVvuxrsCyE-3Dk-578AShjf6bs3vEjTiSZ6ORn8VZ3KiJAuxEeFCAbS93xsVkaTqj-KPpgozufYrS_Lf6Y" */
        token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        OauthPersonalAccess,
        | {
            /**
             * 錯誤訊息
             * @example "使用者未授權GoogleOneTap應用程式或傳入的使用者權仗已過期"
             */
            message?: string
            /**
             * 錯誤代碼
             * @example 401
             */
            status_code?: number
          }
        | {
            /**
             * 錯誤訊息
             * @example "發生預期外的錯誤"
             */
            message?: string
            /**
             * 錯誤代碼
             * @example 500
             */
            status_code?: number
          }
      >({
        path: `/api/v1/onetap`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1OauthAppleCallbackCreate
     * @summary 處理 apple 登入後的 callback
     * @request POST:/api/v1/oauth/apple_callback
     * @secure
     */
    v1OauthAppleCallbackCreate: (
      data: {
        /**
         * apple 登入後的 code
         * @example 123456
         */
        code: string
        /**
         * client
         * * `mem` reelax
         * * `ios_mem` ios reelax (因 ios 的 client_id 與其他不同，所以另外開一個)
         * @example "apple"
         */
        client: 'mem' | 'ios_mem'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        OauthPersonalAccess,
        | {
            /**
             * 錯誤訊息
             * @example "使用者未授權Apple應用程式或傳入的使用者權仗已過期"
             */
            message?: string
            /**
             * 錯誤代碼
             * @example 401
             */
            status_code?: number
          }
        | (
            | {
                /**
                 * 錯誤訊息
                 * @example "發生預期外的錯誤"
                 */
                message?: string
                /**
                 * 錯誤代碼
                 * @example 500
                 */
                status_code?: number
              }
            | {
                /**
                 * 指定的 client 不存在
                 * @example "not found client"
                 */
                message?: string
                /**
                 * 錯誤代碼
                 * @example 500
                 */
                status_code?: number
              }
            | {
                /**
                 * 不合法的 id token
                 * @example "Invalid id_token"
                 */
                message?: string
                /**
                 * 錯誤代碼
                 * @example 500
                 */
                status_code?: number
              }
          )
      >({
        path: `/api/v1/oauth/apple_callback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 會員
     * @name V1OauthAndroidCallbackCreate
     * @summary 處理 android 登入後的 callback
     * @request POST:/api/v1/oauth/android_callback
     * @secure
     */
    v1OauthAndroidCallbackCreate: (data: any, params: RequestParams = {}) =>
      this.request<
        any,
        void | {
          /**
           * 錯誤訊息
           * @example "發生預期外的錯誤"
           */
          message?: string
          /**
           * 錯誤代碼
           * @example 500
           */
          status_code?: number
        }
      >({
        path: `/api/v1/oauth/android_callback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags 獎勵
     * @name V1RewardCountList
     * @summary 獎勵總點數
     * @request GET:/api/v1/reward_count
     * @secure
     */
    v1RewardCountList: (
      query: {
        /**
         * 會員 id (user_id, email 二擇一)
         * @example 5489
         */
        user_id: string
        /**
         * 電子信箱 (user_id, email 二擇一)
         * @example "example@example.com"
         */
        email?: string
        /**
         * 類型
         * * `1`  點數
         * * `2`  儲值金
         * * `3`  票券
         */
        kind?: '1' | '2' | '3'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 總點數
           * @example 700
           */
          count?: number
          /**
           * 點數總點數
           * @example 200
           */
          point?: number
          /**
           * 儲值金總點數
           * @example 500
           */
          store?: number
        },
        any
      >({
        path: `/api/v1/reward_count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 獎勵
     * @name V1RewardPacesCreate
     * @summary 新增獎勵歷程
     * @request POST:/api/v1/reward_paces
     * @secure
     */
    v1RewardPacesCreate: (
      data: {
        /**
         * 會員 id (user_id, email 二擇一)
         * @example 5489
         */
        user_id: string
        /**
         * 電子信箱 (user_id, email 二擇一)
         * @example "example@example.com"
         */
        email?: string
        /**
         * 類型
         * * `1`  點數
         * * `2`  儲值金
         * * `3`  票券
         */
        kind: 1 | 2 | 3
        /**
         * 類型
         * * `1` 員工贈點
         * * `2` 推薦贈點
         * * `3` 生日點數
         * * `4` 消費贈點
         * * `5` 儲值贈點
         * * `6` 員工出差
         * * `7` 貴賓招待
         * * `8` 品牌行銷
         */
        bonus_sort?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
        /**
         * 操作類型
         * * `1`  儲值/獲得點數
         * * `2`  消費/使用點數
         * * `3`  退款/退回點數
         */
        action?: 1 | 2 | 3 | null
        /**
         * 獎勵 id，指定後只會針對該筆獎勵進行數值增加或減少
         * @example 2
         */
        reward_id?: number
        /**
         * 數值，儲值金額/點數
         * @example 1000
         */
        value: any
        /**
         * 過期日期
         * @example "9999-12-31"
         */
        expired_date: string
        /**
         * 備註
         * @example "雀客藏居台中住房消費"
         */
        remark: string
        /**
         * 元資料，任何資料額外資料
         * @example {"booking_number":"UEA3W1","hotel":"雀客藏居南港"}
         */
        meta?: Record<string, any>
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: RewardPace[]
        },
        any
      >({
        path: `/api/v1/reward_paces`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserRewardCountList
     * @summary 獎勵總點數
     * @request GET:/api/v1/user/reward_count
     * @secure
     */
    v1UserRewardCountList: (
      query?: {
        /**
         * 類型
         * * `1`  點數
         * * `2`  儲值金
         * * `3`  票券
         */
        kind?: '1' | '2' | '3'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 總點數
           * @example 700
           */
          count?: number
          /**
           * 點數總點數
           * @example 200
           */
          point?: number
          /**
           * 儲值金總點數
           * @example 500
           */
          store?: number
          /**
           * 票券總點數
           * @example 500
           */
          ticket?: number
        },
        any
      >({
        path: `/api/v1/user/reward_count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserRewardsList
     * @summary 搜尋獎勵
     * @request GET:/api/v1/user/rewards
     * @secure
     */
    v1UserRewardsList: (
      query?: {
        /**
         * 類型
         * * `1`  點數
         * * `2`  儲值金
         * * `3`  票券
         */
        kind?: '1' | '2' | '3'
        /**
         * 頁數
         * @default 1
         * @example 1
         */
        _page?: number
        /**
         * 每頁筆數
         * @default 25
         * @example 25
         */
        _take?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: Reward[]
          /** 分頁資訊 */
          meta?: Pagination
        },
        any
      >({
        path: `/api/v1/user/rewards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 個人會員
     * @name V1UserRewardPacesList
     * @summary 搜尋獎勵歷程
     * @request GET:/api/v1/user/reward_paces
     * @secure
     */
    v1UserRewardPacesList: (
      query: {
        /**
         * 交易開始時間
         * @example "2022-12-01"
         */
        from: string
        /**
         * 交易結束時間
         * @example "2022-12-12"
         */
        to: string
        /**
         * 類型
         * * `1`  點數
         * * `2`  儲值金
         * * `3`  票券
         */
        kind?: '1' | '2' | '3'
        /**
         * 頁數
         * @default 1
         * @example 1
         */
        _page?: number
        /**
         * 每頁筆數
         * @default 25
         * @example 25
         */
        _take?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: RewardPace[]
          /** 分頁資訊 */
          meta?: Pagination
        },
        any
      >({
        path: `/api/v1/user/reward_paces`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 商品卡
     * @name V1ProductsList
     * @summary 取得商品卡資訊
     * @request GET:/api/v1/products
     * @secure
     */
    v1ProductsList: (params: RequestParams = {}) =>
      this.request<
        {
          data?: Product[]
        },
        any
      >({
        path: `/api/v1/products`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 商品卡
     * @name V1ProductsDetail
     * @summary 取得商品資訊
     * @request GET:/api/v1/products/{id}
     * @secure
     */
    v1ProductsDetail: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** 商品卡資訊 */
          data?: Product
        },
        any
      >({
        path: `/api/v1/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 商品卡
     * @name V1TicketProductsDetail
     * @summary 根據票券ID取得商品資訊
     * @request GET:/api/v1/ticket_products/{id}
     * @secure
     */
    v1TicketProductsDetail: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** 商品卡資訊 */
          data?: Product
        },
        any
      >({
        path: `/api/v1/ticket_products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 訂單
     * @name V1FillOrderCreate
     * @summary 填寫訂單
     * @request POST:/api/v1/fill_order
     * @secure
     */
    v1FillOrderCreate: (
      data: {
        /**
         * 會員 id
         * @example 5489
         */
        user_id: string
        /** 下訂商品 */
        products: {
          /**
           * 商品id
           * @example 2
           */
          product_id?: number
          /**
           * 數量
           * @example 2
           */
          qty?: number
        }[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 下訂商品 */
          products?: {
            /**
             * 商品id
             * @example 2
             */
            product_id?: number
            /**
             * 單價
             * @example 1000
             */
            price?: number
            /**
             * 數量
             * @example 2
             */
            qty?: number
          }[]
          reward?: {
            /**
             * 類型 (1:點數, 2:儲值金, 3:票券)
             * @example 1
             */
            kind?: number
            /**
             * 數值，儲值金額/點數
             * @example 500
             */
            value?: number
            /**
             * 價格
             * @example 500
             */
            price?: number
            /**
             * 過期日期
             * @example "2023-12-31"
             */
            expired_date?: string
          }
        },
        any
      >({
        path: `/api/v1/fill_order`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 訂單
     * @name V1OrderCreate
     * @summary 新增訂單
     * @request POST:/api/v1/order
     * @secure
     */
    v1OrderCreate: (
      data: {
        /**
         * 會員 id
         * @example 5489
         */
        user_id: string
        /** 下訂商品 */
        products: {
          /**
           * 商品id
           * @example 2
           */
          product_id?: number
          /**
           * 數量
           * @example 2
           */
          qty?: number
        }[]
        /** 電子發票資訊 */
        invoice?: {
          /**
           * 統一編號
           * @example "54890959"
           */
          customer_identifier?: string
          /**
           * 公司抬頭
           * @example "敦謙國際智能科技股份有限公司"
           */
          customer_name?: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * 訂單編號
           * @example "121434534"
           */
          numebr?: string
          /** 下訂商品 */
          products?: {
            /**
             * 商品id
             * @example 2
             */
            product_id?: number
            /**
             * 單價
             * @example 1000
             */
            price?: number
            /**
             * 數量
             * @example 2
             */
            qty?: number
          }[]
          /**
           * 訂單價格
           * @example 2000
           */
          price?: number
        },
        any
      >({
        path: `/api/v1/order`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 訂單
     * @name V1OrdersList
     * @summary 查詢訂單列表
     * @request GET:/api/v1/orders
     * @secure
     */
    v1OrdersList: (
      query: {
        /**
         * 購買日期
         * @example "2022-11-01"
         */
        date: string
        /**
         * 類型
         * * `1` 商品卡
         * * `2` 票券
         */
        type: 1 | 2
      },
      params: RequestParams = {}
    ) =>
      this.request<Order[], any>({
        path: `/api/v1/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags 訂單
     * @name V1UserOrdersDetail
     * @summary 根據訂單編號查詢訂單
     * @request GET:/api/v1/user/orders/{number}
     * @secure
     */
    v1UserOrdersDetail: (number: string, params: RequestParams = {}) =>
      this.request<OrderDetail, any>({
        path: `/api/v1/user/orders/${number}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 請用表單送出請求，並轉跳到金流商頁面
     *
     * @tags payment
     * @name V1PaymentCreate
     * @summary 訂單付款
     * @request POST:/api/v1/payment
     * @secure
     */
    v1PaymentCreate: (
      data: {
        /**
         * 訂單編號
         * @example "121434534"
         */
        order_number: string
        /**
         * 會員 email
         * @example "example@example.com"
         */
        email: string
        /**
         * 付款結果跳轉頁
         * @example "http://localhost/121434534"
         */
        redirect: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/api/v1/payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        ...params
      }),

    /**
     * No description
     *
     * @tags payment
     * @name V1InvoiceCreate
     * @summary 開立發票
     * @request POST:/api/v1/invoice
     * @secure
     */
    v1InvoiceCreate: (
      data: {
        /** 訂單編號 */
        order_number?: string
        /** 統一編號 */
        customer_identifier?: string
        /** 客戶名稱 */
        name?: string
        /** 手機號碼 */
        phone?: string
        /** 電子信箱 */
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/invoice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        ...params
      })
  }
  oauth = {
    /**
     * No description
     *
     * @tags 個人會員
     * @name GetOauth
     * @summary 取得個人資料
     * @request GET:/oauth/me
     * @secure
     */
    getOauth: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/oauth/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description 此API會直接顯示登入頁面，需要直接跳轉，不可使用CURL/XHR請求<br> 授權登入成功後會跳轉至提供的網址
     *
     * @tags OAuth
     * @name GetOauthAuthorize
     * @summary 授權登入頁面
     * @request GET:/oauth/authorize
     */
    getOauthAuthorize: (
      query: {
        /** oauth 客戶端 ID */
        client_id: string
        /**
         * oauth模式 固定為`code`
         * @example "code"
         */
        response_type: string
        /**
         * 登入完成後重新導向的網址
         * @format uri
         */
        redirect_uri: string
        /** 授權規則 */
        scope: string
        /** 目前不用直接傳null */
        state: string
        /**
         * 來源平台
         * @example "guest"
         */
        role?: 'rms' | 'mem' | 'partner' | 'guest' | 'admin'
        /**
         * 預填email
         * @format email
         * @example "test@test.tw"
         */
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 授權碼 */
          code: string
          /** 目前沒用到 */
          state: string
        },
        any
      >({
        path: `/oauth/authorize`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description 使用授權碼取得個人通行權杖
     *
     * @tags OAuth
     * @name PostOauthToken
     * @summary 取得Access Token
     * @request POST:/oauth/token
     */
    postOauthToken: (
      data: {
        /** 客戶端ID */
        client_id: string
        /** 客戶端密鑰 */
        client_secret: string
        /**
         * 固定為`authorization_code`
         * @example "authorization_code"
         */
        grant_type: 'authorization_code'
        /** 重新導向網址 */
        redirect_uri: string
        /** 授權碼 */
        code: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 權杖類別 */
          token_type: string
          /** 逾期時間 */
          expires_in: number
          /** 通行權杖 */
          access_token: string
          /** 刷新權杖 */
          refresh_token: string
        },
        any
      >({
        path: `/oauth/token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  logout = {
    /**
     * @description 返回會依照有沒有傳入callback參數而有所不同<br> JSONP請參閱： https://www.w3schools.com/js/js_json_jsonp.asp
     *
     * @name GetLogoutJson
     * @summary 登出（JSONP協定）
     * @request GET:/logout/json
     * @secure
     */
    getLogoutJson: (
      query?: {
        /** JSONP callback 函數名 */
        callback?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/logout/json`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  }
}
