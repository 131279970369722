import { Keyword } from '@/api/api'
import { Commit } from 'vuex'
import qs from 'qs'
import format from 'date-fns/format'
import differenceInDays from 'date-fns/differenceInDays'
import { DATA_DATE_FORMAT } from '@/constants'
import { FormState } from '@/store/types/form'
import addDays from 'date-fns/addDays'
import { computeCheckOutDate } from '@/utils'

export const TODAY = new Date()

const START_DATE = addDays(new Date(), 30)
const END_DATE = addDays(new Date(), 31)

// initial state
const state: FormState = {
  search: {
    startDate: START_DATE,
    endDate: computeCheckOutDate(START_DATE, END_DATE),
    city: 1,
    adults: 1,
    children: 0,
    room: 1,
    childrenAge: [],
    lowest_price: 0,
    highest_price: 10000,
    hotel_facility: [],
    room_facility: [],
    filter: [],
    business_type: window.location.pathname.includes('travel/quarantine') ? 2 : 1
  },
  searchBar: '',
  isExpand: false,
  searchHotel: undefined
}

// getters
const getters = {
  getDuration: (state: FormState): number => {
    return differenceInDays(
      new Date(format(state.search.endDate, DATA_DATE_FORMAT)),
      new Date(format(state.search.startDate, DATA_DATE_FORMAT))
    )
  },
  getSearchInfo: (state: FormState): string => {
    return qs.stringify({
      check_in: format(state.search.startDate, DATA_DATE_FORMAT),
      check_out: format(state.search.endDate, DATA_DATE_FORMAT),
      adults: state.search.adults,
      children: state.search.children,
      business_type: state.search.business_type
    })
  }
}

// actions
interface SearchConditionProps {
  field: keyof FormState['search']
  value: any
}
interface AgeProps {
  index: number
  age: number
}
const actions = {
  setSearchCondition({ commit }: { commit: Commit }, { field, value }: SearchConditionProps): void {
    commit('setSearchCondition', { field, value })
  },
  setChildAge({ commit }: { commit: Commit }, { index, age }: AgeProps): void {
    commit('setChildAge', { index, age })
  },
  setSearchBar({ commit }: { commit: Commit }, type: string): void {
    commit('setSearchBar', type)
  },
  setIsExpand({ commit }: { commit: Commit }, open: boolean): void {
    commit('setIsExpand', open)
  },
  setKeyword({ commit }: { commit: Commit }, keyword: Keyword): void {
    commit('setKeyword', keyword)
  }
}

// mutations
const mutations = {
  setSearchCondition(state: FormState, { field, value }: SearchConditionProps): void {
    state.search[field] = value
    if (field === 'children') {
      const orgChildrenCounts = state.search.childrenAge.length
      if (value >= orgChildrenCounts) {
        const addChildrenCounts = value - orgChildrenCounts
        state.search.childrenAge = [...state.search.childrenAge, ...Array(addChildrenCounts).fill(0)]
      } else {
        state.search.childrenAge = state.search.childrenAge.slice(0, orgChildrenCounts - 1)
      }
    }
    if (field === 'business_type' && state.searchHotel?.business_type && state.searchHotel.business_type !== value) {
      state.searchHotel = undefined
    }
  },
  setChildAge(state: FormState, { index, age }: AgeProps): void {
    state.search.childrenAge[index] = age
  },
  setSearchBar(state: FormState, type: string): void {
    state.searchBar = type
  },
  setIsExpand(state: FormState, open: boolean): void {
    state.isExpand = open
  },
  setKeyword(state: FormState, keyword: Keyword): void {
    if (keyword?.type === 'city') {
      state.search.city = keyword.key
    } else {
      state.searchHotel = keyword
    }
    // FIX ME: code generated business_type enum is inconsistent
    if (keyword?.business_type) state.search.business_type = keyword.business_type as any
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
