import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_global = _resolveComponent("global");

  var _component_resizer = _resolveComponent("resizer");

  return _openBlock(), _createBlock(_component_resizer, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_global, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_router_view)];
        }),
        _: 1
      })];
    }),
    _: 1
  });
}