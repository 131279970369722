import { defineComponent, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LoginPopup from '@/components/share/LoginPopup.vue';
export default defineComponent({
  name: 'Global',
  components: {
    LoginPopup: LoginPopup
  },
  setup: function setup() {
    var _useI18n = useI18n({
      useScope: 'global'
    }),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var isLoginModelOpen = ref(false);
    watch(locale, function (newlocale) {
      if (newlocale) {
        localStorage.setItem('locale', newlocale);
        locale.value = newlocale;
      } else {
        localStorage.setItem('locale', 'tw');
        locale.value = 'tw';
      }
    });

    var onLoginModelToggle = function onLoginModelToggle() {
      isLoginModelOpen.value = !isLoginModelOpen.value;
    };

    provide('t', t);
    provide('locale', locale);
    provide('isLoginModelOpen', isLoginModelOpen);
    provide('onLoginModelToggle', onLoginModelToggle);
  }
});