export const setKeywordMeta = (businessType: string): void => {
  if (businessType === '1') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        '國旅, 民宿, 住宿, 飯店, 旅館, 訂房, 酒店, 訂民宿, 訂住宿, 線上訂房, 住宿推薦, 民宿推薦, 民宿預訂, 飯店預訂, 酒店預訂, 旅館預訂, 住宿預訂, 日本, 台灣旅遊, 自由行, 旅遊'
      )
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        '防疫旅館,防疫旅館補助,防疫旅館預訂,防疫補助,台北市防疫旅館,台中市防疫旅館,高雄防疫旅館,台灣防疫旅館,防疫旅館費用,新北市防疫旅館,防疫旅館名單,彰化防疫旅館,桃園防疫旅館,新北市防疫旅館'
      )
  }
}
