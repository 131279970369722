import { Commit } from 'vuex'
import { Hotel, Pagination } from '@/api/api'
import { myApi } from '@/api'
import { HotelState, HotelOptionListRes } from '@/store/types/hotels'
import { SortingEnum } from '@/store/types/options'

// initial state
const state: HotelState = {
  hotelsSort: SortingEnum.Recommend,
  hotelsList: [],
  hotelsListLoading: false,
  hotelsPagination: {},
  hotelsListByLocation: [],
  recommendList: [],
  hitoHotels: {},
  hotelOptionList: {},
  coordinate: {
    lat: null,
    lng: null
  }
}

// getters
const getters = {
  getFirstHotelLocation: (state: HotelState) => {
    return state.hotelsListByLocation?.[0]
      ? {
          lat: state.hotelsListByLocation[0].lat,
          lng: state.hotelsListByLocation[0].lng
        }
      : state.hotelsList?.[0]
      ? {
          lat: state.hotelsList[0].lat,
          lng: state.hotelsList[0].lng
        }
      : { lat: 38.8977859, lng: -77.0057621 }
  },
  getHotelsByLocation: (state: HotelState): Hotel[] => {
    return state.hotelsListByLocation.length > 0 ? state.hotelsListByLocation : state.hotelsList
  },
  getHitoHotels: (state: HotelState): HotelState['hitoHotels'] => {
    return state.hitoHotels
  },
  getHotelOptionList: (state: HotelState): HotelState['hotelOptionList'] => {
    return state.hotelOptionList
  },
  getHotels: (state: HotelState): HotelState['hotelsList'] => {
    return state.hotelsList
  },
  getRecommend: (state: HotelState): HotelState['recommendList'] => {
    return state.recommendList
  },
  getMapCenterCoordinate: (state: HotelState): HotelState['coordinate'] => {
    return state.coordinate
  }
}

// actions
export interface Location {
  lat: number
  lng: number
}
export interface HotelsListQueries {
  city?: number
  ['check_in']?: string
  ['check_out']?: string
  adults: number
  children: number
  ['child_age']?: number
  ['lowest_price']?: number
  ['highest_price']?: number
  ['hotel_facility']?: string
  ['room_facility']?: string
  ['filter']?: string
  ['business_type']?: 1 | 2 | 3
  page?: number
  ['is_random']?: 0 | 1
  lat?: number
  lng?: number
}
const actions = {
  handelHotelsSort({ commit }: { commit: Commit }, sort: SortingEnum): void {
    commit('setHotelsSort', sort)
  },
  async fetchHotelList({ commit }: { commit: Commit }, queries: HotelsListQueries): Promise<void> {
    commit('setHotelsLoading', true)
    const { data } = await myApi.mem.hotelsList(queries)
    commit('setHotelsList', data)
  },
  async fetchHitoHotels({ commit }: { commit: Commit }, queries: HotelsListQueries): Promise<void> {
    const { data } = await myApi.mem.hotelsList(queries)
    commit('setHitoHotelsList', { hotels: data, city: queries?.city || 0 })
  },
  async fetchHotelOptionList({ commit }: { commit: Commit }, queries: HotelsListQueries): Promise<void> {
    const { data } = await myApi.mem.hotelOptionsList(queries)
    commit('setHotelOptionList', data)
  },
  async fetchHotelListByLocation({ commit }: { commit: Commit }, queries: HotelsListQueries): Promise<void> {
    const { data } = await myApi.mem.hotelsList(queries)
    commit('setHotelsListByLocation', data || [])
    commit('setCoordinate', { lat: queries.lat, lng: queries.lng })
  }
}

interface HitoHotelsListProps {
  city: number
  hotels: { data: Hotel[]; recommend?: Hotel[] }
}
// mutations
const mutations = {
  setHotelsSort(state: HotelState, sort: SortingEnum): void {
    state.hotelsSort = sort
  },
  setHitoHotelsList(state: HotelState, { city, hotels }: HitoHotelsListProps): void {
    const data = { ...state.hitoHotels }
    data[city] = hotels.data?.slice(0, 8)
    state.hitoHotels = data
  },
  setHotelsList(state: HotelState, hotels: { data: Hotel[]; recommend: Hotel[]; meta: Pagination }): void {
    if (hotels.meta?.pagination?.current_page === 1) {
      state.hotelsList = hotels.data || []
    } else {
      state.hotelsList = [...state.hotelsList].concat(hotels.data)
    }
    state.recommendList = hotels.recommend || []
    state.hotelsPagination = hotels.meta?.pagination || {}
    state.hotelsListLoading = false
  },
  setHotelsLoading(state: HotelState, loading: boolean): void {
    state.hotelsListLoading = loading
  },
  setHotelOptionList(state: HotelState, data: HotelOptionListRes): void {
    state.hotelOptionList = data || []
  },
  setHotelsListByLocation(state: HotelState, hotels: { data: Hotel[]; recommend: Hotel[] }): void {
    state.hotelsListByLocation = hotels.data || []
  },
  setCoordinate(state: HotelState, coordinate: { lat: number; lng: number }): void {
    state.coordinate = coordinate
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
