export default {
  "facility-groups.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Amenities and Services"])},
  "facility.24hours_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour reception"])},
  "facility.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
  "facility.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food package"])},
  "facility.laundrydelivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry service"])},
  "facility.entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental entertainment equipment "])},
  "facility.3c_peripheral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental 3C accessories"])},
  "facility.non-smokinghotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoke-free property"])},
  "facility-groups.counter_and_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter and Service"])},
  "facility.24hours_counter_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour front desk"])},
  "facility.24hours_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour room service"])},
  "facility.luggage_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luggage storage"])},
  "facility.master_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master credit card service"])},
  "facility.visa_creditcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa credi tcard service"])},
  "facility.electronic_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic payment service"])},
  "facility.personal_checkincheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal check-in / check-out"])},
  "facility.ondutybutler_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On duty butler service"])},
  "facility.foreign_currency_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency exchange"])},
  "facility.mail_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail service"])},
  "facility.security_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental safe box "])},
  "facility.ticket_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket services"])},
  "facility.morning_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning call"])},
  "facility-groups.internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet access"])},
  "facility.public_free_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Internet in public area"])},
  "facility.free_wifi_forallrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi in all rooms"])},
  "facility.paid_internetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid internet service"])},
  "facility.portable_wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portable Wi-Fi"])},
  "facility-groups.leisure_and_ent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure and entertainment"])},
  "facility.gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gym"])},
  "facility.videogame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game room"])},
  "facility.cinema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinema"])},
  "facility.karaoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karaoke"])},
  "facility.steamroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steam room"])},
  "facility.hotspring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot spring"])},
  "facility.sauna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauna"])},
  "facility.hairdressingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon"])},
  "facility.beautyservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty service"])},
  "facility.spa-massageservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spa-massage service"])},
  "facility.massagechair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage chair"])},
  "facility.indoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Swimming pool"])},
  "facility.outdoorswimmingpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Swimming pool"])},
  "facility.healthpath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walking Trail"])},
  "facility.forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forest"])},
  "facility-groups.catering_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering Services"])},
  "facility.restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
  "facility.barbecue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbecue"])},
  "facility.roomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room service"])},
  "facility.bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
  "facility.cafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee shop"])},
  "facility.openkitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open kitchen"])},
  "facility.oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oven"])},
  "facility.weddingservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding service"])},
  "facility.snack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snack"])},
  "facility.publicdrinkingfountain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["public drinking fountains"])},
  "facility-groups.complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex"])},
  "facility.barrier-freefacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrier-free facility"])},
  "facility.elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevator"])},
  "facility.atm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash withdrawal"])},
  "facility.conveniencestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convenience store"])},
  "facility.smokingzone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking zone"])},
  "facility.smokingroomtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking room type"])},
  "facility.giftshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift/souvenir shop"])},
  "facility.businesscenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business center"])},
  "facility.meetingroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting room"])},
  "facility.faxmachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faxmachine"])},
  "facility.printer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printer"])},
  "facility.freenewspaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Newspaper"])},
  "facility.terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrace"])},
  "facility.laundryroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry"])},
  "facility.infirmary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infirmary"])},
  "facility.locker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locker"])},
  "facility.banquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banquet"])},
  "facility.saloon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounge"])},
  "facility.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
  "facility-groups.language_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language service"])},
  "facility.chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "facility.english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "facility.japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
  "facility.korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "facility-groups.health_and_safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health and Safety"])},
  "facility.dailycleaningdisinfection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily disinfection in common areas"])},
  "facility.takeoutthegarbage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily trash collected outside the room"])},
  "facility.disinfectromm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily disinfection in all rooms"])},
  "facility.thermometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature check for guests and staff"])},
  "facility.wearmasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face coverings on staff"])},
  "facility.firstaidkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First-aid kit"])},
  "facility.24hoursecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour Security"])},
  "facility.fireextinguisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire extinguisher"])},
  "facility.sprinklerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprinkler equipment"])},
  "facility.smokedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoke detector"])},
  "facility.carbonmonoxidedetector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon monoxide detector"])},
  "facility.cctvmonitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCTV monitor"])},
  "facility.aed-automatedexternaldefibrillator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AED"])},
  "facility-groups.transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation"])},
  "facility.freeairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport transfer （free of charge）"])},
  "facility.paidairportpick-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport transfer （charges apply）"])},
  "facility.valetservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxi service"])},
  "facility.reservationparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation parking space"])},
  "facility.freeparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free parking space（limited quantity）"])},
  "facility.paidparkingspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car park （charges apply）"])},
  "facility.bicyclerental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bicycle rental"])},
  "facility.charteredtour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charteredtour"])},
  "facility.traveladvisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel advisory"])},
  "facility.electricvehiclechargingstation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car power charging station"])},
  "facility.playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground"])},
  "facility.kids_meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child meal"])},
  "facility.kids_cutlery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child tableware"])},
  "facility.kids_paddling_pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool for Kids"])},
  "facility.ball_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground ball pool"])},
  "facility.slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide"])},
  "facility.kids_books_or_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books, DVDs, music for kids"])},
  "facility.family_tour_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family tour package"])},
  "facility.crib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crib"])},
  "facility.baby_tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baby bath tube"])},
  "facility.feeding_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle sterilizer"])},
  "facility.baby_stroller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroller"])},
  "facility.pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets"])},
  "facility.no_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pets allowed"])},
  "facility.pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets allowed"])},
  "facility.pets_allowed_in_the_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets allowed in the room"])},
  "facility.small_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small pets allowed"])},
  "facility.large_pets_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large pets allowed"])},
  "facility.pets_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets food"])},
  "facility.pets_snacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets snacks"])},
  "facility.pets_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets hotel"])},
  "facility-groups.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Features"])},
  "facility.roomsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very roomy"])},
  "facility.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openable windows"])},
  "facility.non-smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-smoking rooms"])},
  "facility.smoking_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking rooms"])},
  "facility.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tub"])},
  "facility.onlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming service"])},
  "facility.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shower"])},
  "facility.showerequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shower"])},
  "facility.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public shower"])},
  "facility.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed type"])},
  "facility.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big windows"])},
  "facility.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small windows"])},
  "facility.airlight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transom windows"])},
  "facility.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French windows"])},
  "facility.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
  "facility.king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double"])},
  "facility.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quad"])},
  "facility.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
  "facility.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twin"])},
  "facility.bunk_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunk bed"])},
  "facility.king_and_single_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Double & 1 Single Bed"])},
  "facility.king_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Double Size Bed & 2 Single Beds"])},
  "facility.four_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Single Beds"])},
  "facility.three_king_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Queen Size Beds"])},
  "facility.three_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Single Bed"])},
  "facility.king_bed_or_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Double/Queen or 2 Singles"])},
  "facility.double_king_bed_and_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Doubles/Queens & 1 Single"])},
  "facility.double_king_bed_and_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Doubles/Queens & 2 Singles"])},
  "facility.king_and_mattress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Double/Queen + Mattress"])},
  "facility.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Semi-Double"])},
  "facility.queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Semi-Double"])},
  "facility.double_bed_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twin(combined)"])},
  "facility-groups.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room facilities"])},
  "facility.officedeskschairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office desks & chairs"])},
  "facility.readinglight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Readinglight"])},
  "facility.sofa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofa"])},
  "facility.indoorslippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippers"])},
  "facility.roommagnetickeycard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room card"])},
  "facility.roomkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room key"])},
  "facility.airconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air conditioner"])},
  "facility.heater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
  "facility.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "facility-groups.bathroom_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathroom equipment"])},
  "facility.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washlet"])},
  "facility.singlebathtub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tub"])},
  "facility.jacuzzi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jacuzzi"])},
  "facility.dryandwetseparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate shower"])},
  "facility.largetowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large towel"])},
  "facility.smalltowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small towel"])},
  "facility.toothbrushtoothpaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toothbrush & toothpaste"])},
  "facility.hairdryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair dryer"])},
  "facility.bodywash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body wash"])},
  "facility.shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shampoo"])},
  "facility.hairconditioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair conditioner"])},
  "facility.facewash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face wash"])},
  "facility.razor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razor"])},
  "facility.soap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soap"])},
  "facility.showercap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shower cap"])},
  "facility.comb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comb"])},
  "facility.bathrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrobes"])},
  "facility.cottonswab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotton swab"])},
  "facility-groups.ent_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment devices"])},
  "facility.freeonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free streaming service"])},
  "facility.paidonlinevideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid streaming service"])},
  "facility.entertainmentequipmentrental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental sport & entertainment equipment "])},
  "facility.paidwi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Wi-Fi"])},
  "facility.freewi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi"])},
  "facility.paytv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid TV"])},
  "facility.freetv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free TV"])},
  "facility-groups.catering_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering equipment"])},
  "facility.kettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettle"])},
  "facility.freeteabags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free tea bags"])},
  "facility.freeinstantcoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free instant coffee"])},
  "facility.coffeemachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee maker"])},
  "facility.paidsnacanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid snack and drinks"])},
  "facility.freesnacksanddrinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free snack and drinks"])},
  "facility.24-hourroomservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour room service"])},
  "facility.refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerator"])},
  "facility.freebottlewater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Bottled water"])},
  "facility-groups.clothing_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothing equipment"])},
  "facility.laundrybag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry bag"])},
  "facility.wardrobe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wardrobe"])},
  "facility.ironingequipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ironing facilities"])},
  "facility.hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanger"])},
  "facility-groups.safety_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety equipment"])},
  "facility.smokedetector_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoke detector"])},
  "facility.securitybox_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe deposit box"])},
  "facility.fireextinguisher_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire extinguisher"])},
  "room_category.single_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Room"])},
  "room_category.double_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Room"])},
  "room_category.triple_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triple Room"])},
  "room_category.four_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quad Room"])},
  "room_category.five_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-person Room"])},
  "room_category.six_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6-person Room"])},
  "room_category.seven_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-person Room"])},
  "room_category.eight_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8-person Room"])},
  "room_category.nine_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-person Room"])},
  "room_category.ten_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-person Room"])},
  "room_category.bag_room_mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorm bed (Mix)"])},
  "room_category.bag_room_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorm bed (Female)"])},
  "room_category.bag_room_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorm bed (Male)"])},
  "room_category.all_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire House"])},
  "room_category.all_floor_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full floor"])},
  "room_category.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double bed"])},
  "room_category.queen_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queen size bed"])},
  "room_category.king_size_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["King size bed"])},
  "room_category.semi_double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-double bed"])},
  "room_category.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single bed"])},
  "room_category.super_single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super single bed"])},
  "room_category.toddler_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toddler bed"])},
  "room_category.baby_cribs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baby cribs"])},
  "facility.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room popular facilities"])},
  "facility.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very roomy"])},
  "facility.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi"])},
  "facility.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office desk"])},
  "facility.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-smoking rooms"])},
  "facility.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking rooms"])},
  "facility.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel popular service"])},
  "facility.24_hour_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour check-in"])},
  "facility.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laundry delivery service"])},
  "facility.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental entertainment equipment "])},
  "facility.rental_3_c_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental 3C accessories"])},
  "facility.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoke-free property"])},
  "filter.multi-ents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-purpose amenities"])},
  "filter.high-ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality inns"])},
  "filter.parent-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family inns"])},
  "filter.pet-friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet-friendly"])},
  "filter.double_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double bed"])},
  "filter.double_queen_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queen size bed"])},
  "filter.double_king_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["King size bed"])},
  "filter.single_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single bed"])},
  "filter.1km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 1 km"])},
  "filter.3km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 3 km"])},
  "filter.5km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 5 km"])},
  "filter.home_quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home quarantine"])},
  "filter.migrant_worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrant workers only"])},
  "filter.travel-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "tag.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very roomy"])},
  "tag.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
  "tag.openable_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openable windows"])},
  "tag.office_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office desk"])},
  "tag.smoke_free_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoke-free property"])},
  "tag.streaming_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming service"])},
  "tag.rental_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental entertainment equipment "])},
  "tag.rental_sport_entertainment_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental sport & entertainment equipment "])},
  "tag.24_hour_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour reception"])},
  "tag.washlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["washlet"])},
  "tag.food_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food package"])},
  "tag.package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
  "tag.laundry_delivery_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry delivery service"])},
  "tag.free_wi_fi_in_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi in all rooms"])},
  "tag.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi"])},
  "tag.24_hour_front_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour front desk"])},
  "tag.24_hour_room_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour room service"])},
  "homepage.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevention news"])},
  "homepage.prevention_grants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevention grants"])},
  "homepage.prevention_fa_qs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevention FAQs"])},
  "homepage.check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking check"])},
  "homepage.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No booking is found"])},
  "homepage.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan all city/county"])},
  "homepage.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keelung city"])},
  "homepage.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei city"])},
  "homepage.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Taipei city"])},
  "homepage.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan city"])},
  "homepage.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsinchu city"])},
  "homepage.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taichung city"])},
  "homepage.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tainan city"])},
  "homepage.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaohsiung city"])},
  "homepage.yilan_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yilan County"])},
  "homepage.hsinchu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsinchu County"])},
  "homepage.miaoli_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miaoli County"])},
  "homepage.changhua_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changhua County"])},
  "homepage.nantou_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantou County"])},
  "homepage.yunlin_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yunlin County"])},
  "homepage.chiayi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiayi City"])},
  "homepage.chiayi_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiayi County"])},
  "homepage.penghu_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penghu County"])},
  "homepage.pingtung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingtung County"])},
  "homepage.taitung_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taitung County"])},
  "homepage.hualien_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hualien County"])},
  "homepage.kinmen_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinmen County"])},
  "homepage.lienchiang_county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lienchiang County"])},
  "homepage.south_china_sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South China Sea Islands"])},
  "homepage.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " night"])},
  "homepage.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " adult"])},
  "homepage.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Children"])},
  "homepage.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " room"])},
  "homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "homepage.popular_quarantine_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Quarantine Hotels"])},
  "homepage.all_county_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All county(city)"])},
  "homepage.taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei"])},
  "homepage.new_taipei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Taipei"])},
  "homepage.taoyuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan"])},
  "homepage.hsinchu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsinchu"])},
  "homepage.taichung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taichung"])},
  "homepage.tainan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tainan"])},
  "homepage.kaohsiung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaohsiung"])},
  "homepage.from_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From / Night"])},
  "homepage.see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check more"])},
  "homepage.hot_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot searches"])},
  "homepage.very_roomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very roomy"])},
  "homepage.window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openable windows"])},
  "homepage.free_wi_fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Wi-Fi"])},
  "homepage.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tub"])},
  "homepage.prevention_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevention Info"])},
  "homepage.about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "homepage.cooperation_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperation proposal"])},
  "homepage.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "homepage.service_and_unsubscribe_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service and unsubscribe terms"])},
  "homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "homepage.customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service"])},
  "homepage.monday_to_friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday to Friday"])},
  "homepage.official_customer_service_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official customer service Line"])},
  "homepage.mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
  "homepage.customer_service_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service hours "])},
  "homepage.telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone"])},
  "homepage.tourbobo_travel_service_co_ltd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo Travel Service Co., LTD."])},
  "homepage.uniform_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniform numbers"])},
  "homepage.class_a_travel_agencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class-A travel agencies"])},
  "homepage.tourism_a_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tourism A No. ", _interpolate(_named("n"))])},
  "homepage.tqaa_memberships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQAA memberships"])},
  "homepage.north_no_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["North No. ", _interpolate(_named("n"))])},
  "homepage.social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])},
  "homepage.enter_a_destination_or_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a destination or property"])},
  "homepage.select_the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the date"])},
  "homepage.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "homepage.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
  "homepage.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We"])},
  "homepage.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Th"])},
  "homepage.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
  "homepage.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
  "homepage.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "homepage.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "homepage.please_select_the_number_of_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the number of people"])},
  "homepage.adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
  "homepage.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
  "homepage.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["room"])},
  "homepage.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the quarantine rules, the principle for you to check in an quarantine hotel shall be one person per room.  If you need to live with the other person to take care of him/ her, please follow the government's regulations."])},
  "homepage.please_select_the_age_of_the_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the age of the child"])},
  "homepage.years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old"])},
  "homepage.hot_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot topics"])},
  "homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My booking"])},
  "homepage.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "homepage.please_enter_your_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your e-mail"])},
  "homepage.please_enter_a_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
  "homepage.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booking ID"])},
  "homepage.please_enter_your_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your booking ID"])},
  "homepage.please_enter_a_valid_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid booking ID"])},
  "homepage.inquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquire"])},
  "homepage.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "homepage.manage_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Bookings"])},
  "homepage.destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
  "homepage.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in date"])},
  "homepage.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out date"])},
  "homepage.guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
  "homepage.please_enter_a_destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a destination"])},
  "homepage.n_people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " people"])},
  "homepage.setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting "])},
  "homepage.please_pick_your_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick your language"])},
  "homepage.the_quarantine_protocol_accepts_only_one_person_in_one_room_in_principle_n_please_contact_the_customer_service_for_the_extra_person_rules_and_the_person_added_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quarantine protocol accepts only one person in one room in principle.\nPlease contact the customer service for the extra-person rules and the person-added price if needed."])},
  "homepage.n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " years old"])},
  "homepage.n_from_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " From / Night"])},
  "homepage.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid’s age"])},
  "homepage.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved room countdown"])},
  "homepage.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel type"])},
  "homepage.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nation"])},
  "homepage.common_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common hotels search"])},
  "homepage.quarantine_hotels_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine hotels search "])},
  "homepage.unique_hotels_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique hotels / inns"])},
  "homepage.nantou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantou"])},
  "homepage.yilan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yilan"])},
  "homepage.hualien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hualien"])},
  "homepage.taitung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taitung"])},
  "homepage.pingtung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingtung"])},
  "homepage.normal_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common hotels"])},
  "homepage.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine hotels"])},
  "homepage.self_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal health management"])},
  "homepage.multi_purpose_amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-purpose amenities"])},
  "homepage.quality_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality inns"])},
  "homepage.family_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family inns"])},
  "homepage.pet_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet-friendly "])},
  "homepage.hot_city_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot city in Taiwan "])},
  "homepage.recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
  "homepage.datepicker_hint_for_quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quarantine hotel booking system will pick the ruled days for a quarantine stay since the COVID-19 prevention policy accepts no early check-outs in any case."])},
  "homepage.tokyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokyo"])},
  "homepage.osaka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaka"])},
  "homepage.kyoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyoto"])},
  "homepage.nara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nara"])},
  "homepage.hokkaido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hokkaido"])},
  "homepage.kyushu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyushu"])},
  "homepage.switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])},
  "homepage.hotel_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel type"])},
  "homepage.choose_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose currency"])},
  "homepage.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "homepage.choose_type_and_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date"])},
  "homepage.about_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Tourbobo"])},
  "homepage.new_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New activities"])},
  "homepage.tourbobo_accompanies_you_to_enjoy_each_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourbobo accompanies you to enjoy each journey. "])},
  "homepage.starting_from_taiwan_tourbobo_is_dedicated_to_forging_a_simple_and_useful_booking_platform_we_not_only_gather_and_collect_quarantine_hotels_in_entire_taiwan_but_provide_profuse_common_hotels_we_expect_to_enter_the_asian_market_in_the_future_to_link_up_to_the_world_and_give_our_assistance_so_that_every_guest_can_travel_happily_and_go_home_feeling_carefree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from Taiwan, Tourbobo is dedicated to forging a simple and useful booking platform.  We not only gather and collect quarantine hotels in entire Taiwan, but provide profuse common hotels.  We expect to enter the Asian market in the future to link up to the world, and give our assistance, so that every guest can travel happily and go home feeling carefree. "])},
  "homepage.in_tourbobo_you_will_enjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Tourbobo, you will enjoy: "])},
  "homepage.service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Personal customer service, counseling, and assistance.\n* Legally compliant and carefree hotels. \n* Simple and intuitive booking process. \n* Open and transparent price, and preferential treatment from time to time. \n* Selecting popular facilities, and finding an ideal accommodation. "])},
  "homepage.tourbobo_makes_a_journey_easy_relaxed_with_personal_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourbobo makes a journey easy, relaxed, with personal service.  "])},
  "homepage.if_your_case_is_of_personal_health_management_only_by_completely_staying_for_the_number_of_days_stipulated_by_the_quarantine_policy_can_you_check_out_the_system_will_select_the_stipulated_number_of_days_automatically_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your case is of personal health management, only by completely staying for the number of days stipulated by the quarantine policy can you check out.  The system will select the stipulated number of days automatically for you!  "])},
  "homepage.if_your_case_is_of_personal_health_management_it_will_be_one_person_per_room_if_you_have_any_demands_of_living_together_to_take_care_of_the_other_person_for_the_relevant_rules_please_follow_the_policy_issued_by_the_government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your case is of personal health management, it will be one person per room.  If you have any demands of living together to take care of the other person , for the relevant rules please follow the policy issued by the government.  "])},
  "homepage.to_fill_up_the_personal_data_completely_sooner_will_it_be_for_your_booking_next_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To fill up the personal data completely, sooner will it be for your booking next time!"])},
  "homepage.fill_up_immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill up immediately"])},
  "homepage.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
  "homepage.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "homepage.this_is_the_email_address_of_which_you_use_to_login_we_will_also_send_a_reservation_confirmation_letter_to_this_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the email address of which you use to login.  We will also send a reservation confirmation letter to this email address. "])},
  "homepage.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "homepage.keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
  "homepage.booking_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booking management"])},
  "homepage.account_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account management"])},
  "homepage.personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])},
  "homepage.login_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login method"])},
  "homepage.account_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account security"])},
  "homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "homepage.please_login_immediately_to_search_good_room_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login immediately to search good room rate"])},
  "homepage.login_failed_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed.  Please try again"])},
  "homepage.a_password_should_contain_8_to_12_characters_including_english_letters_and_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password should contain 8 to 12 characters, including English letters and numbers"])},
  "homepage.stay_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay logged in"])},
  "homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget the password?"])},
  "homepage.or_use_the_social_media_account_to_login_quickly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or use the social media account to login quickly"])},
  "homepage.you_must_accept_google_s_requests_so_as_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept Google's requests so as to login"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_tourbobo_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you click to login or register, it means that you agree Tourbobo's ", _interpolate(_named("n")), " and ", _interpolate(_named("m"))])},
  "homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "homepage.join_tourbobo_to_find_an_exclusive_preferential_room_rate_for_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Tourbobo to find an exclusive preferential room rate for you"])},
  "homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The two passwords you entered are inconsistent.  Please try again"])},
  "homepage.linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linking"])},
  "homepage.cancel_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel linking"])},
  "homepage.email_address_linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address linking"])},
  "homepage.set_the_email_address_and_password_for_you_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the email address and password for you to login"])},
  "homepage.verify_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify email address"])},
  "homepage.to_ensure_account_security_we_have_sent_a_verification_letter_to_your_email_address_please_follow_the_instructions_within_to_verify_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure account security, we have sent a verification letter to your email address.  Please follow the instructions within to verify your email address"])},
  "homepage.the_verification_letter_has_been_sent_to_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The verification letter has been sent to ", _interpolate(_named("n"))])},
  "homepage.resending_available_after_{n}_seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resending available after ", _interpolate(_named("n")), " seconds"])},
  "homepage.resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
  "homepage.broken_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broken link"])},
  "homepage.link_has_expired_or_been_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link has expired or been used"])},
  "homepage.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])},
  "homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the password"])},
  "homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "homepage.go_to_tourbobo_account_to_set_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Tourbobo account to set the password"])},
  "homepage.delete_the_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the account"])},
  "homepage.delete_your_tourbobo_account_and_personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your Tourbobo account and personal data"])},
  "homepage.join_the_membership_for_free_and_receive_the_preferential_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the membership for free, and receive the preferential code"])},
  "homepage.hot_cities_in_japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot cities in Japan"])},
  "homepage.selected_inns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected inns"])},
  "homepage.language_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language settings"])},
  "homepage.select_the_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the currency"])},
  "homepage.practical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical information"])},
  "homepage.my_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My bookings"])},
  "homepage.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal management"])},
  "homepage.never_heard_of_this_place_change_a_word_and_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never heard of this place.  Change a word, and try again!"])},
  "homepage.enter_district_and_name_of_the_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter district and name of the accommodation"])},
  "homepage.activity_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity information"])},
  "homepage.{price}_and_above": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " and above"])},
  "homepage.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "homepage.booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
  "homepage.recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured recommended"])},
  "homepage.popular_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popularity accommodation"])},
  "homepage.travel_info_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmissable travel information"])},
  "homepage.spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot focus"])},
  "homepage.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation discount"])},
  "homepage.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot accommodation "])},
  "homepage.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasure island featured"])},
  "homepage.if_you_click_to_login_or_register_it_means_that_you_agree_reelax_s_{n}_and_{m}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you click to login or register, it means that you agree Reelax's ", _interpolate(_named("n")), " and ", _interpolate(_named("m"))])},
  "search.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "search.taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
  "search.keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keelung city"])},
  "search.taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei city"])},
  "search.new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Taipei city"])},
  "search.taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan city"])},
  "search.hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsinchu city"])},
  "search.taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taichung city"])},
  "search.tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tainan city"])},
  "search.kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaohsiung city"])},
  "search.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "search.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View map"])},
  "search.filter_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter search"])},
  "search.per_night_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per night"])},
  "search.sorting.recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend"])},
  "search.sorting.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest price"])},
  "search.sorting.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topest rated"])},
  "search.sorting.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topest price"])},
  "search.n_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " reviews"])},
  "search.view_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View room type"])},
  "search.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "search.other_recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other recommendations "])},
  "search.room_popular_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room popular facilities"])},
  "search.hotel_popular_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel popular service"])},
  "search.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "search.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "search.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "search.map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "search.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
  "search.sorting_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
  "search.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "search.hotel_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel services"])},
  "search.room_facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room facilities"])},
  "search.is_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random ranking"])},
  "search.lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
  "search.lng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
  "search.lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest room price"])},
  "search.highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest room price"])},
  "search.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "search.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "search.hotel_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hotel specified is found"])},
  "search.data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No info material is found"])},
  "search.unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized operation "])},
  "search.hot_room_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot room search"])},
  "search.hotel_hot_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel hot services"])},
  "search.personal_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal management"])},
  "search.quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine"])},
  "search.the_hotel_has_been_removed_and_cannot_be_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hotel has been removed, and cannot be found!"])},
  "search.find_other_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find other hotels"])},
  "search.personal_health_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal health management"])},
  "search.national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅補助"])},
  "search.national_travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悠遊國旅"])},
  "search.national_travel_subsidy_type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅宿適用補助1,300"])},
  "search.national_travel_subsidy_type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打滿3劑最高補助1,300"])},
  "search.popular_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot search"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
  "keelung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keelung city"])},
  "taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipei city"])},
  "new_taipei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Taipei city"])},
  "taoyuan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan city"])},
  "hsinchu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsinchu city"])},
  "taichung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taichung city"])},
  "tainan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tainan city"])},
  "kaohsiung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaohsiung city"])},
  "detail.view_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view map"])},
  "detail.more_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more photos"])},
  "detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the hotel"])},
  "detail.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show more"])},
  "detail.highlights_of_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation Highlights"])},
  "detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room type"])},
  "detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Basic number of guest(s): ", _interpolate(_named("n"))])},
  "detail.extra_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Number of extra guest(s) available: ", _interpolate(_named("n"))])},
  "detail.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prevention rules accept only one person in one room.\nAny person in need of extra person in one room must receive the permit of the local government’s health competent authority."])},
  "detail.extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra beds"])},
  "detail.no_extra_beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No extra beds"])},
  "detail.room_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Features"])},
  "detail.n_m_ft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " m²"])},
  "detail.non_smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-smoking rooms"])},
  "detail.smoking_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking rooms"])},
  "detail.tub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tub"])},
  "detail.shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shower"])},
  "detail.public_shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public shower"])},
  "detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed type"])},
  "detail.big_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big windows"])},
  "detail.small_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small windows"])},
  "detail.airtight_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airtight windows"])},
  "detail.french_windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French windows"])},
  "detail.balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
  "detail.more_info_on_room_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See room details"])},
  "detail.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home quarantine plan"])},
  "detail.price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per night"])},
  "detail.book_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
  "detail.facilities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities and services"])},
  "detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking notices"])},
  "detail.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in time"])},
  "detail.check_out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out time"])},
  "detail.extra_capacity_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra guest rules"])},
  "detail.the_extra_charges_on_extra_minors_and_adults_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The extra charges on extra minors and adults are:"])},
  "detail.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
  "detail.minor_under_the_age_of_5_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ~ 5 years old"])},
  "detail.infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infants"])},
  "detail.when_no_need_an_extra_be_they_can_stay_with_the_adult_visitor_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When no need an extra be, they can stay with the adult visitor free."])},
  "detail.6_12_years_old_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 ~ 12 years old"])},
  "detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
  "detail.nt_n_charged_if_no_extra_bed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["NT$ ", _interpolate(_named("n")), " charged if no extra bed"])},
  "detail.13_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13+ (included)"])},
  "detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adults"])},
  "detail.room_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room notices"])},
  "detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel notices"])},
  "detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation rules"])},
  "detail.guest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest reviews"])},
  "detail.n_points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " points"])},
  "detail.n_comments_in_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " reviews"])},
  "detail.check_all_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all reviews"])},
  "detail.hotel_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel services"])},
  "detail.entertaining_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertaining facilities"])},
  "detail.room_facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room facilities"])},
  "detail.more_info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info on the hotel"])},
  "detail.info_on_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info on the hotel"])},
  "detail.info_on_the_room_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info on the room model"])},
  "detail.n_after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_before": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
  "detail.n_years_old_and_minus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " years old and minus"])},
  "detail.m_n_years_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "-", _interpolate(_named("n")), " years old "])},
  "detail.n_years_old_and_plus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " years old and plus"])},
  "detail.please_contact_the_customer_service_for_the_extra_person_rules_and_room_price_if_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the customer service for the extra-person rules and room price if needed"])},
  "detail.the_room_price_per_night_with_no_bed_added_is_nt_count_per_head": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The room price per night with no bed added is NT$ ", _interpolate(_named("count")), " per head"])},
  "detail.show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show more"])},
  "detail.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show less"])},
  "detail.latest_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest reviews"])},
  "detail.highest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest score"])},
  "detail.lowest_ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest score"])},
  "detail.date_review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " review"])},
  "detail.from_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["from ", _interpolate(_named("source"))])},
  "detail.hotel_s_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel’s reply"])},
  "detail.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "detail.source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering the source"])},
  "area.zhongzheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhongzheng Dist"])},
  "area.datong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datong Dist"])},
  "area.zhongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhongshan Dist"])},
  "area.songshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songshan Dist"])},
  "area.da_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da’an Dist"])},
  "area.wanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanhua Dist"])},
  "area.xinyi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinyi Dist"])},
  "area.shilin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shilin Dist"])},
  "area.beitou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitou Dist"])},
  "area.neihu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neihu Dist"])},
  "area.nangang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nangang Dist"])},
  "area.wenshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenshan Dist"])},
  "area.ren_ai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ren’ai Dist"])},
  "area.anle_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anle Dist"])},
  "area.nuannuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuannuan Dist"])},
  "area.qidu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qidu Dist"])},
  "area.wanli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanli Dist"])},
  "area.jinshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinshan Dist"])},
  "area.nangan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nangan Township"])},
  "area.beigan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigan Township"])},
  "area.juguang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juguang Township"])},
  "area.dongyin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongyin Township"])},
  "area.banqiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banqiao Dist"])},
  "area.xizhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xizhi Dist"])},
  "area.shenkeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shenkeng Dist"])},
  "area.shiding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shiding Dist"])},
  "area.ruifang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruifang Dist"])},
  "area.pingxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingxi Dist"])},
  "area.shuangxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuangxi Dist"])},
  "area.gongliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gongliao Dist"])},
  "area.xindian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xindian Dist"])},
  "area.pinglin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinglin Dist"])},
  "area.wulai_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wulai Dist"])},
  "area.yonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yonghe Dist"])},
  "area.zhonghe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhonghe Dist"])},
  "area.tucheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tucheng Dist"])},
  "area.sanxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanxia Dist"])},
  "area.shulin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shulin Dist"])},
  "area.yingge_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yingge Dist"])},
  "area.sanchong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanchong Dist"])},
  "area.xinzhuang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinzhuang Dist"])},
  "area.taishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taishan Dist"])},
  "area.linkou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkou Dist"])},
  "area.luzhou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luzhou Dist"])},
  "area.wugu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wugu Dist"])},
  "area.bali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bali Dist"])},
  "area.tamsui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamsui Dist"])},
  "area.sanzhi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanzhi Dist"])},
  "area.shimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shimen Dist"])},
  "area.yilan_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yilan City"])},
  "area.toucheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toucheng Township"])},
  "area.jiaoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiaoxi Township"])},
  "area.zhuangwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhuangwei Township"])},
  "area.yuanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuanshan Township"])},
  "area.luodong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luodong Township"])},
  "area.sanxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanxing Township"])},
  "area.datong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datong Township"])},
  "area.wujie_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wujie Township"])},
  "area.dongshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongshan Township"])},
  "area.su_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su’ao Township"])},
  "area.nan_ao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nan’ao Township"])},
  "area.diaoyutai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diaoyutai"])},
  "area.east_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East Dist"])},
  "area.zhubei_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhubei City"])},
  "area.hukou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hukou Township"])},
  "area.xinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinfeng Township"])},
  "area.xinpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinpu Township"])},
  "area.guanxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guanxi Township"])},
  "area.qionglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qionglin Township"])},
  "area.baoshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baoshan Township"])},
  "area.zhudong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhudong Township"])},
  "area.wufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wufeng Township"])},
  "area.hengshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hengshan Township"])},
  "area.jianshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jianshi Township"])},
  "area.beipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beipu Township"])},
  "area.emei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emei Township"])},
  "area.zhongli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhongli Dist"])},
  "area.pingzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingzhen Dist"])},
  "area.longtan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longtan Dist"])},
  "area.yangmei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangmei Dist"])},
  "area.xinwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinwu Dist"])},
  "area.guanyin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guanyin Dist"])},
  "area.taoyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan Dist"])},
  "area.guishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guishan Dist"])},
  "area.bade_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bade Dist"])},
  "area.daxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxi Dist"])},
  "area.fuxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuxing Dist"])},
  "area.dayuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dayuan Dist"])},
  "area.luzhu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luzhu Dist"])},
  "area.zhunan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhunan Township"])},
  "area.toufen_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toufen City"])},
  "area.sanwan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanwan Township"])},
  "area.nanzhuang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nanzhuang Township"])},
  "area.shitan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shitan Township"])},
  "area.houlong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houlong Township"])},
  "area.tongxiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tongxiao Township"])},
  "area.yuanli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuanli Township"])},
  "area.miaoli_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miaoli City"])},
  "area.zaoqiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaoqiao Township"])},
  "area.touwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touwu Township"])},
  "area.gongguan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gongguan Township"])},
  "area.dahu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahu Township"])},
  "area.tai_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tai’an Township"])},
  "area.tongluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tongluo Township"])},
  "area.sanyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanyi Township"])},
  "area.xihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xihu Township"])},
  "area.zhuolan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhuolan Township"])},
  "area.central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Dist"])},
  "area.south_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Dist"])},
  "area.west_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West Dist"])},
  "area.north_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Dist"])},
  "area.beitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitun Dist"])},
  "area.xitun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xitun Dist"])},
  "area.nantun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantun Dist"])},
  "area.taiping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiping Dist"])},
  "area.dali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dali Dist"])},
  "area.wufeng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wufeng Dist"])},
  "area.wuri_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wuri Dist"])},
  "area.fengyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fengyuan Dist"])},
  "area.houli_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houli Dist"])},
  "area.shigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shigang Dist"])},
  "area.dongshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongshi Dist"])},
  "area.heping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heping Dist"])},
  "area.xinshe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinshe Dist"])},
  "area.tanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzi Dist"])},
  "area.daya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daya Dist"])},
  "area.shengang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shengang Dist"])},
  "area.dadu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadu Dist"])},
  "area.shalu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shalu Dist"])},
  "area.longjing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longjing Dist"])},
  "area.wuqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wuqi Dist"])},
  "area.qingshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qingshui Dist"])},
  "area.dajia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dajia Dist"])},
  "area.waipu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waipu Dist"])},
  "area.changhua_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changhua City"])},
  "area.fenyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenyuan Township"])},
  "area.huatan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huatan Township"])},
  "area.xiushui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiushui Township"])},
  "area.lukang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukang Township"])},
  "area.fuxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuxing Township"])},
  "area.xianxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xianxi Township"])},
  "area.hemei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemei Township"])},
  "area.shengang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shengang Township"])},
  "area.yuanlin_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuanlin City"])},
  "area.shetou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shetou Township"])},
  "area.yongjing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yongjing Township"])},
  "area.puxin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puxin Township"])},
  "area.dacun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacun Township"])},
  "area.puyan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puyan Township"])},
  "area.tianzhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tianzhong Township"])},
  "area.beidou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beidou Township"])},
  "area.tianwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tianwei Township"])},
  "area.pitou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitou Township"])},
  "area.xizhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xizhou Township"])},
  "area.zhutang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhutang Township"])},
  "area.erlin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlin Township"])},
  "area.dacheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacheng Township"])},
  "area.fangyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fangyuan Township"])},
  "area.ershui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ershui Township"])},
  "area.nantou_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantou City"])},
  "area.zhongliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhongliao Township"])},
  "area.caotun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caotun Township"])},
  "area.guoxing_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guoxing Township"])},
  "area.puli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puli Township"])},
  "area.ren_ai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ren’ai Township"])},
  "area.mingjian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mingjian Township"])},
  "area.jiji_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiji Township"])},
  "area.shuili_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuili Township"])},
  "area.yuchi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuchi Township"])},
  "area.xinyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinyi Township"])},
  "area.zhushan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhushan Township"])},
  "area.lugu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugu Township"])},
  "area.fanlu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fanlu Township"])},
  "area.meishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meishan Township"])},
  "area.zhuqi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhuqi Township"])},
  "area.alishan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alishan Township"])},
  "area.zhongpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhongpu Township"])},
  "area.dapu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapu Township"])},
  "area.shuishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuishang Township"])},
  "area.lucao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucao Township"])},
  "area.taibao_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taibao City"])},
  "area.puzi_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puzi City"])},
  "area.dongshi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongshi Township"])},
  "area.liujiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liujiao Township"])},
  "area.xingang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xingang Township"])},
  "area.minxiong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minxiong Township"])},
  "area.dalin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalin Township"])},
  "area.xikou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xikou Township"])},
  "area.yizhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yizhu Township"])},
  "area.budai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budai Township"])},
  "area.dounan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dounan Township"])},
  "area.dapi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapi Township"])},
  "area.huwei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huwei Township"])},
  "area.tuku_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuku Township"])},
  "area.baozhong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baozhong Township"])},
  "area.taixi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taixi Township"])},
  "area.lunbei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunbei Township"])},
  "area.mailiao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailiao Township"])},
  "area.douliu_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Douliu City"])},
  "area.linnei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linnei Township"])},
  "area.gukeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gukeng Township"])},
  "area.citong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citong Township"])},
  "area.xiluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiluo Township"])},
  "area.erlun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlun Township"])},
  "area.beigang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigang Township"])},
  "area.shuilin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shuilin Township"])},
  "area.kouhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouhu Township"])},
  "area.sihu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihu Township"])},
  "area.yuanzhang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuanzhang Township"])},
  "area.west_central_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West Central Dist"])},
  "area.anping_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anping Dist"])},
  "area.annan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annan Dist"])},
  "area.yongkang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yongkang Dist"])},
  "area.guiren_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiren Dist"])},
  "area.xinhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinhua Dist"])},
  "area.zuozhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuozhen Dist"])},
  "area.yujing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yujing Dist"])},
  "area.nanxi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nanxi Dist"])},
  "area.nanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nanhua Dist"])},
  "area.rende_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rende Dist"])},
  "area.guanmiao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guanmiao Dist"])},
  "area.longqi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longqi Dist"])},
  "area.guantian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guantian Dist"])},
  "area.madou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madou Dist"])},
  "area.jiali_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiali Dist"])},
  "area.xigang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xigang Dist"])},
  "area.qigu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qigu Dist"])},
  "area.jiangjun_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiangjun Dist"])},
  "area.xuejia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuejia Dist"])},
  "area.beimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beimen Dist"])},
  "area.xinying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinying Dist"])},
  "area.houbi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houbi Dist"])},
  "area.baihe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baihe Dist"])},
  "area.dongshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongshan Dist"])},
  "area.liujia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liujia Dist"])},
  "area.xiaying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiaying Dist"])},
  "area.liuying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liuying Dist"])},
  "area.yanshui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanshui Dist"])},
  "area.shanhua_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanhua Dist"])},
  "area.danei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danei Dist"])},
  "area.shanshang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanshang Dist"])},
  "area.xinshi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinshi Dist"])},
  "area.anding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anding Dist"])},
  "area.xinxing_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinxing Dist"])},
  "area.qianjin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qianjin Dist"])},
  "area.lingya_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingya Dist"])},
  "area.yancheng_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yancheng Dist"])},
  "area.gushan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gushan Dist"])},
  "area.qijin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qijin Dist"])},
  "area.qianzhen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qianzhen Dist"])},
  "area.sanmin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanmin Dist"])},
  "area.nanzi_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nanzi Dist"])},
  "area.xiaogang_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiaogang Dist"])},
  "area.zuoying_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuoying Dist"])},
  "area.renwu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renwu Dist"])},
  "area.dashe_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashe Dist"])},
  "area.dongsha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongsha Islands"])},
  "area.nansha_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nansha Islands"])},
  "area.gangshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangshan Dist"])},
  "area.alian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alian Dist"])},
  "area.tianliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tianliao Dist"])},
  "area.yanchao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanchao Dist"])},
  "area.qiaotou_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qiaotou Dist"])},
  "area.ziguan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziguan Dist"])},
  "area.mituo_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mituo Dist"])},
  "area.yong_an_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yong’an Dist"])},
  "area.hunei_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunei Dist"])},
  "area.fengshan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fengshan Dist"])},
  "area.daliao_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daliao Dist"])},
  "area.linyuan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linyuan Dist"])},
  "area.niaosong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niaosong Dist"])},
  "area.dashu_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashu Dist"])},
  "area.qishan_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qishan Dist"])},
  "area.meinong_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meinong Dist"])},
  "area.liugui_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liugui Dist"])},
  "area.neimen_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neimen Dist"])},
  "area.shanlin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanlin Dist"])},
  "area.jiaxian_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiaxian Dist"])},
  "area.namaxia_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namaxia Dist"])},
  "area.maolin_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maolin Dist"])},
  "area.qieding_dist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qieding Dist"])},
  "area.magong_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magong City"])},
  "area.xiyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiyu Township"])},
  "area.wang_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wang’an Township"])},
  "area.qimei_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qimei Township"])},
  "area.baisha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baisha Township"])},
  "area.huxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huxi Township"])},
  "area.jinsha_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinsha Township"])},
  "area.jinhu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinhu Township"])},
  "area.jinning_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinning Township"])},
  "area.jincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jincheng Township"])},
  "area.lieyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieyu Township"])},
  "area.wuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wuqiu Township"])},
  "area.pingtung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pingtung City"])},
  "area.sandimen_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandimen Township"])},
  "area.wutai_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wutai Township"])},
  "area.majia_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majia Township"])},
  "area.jiuru_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiuru Township"])},
  "area.ligang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligang Township"])},
  "area.gaoshu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaoshu Township"])},
  "area.yanpu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanpu Township"])},
  "area.changzhi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changzhi Township"])},
  "area.linluo_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linluo Township"])},
  "area.zhutian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhutian Township"])},
  "area.neipu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neipu Township"])},
  "area.wandan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wandan Township"])},
  "area.chaozhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaozhou Township"])},
  "area.taiwu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwu Township"])},
  "area.laiyi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laiyi Township"])},
  "area.wanluan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanluan Township"])},
  "area.kanding_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanding Township"])},
  "area.xinpi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinpi Township"])},
  "area.nanzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nanzhou Township"])},
  "area.linbian_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linbian Township"])},
  "area.donggang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donggang Township"])},
  "area.liuqiu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liuqiu Township"])},
  "area.jiadong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiadong Township"])},
  "area.xinyuan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xinyuan Township"])},
  "area.fangliao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fangliao Township"])},
  "area.fangshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fangshan Township"])},
  "area.chunri_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chunri Township"])},
  "area.shizi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shizi Township"])},
  "area.checheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checheng Township"])},
  "area.mudan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudan Township"])},
  "area.hengchun_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hengchun Township"])},
  "area.manzhou_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzhou Township"])},
  "area.taitung_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taitung City"])},
  "area.ludao_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludao Township"])},
  "area.lanyu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanyu Township"])},
  "area.yanping_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanping Township"])},
  "area.beinan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beinan Township"])},
  "area.luye_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luye Township"])},
  "area.guanshan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guanshan Township"])},
  "area.haiduan_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiduan Township"])},
  "area.chishang_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chishang Township"])},
  "area.donghe_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donghe Township"])},
  "area.chenggong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chenggong Township"])},
  "area.changbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changbin Township"])},
  "area.taimali_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taimali Township"])},
  "area.jinfeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinfeng Township"])},
  "area.dawu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawu Township"])},
  "area.daren_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daren Township"])},
  "area.hualien_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hualien City"])},
  "area.xincheng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xincheng Township"])},
  "area.xiulin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiulin Township"])},
  "area.ji_an_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ji’an Township"])},
  "area.shoufeng_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoufeng Township"])},
  "area.fenglin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenglin Township"])},
  "area.guangfu_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guangfu Township"])},
  "area.fengbin_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fengbin Township"])},
  "area.ruisui_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruisui Township"])},
  "area.wanrong_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanrong Township"])},
  "area.yuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuli Township"])},
  "area.zhuoxi_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhuoxi Township"])},
  "area.fuli_township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuli Township"])},
  "area.naniwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naniwa Dist"])},
  "area.doun_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daun Penh"])},
  "homepage.phnom_penh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cancellation_and_cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cancellation rules shall be subject to the cancellation refund policy set by a hotel operator for each room type and project.  If there is no such policy, please refer to our terms of Service and Cancellation Refund Policy."])},
  "detail.the_cancellation_rules_shall_be_subject_to_the_cancellation_refund_policy_set_by_a_hotel_operator_for_each_room_type_and_project_if_there_is_no_such_policy_please_refer_to_n_of_our_terms_of_service_and_cancellation_refund_policy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The cancellation rules shall be subject to the cancellation refund policy set by a hotel operator for each room type and project.  If there is no such policy, please refer to ", _interpolate(_named("n")), " of our terms of Service and Cancellation Refund Policy."])},
  "detail.cancellation_refund_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Refund Rules"])},
  "detail.quarantine_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine"])},
  "hotel.this_hotel_have_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此旅宿適用悠遊國旅補助，請詳閱說明！"])},
  "hotel.this_hotel_no_national_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週五、週六、連續假期不適用國旅補助，請詳閱說明！"])},
  "hotel.lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "hotel.national_travel_subsidy_directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國旅補助說明"])},
  "hotel.national_travel_subsidy_directions_for_tourbobo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tourbobo 悠遊國旅補助說明："])},
  "hotel.national_travel_subsidy_during_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動期間"])},
  "hotel.national_travel_subsidy_during_activity_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022年7月15日 至 2022年12月15日，適用於平日（週日至週四），活動至經費預算用罄為止。\n不適用日期：週五、週六、中秋節連續假期（2022年9月9日至9月11日）、國慶日連續假期（2022年10月8日至10月10日）不適用。"])},
  "hotel.national_travel_subsidy_promotion_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠方式"])},
  "hotel.national_travel_subsidy_promotion_method_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本國籍自由行旅客，若入住全台灣縣市有參加本活動且領有營業執照或旅宿業登記證之旅宿業，每房可享折抵 800 元。另使用優惠旅客本人完整接種三劑疫苗，或符合入住星級旅館、好客民宿、自行車友善旅宿、取得環保標章或溫泉標章之旅宿業，再加碼折抵 500 元，最高每房每晚可折抵 1,300 元！"])},
  "hotel.national_travel_subsidy_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方法"])},
  "hotel.national_travel_subsidy_use_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 於 tourbobo 平台選擇標註「悠遊國旅」文字的旅宿，且依照補助活動期間預訂住宿。於訂房流程中，悠遊國旅補助欄位選擇「使用補助」，旅客自行確認補助資格並勾選同意免責聲明。入住當天告知旅宿業者欲申請國旅補助，查核身份符合後，於旅宿現場給予折扣。\n\n2. 請於入住前或入住當天至「悠遊國旅個別旅客住宿優惠活動」網頁專區，掃描並上傳身分證或健保卡（任一）正面圖檔，建立旅客資料檔案（健保卡無照片亦可），且入住時將您身分證或健保卡正本（任一）提供給旅宿業者核對是否本人入住。\n\n3. 若您勾選「已完整接種三劑」：請於入住前或入住當日至「悠遊國旅個別旅客住宿優惠活動」網頁專區掃描並上傳完整接種3劑疫苗證明，並於入住時出示證明供旅宿查核。接種3劑疫苗之證明以衛生福利部或中央流行疫情指揮中心認定之辨別方式為準，如紙本疫苗接種卡（俗稱小黃卡）、健保快易通/健康存摺APP、數位新冠病毒健康證明等。"])},
  "hotel.national_travel_subsidy_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意事項"])},
  "hotel.national_travel_subsidy_notice_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 每筆訂單限一位入住旅客申請補助，每一身分證字號限使用一次補助，同時使用旅遊補助與接種三劑加碼補助需為同一入住旅客。\n\n2. 入住時由旅宿做補助資格查核，若資格不符，您同意於旅宿現場支付全額，且付款完成後才能入住，否則旅宿業者得拒絕您入住，且您不得請求免費取消或延期入住。\n\n3. 若您沒有勾選「已完整接種三劑」，提交訂單後，您將無法再更改選項，也無法要求旅宿業者或 tourbobo 提供加碼補助折抵。\n\n4. 悠遊國旅補助相關活動辦法，依照政府公告為準，倘若因政府補助經費用完而不再受理申請，或因悠遊國旅補助辦法異動，您同意旅宿業者或 tourbobo 可能因此變更您的訂單，包含但不限於：由旅客支付訂單全額、更改入住日期...等。\n\n5. 因知悉政府補助經費已用完，您於入住前想要取消訂房時，您同意依著個別旅客訂房定型化契約之規定辦理。"])},
  "hotel.clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "order.my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "order.personal_profile_for_quarantine_lodging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal profile for lodging"])},
  "order.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking info"])},
  "order.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in date"])},
  "order.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out date"])},
  "order.n_total_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total stay"])},
  "order.n_night": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " night"])},
  "order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total room"])},
  "order.n_room": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " room"])},
  "order.total_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total guests"])},
  "order.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " adults，", _interpolate(_named("n")), " children"])},
  "order.home_quarantine_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home quarantine plan"])},
  "order.daily_three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily three meals included"])},
  "order.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
  "order.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "order.discount_by_the_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount by the code"])},
  "order.plan_with_three_meals_no_extra_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan with three meals (no extra bed)"])},
  "order.early_check_in_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early check-in plan"])},
  "order.hotel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel ID"])},
  "order.room_type_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room ID"])},
  "order.plan_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan ID"])},
  "order.extra_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult added"])},
  "order.extra_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid added"])},
  "order.child_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid’s age"])},
  "order.check_out_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out payment"])},
  "order.basic_information_of_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information of the visitor"])},
  "order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your last name"])},
  "order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "order.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your first name"])},
  "order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your nationality"])},
  "order.id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card No./ Passport No."])},
  "order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your ID No."])},
  "order.enter_valid_id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid ID Card No. "])},
  "order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Passport No."])},
  "order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid Passport No."])},
  "order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone number"])},
  "order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid cellphone number"])},
  "order.choose_country_code_of_cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country code of cellphone"])},
  "order.enter_keyword_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter keyword(s)"])},
  "order.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "order.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
  "order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "order.please_enter_your_email_address_and_you_will_receive_the_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address, and the system will send you the booking ID"])},
  "order.confirm_e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm E-mail"])},
  "order.please_enter_your_email_address_to_avoid_any_biased_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your valid email address again to make sure data are correct."])},
  "order.different_from_the_email_address_column_please_confirm_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different from the email address column.  Please confirm again!"])},
  "order.emergency_contact_person_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact person profile"])},
  "order.please_enter_the_last_name_of_the_emergency_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the last name of the emergency contact person"])},
  "order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact’s name"])},
  "order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact’s last name"])},
  "order.contact_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact’s smartphone number"])},
  "order.please_enter_the_first_name_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the first name of the emergency contact"])},
  "order.please_enter_the_cell_phone_number_of_the_emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the cell phone number of the emergency contact"])},
  "order.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "order.finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "nation.tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
  "nation.cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
  "nation.hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
  "nation.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macao"])},
  "nation.jp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
  "nation.us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["America"])},
  "nation.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
  "nation.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
  "nation.vn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
  "nation.my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
  "nation.ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippine"])},
  "nation.ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
  "nation.au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
  "nation.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
  "nation.kh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
  "nation.mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar"])},
  "nation.ae": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
  "nation.tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
  "nation.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
  "nation.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
  "nation.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
  "nation.gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
  "nation.vi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.S. Virgin Islands"])},
  "nation.mp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
  "nation.gu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
  "nation.as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
  "nation.pr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
  "nation.bs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
  "nation.bb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
  "nation.ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
  "nation.ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
  "nation.vg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Virgin Islands"])},
  "nation.ky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Islands"])},
  "nation.bm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
  "nation.gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
  "nation.tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos Islands"])},
  "nation.ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongera"])},
  "nation.sx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sint Maarten"])},
  "nation.lc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
  "nation.dm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
  "nation.vc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
  "nation.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
  "nation.tt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
  "nation.kn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
  "nation.jm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
  "nation.eg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
  "nation.ss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
  "nation.ma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
  "nation.dz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
  "nation.tn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
  "nation.ly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
  "nation.gm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
  "nation.sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
  "nation.mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
  "nation.ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marley"])},
  "nation.gn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
  "nation.ci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivory coast"])},
  "nation.bf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
  "nation.ne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
  "nation.tg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
  "nation.bj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
  "nation.mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
  "nation.lr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
  "nation.sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lion Rock"])},
  "nation.gh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
  "nation.ng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
  "nation.td": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
  "nation.cf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Africa"])},
  "nation.cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
  "nation.cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
  "nation.st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome and Principe"])},
  "nation.gq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
  "nation.ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
  "nation.cg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the republic of Congo"])},
  "nation.cd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo Democratic Republic"])},
  "nation.ao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
  "nation.gw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea Bissau"])},
  "nation.io": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
  "nation.ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascension Island"])},
  "nation.sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
  "nation.sd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
  "nation.rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luanda"])},
  "nation.et": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
  "nation.so": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
  "nation.dj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
  "nation.ke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
  "nation.tz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zanzibar"])},
  "nation.ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
  "nation.bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
  "nation.mz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
  "nation.zm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
  "nation.mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
  "nation.re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunion"])},
  "nation.yt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
  "nation.zw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
  "nation.na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
  "nation.mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
  "nation.ls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
  "nation.bw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
  "nation.sz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swadini"])},
  "nation.km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comor"])},
  "nation.za": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
  "nation.sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tristan da Cunha"])},
  "nation.er": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
  "nation.aw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
  "nation.fo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Islands"])},
  "nation.gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
  "nation.gr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
  "nation.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
  "nation.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
  "nation.gi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
  "nation.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
  "nation.lu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
  "nation.ie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
  "nation.is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
  "nation.al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
  "nation.mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maltese"])},
  "nation.cy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
  "nation.fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
  "nation.ax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oran"])},
  "nation.bg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
  "nation.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
  "nation.lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
  "nation.lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
  "nation.ee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
  "nation.md": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
  "nation.am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
  "nation.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
  "nation.ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
  "nation.mc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
  "nation.sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
  "nation.va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican"])},
  "nation.ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
  "nation.rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
  "nation.me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
  "nation.xk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
  "nation.hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
  "nation.si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
  "nation.ba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
  "nation.mk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Macedonia"])},
  "nation.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
  "nation.ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
  "nation.ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
  "nation.cz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
  "nation.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
  "nation.li": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
  "nation.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
  "nation.gg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
  "nation.je": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
  "nation.im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isle of Man"])},
  "nation.dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
  "nation.se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
  "nation.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
  "nation.sj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard and Jan Mayen"])},
  "nation.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
  "nation.fk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands"])},
  "nation.bz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
  "nation.gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
  "nation.sv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvador"])},
  "nation.hn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
  "nation.ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
  "nation.cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
  "nation.pa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
  "nation.pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre and Miquelon"])},
  "nation.ht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
  "nation.pe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
  "nation.mx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
  "nation.cu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
  "nation.ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
  "nation.br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
  "nation.cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
  "nation.co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
  "nation.ve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
  "nation.gp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadeloupe"])},
  "nation.bo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
  "nation.gy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
  "nation.ec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
  "nation.gf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Guiana"])},
  "nation.py": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
  "nation.mq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinique"])},
  "nation.sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
  "nation.uy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
  "nation.cw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gulaso"])},
  "nation.cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocos (Keeling) Islands"])},
  "nation.cx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas island"])},
  "nation.nz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
  "nation.pn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn Islands"])},
  "nation.sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
  "nation.tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East Timor"])},
  "nation.nf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norfolk Island"])},
  "nation.bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
  "nation.nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noru"])},
  "nation.pg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
  "nation.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
  "nation.sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
  "nation.vu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanado"])},
  "nation.fj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
  "nation.pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
  "nation.wf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis and Futuna"])},
  "nation.ck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Island"])},
  "nation.nu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
  "nation.ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
  "nation.ki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giribas"])},
  "nation.nc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
  "nation.tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
  "nation.pf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Polynesia"])},
  "nation.tk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
  "nation.fm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federated States of Micronesia"])},
  "nation.mh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
  "nation.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
  "nation.kz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
  "nation.kp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Korea"])},
  "nation.la": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
  "nation.bd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bengal"])},
  "nation.in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
  "nation.pk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
  "nation.af": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
  "nation.lk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
  "nation.mv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
  "nation.lb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
  "nation.jo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
  "nation.sy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
  "nation.iq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
  "nation.kw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
  "nation.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
  "nation.ye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
  "nation.om": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
  "nation.ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestine"])},
  "nation.il": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
  "nation.bh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
  "nation.qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kada"])},
  "nation.bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
  "nation.mn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
  "nation.np": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
  "nation.ir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
  "nation.tj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajik"])},
  "nation.tm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
  "nation.az": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
  "nation.ge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
  "nation.kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyz"])},
  "nation.uz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbek"])},
  "order.departures_arrivals_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departures / Arrivals"])},
  "order.departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure nation"])},
  "order.please_pick_the_departure_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick the departure nation"])},
  "order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival flight number"])},
  "order.please_enter_your_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your flight number"])},
  "order.arrival_date_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date in Taiwan"])},
  "order.please_pick_your_arrival_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick your arrival date"])},
  "order.arrival_time_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival time in Taiwan"])},
  "order.please_pick_your_arrival_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick your arrival time"])},
  "order.scheduled_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled check-in time"])},
  "order.please_pick_your_check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please pick your check-in time"])},
  "order.estimated_check_in_time_shall_not_be_earlier_than_the_time_arriving_in_taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated check-in time shall not be earlier than the time arriving in Taiwan."])},
  "order.any_visitor_in_need_of_early_check_in_must_pay_the_early_check_in_bill_pursuant_to_the_rules_made_by_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you check in early, an early check-in fee may be charged according to the regulations of each hotel."])},
  "order.health_and_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health and food"])},
  "order.food_habits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food habits"])},
  "order.non_vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-vegan"])},
  "order.vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegan"])},
  "order.any_specific_illness_cardiovascular_or_chronic_disease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any specific illness (cardiovascular or chronic disease)"])},
  "order.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "order.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "order.please_enter_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your specific illness"])},
  "order.any_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any allergic or banned food"])},
  "order.please_do_enter_the_allergic_or_banned_food_if_you_pick_the_plan_with_meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do enter the allergic or banned food if you pick the plan with meals"])},
  "order.please_enter_the_allergic_or_banned_food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the allergic or banned food"])},
  "order.use_the_discount_cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the discount code"])},
  "order.please_enter_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the discount code"])},
  "order.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
  "order.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "order.please_enter_your_specific_need_but_the_hotel_cannot_ensure_provision_of_such_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your special needs.  Yet the hotel does not guarantee to provide the service, and will charge on-site depending on the situations. "])},
  "order.extra_purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra purchase"])},
  "order.the_room_type_capacity_is_2_persons_and_the_hotel_receives_no_extra_person_unless_the_caregiver_is_a_must": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The room accepts up to 2 persons and accepts no extra person unless the care protocol can apply."])},
  "order.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adults"])},
  "order.12_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12+(included)"])},
  "order.child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])},
  "order.0_12_years_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0-12 years old"])},
  "order.night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["night"])},
  "order.the_hotel_receives_the_visitors_after_15_00_and_will_issue_the_early_check_in_bill_for_any_visitor_in_need_of_early_check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hotel receives the visitors after 15:00 and will issue the early-check-in bill for any visitor in need of early check-in."])},
  "order.early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early check-in fee"])},
  "order.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "order.please_enter_the_name_of_your_specific_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name of your specific illness"])},
  "order.please_enter_the_valid_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the valid discount code"])},
  "order.10_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% off coupon"])},
  "order.20_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% off coupon"])},
  "order.30_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% off coupon"])},
  "order.40_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40% off coupon"])},
  "order.50_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50% off coupon"])},
  "order.60_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60% off coupon"])},
  "order.70_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70% off coupon"])},
  "order.80_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80% off coupon"])},
  "order.90_off_roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90% off coupon"])},
  "order.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "order.booking_serial_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
  "order.tax_id_no_for_the_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue an invoice with Unified Business No."])},
  "order.tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID No."])},
  "order.please_enter_the_company_tax_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the company Tax ID No."])},
  "order.company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company title"])},
  "order.please_enter_the_company_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the company title"])},
  "order.payment_manner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment manner"])},
  "order.pay_by_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay by card"])},
  "order.cancellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
  "order.1_any_booking_altered_cancelled_on_3_days_before_the_check_in_date_the_hotel_receives_the_booking_to_be_altered_by_the_visitor_once_only_or_refunds_the_full_deposit_to_the_visitor_if_requested_as_well_with_the_processing_fee_of_nt_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Any booking altered / cancelled on 3 days before the check-in date, the hotel receives the booking to be altered by the visitor once only or refunds the full deposit to the visitor if requested as well (with the processing fee of NT$ 100 )."])},
  "order.2_in_the_event_of_the_booking_to_be_cancelled_on_1_2_days_before_the_check_in_date_the_hotel_will_deduct_70_and_refund_the_rest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. In the event of the booking to be cancelled on 1-2 days before the check-in date, the hotel will deduct 50% and refund the rest."])},
  "order.3_any_booking_to_be_altered_cancelled_on_the_check_in_date_the_hotel_neither_receives_the_booking_to_be_altered_nor_refunds_the_deposit_to_the_visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Any booking to be altered / cancelled on the check-in date, the hotel neither receives the booking to be altered nor refunds the deposit to the visitor."])},
  "order.4_any_contingent_cancellation_after_the_stay_the_hotel_will_deduct_the_room_price_calculated_by_the_actual_lodging_days_from_the_scheduled_lodging_price_and_charge_the_sterilization_cleaning_fee_by_nt_800_1_000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Any contingent cancellation after the stay, the hotel will deduct the room price calculated by the actual lodging days from the scheduled lodging price and charge the sterilization & cleaning fee."])},
  "order.i_have_read_the_cancellation_policy_and_agree_to_the_privacy_terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I have read the ", _interpolate(_named("m")), " and agree to the ", _interpolate(_named("n")), "."])},
  "order.submit_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "order.booking_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking overdue"])},
  "order.sorry_you_fail_to_pay_within_a_given_time_limit_and_the_system_cancels_your_booking_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! You fail to pay within a given time limit and the system cancels your booking. Please book again."])},
  "order.failed_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed submission"])},
  "order.sorry_the_room_type_of_the_hotel_is_fully_booked_please_book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! The room type of the hotel is fully booked. Please book again."])},
  "order.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations for your booking completed!"])},
  "order.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. Failed payment. Please check the booking and confirm the payment again."])},
  "order.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the booking"])},
  "order.my_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My booking"])},
  "order.booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking completed"])},
  "order.your_payment_completed_have_a_nice_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment completed. Have a nice trip."])},
  "order.su": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "order.mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
  "order.tu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We"])},
  "order.we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Th"])},
  "order.th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
  "order.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
  "order.sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "order.booking_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking status"])},
  "order.booking_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking received"])},
  "order.booking_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking cancelled"])},
  "order.booking_received_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking received time"])},
  "order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
  "order.not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
  "order.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "order.payment_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment time"])},
  "order.booking_total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking total price"])},
  "order.invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue a receipt"])},
  "order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt query"])},
  "order.the_hotel_will_issue_the_collection_and_remittance_receipt_after_7_days_from_the_check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hotel will issue the collection and remittance receipt after 7 days from the check-out date."])},
  "order.room_type_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room type info"])},
  "order.total_room_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total room number"])},
  "order.total_person_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total person number"])},
  "order.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " adults"])},
  "order.three_meals_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three meals included"])},
  "order.n_person_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " person(s)"])},
  "order.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "order.hotel_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel address"])},
  "order.hotel_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel phone number"])},
  "order.hotel_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel LINE"])},
  "order.tourbobo_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact tourbobo"])},
  "order.customer_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINE"])},
  "order.payment_failed_your_booking_will_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment not finished yet"])},
  "order.you_are_so_close_to_get_your_booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are so close to get your booking done!"])},
  "order.pay_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
  "order.the_booking_has_been_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking has been cancelled."])},
  "order.what_a_pity_hope_to_see_you_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What a pity! Hope to see you again."])},
  "order.booking_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking done"])},
  "order.sorry_no_booking_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. No booking found."])},
  "order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])},
  "order.please_enter_your_passport_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your passport number"])},
  "order.please_enter_your_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your cellphone number"])},
  "order.check_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check details"])},
  "order.booking_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking details"])},
  "order.last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last "])},
  "order.terms_of_privacy_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Privacy & Services "])},
  "order.please_enter_valid_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid data"])},
  "order.the_room_rice_depends_on_weekdays_or_holidays_check_the_price_per_night_by_pressing_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The room rice depends on weekdays or holidays.Check the price per night by pressing Price."])},
  "order.within_7_days_after_cancellation_a_receipt_of_collection_and_transfer_will_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 7 days after cancellation, a receipt of collection and transfer will be issued. "])},
  "order.to_be_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be checked in"])},
  "order.checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked in"])},
  "order.checked_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked out"])},
  "order.not_checked_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not checked in "])},
  "order.reserved_room_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved room countdown"])},
  "order.non_editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking editing is disabled for not to-be-checked-in."])},
  "order.booking_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No booking is found"])},
  "order.card_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No valid pay by card is found"])},
  "order.transaction_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No such deal is found"])},
  "order.already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pay is done. Please do not pay again. "])},
  "order.accommodation_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property bill"])},
  "order.room_price_add_ons_and_tax_included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room price, add-ons and tax included"])},
  "order.detailed_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed booking"])},
  "order.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
  "order.additional_purchase_item_early_check_in_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional purchase item-Early check-in fee"])},
  "order.additional_purchase_item_extra_guest_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional purchase item-Extra guest fee"])},
  "order.additional_purchase_item_extra_bed_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional purchase item-Extra bed fee"])},
  "order.booking_to_be_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking cancelled "])},
  "order.hotel_operator_to_refund": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation ", _interpolate(_named("d")), " days prior to the desired check-in date (including the check-in date) can ask the hotel operator to refund ", _interpolate(_named("n")), " of the amount paid ."])},
  "order.before_{n}_day_s": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["before ", _interpolate(_named("n")), " day (s)"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_50_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation ", _interpolate(_named("d")), " days prior to the desired check-in date (including the check-in date) shall bear ", _interpolate(_named("n")), " of the amount paid as the handling fee."])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_100_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation on the check-in date or no show shall bear ", _interpolate(_named("n")), " of the amount paid as the handling fee."])},
  "order.the_guest_who_cancels_the_reversation_{n}_days_prior_to_the_desired_check_in_date_including_the_check_in_date_or_no_show_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation ", _interpolate(_named("n")), " days prior to the desired check-in date (including the check-in date) or no show shall bear ", _interpolate(_named("n")), " of the amount paid as the handling fee."])},
  "order.the_guest_who_cancels_the_reversation_at_any_time_or_no_show_on_the_check_in_date_shall_bear_{n}_of_the_amount_paid_as_the_handling_fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation at any time or no show on the check-in date shall bear ", _interpolate(_named("n")), " of the amount paid as the handling fee."])},
  "order.on_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the booked date"])},
  "order.cancellation_on_n_day_s_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancellation on ", _interpolate(_named("n")), " day(s) before the check-in date"])},
  "order.no_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bill to be issued"])},
  "order.the_50_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge 50% of the amount paid."])},
  "order.check_in_date_booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in date"])},
  "order.the_100_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge 100% of the amount paid."])},
  "order.the_cancellation_bill_calculated_by_50_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The guest who cancels the reversation ", _interpolate(_named("n")), " days prior to the desired check-in date (including the check-in date) shall bear 50% of the amount paid as the handling fee."])},
  "order.1_2_days_before_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-2 days before the check-in date"])},
  "order.real_payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount of charge"])},
  "order.cancellation_bill_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation fee"])},
  "order.refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund amount"])},
  "order.cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause of cancellation"])},
  "order.the_prepaid_deposit_will_be_deducted_from_the_credit_card_account_within_21_working_days_for_any_refund_meeting_the_terms_and_conditions_of_refunds_due_to_cancelled_altered_bookings_or_early_check_outs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All refunds that occur owing to cancellation, modified reservation, or early check-out shall be refunded to your credit card account within 21 working days as long as tallying with the refund rules.  Please contact our customer service if you have any doubts.  Any handling fee deriving from adopting a different refund method due to a special cause shall be deducted from the refund amount."])},
  "order.the_room_prices_on_weekdays_and_holidays_differ_please_click_total_price_and_check_the_detailed_room_price_per_night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room rates may vary depending on weekdays or holidays.  Click the total amount of charge to check the price details per night.   "])},
  "order.date_room_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " Room price"])},
  "order.please_enter_a_cause_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a cause"])},
  "order.upon_submission_of_the_booking_with_or_without_completion_of_the_prepaid_deposit_this_bargain_code_cannot_be_used_again_and_no_request_of_bargains_in_any_way_will_be_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon submission of the booking with or without completion of the prepaid deposit, this bargain code cannot be used again and no request of bargains in any way will be accepted."])},
  "order.my_schedule_is_delayed_or_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My schedule is delayed or cancelled."])},
  "order.cheaper_prices_are_found_on_the_internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheaper prices are found on the internet."])},
  "order.the_inn_is_not_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inn is not accessible."])},
  "order.force_majeure_e_g_natural_disasters_and_pandemics_illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force majeure e.g. natural disasters and pandemics / illness"])},
  "order.i_d_like_to_book_the_room_from_this_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’d like to book the room from this inn."])},
  "order.i_d_like_to_book_a_new_inn_on_this_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’d like to book a new inn on this website."])},
  "order.the_offers_from_other_tour_agencies_are_cheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offers from other tour agencies are cheaper."])},
  "order.i_am_unhappy_with_the_payment_rules_for_the_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am unhappy with the payment rules  for the deposit."])},
  "order.due_to_personal_health_factors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to personal health factors"])},
  "order.changes_in_the_guest_number_or_needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes in the guest number or needs"])},
  "order.others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "order.please_enter_the_cause_of_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the cause of cancellation"])},
  "order.keep_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the booking"])},
  "order.please_enter_or_pick_a_nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter or pick nation"])},
  "order.a_visitor_having_cancelled_the_booking_not_within_3_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visitor having cancelled the booking not within 3 days prior to the booked check-in date has the discretion to request the business owner to refund the whole paid deposit. "])},
  "order.a_visitor_having_cancelled_the_booking_within_1_2_days_prior_to_the_booked_check_in_date_has_the_discretion_to_request_the_business_owner_to_refund_half_of_the_whole_paid_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visitor having cancelled the booking within 1-2 days prior to the booked check-in date has the discretion to request the business owner to refund half of the whole paid deposit."])},
  "order.a_visitor_having_cancelled_the_booking_on_the_booked_check_in_date_or_failing_to_stay_in_the_booked_room_shall_pay_the_whole_paid_deposit_to_the_business_owner_as_the_cancellation_bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visitor having cancelled the booking on the booked check-in date or failing to stay in the booked room shall pay the whole paid deposit to the business owner as the cancellation bill. "])},
  "cancelled_policy.loose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loose"])},
  "cancelled_policy.normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "cancelled_policy.strict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strict"])},
  "cancelled_policy.non": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-refundable"])},
  "order.the_percent_room_price_bill_to_be_issued": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("n")), "% room price bill to be issued"])},
  "order.the_visitor_has_the_discretion_to_request_the_business_owner_refund_the_prepaid_percent_room_price_deposit_if_the_booking_is_cancelled_on_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The visitor has the discretion to request the business owner refund the prepaid ", _interpolate(_named("n")), "% room price deposit if the booking is cancelled on ", _interpolate(_named("date")), " days before the booked date."])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_n_days_before_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order.the_cancellation_bill_calculated_by_percent_of_the_non_bargain_booked_room_price_will_be_issued_to_the_booking_to_be_cancelled_on_the_booked_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order.contact_customer_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Customer Service"])},
  "order.alert_not_upcoming_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a prospective guest, thus unable to cancel the booking."])},
  "order.alert_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking has been cancelled, thus cannot be cancelled again."])},
  "order.alert_is_epidemic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belongs to a quarantine booking, and cannot be cancelled."])},
  "order.alert_refunded_type_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund cash flow does not exist."])},
  "order.alert_expired_refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking has exceeded the refund time."])},
  "order.refund_has_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund has been requested."])},
  "order.check_the_accommodation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the accommodation rules."])},
  "order.send_an_email_to_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an email to the hotel."])},
  "order.book_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book again"])},
  "order.sorry_no_booking_data_have_been_found_please_confirm_the_email_address_and_booking_id_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no booking data have been found.  Please confirm the email address and booking ID again!  "])},
  "order.after_submitting_the_booking_the_preferential_code_is_not_available_whether_completing_check_out_settling_the_bill_or_not_and_no_discount_can_be_requested_in_any_other_ways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After submitting the booking, the preferential code is not available whether completing check-out/ settling the bill or not, and no discount can be requested in any other ways.  "])},
  "order.cancellation_policy_for_this_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation policy for this booking"])},
  "order.special_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special situation"])},
  "order.after_check_in_if_a_guest_cancels_the_reservation_for_subsequent_days_not_staying_in_owing_to_an_emergency_we_will_deduct_the_amount_of_money_already_staying_in_and_charge_the_cleaning_fee_and_cost_of_disinfection_additionally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After check-in, if a guest cancels the reservation for subsequent days not staying in owing to an emergency, we will deduct the amount of money already staying in, and charge the cleaning fee and cost of disinfection additionally."])},
  "order.credit_card_refund_chargeback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card refund/ chargeback"])},
  "order.invoice_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice/ Receipt"])},
  "order.a_hotel_operator_can_issue_an_invoice_or_reciept_for_the_booking_that_is_paid_at_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hotel operator can issue an invoice or reciept for the booking that is paid at hotel.  "])},
  "order.if_your_booking_belongs_to_the_pre_paid_booking_fully_paid_to_tourbobo_we_will_issue_a_reciept_of_travel_agency_and_you_can_go_to_the_booking_content_to_check_in_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your booking belongs to the pre-paid booking fully paid to Tourbobo, we will issue a reciept of travel agency, and you can go to the booking content to check in 7 days after check-out."])},
  "order.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])},
  "order.please_read_policies_and_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read policies and terms."])},
  "order.tick_the_box_of_i_have_carefully_read_the_cancellation_refund_policy_and_agreed_the_terms_of_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tick the box of \"I have carefully read the Cancellation Refund Policy, and agreed the terms of  Privacy Policy.\""])},
  "order.leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])},
  "order.stay_at_the_current_sreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay at the current sreen"])},
  "order.are_you_sure_you_want_to_leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave?"])},
  "order.the_booking_will_be_cancelled_automatically_after_leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking will be cancelled automatically after leaving.  "])},
  "order.the_booking_has_been_established_please_remember_the_booking_number_so_as_to_check_the_booking_content_the_booking_will_be_cancelled_automatically_if_not_paid_within_the_time_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The booking has been established.  Please remember the booking number so as to check the booking content.  The booking will be cancelled automatically if not paid within the time-limit."])},
  "order.failed_to_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to cancel"])},
  "order.whoops_a_technical_problem_and_cannot_cancel_the_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops!  A technical problem, and cannot cancel the reservation.  "])},
  "order.open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Google map"])},
  "order.failed_to_open_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open Google map"])},
  "order.open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Apple map"])},
  "order.failed_to_open_apple_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open Apple map"])},
  "order.succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeed"])},
  "order.cancellation_refund_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Refund Policy"])},
  "order.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "order.the_address_has_been_copied_to_the_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address has been copied to the clipboard"])},
  "order.rules_of_accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules of Accommodation"])},
  "order.please_choose_the_reason_for_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose the reason for cancellation"])},
  "order.please_enter_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address"])},
  "order.please_enter_your_booking_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your booking ID"])},
  "order.query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query"])},
  "order.please_choose_the_departure_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose the departure country"])},
  "order.failed_to_dial_the_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to dial the telephone"])},
  "order.failed_to_open_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open Facebook"])},
  "order.failed_to_open_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to open Line"])},
  "order.failed_to_send_an_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send an email"])},
  "order.national_travel.check_in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time of Check-in"])},
  "order.national_travel.allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National travel subsidy"])},
  "order.national_travel.once_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A subsidy is available for one booking only, and can not be combined with other preferential treatment for you to use"])},
  "order.national_travel.no_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not use it"])},
  "order.national_travel.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the subsidy(On-site discount)"])},
  "order.national_travel.three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully vaccinated and with a booster (three doses in total) ?"])},
  "order.national_travel.no_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. (less than three doses)"])},
  "order.national_travel.had_three_shots_of_vaccine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. (three doses)"])},
  "order.national_travel.agree_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客需同意「 免責聲明 」，才可使用悠遊國旅補助。入住當天，現場查核資格符合，旅宿將於現場折抵補助金額。如資格不符或政府預算已用完，旅客須支付全額費用。"])},
  "order.national_travel.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免責聲明"])},
  "order.national_travel.agree_for_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已確認身份資格，可使用悠遊國旅補助，且同意免責聲明。"])},
  "order.national_travel.understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])},
  "order.credit_card.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of cardholder"])},
  "order.credit_card.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card number"])},
  "order.credit_card.validity_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date"])},
  "order.credit_card.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter name of cardholder"])},
  "order.credit_card.validity_period.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ＭＭ/YY"])},
  "order.pay_by_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay upon Check-in "])},
  "order.pay_by_arrival_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The platform will not charge money from your credit card, and it is merely for the usage of pre-authorization.  Cancellation to the reservation or failure to arrive will incur fees according to the hotel's Cancellation & Refund Policy.  "])},
  "order.pay_can_use_each_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to use any payment card："])},
  "order.pay_by_arrival_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No prepayment needed-You will make the payment when you check in."])},
  "order.type_of_charge_and_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of payment"])},
  "order.platform_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform payment"])},
  "order.excuse_me_your_credit_card_can_not_be_verified_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuse me!  Your credit card can not be verified .  Please try again!"])},
  "order.please_tick_and_agree_the_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tick and agree the Disclaimer!  "])},
  "order.please_enter_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid credit card number"])},
  "order.please_enter_valid_expiry_date_of_your_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid expiry date of your credit card"])},
  "order.partial_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial payment"])},
  "validation.cnn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credit card number"])},
  "order.enter_your_id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your ID number"])},
  "order.invoice_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice issuance"])},
  "order.if_full_payment_is_made_for_a_prepaid_stay_through_tourbobo_a_receipt_for_payment_confirmation_issued_by_tourbobo_will_be_available_in_your_booking_details_within_7_days_after_check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If full payment is made for a prepaid stay through tourbobo, a receipt for payment confirmation issued by tourbobo will be available in your booking details within 7 days after check-out."])},
  "order.in_case_of_any_unexpected_situation_occurs_and_result_in_early_checkout_the_unused_reserved_nights_will_be_refunded_an_additional_cleaning_fee_will_be_charged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of any unexpected situation occurs and result in early checkout , the unused reserved nights will be refunded. An additional cleaning fee will be charged. "])},
  "order.send_a_mail_to_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a mail to hotel"])},
  "order.looking_forward_to_your_next_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking forward to your next booking!"])},
  "order.refunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund request in progress"])},
  "order.id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID number"])},
  "order.designated_credit_card_number_first_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designated credit card number (first 8 digits)"])},
  "order.this_is_to_verify_the_cardholder_s_identity_and_no_credit_card_payment_will_be_processed_now_you_may_use_the_discount_code_upon_successful_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is to verify the cardholder's identity, and no credit card payment will be processed now. You may use the discount code upon successful verification."])},
  "order.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
  "order.cardholder_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder verification"])},
  "order.please_enter_a_valid_credit_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid credit card number"])},
  "order.unlock_exclusive_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock exclusive Offers"])},
  "order.view_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View offers"])},
  "order.view_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view now"])},
  "order.exclusive_discount_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive discount offers"])},
  "order.offer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Details"])},
  "order.get_your_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your offers"])},
  "order.offer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer description"])},
  "order.validity_span": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity span"])},
  "order.discount_eligibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Eligibility "])},
  "order.how_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
  "order.other_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other conditions"])},
  "news.prevention_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevention news"])},
  "news.hot_topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot topics"])},
  "loading.loading_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Loading…Please wait."])},
  "loading.reloading_to_show_the_latest_discount_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reloading to show the latest discount prices…"])},
  "loading.error_non_displayable_try_reloading_the_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error. Non-displayable. Try reloading the page."])},
  "loading.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "loading.reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "loading.congratulations_for_your_booking_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations for your booking completed!"])},
  "loading.check_the_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the booking"])},
  "loading.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. Failed payment. Please check the booking and confirm the payment again."])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_search_criteria_please_reset_the_search_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no available properties fit your search. Please enter new terms and search again."])},
  "loading.search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search again"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no available properties fit your filter terms. Please reset filter terms and search again."])},
  "loading.clear_filter_search_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset the filter & search again"])},
  "loading.sorry_we_don_t_have_available_rooms_fit_your_filter_criteria_please_reset_the_filter_criteria_to_show_all_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no available room fit your filter terms. Please reset filter terms for more available rooms."])},
  "loading.clear_filter_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset the filter & display all"])},
  "user-homepage.order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My booking"])},
  "user-homepage.please_enter_order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter order number"])},
  "user-homepage.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "user-homepage.please_select_a_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a language"])},
  "user-homepage.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "user-homepage.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "user-homepage.forget_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget the password?"])},
  "user-homepage.my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My password"])},
  "user-homepage.login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Facebook"])},
  "user-homepage.login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
  "user-homepage.member_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "user-homepage.account_not_found_please_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not found; please register."])},
  "user-homepage.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "user-homepage.sign_in_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Facebook"])},
  "user-homepage.sign_in_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
  "user-homepage.account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account password"])},
  "user-homepage.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "user-homepage.please_enter_your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name"])},
  "user-order.e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "user-order.please_enter_your_e-mail_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your e-mail address"])},
  "user-homepage.enter_your_valid_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your valid email address"])},
  "user-homepage.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "user-homepage.confirm_the_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the password"])},
  "user-homepage.create_password_6_to_30_characters_long_including_letters_numbers_and_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create password: 6 to 30 characters long, including letters, numbers, and symbols."])},
  "user-homepage.the_two_passwords_you_entered_are_inconsistent_please_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The two passwords you entered are inconsistent.  Please try again"])},
  "user-homepage.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "user-homepage.i_understand_and_agree_to_the_terms_of_service_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and agree to the terms of service and privacy policy."])},
  "user-homepage.login_to_review_more_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to review more bookings"])},
  "user-homepage.go_to_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Reservation"])},
  "user-homepage.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "user-detail.check_in_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in date"])},
  "user-detail.check_out_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out date"])},
  "user-detail.choose_the_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the room type"])},
  "user-detail.guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
  "user-detail.adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
  "user-detail.children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
  "user-detail.n_adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " adult"])},
  "user-detail.n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " children"])},
  "user-detail.n_rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " room"])},
  "user-detail.about_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the hotel"])},
  "user-detail.basic_capacity_n": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Basic number of guest(s): ", _interpolate(_named("n"))])},
  "user-detail.bed_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed type"])},
  "user-detail.select_the_age_of_the_child_no_additional_charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the age of the child; no additional charge"])},
  "user-detail.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in time"])},
  "user-detail.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out time"])},
  "user-detail.booking_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking notices"])},
  "user-detail.amenities_and_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities and services"])},
  "user-detail.hotel_notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel notices"])},
  "user-detail.cancellation_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation rules"])},
  "user-detail.booking_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking list"])},
  "user-detail.number_of_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
  "user-detail.n_adults_n_children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " adults，", _interpolate(_named("n")), " children"])},
  "user-detail.no_current_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current reservation"])},
  "user-detail.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "user-detail.add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to list"])},
  "user-detail.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "user-detail.no_room_is_available_for_dates_requested_please_change_the_dates_to_continue_booking_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No room is available for the dates requested; please change the dates to continue booking. Thank you"])},
  "user-detail.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "user-order.booking_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking info"])},
  "user-order.total_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total room"])},
  "user-order.total_guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total guests"])},
  "user-order.bed_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed size"])},
  "user-order.add-on_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-on items"])},
  "user-order.subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "user-order.add-on_parking_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-on parking reservation"])},
  "user-order.please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
  "user-order.domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domestic travel subsidy"])},
  "user-order.no_use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "user-order.use_domestic_travel_subsidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "user-order.use_the_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the discount code"])},
  "user-order.please_enter_your_promo_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your promo code"])},
  "user-order.discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "user-order.please_fill_in_ID_numbers_for_domestic_travellers_and_passport_numbers_for_foreign_travellers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in ID numbers for domestic travellers and passport numbers for foreign travellers."])},
  "user-order.the_system_will_send_the_booking_confirmation_and_contact_you_via_the_email_address_and_mobile_number_provided_please_ensure_the_information_is_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system will send the booking confirmation and contact you via the email address and mobile number provided. Please ensure the information is correct."])},
  "user-order.check_the_spam_mailbox_if_you_do_not_receive_the_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the spam mailbox if you do not receive the confirmation."])},
  "user-order.guest_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest information"])},
  "user-order.last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "user-order.please_enter_your_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your last name"])},
  "user-order.first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "user-eorder.please_enter_your_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your first name"])},
  "user-order.gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "user-order.male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "user-order.female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "user-order.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "user-order.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "user-order.please_enter_your_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your nationality"])},
  "user-order.id_card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card No."])},
  "user-order.passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])},
  "user-order.please_enter_your_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your ID No."])},
  "user-order.enter_valid_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid ID Card No. "])},
  "user-order.enter_your_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Passport No."])},
  "user-order.enter_valid_passport_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid Passport No."])},
  "user-order.id_card_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID card format (A012345678)"])},
  "user-order.passport_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport format (9 digits)"])},
  "user-order.mobile_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone number"])},
  "user-order.enter_valid_cellphone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid cellphone number"])},
  "user-order.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed！"])},
  "user-order.required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "user-order.date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "user-order.gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI number"])},
  "user-order.please_enter_gui_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter GUI number"])},
  "user-order.gui_composed_of_8_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI composed of 8 digits"])},
  "user-order.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "user-order.please_enter_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter company name"])},
  "user-order.limited_255_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited 255 characters"])},
  "user-order.estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time of arrival"])},
  "user-order.please_enter_estimated_time_of_arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter estimated time of arrival"])},
  "user-order.country_of_departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of departure"])},
  "user-order.elligibility_for_entry_to_taiwain_native_citizens_foreign_nationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elligibility for entry to Taiwan (Native citizens / Foreign nationals)"])},
  "user-order.special_medical_conditions_such_as_cardiovascular_or_chronic_diseases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special medical conditions such as cardiovascular or chronic diseases"])},
  "user-order.arrival_flight_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival flight number"])},
  "user-order.special_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special requests"])},
  "user-order.please_note_that_the_hotel_does_not_guarantee_to_meet_your_needs_and_will_be_subject_to_on-site_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the hotel does not guarantee to meet your needs and will be subject to on-site conditions."])},
  "user-order.emergency_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact information"])},
  "user-order.contact_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "user-order.please_enter_the_last_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the last name of the contact person"])},
  "user-order.contact_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "user-order.please_enter_the_first_name_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the first name of the contact person"])},
  "user-order.contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact number"])},
  "user-order.please_enter_the_phone_number_of_the_contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the phone number of the contact person"])},
  "user-order.return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "user-order.confirm_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm order"])},
  "user-order.order_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order completed"])},
  "user-order.order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "user-order.payment_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
  "user-order.payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
  "user-order.select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method"])},
  "user-order.please_provide_a_valid_unified_business_number_if_an_invoice_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid unified business number if an invoice is required."])},
  "user-order.if_paid_by_the_unionpay_card_the_above_payment_success_page_is_only_the_reply_message_from_the_card_issuing_bank_please_refer_to_the_notification_from_the_property_you_booked_to_ensure_the_completion_of_your_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If paid by the UnionPay card, the above payment success page is only the reply message from the card issuing bank. Please refer to the notification from the property you booked to ensure the completion of your transaction."])},
  "user-order.cancellation_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation instruction"])},
  "user-order.i_have_read_and_agree_to_the _terms_and_conditions_of_the_cacellation_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree to the terms and conditions of the cancellation policy."])},
  "user-order.proceed_to_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to payment"])},
  "user-order.payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])},
  "user-order.note_full_payment_is_due_on_the_check-in_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Full payment is due on the check-in day."])},
  "user-order.thank_you_for_your_reservation_the_system_will_send_you_a_confirmation_for_the_reservation_to_your_email_address_please_should_there_be_any_questions_thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your reservation. The system will send you a confirmation for the reservation to your email address. Please contact us should there be any questions. Thank you."])},
  "user-homepage.terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "user-homepage.privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "user-unit.room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
  "user-unit.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
  "user-unit.NTD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTD"])},
  "user-unit.stick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stick"])},
  "user-unit.piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piece"])},
  "user-unit.portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portion"])},
  "user-unit.tube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tube"])},
  "user-unit.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "user-unit.bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed"])},
  "user-unit.pillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pillow"])},
  "user-unit.blanket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanket"])},
  "user-unit.toiletries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiletries"])},
  "user-unit.towel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towel"])},
  "user-unit.pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet"])},
  "user-date.january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "user-date.fabruary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "user-date.march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "user-date.april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "user-date.may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "user-date.june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "user-date.july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "user-date.august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "user-date.september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "user-date.october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "user-date.november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "user-date.december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "user-date.sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat."])},
  "user-date.sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun."])},
  "user-date.mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon."])},
  "user-date.tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue."])},
  "user-date.wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed."])},
  "user-date.thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu."])},
  "user-date.fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri."])},
  "user-nav.make_a_reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a reservation"])},
  "user-nav.reservation_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Center"])},
  "user-nav.personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
  "user-nav.chang_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "user-nav.my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
  "user-nav.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "user-nav.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "user-nav.arriving_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriving soon"])},
  "user-order.check_into_the_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check into the hotel"])},
  "user-order.check-in_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in time"])},
  "user-order.check-out_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out time"])},
  "user-order.order_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order price"])},
  "user-order.order_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
  "user-order.payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
  "user-order.pay_in_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay in full"])},
  "user-order.pay_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay deposit"])},
  "user-order.invoice_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
  "user-order.invoice_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice query"])},
  "user-order.processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "user-dialog.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "user-dialog.please_contact_us_should_there_be_any_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us should there be any questions"])},
  "user-dialog.consumer_sevice_hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer service hotline"])},
  "user-dialog.customer_service_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service email"])},
  "user-dialog.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "user-homepage.invitation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code"])},
  "user-homepage.invitation_code_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the correct referral code"])},
  "hotel-group.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "hotel-group.north": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North"])},
  "hotel-group.ila": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yilan"])},
  "hotel-group.tyn_hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taoyuan Hsinchu"])},
  "hotel-group.center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
  "hotel-group.center1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center 1"])},
  "hotel-group.center2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center 2"])},
  "hotel-group.south": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South"])},
  "hotel-group.foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign"])},
  "userInfo.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}